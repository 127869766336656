import { useSnackbar } from "notistack";
import { useCallback, useContext, useRef } from "react";
import AssistantContext from "../AssistantContext";
import { useFormikContext } from "formik";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { TextField } from "@mui/material";
import { AssistantBuilderFields, type IAssistantBuilder } from "../types";
import { patchAssistant } from "api/assistant/assistant";

const goal_label = "Goal";
const goal_placeholder =
  "E.g. Assist customers with tasks like flight bookings, cancellations, and providing real-time flight status updates, ensuring a smooth and efficient customer experience.";
const goal_helper_text =
  "Defines the primary tasks the bot will perform, ensuring clarity on its main functions and responsibilities.";
const company_info_label = "Company info";
const company_info_placeholder =
  "E.g. TravelEase specialises in online flight bookings, providing customers with seamless services like flight reservations, cancellations, and travel support. Located in New York, we are committed to making travel easy and accessible for all.";

const AssistantDetails: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext;

  const { values, handleBlur, handleChange } =
    useFormikContext<IAssistantBuilder>();

  const currentGoalScope = useRef<string>(values.goal);
  const currentCompanyInfoTextScope = useRef<string>(values.goal);

  const handleOnBlurGoal = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);

      if (!values.goal || values.goal === currentGoalScope.current) {
        return;
      }

      try {
        await patchAssistant(assistantId, {
          general_info: { goal: values.goal },
        });

        currentGoalScope.current = values.goal;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      }
    },
    [values.goal]
  );

  const handleOnBlurCompanyInfoText = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);

      if (
        !values.company_info_text ||
        values.company_info_text === currentCompanyInfoTextScope.current
      ) {
        return;
      }

      try {
        await patchAssistant(assistantId, {
          general_info: { company_info_text: values.company_info_text },
        });

        currentCompanyInfoTextScope.current = values.company_info_text;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      }
    },
    [values.company_info_text]
  );

  return (
    <div className="flex flex-col row-gap-24">
      <div className="flex-col row-gap-8">
        <Typography
          variant={TypographyVariants.textLarge}
          weight={TypographyWeights.bold}
        >
          {goal_label}
        </Typography>
        <TextField
          name={AssistantBuilderFields.goal}
          variant="outlined"
          size="small"
          placeholder={goal_placeholder}
          fullWidth
          value={values.goal}
          onChange={handleChange}
          onBlur={(event) => {
            void handleOnBlurGoal(event);
          }}
          minRows={3}
          maxRows={10}
          multiline
        />
        <Typography
          variant={TypographyVariants.textSmall}
          weight={TypographyWeights.medium}
          color={TypographyColors.subtle}
        >
          {goal_helper_text}
        </Typography>
      </div>
      <div className="flex-col row-gap-8">
        <Typography weight={TypographyWeights.semiBold}>
          {company_info_label}
        </Typography>
        <TextField
          name={AssistantBuilderFields.company_info_text}
          variant="outlined"
          size="small"
          placeholder={company_info_placeholder}
          fullWidth
          value={values.company_info_text}
          onChange={handleChange}
          onBlur={(event) => {
            void handleOnBlurCompanyInfoText(event);
          }}
          minRows={3}
          maxRows={10}
          multiline
        />
      </div>
    </div>
  );
};

export default AssistantDetails;
