import React, { useCallback } from "react";
import PageHeader from "components/shared/PageHeader";
import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Outlet, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  "faq-suggestions-page": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      alignItems: "flex-start",
    },

    "& .faq-responsive-container": {
      minWidth: "1364px",
      maxWidth: "1376px",
      overflow: "auto",

      "& .faq-suggestions-container": {
        padding: "0px 24px 16px",

        "& .faq-list-container": {
          padding: "32px 0px 16px",
          width: "100%",
          height: "calc(100vh - 160px)",
          overflow: "auto",
        },
      },
    },
  },
}));

const FAQSuggestions: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      switch (newValue) {
        case 0:
          navigate("./recommendations");
          setTab(0);
          break;
        case 1:
          navigate("./shortlisted");
          setTab(1);
          break;
        case 2:
          navigate("./rejected");
          setTab(2);
          break;
      }
    },
    []
  );

  return (
    <div className={classes["faq-suggestions-page"]}>
      <div className="faq-responsive-container">
        <PageHeader
          title={"FAQs Suggestion"}
          subtitle={
            "Enrich your help center with frequently asked questions by customers"
          }
        />

        <div className={"faq-suggestions-container"}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Recommendations" />
            <Tab disabled label="Shortlisted" />
            <Tab disabled label="Rejected" />
          </Tabs>

          <div className="faq-list-container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSuggestions;
