import { getBotId, getTenantName } from "utils/helpers";
import { botBEaxiosInstance } from ".";
import type BotSettings from "types/BotSettings";
import { type PostBotSettings } from "types/BotSettings";

export const getBotSettings = async (token: string): Promise<BotSettings> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "GET",
      url: `/bot/${getBotId()}/settings`,
      withCredentials: true,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postBotSettings = async (
  values: PostBotSettings,
  token: string
): Promise<BotSettings> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "PUT",
      url: `/bot/${getBotId()}/settings`,
      data: values,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
