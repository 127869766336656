import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    padding: "16px 24px",
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="textXl" className="w-bold" sx={{ color: "#282624" }}>
        Chatbot conversation analysis
      </Typography>
      <Typography variant="textSm" sx={{ color: "#6D6D6D" }}>
        Find opportunities to improve the chatbot experience and higher
        successful resolution
      </Typography>
    </Box>
  );
};

export default Header;
