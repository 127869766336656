import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { ReactComponent as ArrowLeft } from "assets/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    minWidth: "1368px",
    height: "56px",
    padding: "8px 32px",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
  },
}));

interface Props {
  children?: React.ReactNode;
  title: string;
}

const Header: React.FC<Props> = ({ children, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const { botId } = params || {};

  // const { runDagreLayout } = useLayout();

  return (
    <Box className={classes.container}>
      <div className="flex justify-content-center align-items-center col-gap-24">
        <ArrowLeft
          className="cursor-pointer"
          onClick={() => {
            navigate(`/bot/chat/${botId}/workflows`);
          }}
        />
        <Typography
          variant="textXl"
          className="w-bold"
          sx={{ color: "#282624" }}
        >
          {title}
        </Typography>

        {/* <Button
          color={"secondary"}
          className="col-gap-8"
          onClick={() => {
            runDagreLayout();
          }}
        >
          Dagre Layout
        </Button> */}
      </div>

      <div className="page-action">{children}</div>
    </Box>
  );
};

export default Header;
