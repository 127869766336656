import { useContext, useEffect, useState } from "react";
import { useAssistantAgentsStyles as useStyles } from "./styles";
import AssistantContext from "../AssistantContext";
import { type TableStateProps } from "aether/TableState";
import Table, { type TableColumnProps } from "aether/Table";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
import Chip from "aether/Chip";
import DeleteAgentModal from "./DeleteAgentModal";
import { getAllAgents } from "api/assistant/agents";
import IconButton from "aether/IconButton";
import useRouteNavigator from "hooks/useRouteNavigator";
import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";

interface AssistantsData {
  icon: React.ReactNode;
  name: string;
  description: string;
  editedOn: string;
  status: string;
  actions: string;
}

interface Props {
  onClickItem: () => void;
}

const AssistantAgents: React.FC<Props> = ({ onClickItem }) => {
  const classes = useStyles();
  const { gotoAgentBuilder } = useRouteNavigator();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext;

  const [tableData, setTableData] = useState<AssistantsData[]>([]);
  const [tableState, setTableState] = useState<TableStateProps>({
    loading: false,
    empty: false,
    error: false,
  });

  const handleOnSuccessDeleteRowCb = (actionId: string): void => {
    const updatedActions = tableData.filter(
      (action: any) => action._id !== actionId
    );
    setTableData(updatedActions);
  };

  const handleGotoAgentBuilder = (event: any, agentId: string): void => {
    gotoAgentBuilder(assistantId, agentId);
  };

  const AgentsTable = Table<AssistantsData>();
  const agentsColumns: Array<TableColumnProps<AssistantsData>> = [
    {
      id: "name",
      label: "Agent Name",
      width: "40%",
      render: (row: any) => (
        <div className={"agent-name-container"}>
          <div className="flex flex-col">
            <Typography
              className="agent-name"
              weight={TypographyWeights.semiBold}
              onClick={(event) => {
                handleGotoAgentBuilder(event, row._id);
              }}
            >
              {row.name}
            </Typography>
            <Typography variant={TypographyVariants.textSmall}>
              {row.description}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      id: "editedOn",
      label: "Last edited",
      width: "20%",
      render: (row: any) => (
        <Typography>
          {formatToHumanStringFromDateTime(
            new Date(row?.modified),
            "MMM d, yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      width: "20%",
      render: (row: any) => (
        <Chip label={"Active"} color="success" size="large" />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "20%",
      align: "right",
      render: (row: any) => (
        <div className={"row-action-container"}>
          <DeleteAgentModal
            agentId={row._id}
            onSuccessDeleteCb={handleOnSuccessDeleteRowCb}
          />
          <IconButton
            size="small"
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleGotoAgentBuilder(event, row._id);
            }}
          >
            <ArrowRight />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTableState((state) => ({ ...state, loading: true }));
    getAllAgents(assistantId)
      .then((response) => {
        if (response?.length) {
          setTableData(response);
        } else {
          setTableState((state) => ({ ...state, empty: true }));
        }
      })
      .catch((error) => {
        console.log(error);
        setTableState((state) => ({
          ...state,
          error: true,
        }));
      })
      .finally(() => {
        setTableState((state) => ({ ...state, loading: false }));
      });
  }, []);

  return (
    <div className={classes.assistantAgentsContainer}>
      <AgentsTable
        columns={agentsColumns}
        data={tableData}
        loading={tableState.loading}
        empty={tableState.empty}
        emptyMessage="No skills here yet. Add your first one!"
        addItemButton={{
          label: "Add a new agent",
          onClickItem: () => {
            onClickItem();
          },
        }}
        error={tableState.error}
      />
    </div>
  );
};

export default AssistantAgents;
