import Chip from "aether/Chip";
// import IconButton from "aether/IconButton";
import Table, { type TableColumnProps } from "aether/Table";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { getToolList } from "api/bot/bot-agents";
import { useContext, useEffect, useState } from "react";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
import AgentBuilderContext from "../AgentBuilderContext";
import ActionIcon from "./ActionIcon";
import { useActionsStyles as useStyles } from "./style";
import DeleteActionModal from "./DeleteActionModel";

interface TableStateProps {
  loading: boolean;
  empty: boolean;
  error: boolean;
}

interface ActionsData {
  icon: React.ReactNode;
  name: string;
  description: string;
  editedOn: string;
  status: string;
  actions: string;
}

const Actions: React.FC = () => {
  const classes = useStyles();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId } = agentBuilderContext;

  const [tableData, setTableData] = useState<any>([]);
  const [tableState, setTableState] = useState<TableStateProps>({
    loading: false,
    empty: false,
    error: false,
  });

  const handleOnSuccessDeleteRowCb = (actionId: string): void => {
    const updatedActions = tableData.filter(
      (action: any) => action._id !== actionId
    );
    setTableData(updatedActions);
  };

  const ActionsTable = Table<ActionsData>();
  const actionsColumns: Array<TableColumnProps<ActionsData>> = [
    {
      id: "name",
      label: "Actions Name",
      width: "40%",
      render: (row: any) => (
        <div className={"action-name-container"}>
          <ActionIcon
            actionType={row.action_type}
            icon={row?.config?.action_config?.connector?.icon}
          />

          <div className="flex flex-col">
            <Typography
              className="action-name"
              weight={TypographyWeights.semiBold}
            >
              {row.name}
            </Typography>
            <Typography variant={TypographyVariants.textSmall}>
              {row.description}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      id: "editedOn",
      label: "Last edited",
      width: "20%",
      render: (row: any) => (
        <Typography>
          {formatToHumanStringFromDateTime(
            new Date(row?.modified),
            "MMM d, yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      width: "20%",
      render: (row: any) => (
        <Chip label={"Active"} color="success" size="large" />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "20%",
      align: "right",
      render: (row: any) => (
        <div className={"row-action-container"}>
          <DeleteActionModal
            actionId={row._id}
            onSuccessDeleteCb={handleOnSuccessDeleteRowCb}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTableState((state) => ({ ...state, loading: true }));
    getToolList(agentId)
      .then((response) => {
        if (response?.length) {
          setTableData(response);
        } else {
          setTableState((state) => ({ ...state, empty: true }));
        }
      })
      .catch((error) => {
        console.log(error);
        setTableState((state) => ({
          ...state,
          error: true,
        }));
      })
      .finally(() => {
        setTableState((state) => ({ ...state, loading: false }));
      });
  }, []);

  return (
    <div className={classes.actionsContainer}>
      <ActionsTable
        columns={actionsColumns}
        data={tableData}
        loading={tableState.loading}
        empty={tableState.empty}
        error={tableState.error}
      />
    </div>
  );
};

export default Actions;
