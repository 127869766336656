import { type IAssistant } from "types/assistant.types";
import AssistantCard from "./AssistantCard";
import { useAssistantListStyles as useStyles } from "./styles";

interface Props {
  data: IAssistant[];
  nextUrl: string;
  setLastElement: any;
  onUpdateAssistant: (updatedAssistant: IAssistant) => void;
  onRemoveAssistant: (removeAssistant: IAssistant) => void;
}

const AssistantList: React.FC<Props> = ({
  data,
  nextUrl,
  setLastElement,
  onUpdateAssistant,
  onRemoveAssistant,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.assistantListContainer}>
      {data.map((assistant, index) => {
        return (
          <div key={index} className={classes.assistantCardWrapper}>
            <AssistantCard
              key={assistant._id}
              data={assistant}
              setRef={
                index === data?.length - 1 && nextUrl ? setLastElement : null
              }
              onUpdateAssistant={onUpdateAssistant}
              onRemoveAssistant={onRemoveAssistant}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AssistantList;
