import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "16px",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    borderRadius: "8px",
    border: "1px solid #E1DEDA",
    backgroundColor: "#fff",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  },
  selected: {
    borderColor: "#F07400",
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
    padding: "12px",
    borderRadius: "50%",
    border: "1.5px solid #FF8C1F",
    backgroundColor: "#FFF5EB",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  label: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  sublabel: {
    color: "var(--text-default, #282624)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
  },
}));

export default useStyles;
