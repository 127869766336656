import { getBotId, getTenantName } from "utils/helpers";
import type PaginatedResponse from "types/PaginatedResponse";
import type Source from "types/Source";
import { botBEaxiosInstance } from "api/bot";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// const getCrawlerHeaders = () => {
// 	console.log("store", store, store.getState());
// 	const headers = {
// 		"X-DTS-SCHEMA": getTenantName(),
// 		Authorization: `Bearer ${store.getState().app_user.token}`,
// 	};
// 	return headers;
// };

export const postAddSourceToTrain = async (
  url: string,
  name: string,
  token: string
): Promise<Source> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "POST",
      url: `/crawler/crawlResources/${getBotId()}/`,
      data: {
        url,
        name,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSourceList = async (
  token: string
): Promise<PaginatedResponse<Source>> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "GET",
      url: `/crawler/crawlResources/${getBotId()}/`,
      withCredentials: true,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteLinkSource = async (
  sourceId: string,
  token: string
): Promise<void> => {
  await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "DELETE",
      url: `/crawler/crawlResources/byId/${sourceId}/`,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteFileSource = async (
  sourceId: string,
  token: string
): Promise<void> => {
  await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "DELETE",
      url: `/customer-resource/uploads/${sourceId}/`,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchLinkSource = async (
  sourceId: string,
  values: Record<string, any>,
  token: string
): Promise<Source> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "PATCH",
      url: `/crawler/crawlResources/byId/${sourceId}/`,
      data: {
        ...values,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchFileSource = async (
  sourceId: string,
  values: Record<string, any>,
  token: string
): Promise<any> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "PATCH",
      url: `/customer-resource/uploads/${sourceId}/`,
      data: {
        ...values,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadSource = async (data: any, token: string): Promise<any> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "POST",
      url: `/customer-resource/uploads/upload/`,
      data,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSourceListByBotId = async (
  botId: string,
  token: string
): Promise<any> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "GET",
      url: `/customer-resource/uploads/?bot=${botId}`,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
