import {
  type EdgeProps,
  getSmoothStepPath,
  EdgeLabelRenderer,
} from "reactflow";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  edgePath: { fill: "none", stroke: "#B5B1AD", strokeWidth: 1 },
  edgeLabel: {
    display: "inline-flex",
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    border: "1px dashed var(--icon-placeholder, #B5B1AD)",
    background: "#FFF",
  },
}));

export default function WorkflowEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  label,
}: EdgeProps): any {
  const classes = useStyles();

  // see the hook for implementation details
  // onClick adds a node in between the nodes that are connected by this edge
  // const onClick = useEdgeClick(id);

  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className={classes.edgePath}
        d={edgePath}
        markerEnd={markerEnd}
      />
      {label && (
        <EdgeLabelRenderer>
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${edgeCenterX}px,${edgeCenterY}px)`,
            }}
            className={classes.edgeLabel}
          >
            <Typography className="w-semi-bold" sx={{ color: "#7C7972" }}>
              {label}
            </Typography>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
