export const ChatBotNavigationItems = [
  {
    category: "build",
    key: "build",
    title: "Build",
    paths: [
      {
        key: "knowledge-bot",
        path: "knowledge-bot",
        title: "Knowledge Bot",
        enabled: true,
      },
      {
        key: "workflows",
        path: "workflows",
        title: "Workflows",
        enabled: true,
      },
      {
        key: "agents",
        path: "agents",
        title: "Agents",
        enabled: true,
      },
    ],
  },
  {
    category: "services",
    key: "services",
    title: "Services",
    paths: [
      {
        key: "api-integration",
        path: "api-integration",
        title: "API",
        enabled: true,
      },
    ],
  },
  {
    category: "settings",
    key: "settings",
    title: "Settings",
    paths: [
      {
        key: "appearance",
        path: "appearance",
        title: "Appearance",
        enabled: true,
      },
      {
        key: "agent-handoff",
        path: "agent-handoff",
        title: "Agent Handoff",
        enabled: false,
      },
      {
        key: "nature-of-response",
        path: "nature-of-response",
        title: "Nature of Response",
        enabled: false,
      },
    ],
  },
  {
    category: "deploy",
    key: "deploy",
    title: "Deploy",
    paths: [
      {
        key: "embed-chatbot",
        path: "embed-chatbot",
        title: "Embed Chatbot",
        enabled: true,
      },
      {
        key: "security",
        path: "security",
        title: "Security",
        enabled: false,
      },
      {
        key: "publish",
        path: "publish",
        title: "Publish",
        enabled: false,
      },
    ],
  },
];

export const VoiceBotNavigationItems = [
  {
    category: "build",
    key: "build",
    title: "Build",
    paths: [
      {
        key: "knowledge-bot",
        path: "knowledge-bot",
        title: "Knowledge Bot",
        enabled: false,
      },
      {
        key: "workflows",
        path: "workflows",
        title: "Workflows",
        enabled: false,
      },
      {
        key: "agents",
        path: "agents",
        title: "Agents",
        enabled: true,
      },
    ],
  },
  {
    category: "services",
    key: "services",
    title: "Services",
    paths: [
      {
        key: "api-integration",
        path: "api-integration",
        title: "API",
        enabled: true,
      },
    ],
  },
  // {
  //   category: "settings",
  //   key: "settings",
  //   title: "Settings",
  //   paths: [
  //     {
  //       key: "appearance",
  //       path: "appearance",
  //       title: "Appearance",
  //       enabled: true,
  //     },
  //     {
  //       key: "agent-handoff",
  //       path: "agent-handoff",
  //       title: "Agent Handoff",
  //       enabled: false,
  //     },
  //     {
  //       key: "nature-of-response",
  //       path: "nature-of-response",
  //       title: "Nature of Response",
  //       enabled: false,
  //     },
  //   ],
  // },
  // {
  //   category: "deploy",
  //   key: "deploy",
  //   title: "Deploy",
  //   paths: [
  //     {
  //       key: "embed-chatbot",
  //       path: "embed-chatbot",
  //       title: "Embed Chatbot",
  //       enabled: true,
  //     },
  //     {
  //       key: "security",
  //       path: "security",
  //       title: "Security",
  //       enabled: false,
  //     },
  //     {
  //       key: "publish",
  //       path: "publish",
  //       title: "Publish",
  //       enabled: false,
  //     },
  //   ],
  // },
];
