import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddedBotActions } from "api/actions";
import { ReduxActionActions } from "store/reduxActions/ReduxActionActions";
import { Box } from "@mui/material";
import Typography from "components/base/Typography";
import { makeStyles } from "@mui/styles";
import ViewExamplePopUp from "./ViewExamplePopUp";
import RenderConversationSuggestions from "./RenderConversationSuggestions";
import type BotAction from "types/BotAction";
import { ReactComponent as ConversationSuggestionIcon } from "./imgs/conversationSuggestionIcon.svg";
import CollapsibleCard from "components/shared/CollapsibleCard";

const useStyles = makeStyles(() => ({
  viewExample: {
    cursor: "pointer",
  },
}));

const RenderConversationSuggestionsWrapper: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const addedActionList = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.actions.action_list.data
  );

  const [openViewExamplePopUp, setOpenViewExamplePopUp] = useState(false);

  const fetchAddedActionList = useCallback(async () => {
    getAddedBotActions(token)
      .then((response) => {
        dispatch({
          type: ReduxActionActions.FETCH_ACTION_LIST_SUCCESS,
          payload: response.results,
        });
      })
      .catch(() => {
        dispatch({
          type: ReduxActionActions.FETCH_ACTION_LIST_ERROR,
        });
      });
  }, [dispatch, token]);

  const handleOpenPopUp = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation();

      setOpenViewExamplePopUp(true);
    },
    []
  );

  const handleClosePopUp = useCallback(() => {
    setOpenViewExamplePopUp(false);
  }, []);

  useEffect(() => {
    if (!addedActionList) {
      dispatch({
        type: ReduxActionActions.FETCH_ACTION_LIST_LOADING,
      });
      void fetchAddedActionList();
    }
  }, [addedActionList, dispatch, fetchAddedActionList]);

  return (
    <>
      <CollapsibleCard
        header={
          <>
            <Typography variant="textLg" className="w-bold">
              Conversation Suggestions
            </Typography>
            <Box color="#7C7972">
              <Typography variant="textSm" className="w-medium" color="inherit">
                Quick prompts for users to kickstart a conversation.
                <Box
                  component="span"
                  color="#F07400"
                  className={classes.viewExample}
                  onClick={(e) => {
                    handleOpenPopUp(e);
                  }}
                >
                  View example
                </Box>
              </Typography>
            </Box>
          </>
        }
        headerIcon={<ConversationSuggestionIcon />}
        renderContent={
          <Box p={3}>
            <Typography variant="textSm" className="w-medium">
              Come up with conversation suggestions to help users start a chat
              easily. You may connect them to ’Actions’. When you link an
              action, it automatically carries out the designated task.
            </Typography>

            <Box mt={1.5}>
              {/* <Banner
								message={
									<Typography
										variant="textSm"
										className="w-medium"
									>
										You may use this info to create prompt.
										In the last 15 days, users have commonly
										asked about print quality, print
										location, order procedures, and charges.
									</Typography>
								}
							/> */}
              {addedActionList && (
                <RenderConversationSuggestions
                  actionList={addedActionList as BotAction[]}
                />
              )}
            </Box>
          </Box>
        }
      />
      {openViewExamplePopUp && (
        <ViewExamplePopUp
          open={openViewExamplePopUp}
          header={
            <Typography variant="textXl" className="w-medium">
              Conversation suggestion example
            </Typography>
          }
          onClickClose={handleClosePopUp}
        />
      )}
    </>
  );
};

export default RenderConversationSuggestionsWrapper;
