import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidePanel from "components/shared/SidePanel";
import Typography from "components/base/Typography";
import type BotAction from "types/BotAction";
import { putBotAction } from "api/actions";
import { ReduxActionActions } from "store/reduxActions/ReduxActionActions";
import { type PostBotAction } from "types/BotAction";
import StepEditActionWrapper from "./StepEditActionWrapper";

interface EditActionProps {
  editActionDetails: BotAction;
  setEditActionDetails: (value: BotAction | null) => void;
}

const EditAction: React.FC<EditActionProps> = ({
  editActionDetails,
  setEditActionDetails,
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const dispatch = useDispatch();

  const handleSidePanelClose = useCallback(() => {
    setEditActionDetails(null);
  }, [setEditActionDetails]);

  const handleSubmitForm = useCallback(
    (values: PostBotAction) => {
      setIsFormSubmitting(true);

      putBotAction(editActionDetails.id, values, token)
        .then((response) => {
          dispatch({
            type: ReduxActionActions.PUT_ACTION_SUCCESS,
            payload: response,
          });
          enqueueSnackbar("Action created", {
            variant: "success",
          });

          setEditActionDetails(null);
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsFormSubmitting(false);
        });
    },
    [
      dispatch,
      editActionDetails?.id,
      enqueueSnackbar,
      setEditActionDetails,
      token,
    ]
  );

  return (
    <>
      {editActionDetails && (
        <SidePanel
          open={!!editActionDetails}
          onClickClose={handleSidePanelClose}
          header={
            <Typography variant="textXl" className="w-semi-bold">
              Edit action
            </Typography>
          }
        >
          <StepEditActionWrapper
            isFormSubmitting={isFormSubmitting}
            onClickDone={handleSubmitForm}
            editActionDetails={editActionDetails}
          />
        </SidePanel>
      )}
    </>
  );
};

export default EditAction;
