// import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { Handle, Position } from "reactflow";
import { useSelector } from "store";
import clsx from "clsx";
import { BotPromptNodeConfig } from "pages/Bot/WorkflowBuilder/config";
// import { type IBotPromptNode } from "types/Workflow";
import { ReactComponent as NodeHandleIcon } from "assets/node-handle.svg";
import { Link } from "react-router-dom";
import { type INodeProps, type NodeRenderProps } from "../../types";
import NodeContainer from "./NodeContainer";

const useStyles = makeStyles((theme) => ({
  "bot-prompt-node-wrapper": {
    width: "260px",
    height: "150px",

    "& .node-container": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "stretch",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

      "& .node-header": {
        padding: "12px 16px",

        "& .node-type-icon": {
          display: "flex",
          padding: "8px",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "8px",
          background: "#AD79EF",
        },
      },

      "& .carousel-action": {
        "& .action-btn": {
          height: "44px",
          position: "relative",
          borderTop: "1px solid #E7E5E1",
          padding: "10px 12px",

          "& a": {
            textDecoration: "none",
            color: "#AD79EF",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
          },

          "& .handle-icon": {
            position: "absolute",
            right: "12px",
          },
        },
      },

      "& .button-1-source-handle": {
        top: "60%",
      },

      "& .button-2-source-handle": {
        top: "88%",
      },
    },

    "&.node-selected": {
      "& .node-container": {
        border: "1px solid #734BA6",
      },
    },
  },
}));

const NodeComponent: React.FC<INodeProps & NodeRenderProps> = ({
  id,
  type,
  data,
  onNodeClick,
}) => {
  const classes = useStyles();

  const { selectNode, openCreateNodeDropdown } = useSelector(
    (state) => state.bot.workflow.workflow
  );

  const { name, fields_map, description } = data;

  const button1 = fields_map?.[6];
  const button2 = fields_map?.[7];

  // const addActionsMap = useCallback(() => {
  //   if (actions_map?.button_1 && actions_map?.button_2) {
  //     return;
  //   }

  //   const { addNode: button1Node, addNodeEdge: button1Edge } =
  //     createBranchAddNodeAndEdge({
  //       nodeId: id,
  //       absolutePosition: {
  //         x: 0,
  //         y: 0,
  //       },
  //       branchIndex: 0,
  //       branchId: 1,
  //     });

  //   const { addNode: button2Node, addNodeEdge: button2Edge } =
  //     createBranchAddNodeAndEdge({
  //       nodeId: id,
  //       absolutePosition: {
  //         x: 0,
  //         y: 0,
  //       },
  //       branchIndex: 1,
  //       branchId: 2,
  //     });

  //   const updatedNodes = updateNodeById({
  //     id,
  //     nodes,
  //     config: {
  //       actions_map: {
  //         button_1: button1Edge.target,
  //         button_2: button2Edge.target,
  //       },
  //     },
  //   });

  //   dispatch({
  //     type: WorkflowActions.SET_WORKFLOW_NODES,
  //     payload: { pointer, nodes: [...updatedNodes, button1Node, button2Node] },
  //   });
  //   dispatch({
  //     type: WorkflowActions.SET_WORKFLOW_EDGES,
  //     payload: { edges: [...edges, button1Edge, button2Edge] },
  //   });
  // }, []);

  // useEffect(() => {
  //   if (
  //     button1 &&
  //     button2 &&
  //     !actions_map?.button_1 &&
  //     !actions_map?.button_2
  //   ) {
  //     addActionsMap();
  //   }
  // }, [button1, button2]);

  const NodeIcon = BotPromptNodeConfig?.[type]?.ICON;

  return (
    <div
      id={`node-${id}`}
      onClick={(event) => {
        onNodeClick(event);
      }}
      className={clsx(classes["bot-prompt-node-wrapper"], {
        "node-selected": selectNode.id === id && !openCreateNodeDropdown,
      })}
    >
      <div className="node-container">
        {/* Handle - Target Clockwise: For dynamic connection */}
        <Handle
          className="handle"
          type="target"
          position={Position.Top}
          id={`handle-target-${id}-0`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Right}
          id={`handle-target-${id}-1`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Bottom}
          id={`handle-target-${id}-2`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Left}
          id={`handle-target-${id}-3`}
        />

        {/* Handle - Source Clockwise: For dynamic connection */}
        <Handle
          className="handle"
          type="source"
          position={Position.Top}
          id={`handle-source-${id}-0`}
        />
        <Handle
          className="handle button-1-source-handle"
          type="source"
          position={Position.Right}
          id={`handle-source-${id}-1`}
        />
        <Handle
          className="handle button-2-source-handle"
          type="source"
          position={Position.Right}
          id={`handle-source-${id}-2`}
        />
        <Handle
          className="handle"
          type="source"
          position={Position.Left}
          id={`handle-source-${id}-3`}
        />

        <div className="w-100 node-header flex align-items-start col-gap-12">
          <div className="node-type-icon">{NodeIcon && <NodeIcon />}</div>
          <div className="w-100 flex flex-col row-gap-2">
            <div className="flex justify-content-between align-items-center">
              <Typography
                variant="textTiny"
                className="w-semi-bold"
                sx={{ color: "#B5B1AD" }}
              >
                Bot Prompt
              </Typography>
              <Typography
                variant="textTiny"
                className="w-bold"
                sx={{ color: "#B5B1AD" }}
              >
                {String(data?.ui?.counter).padStart(3, "0")}
              </Typography>
            </div>
            <Typography className="w-semi-bold" sx={{ color: "#000" }}>
              {name}
            </Typography>
          </div>
        </div>

        <div className="node-content px-12 pb-16">
          <Typography
            variant="textSm"
            className="w-medium"
            sx={{ color: "#7C7972" }}
          >
            {description}
          </Typography>
        </div>

        <div className="carousel-action flex flex-col justify-content-end align-items-stretch">
          {button1 && (
            <div className="action-btn center">
              <Link to={""}>{button1?.value}</Link>
              <NodeHandleIcon className="handle-icon" />
            </div>
          )}

          {button2 && (
            <div className="action-btn center">
              <Link to={""}>{button2?.value}</Link>
              <NodeHandleIcon className="handle-icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CarouselResponseNode: React.FC<INodeProps> = (props) => {
  return (
    <NodeContainer
      id={props.id}
      type={props.type}
      renderNode={(nodeRenderProps: NodeRenderProps) => (
        <NodeComponent {...nodeRenderProps} {...props} />
      )}
    />
  );
};

export default CarouselResponseNode;
