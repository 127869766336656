import { useCallback, useState } from "react";
import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabParams from "./TabParams";
import TabAuthorization from "./TabAuthorization";
import TabHeaders from "./TabHeaders";
import { type IIntegrationFormValues } from "types/APIIntegration";
import TabBody from "./TabBody";
import { RequestType } from "pages/Bot/types";

const useStyles = makeStyles((theme: Theme) => ({
  "request-body-wrapper": {
    height: "384px",
    display: "flex",
    padding: "16px 24px",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: "16px",
    background: "#FFF",

    "& .MuiTab-root": {
      textTransform: "none",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },

    "& .tab-container": {
      height: "100%",
      overflowY: "auto",
    },
  },
}));

enum TabType {
  PARAMS = "PARAMS",
  AUTHORIZATION = "AUTHORIZATION",
  HEADERS = "HEADERS",
  BODY = "BODY",
}

const TabComponentMap: Record<TabType, React.FunctionComponent<Props>> = {
  [TabType.PARAMS]: (props) => <TabParams {...props} />,
  [TabType.AUTHORIZATION]: (props) => <TabAuthorization {...props} />,
  [TabType.HEADERS]: (props) => <TabHeaders {...props} />,
  [TabType.BODY]: (props) => <TabBody {...props} />,
};

interface Props {
  disableAllFields: boolean;
}

const RequestBody: React.FC<Props> = ({ disableAllFields = false }) => {
  const classes = useStyles();

  const { values } = useFormikContext<IIntegrationFormValues>();

  const [tab, setTab] = useState<TabType>(TabType.PARAMS);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: TabType) => {
      setTab(newValue);
    },
    []
  );

  const TabComponent = TabComponentMap?.[tab];

  const paramsCount = values?.params?.length;
  const headersCount = values?.headers?.length;

  return (
    <div className={classes["request-body-wrapper"]}>
      <div>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label={`Params(${paramsCount})`} value={TabType.PARAMS} />
          <Tab label="Authorization" value={TabType.AUTHORIZATION} />
          <Tab label={`Headers(${headersCount})`} value={TabType.HEADERS} />
          {(values.http_method === RequestType.POST ||
            values.http_method === RequestType.PUT ||
            values.http_method === RequestType.PATCH) && (
            <Tab label={`Body`} value={TabType.BODY} />
          )}
        </Tabs>
      </div>

      <div className="tab-container">
        <TabComponent disableAllFields={disableAllFields} />
      </div>
    </div>
  );
};

export default RequestBody;
