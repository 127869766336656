/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Grid, TextField as MuiTextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { type SyntheticEvent, useState, useCallback, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  type AutocompleteRenderInputParams,
} from "formik-mui";
import {
  type SalesForceConnectorDetails,
  type PostBotAction,
} from "../../../../../types/BotAction";
import type BotAction from "../../../../../types/BotAction";
import Typography from "../../../../../components/base/Typography";
import RequiredFieldMark from "../../../../../components/shared/RequiredFieldMark";
import CommonAskConfirmationButton from "../../../../../components/shared/CommonAskConfirmationButton";
import Button from "../../../../../components/base/Button";
import { ConnectorType } from "../../../../../types/ActionConnector";
import { type ActionType } from "../../../../../types/ActionType";
import type SalesForceConfig from "../../../../../types/ConnectorConfig/SalesForceConfig";
import { type SalesForceObject } from "../../../../../types/ConnectorConfig/SalesForceConfig";

enum SalesForceFormikValuesEnum {
  name = "name",
  description = "description",
  policy = "policy",
  object_name = "object_name",
  field_name = "field_name",
  action_type_name = "action_type_name",
  action_type_display_name = "action_type_display_name",
}

const validationSchema = yup.object({
  [SalesForceFormikValuesEnum.name]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .required("This is a required field"),
  [SalesForceFormikValuesEnum.policy]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .required("This is a required field"),
  [SalesForceFormikValuesEnum.action_type_display_name]: yup
    .string()
    .min(3, "Please select a value from dropdown")
    .required("This is a required field"),
  [SalesForceFormikValuesEnum.object_name]: yup
    .string()
    .min(3, "Please select a value from dropdown")
    .required("This is a required field"),
  [SalesForceFormikValuesEnum.field_name]: yup
    .string()
    .min(3, "Please select a value from dropdown")
    .required("This is a required field"),
});

interface SalesForceActionProps {
  connectorConfig: SalesForceConfig;
  isFormSubmitting: boolean;
  onClickDone: (values: PostBotAction) => void;
  editActionDetails?: BotAction;
}

const SalesForceAction: React.FC<SalesForceActionProps> = ({
  connectorConfig,
  isFormSubmitting,
  onClickDone,
  editActionDetails,
}) => {
  const [fieldList, setFieldList] = useState<string[]>([]);

  const [initialValues, setInitialValues] = useState({
    [SalesForceFormikValuesEnum.name]: "",
    [SalesForceFormikValuesEnum.description]: "",
    [SalesForceFormikValuesEnum.policy]: "",
    [SalesForceFormikValuesEnum.object_name]: "",
    [SalesForceFormikValuesEnum.field_name]: "",
    [SalesForceFormikValuesEnum.action_type_name]: "",
    [SalesForceFormikValuesEnum.action_type_display_name]: "",
  });

  const getInitialObjectDetails = useCallback(
    (object_name: string) => {
      const objectDetailsIndex = connectorConfig.objects.findIndex(
        (object) => object.name === object_name
      );

      const objectDetails = connectorConfig.objects[objectDetailsIndex];

      return {
        objectDetails,
      };
    },
    [connectorConfig.objects]
  );

  const getActionTypeDisplayName = useCallback(
    (action_type: string) => {
      const actionTypeIndex = connectorConfig.action_types.findIndex(
        (actionType) => actionType.name === action_type
      );

      if (actionTypeIndex === -1) return "";

      return connectorConfig.action_types[actionTypeIndex].display_name;
    },
    [connectorConfig.action_types]
  );

  const initializeEditFlowValues = useCallback(
    (editActionDetails: BotAction) => {
      const { name, description, policy, connector_config } = editActionDetails;

      const { object_name, field_name, action_type } =
        connector_config as SalesForceConnectorDetails;

      const { objectDetails } = getInitialObjectDetails(object_name);

      setFieldList(objectDetails.fields);

      const updatedInitialValues = {
        [SalesForceFormikValuesEnum.name]: name,
        [SalesForceFormikValuesEnum.description]: description,
        [SalesForceFormikValuesEnum.policy]: policy,
        [SalesForceFormikValuesEnum.object_name]: object_name,
        [SalesForceFormikValuesEnum.field_name]: field_name,
        [SalesForceFormikValuesEnum.action_type_name]: action_type || "",
        [SalesForceFormikValuesEnum.action_type_display_name]:
          getActionTypeDisplayName(action_type),
      };
      setInitialValues(updatedInitialValues);
    },
    [getActionTypeDisplayName, getInitialObjectDetails]
  );

  useEffect(() => {
    if (editActionDetails) {
      initializeEditFlowValues(editActionDetails);
    }
  }, [editActionDetails, initializeEditFlowValues]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        const payload: PostBotAction = {
          name: values.name,
          description: values.description,
          policy: values.policy,
          connector_type: ConnectorType.SALESFORCE,
          connector_config: {
            action_type: values.action_type_name,
            object_name: values.object_name,
            field_name: values.field_name,
          },
        };
        onClickDone?.(payload);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleChange,
      }) => {
        const refreshInputFiled = async (fieldName: string): Promise<void> => {
          await setFieldValue(fieldName, "");
          await setFieldTouched(fieldName, false);
        };

        return (
          <Form>
            <Box mx={0.5} mb={4}>
              <Box pb={0.5}>
                <Typography variant="textMd" className="w-semi-bold">
                  Action name
                  <RequiredFieldMark />
                </Typography>
              </Box>

              <MuiTextField
                name={SalesForceFormikValuesEnum.name}
                variant="outlined"
                size="small"
                placeholder="Create order"
                fullWidth
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{
                  classes: {
                    input: "py-8 px-12",
                  },
                }}
              />
            </Box>
            <Box mx={0.5} mb={4}>
              <Box pb={0.5}>
                <Typography variant="textMd" className="w-medium">
                  Description for your reference (Optional)
                </Typography>
              </Box>

              <MuiTextField
                name={SalesForceFormikValuesEnum.description}
                multiline
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                minRows={2}
                maxRows={2}
                variant="outlined"
                size="small"
                placeholder="Eg. A knowledge bot to answer questions related to how to use the product"
                fullWidth
                InputProps={{
                  classes: {
                    multiline: "py-12 px-14",
                  },
                }}
              />
            </Box>
            <Box mx={0.5} mb={4}>
              <Box pb={0.5}>
                <Typography variant="textMd" className="w-semi-bold">
                  Policy
                  <RequiredFieldMark />
                </Typography>
              </Box>

              <MuiTextField
                name={SalesForceFormikValuesEnum.policy}
                value={values.policy}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                placeholder="Define Policy"
                fullWidth
                error={touched.policy && Boolean(errors.policy)}
                helperText={touched.policy && errors.policy}
                InputProps={{
                  classes: {
                    input: "py-12 px-14",
                  },
                }}
              />
            </Box>
            <Box mx={0.5} mb={4}>
              <Box pb={0.5}>
                <Typography variant="textMd" className="w-semi-bold">
                  Select Action Type
                  <RequiredFieldMark />
                </Typography>
              </Box>
              <Field
                name={SalesForceFormikValuesEnum.action_type_display_name}
                freeSolo
                disabled={false}
                forcePopupIcon
                disableClearable
                value={values.action_type_display_name}
                component={Autocomplete}
                sx={{ width: 300 }}
                options={connectorConfig.action_types}
                filterOptions={(options: ActionType[]) => options}
                onChange={async (_: SyntheticEvent, newValue: ActionType) => {
                  await setFieldValue(
                    SalesForceFormikValuesEnum.action_type_display_name,
                    newValue.display_name
                  );

                  await setFieldValue(
                    SalesForceFormikValuesEnum.action_type_name,
                    newValue.name
                  );
                }}
                inputValue={values.action_type_display_name}
                getOptionLabel={(option: ActionType) =>
                  option.display_name || ""
                }
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <Field
                    {...params}
                    name={SalesForceFormikValuesEnum.action_type_display_name}
                    value={values.action_type_display_name}
                    component={TextField}
                    placeholder="Pick an action type"
                    variant="outlined"
                    error={
                      touched.action_type_display_name &&
                      Boolean(errors.action_type_display_name)
                    }
                    helperText={
                      touched.action_type_display_name &&
                      errors.action_type_display_name
                    }
                  />
                )}
              />
            </Box>
            <Box mx={0.5} mb={4}>
              <Box pb={0.5}>
                <Typography variant="textMd" className="w-semi-bold">
                  Select Object
                  <RequiredFieldMark />
                </Typography>
              </Box>
              <Field
                name={SalesForceFormikValuesEnum.object_name}
                freeSolo
                disabled={false}
                forcePopupIcon
                disableClearable
                value={values.object_name}
                sx={{ width: 400 }}
                component={Autocomplete}
                options={connectorConfig.objects}
                filterOptions={(options: SalesForceObject[]) => options}
                onChange={async (
                  _: SyntheticEvent,
                  newValue: SalesForceObject
                ) => {
                  if (newValue.name !== values.object_name) {
                    await refreshInputFiled(
                      SalesForceFormikValuesEnum.field_name
                    );
                  }
                  await setFieldValue(
                    SalesForceFormikValuesEnum.object_name,
                    newValue.name
                  );

                  setFieldList(newValue.fields);
                }}
                inputValue={values.object_name}
                getOptionLabel={(option: SalesForceObject) => option.name || ""}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <Field
                    {...params}
                    name={SalesForceFormikValuesEnum.object_name}
                    value={values.object_name}
                    component={TextField}
                    placeholder="Pick an object for action"
                    variant="outlined"
                    error={touched.object_name && Boolean(errors.object_name)}
                    helperText={touched.object_name && errors.object_name}
                  />
                )}
              />
            </Box>
            <Grid container width="100%" justifyContent="space-between">
              <Grid item xs={6}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      Select Field
                      <RequiredFieldMark />
                    </Typography>
                  </Box>
                  <Field
                    name={SalesForceFormikValuesEnum.field_name}
                    freeSolo
                    disabled={!values.object_name}
                    forcePopupIcon
                    disableClearable
                    value={values.field_name}
                    sx={{ width: 400 }}
                    component={Autocomplete}
                    options={fieldList}
                    filterOptions={(options: string[]) => options}
                    inputValue={values.field_name}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <Field
                        {...params}
                        name={SalesForceFormikValuesEnum.field_name}
                        value={values.field_name}
                        component={TextField}
                        placeholder="Pick a field for the selected object"
                        variant="outlined"
                        error={touched.field_name && Boolean(errors.field_name)}
                        helperText={touched.field_name && errors.field_name}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>

            <CommonAskConfirmationButton isLoading={isFormSubmitting}>
              <Button
                color="tertiary"
                size="medium"
                type="submit"
                disabled={!(isValid && dirty)}
              >
                Done
              </Button>
            </CommonAskConfirmationButton>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SalesForceAction;
