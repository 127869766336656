// import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Popover, type Theme } from "@mui/material";
// import ListSubheader from "@mui/material/ListSubheader";
// import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Collapse from "@mui/material/Collapse";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
import Typography from "components/base/Typography";
import { useState } from "react";
import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import { type IVariable } from ".";

const useStyles = makeStyles((theme: Theme) => ({
  "context-child-popover": {
    zIndex: 1300,
  },
  "context-child-container": {
    display: "inline-flex",
    padding: "16px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--Base-White, #FFF)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
}));

const ContextChildOption = ({
  option,
  path,
  onSelect,
  onClose,
}: {
  option: any;
  path: string;
  onSelect: (variable: IVariable) => void;
  onClose: () => void;
}): JSX.Element => {
  const [anchor, setAnchor] = useState<any>();

  const handleOpen = (event: any): void => {
    setAnchor(event.target);
  };

  const handleClose = (): void => {
    setAnchor(null);
  };

  const handleSelect = (opt: any): void => {
    onSelect({ key: opt.key, value: `${path}.${opt.value}` });
    onClose();
  };

  if (option?.options) {
    return (
      <div>
        <ListItemButton onClick={handleOpen}>
          <div className="w-100 flex justify-content-between align-items-center col-gap-8">
            <Typography className="w-semi-bold">{option?.key}</Typography>
            <ChevronRight />
          </div>
        </ListItemButton>
        <ContextChild
          anchor={anchor}
          option={option.options}
          path={`${path}.${option.key}`}
          onSelect={onSelect}
          onClose={handleClose}
        />
      </div>
    );
  }

  return (
    <ListItemButton
      onClick={() => {
        handleSelect(option);
      }}
    >
      <div className="w-full flex align-items-center col-gap-8">
        <Typography className="w-semi-bold">{option?.key}</Typography>
      </div>
    </ListItemButton>
  );
};

interface Props {
  anchor: any;
  path: string;
  option: any;
  onSelect: (variable: IVariable) => void;
  onClose: () => void;
}

const ContextChild: React.FC<Props> = ({
  anchor,
  option,
  path,
  onSelect,
  onClose,
}) => {
  const classes = useStyles();

  const open = Boolean(anchor);

  return (
    <Popover
      id={"context-child-popover"}
      className={classes["context-child-popover"]}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Paper className={classes["context-child-container"]}>
        {option?.map((opt: any, index: number) => (
          <ContextChildOption
            key={index}
            option={opt}
            path={path}
            onSelect={onSelect}
            onClose={onClose}
          />
        ))}
      </Paper>
    </Popover>
  );
};

export default ContextChild;
