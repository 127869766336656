export const enum ActionType {
  CONNECTORS = "CONNECTOR",
  FLOWS = "FLOWS",
  API = "API",
}

export interface ActionTileConfig {
  type: ActionType;
  name: string;
  description: string;
  actionCount: number;
  actionCountLabel: string;
  subActionCount: number;
  subActionLabel: string;
  icon: React.ReactNode;
}

export interface ConnectorConfig {
  _id: string;
  name: string;
  description: string;
  action_count: number;
  icon: string;
  connection_count: number;
  credential_type: string;
  label: string;
}

export interface ActionParam {
  _id: number;
  name: string;
  description: string;
  data_type: string;
  example: string;
  default?: string;
  required: boolean;
}

export interface ConnectorActionsConfig {
  _id: string;
  connector_id: string;
  name: string;
  description: string;
  label: string;
  input_params: ActionParam[];
  output_params: ActionParam[];
}
