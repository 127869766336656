import { type INode } from "pages/Bot/WorkflowBuilder/types";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormikContext, FieldArray } from "formik";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import TextField from "@mui/material/TextField";
import { type IServiceNodeForm } from "types/Workflow";
import Button from "components/base/Button";
import { testAPIIntegration } from "api/workflow/integration";
import ReactJson from "react-json-view";
// import { IStepContentProps } from "pages/Bot/WorkflowBuilder/types";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  "test-container": {
    "& .api-response-container": {
      display: "flex",
      padding: "16px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      alignSelf: "stretch",
      borderRadius: "8px",
      background: "var(--bg-disabled, #F9F8F8)",
      wordBreak: "break-all",
    },
  },
}));

interface Props {
  node: INode;
}

const TabTest: React.FC<Props> = ({ node }) => {
  const classes = useStyles();

  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IServiceNodeForm>();

  const { api_integration_valid, api_integration_id, test_api, api_response } =
    values;

  const [loading, setLoading] = useState(true);

  const handleTestAPIIntegration = (): void => {
    setLoading(true);

    const body: Record<string, string> = {};

    for (const inputVariable of test_api ?? []) {
      body[inputVariable.key] = inputVariable.input_value;
    }

    testAPIIntegration(api_integration_id, body)
      .then((response) => {
        if (response?.status_code === 200) {
          const data = JSON.parse(response.body);

          void setFieldValue("api_response", data, true);
        }
      })
      .catch((err) => {
        console.log(err);
        // const data = {
        //   code: err.code,
        //   message: err.message,
        // };
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      api_integration_valid &&
      api_integration_id &&
      test_api?.length === 0 &&
      !api_response
    ) {
      handleTestAPIIntegration();
    } else {
      setLoading(false);
    }
  }, [api_integration_valid, api_integration_id, test_api, api_response]);

  const enableRun = test_api?.every((value) => value.input_value);

  if (loading) {
    return (
      <div className="flex flex-col align-items-center justify-center py-72">
        <CircularProgress size={28} />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        classes["test-container"],
        "flex flex-col align-items-stretch"
      )}
    >
      {test_api?.length ? (
        <>
          <FieldArray name="test_api">
            {({ insert, remove, push }) => (
              <div className="flex flex-col row-gap-16">
                {values?.test_api &&
                  values?.test_api?.length > 0 &&
                  values?.test_api?.map((input_variable, index) => (
                    <div
                      key={index}
                      className="input-map-variable flex flex-col align-items-stretch row-gap-8"
                    >
                      <div className="flex flex-col">
                        <Typography>{input_variable.key}</Typography>
                        <Typography variant="textSm" sx={{ color: "#7C7972" }}>
                          {input_variable.description}
                        </Typography>
                      </div>

                      <TextField
                        name={`test_api.${index}.input_value`}
                        variant="outlined"
                        size="small"
                        placeholder="Enter value"
                        fullWidth
                        value={input_variable?.input_value || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //   error={
                        //     touched.api_integration_id && Boolean(errors.api_integration_id)
                        //   }
                        //   helperText={touched.api_integration_id && errors.api_integration_id}
                      />
                    </div>
                  ))}
              </div>
            )}
          </FieldArray>

          <div className="mt-16">
            <Button
              sx={{ width: 170 }}
              size="small"
              color={"secondary"}
              disabled={!enableRun}
              onClick={handleTestAPIIntegration}
              loading={loading}
            >
              Run
            </Button>
          </div>
        </>
      ) : null}

      {api_response && (
        <div className="api-response-container">
          <ReactJson
            src={api_response}
            name={null}
            collapsed={1}
            enableClipboard={false}
          />
        </div>
      )}
    </div>
  );
};

export default TabTest;
