import { Box, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface CollapsibleCardProps {
	header: React.ReactNode;
	headerIcon: React.ReactNode;
	renderContent: React.ReactNode;
}

const useStyles = makeStyles(() => ({
	heading: {
		borderBottom: "1px solid #E7E5E1",
		cursor: "pointer",
	},
}));

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
	header,
	headerIcon,
	renderContent,
}) => {
	const classes = useStyles();

	const cardContentRef = useRef<HTMLDivElement>(null);

	const [displayChild, setDisplayChild] = useState<boolean>(false);

	const toggleDisplayChild = useCallback(() => {
		setDisplayChild(!displayChild);
	}, [displayChild]);

	useEffect(() => {
		if (displayChild) {
			cardContentRef.current?.scrollIntoView({
				block: "nearest",
				behavior: "smooth",
			});
		}
	}, [displayChild]);

	return (
		<Paper elevation={1}>
			<Box
				className={clsx(classes.heading, "py-16", "px-24")}
				display="flex"
				onClick={toggleDisplayChild}
			>
				<Box display="flex" alignItems="center" mr={2}>
					{headerIcon}
				</Box>
				<Box flex={1}>{header}</Box>
				<Box display="flex" alignItems="center">
					{displayChild ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</Box>
			</Box>
			{displayChild && <div ref={cardContentRef}>{renderContent}</div>}
		</Paper>
	);
};

export default CollapsibleCard;
