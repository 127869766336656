import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  "chat-bot-container": {
    [theme.breakpoints.down("lg")]: {
      //   height: "100%",
      //   maxHeight: "100%",
      //   maxWidth: "100%",
      //   width: "100%",
    },
  },
  "chat-bot-icon-wrapper": {
    width: "100px",
    height: "100px",
    position: "absolute",
    right: "0",
    bottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "transform 0.25s ease-in-out",

    "&:hover": { transform: "scale(1.1)" },

    "&.chat-bot-icon-hide": {
      transform: "translateY(100%)",
      pointerEvents: "none",
    },
  },
  "chat-container": {
    height: "68vh",
    width: "360px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    bottom: "7px",
    backgroundColor: "#fff",
    borderRadius: "16px",
    boxShadow:
      "0px 0px 1px 0px rgba(38, 38, 38, 0.31), 0px 18px 28px 0px rgba(38, 38, 38, 0.15)",

    "&.chat-container-show": { transform: "translateY(0%)", opacity: 1 },

    "&.chat-container-hide": {
      height: "0px",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    [theme.breakpoints.down("xl")]: {
      height: "680px",
      margin: "40px 20px 0px",
      maxHeight: "calc(100% - 40px)",
      maxWidth: "calc(100% - 40px)",
      opacity: 0,
      transform: "translateY(150%)",
      transition: `transform 0.5s cubic-bezier(0.68, 0, 0.265, 1) 0.2s, opacity 0.5s linear 0.2s, height 0.5s cubic-bezier(0.68, 0, 0.265, 1) 0.2s`,
    },
  },
  "chat-bot-content": {
    flex: 1,
    overflow: "auto",
  },
  "chat-header": {
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "16px 16px 0px 0px",
    fontSize: "16px",

    "& .bot-logo-container": {
      height: "40px",
      width: "40px",

      "& img, svg": {
        height: "100%",
        width: "100%",
      },
    },

    "& .chatbot-action-container": {
      "& .chat-reset-button": {
        "& svg": {
          width: "20px",
          height: "20px",

          "& path": {
            fill: (props: any) => props?.color ?? "white",
          },
        },
      },
    },

    "& .minimize-icon-wrapper": {
      display: "none",

      "& svg": {
        width: "20px",
        height: "20px",

        "& path": {
          fill: (props: any) => props?.color ?? "white",
        },
      },

      "&.show-icon": {
        display: "block",
      },

      [theme.breakpoints.down("xl")]: {
        display: "block",
      },
    },
  },
}));

export default useStyles;
