import { TableBody, TableCell, TableRow } from "@mui/material";
import { type AddItemButtonProps, type TableColumnProps } from ".";
import { TableEmpty, TableError, TableLoader } from "aether/TableState";
import clsx from "clsx";
import useStyles from "./style";

interface TableBodyProps<RowData> {
  columns: Array<TableColumnProps<RowData>>;
  data: RowData[];
  loading: boolean;
  empty: boolean;
  emptyMessage: string;
  error: boolean;
  errorMessage: string;
  rowClassNames?: string;
  addItemButton?: AddItemButtonProps;
}

const TableBodyContainer = <RowData,>(): React.FC<TableBodyProps<RowData>> => {
  const TableBodyContainerComponent: React.FC<TableBodyProps<RowData>> = ({
    data,
    columns,
    loading,
    empty,
    emptyMessage,
    error,
    errorMessage,
    rowClassNames,
    addItemButton,
  }) => {
    const classes = useStyles();

    if (loading) {
      return <TableLoader colSpan={columns.length} />;
    }

    if (empty) {
      return (
        <TableEmpty
          colSpan={columns.length}
          message={emptyMessage}
          addItemButton={addItemButton}
        />
      );
    }

    if (error) {
      return <TableError colSpan={columns.length} />;
    }

    return (
      <TableBody>
        {data?.map((row, rowIndex) => (
          <TableRow
            hover
            tabIndex={-1}
            key={rowIndex}
            className={clsx(classes.rowContainer, rowClassNames)}
          >
            {columns.map((column, colIndex) => (
              <TableCell key={colIndex}>
                {column?.render ? column.render(row) : "-"}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  TableBodyContainerComponent.displayName = "TableBodyContainer";

  return TableBodyContainerComponent;
};

export default TableBodyContainer;
