import { useCallback, useEffect, useMemo, useState } from "react";
import { getBotData } from "api/bot";
import { useDispatch, useSelector } from "react-redux";
import type IBot from "types/BotType";
import { type AxiosError } from "axios";
import { BotActions, SettingsActions } from "store/reduxActions/botActions";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";

interface Props {
  botId: string | undefined;
}

const useBotStatus = ({
  botId = "",
}: Props): {
  botData: IBot | null;
  error: AxiosError | null;
  onRetry: () => void;
} => {
  const token = useSelector((state: any) => state.app_user.token);

  const dispatch = useDispatch();

  const [botData, setBotData] = useState<IBot | null>(null);
  const [error, setError] = useState(null);

  const fetchBotStatus = useCallback(() => {
    dispatch({
      type: SettingsActions.FETCH_BOT_SETTINGS_LOADING,
    });
    getBotData(botId)
      .then((response) => {
        setBotData(response);
        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_SUCCESS,
          payload: response.settings,
        });
        dispatch({
          type: BotActions.SET_BOT_META,
          payload: response,
        });
        dispatch({
          type: ReduxChatbotActions.ACTION_SET_BOT_TYPE,
          payload: { botName: response.name },
        });
      })
      .catch((errorResponse) => {
        setError(errorResponse);
      });
  }, [botId, dispatch, token]);

  const onRetry = useCallback(() => {
    setError(null);
    fetchBotStatus();
  }, [fetchBotStatus]);

  useEffect(() => {
    fetchBotStatus();
  }, [fetchBotStatus]);

  return useMemo(
    () => ({
      botData,
      error,
      onRetry,
    }),
    [botData, error, onRetry]
  );
};

export default useBotStatus;
