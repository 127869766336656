import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { type ISource } from "types/Source";
import { useCallback, useEffect } from "react";
import { getSourceList, getSourceListByBotId } from "api/source";
import { useDispatch, useSelector } from "store";
import { useParams } from "react-router-dom";
import { formatToHumanStringFromDate } from "utils/dateUtils";
import SourceStatus from "./SourceStatus";
import SourceDelete from "./SourceDelete";
import SourceVisibility from "./SourceVisibility";
import SourceRefresh from "./SourceRefresh";
import SourceUpload from "./SourceUpload";
import { ReactComponent as EmptyState } from "assets/empty-state.svg";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import FileTypeIcon from "components/shared/FileTypeIcon";
import { CircularProgress } from "@mui/material";
import { SourceActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme) => ({
  "source-list-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    padding: "24px",
    gap: "16px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",

    "& .source-table-container": {
      "& .table-body": {
        "& .title-cell": {
          height: "76px",

          "& .source-type-icon": {
            width: "24px",
            height: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            background: "var(--inactive-surfaces-subtle, #F9F8F8)",
            boxShadow:
              "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
          },
          "& .title": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
          },
        },
      },
    },

    "& .source-empty-container": {
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "& svg": {
        height: "100%",
      },
    },

    "& .source-loader-container": {
      height: "28vh",
    },
  },
}));

interface IColumn {
  id: "name" | "dateUpdated" | "status" | "visibility" | "rowAction";
  label: string;
  maxWidth?: string;
  width?: string;
  alignment?: "center" | "right" | "left";
}

const columns: readonly IColumn[] = [
  {
    id: "name",
    label: "Source",
    maxWidth: "40%",
    width: "40%",
    alignment: "left",
  },
  {
    id: "dateUpdated",
    label: "Date uploaded",
    maxWidth: "20%",
    width: "20%",
    alignment: "center",
  },
  {
    id: "status",
    label: "Status",
    maxWidth: "15%",
    width: "15%",
    alignment: "center",
  },
  {
    id: "visibility",
    label: "Visibility",
    maxWidth: "10%",
    width: "10%",
    alignment: "center",
  },
  {
    id: "rowAction",
    label: "",
    maxWidth: "15%",
    width: "15%",
    alignment: "right",
  },
];

const SourceList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const { botId } = params || {};

  const { token } = useSelector((state) => state.app_user);
  const { data, loading } = useSelector((state) => state.bot.sources);
  const { link: linkData, file: fileData } = data;

  useEffect(() => {
    if (!botId || !token) return;

    dispatch({
      type: SourceActions.FETCH_SOURCE_LIST_LOADING,
      payload: { loading: true },
    });

    Promise.all([getSourceList(token), getSourceListByBotId(botId, token)])
      .then((response: any) => {
        console.log(response);
        const _linkData = response[0].results;
        const _fileData = response[1].results;

        dispatch({
          type: SourceActions.FETCH_SOURCE_LIST_SUCCESS,
          payload: { link: _linkData, file: _fileData },
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: SourceActions.FETCH_SOURCE_LIST_ERROR,
        });
      })
      .finally(() => {
        dispatch({
          type: SourceActions.FETCH_SOURCE_LIST_LOADING,
          payload: { loading: false },
        });
      });
  }, [botId, dispatch, token]);

  const createDate = useCallback((created: string) => {
    const date = new Date(created);

    return formatToHumanStringFromDate(date);
  }, []);

  const handleOpenLink = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  if (loading) {
    return (
      <div className={classes["source-list-container"]}>
        <div className="source-loader-container center">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className={classes["source-list-container"]}>
      <div className="source-table-header">
        <Typography variant="textXl" className="w-semi-bold">
          Phi Sources
        </Typography>
      </div>

      {fileData?.length || linkData?.length ? (
        <div className="source-table-container">
          <TableContainer>
            <Table>
              <TableHead className="table-head">
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        maxWidth: column.maxWidth,
                        width: column.width,
                      }}
                      align={column.alignment}
                    >
                      <Typography variant="textSm" className="w-bold">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody className="table-body">
                {fileData?.map(
                  (
                    {
                      id,
                      file_type,
                      title,
                      active,
                      pending_sync,
                      created,
                    }: any,
                    index: number
                  ) => {
                    return (
                      <TableRow hover tabIndex={-1} key={`${id}-${index}`}>
                        <TableCell
                          className="title-cell flex align-items-center col-gap-8"
                          align={columns[0].alignment}
                        >
                          <div className="source-type-icon">
                            <FileTypeIcon type={file_type} />
                          </div>
                          <Typography className="title w-medium">
                            {title}
                          </Typography>
                        </TableCell>

                        <TableCell align={columns[1].alignment}>
                          <Typography variant="textSm" className="w-semi-bold">
                            {createDate(created)}
                          </Typography>
                        </TableCell>

                        <TableCell align={columns[2].alignment}>
                          <SourceStatus pending_sync={pending_sync} />
                        </TableCell>

                        <TableCell align={columns[3].alignment}>
                          <SourceVisibility
                            action={"file"}
                            enabled={active}
                            id={id}
                          />
                        </TableCell>

                        <TableCell align={columns[4].alignment}>
                          <div className="flex align-items-center col-gap-16">
                            <SourceUpload id={id} name={title} />
                            <SourceDelete
                              action={"file"}
                              id={id}
                              onClose={() => {}}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}

                {linkData?.map(
                  (
                    { id, name, render_status, enabled, created, url }: any,
                    index: number
                  ) => {
                    return (
                      <TableRow hover tabIndex={-1} key={`${id}-${index}`}>
                        <TableCell
                          className="title-cell flex align-items-center col-gap-8"
                          align={columns[0].alignment}
                        >
                          <LinkIcon />
                          <Typography
                            className="w-medium title anchor"
                            onClick={() => {
                              handleOpenLink(url);
                            }}
                          >
                            {name}
                          </Typography>
                        </TableCell>

                        <TableCell align={columns[1].alignment}>
                          <Typography variant="textSm" className="w-semi-bold">
                            {createDate(created)}
                          </Typography>
                        </TableCell>

                        <TableCell align={columns[2].alignment}>
                          <SourceStatus render_status={render_status} />
                        </TableCell>

                        <TableCell align={columns[3].alignment}>
                          <SourceVisibility
                            action={"link"}
                            enabled={enabled}
                            id={id}
                          />
                        </TableCell>

                        <TableCell align={columns[4].alignment}>
                          <div className="flex align-items-center col-gap-16">
                            <SourceRefresh id={id} name={name} />
                            <SourceDelete
                              action={"link"}
                              id={id}
                              onClose={() => {}}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="source-empty-container">
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default SourceList;
