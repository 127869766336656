import type React from "react";
import ActionBuilder from "./ActionBuilder";
import useStyles from "./ActionBuilder.style";
import ActionHeader from "./Header/ActionHeader";
import RightPanelContainer from "./RightPanel/RightPanel.container";
import FinalSubmitAddAction from "./FooterFinalSubmit/FinalSubmitAddAction";
import { useNavigate } from "react-router";

const ActionBuilderContainer: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onSave = (): void => {
    // not implemented yet
    //  make an API call to save draft
  };

  return (
    <div className={classes.container}>
      <ActionHeader
        onSave={onSave}
        onClickExit={() => {
          navigate(-1);
        }}
      />
      <div className={classes.ContentAndRightPanelContainer}>
        <div className={classes.contentContainer}>
          <ActionBuilder />
          <FinalSubmitAddAction />
        </div>
        <RightPanelContainer />
      </div>
    </div>
  );
};

export default ActionBuilderContainer;
