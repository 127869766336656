import {
  Box,
  Grid,
  Radio,
  FormControlLabel,
  TextField as MuiTextField,
} from "@mui/material";
import Typography from "components/base/Typography";
import { Field, type FormikProps, useFormikContext } from "formik";
import { BotSettingsFields, type BotSettingsFieldsValues } from "../constants";
import { RadioGroup } from "formik-mui";
import { BotUnableToRespond, RadioValue } from "types/BotSettings";
import { ReactComponent as AgentBehaviorIcon } from "./imgs/agentBehaviorIcon.svg";
import CollapsibleCard from "components/shared/CollapsibleCard";

const RenderAgentBehavior: React.FC = () => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
  }: FormikProps<BotSettingsFieldsValues> = useFormikContext();

  return (
    <CollapsibleCard
      header={
        <>
          <Typography variant="textLg" className="w-bold">
            Agent Behavior
          </Typography>
          <Box color="#7C7972">
            <Typography variant="textSm" className="w-medium" color="inherit">
              Status when agent is online and offline, Fallback when bot is not
              able to respond
            </Typography>
          </Box>
        </>
      }
      headerIcon={<AgentBehaviorIcon />}
      renderContent={
        <Box p={3}>
          <Grid container width="100%" justifyContent="space-between">
            <Grid item xs={6}>
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Define what to do when bot is not able to respond
                  </Typography>
                </Box>
                <Field
                  component={RadioGroup}
                  name={BotSettingsFields.no_bot_response}
                >
                  <FormControlLabel
                    value={BotUnableToRespond.END_CONVERSATION}
                    control={<Radio />}
                    label="End conversation"
                  />
                  <FormControlLabel
                    value={BotUnableToRespond.AGENT_HANDOFF}
                    control={<Radio />}
                    label="Handoff to an agent if they are online"
                  />
                  <FormControlLabel
                    value={BotUnableToRespond.END_CONVERSATION_CREATE_TICKET}
                    control={<Radio />}
                    label="End conversation and create a ticket"
                  />
                </Field>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Agent online or offline status banner
                  </Typography>
                </Box>
                <Field
                  component={RadioGroup}
                  name={BotSettingsFields.status_banner}
                >
                  <FormControlLabel
                    value={RadioValue.YES}
                    control={<Radio />}
                    label="Show Banner"
                  />
                  <FormControlLabel
                    value={RadioValue.NO}
                    control={<Radio />}
                    label="Banner is not required"
                  />
                </Field>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mx={0.5}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    End conversation message
                  </Typography>
                </Box>
                <MuiTextField
                  name={BotSettingsFields.end_conversation_msg}
                  value={values.end_conversation_msg}
                  multiline
                  minRows={2}
                  maxRows={2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  placeholder="Enter Nudge Message"
                  fullWidth
                  error={
                    touched.end_conversation_msg &&
                    Boolean(errors.end_conversation_msg)
                  }
                  helperText={
                    touched.end_conversation_msg && errors.end_conversation_msg
                      ? errors.end_conversation_msg
                      : "Max 200 characters"
                  }
                  InputProps={{
                    classes: {
                      multiline: "py-12 px-14",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default RenderAgentBehavior;
