import Axios, { type AxiosInstance } from "axios";
import { BASE_URL, BOT_BE_BASE_URL, BOT_BE_SERVER } from "./base-url";
import { getTenantName } from "utils/helpers";

const _baseAxiosInstance: AxiosInstance = Axios.create({
  baseURL: BASE_URL(),
  headers: {
    "X-DTS-SCHEMA": getTenantName(),
  },
});
const _botAxiosInstance: AxiosInstance = Axios.create({
  baseURL: BOT_BE_BASE_URL(),
});
const _botBEAxiosInstance: AxiosInstance = Axios.create({
  baseURL: BOT_BE_SERVER(),
});

export const setAxiosInstance = (token: string): void => {
  _botAxiosInstance.interceptors.request.use((config) => {
    const tenant = getTenantName();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["X-DTS-SCHEMA"] = tenant;
      // config.headers["ngrok-skip-browser-warning"] = "69420";
    }

    return config;
  });

  _botAxiosInstance.interceptors.response.use(
    (response) => {
      return response?.data ?? response;
    },
    async (error) => {
      return await Promise.reject(error);
    }
  );

  // BOT BE AXIOS INSTANCE SETUP
  _botBEAxiosInstance.interceptors.request.use((config) => {
    const tenant = getTenantName();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["X-DTS-SCHEMA"] = tenant;
      // config.headers["ngrok-skip-browser-warning"] = "69420";
    }

    return config;
  });

  _botBEAxiosInstance.interceptors.response.use(
    (response) => {
      return response?.data ?? response;
    },
    async (error) => {
      return await Promise.reject(error);
    }
  );
};

export const getBaseAxiosInstance = (): AxiosInstance => {
  if (!_baseAxiosInstance) {
    throw new Error("Axios instance not created. Call setAxiosInstance first.");
  }
  return _baseAxiosInstance;
};
export const getBotAxiosInstance = (): AxiosInstance => {
  if (!_botAxiosInstance) {
    throw new Error("Axios instance not created. Call setAxiosInstance first.");
  }
  return _botAxiosInstance;
};

export const getBotBEAxiosInstance = (): AxiosInstance => {
  if (!_botBEAxiosInstance) {
    throw new Error("Axios instance not created. Call setAxiosInstance first.");
  }
  return _botBEAxiosInstance;
};

export const baseAxiosInstance = getBaseAxiosInstance();
export const botAxiosInstance = getBotAxiosInstance();
export const botBEAxiosInstance = getBotBEAxiosInstance();
