import { useEffect, useState } from "react";
import { type Connector, type Connection } from "../integrations.types";
import ConnectionsList from "./ConnectionsList";
import ConnectorsList from "./ConnectorLists";
import useStyles from "./IntegrationContentContainer.styles";
import useIntegrationsRouteNavigator from "hooks/useIntegrationsRouteNavigator";
import {
  getAllConnections,
  getAllConnectors,
} from "../Services/integrations.service";
import { useSnackbar } from "notistack";

const IntegrationsContentContainer: React.FC = () => {
  const classes = useStyles();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [connectors, setConnectors] = useState<Connector[]>([]);
  const [loading, setLoading] = useState(false);

  const { gotoConnector, gotoConnection } = useIntegrationsRouteNavigator();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchConnectionsAndConnectors = async (): Promise<void> => {
      try {
        setLoading(true);
        const [connectorsResponse, connectionsResponse] = await Promise.all([
          getAllConnectors(),
          getAllConnections(),
          [],
        ]);
        setConnections(connectionsResponse ?? []);
        setConnectors(connectorsResponse ?? []);
      } catch (error: any) {
        enqueueSnackbar(`Something went wrong: ${error}`, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    void fetchConnectionsAndConnectors();
  }, []);

  const handleConnectorClick = (item: Connector): void => {
    gotoConnector(item._id);
  };

  const handleConnectionClick = (item: Connection): void => {
    gotoConnection(item._id);
  };

  return (
    <div className={classes.container}>
      <ConnectionsList
        connections={connections}
        onClick={handleConnectionClick}
        loading={loading}
      />
      <ConnectorsList
        connectors={connectors}
        onClick={handleConnectorClick}
        loading={loading}
      />
    </div>
  );
};

export default IntegrationsContentContainer;
