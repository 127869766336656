import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import EmptyList from "./EmptyList";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ReduxBotActions } from "store/reduxActions/ReduxBotActions";
import ApiError from "components/shared/APIError";
import AssistantList from "./AssistantList";
import CenteredLoader from "components/shared/CenteredLoader";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { useAssistantsStyles as useStyles } from "./styles";
import { type IAssistant } from "types/assistant.types";
import { getAssistantList } from "api/assistant/assistant";

const assistant_list_header_label = "Your virtual assistants";
const ListContainer: React.FC = () => {
  const dispatch = useDispatch();

  const [parentNode, setParentNode] = useState<HTMLDivElement | null>();
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>();
  const [globalSpinner, setGlobalSpinner] = useState(true);
  const [pageState, setPageState] = useState({ empty: false, error: false });
  const [retryCount, setRetryCount] = useState(0);

  const onDataFetched = ({
    count,
    results,
  }: {
    count: number;
    results: IAssistant;
  }): void => {
    if (!count) {
      setPageState({ empty: true, error: false });
    }

    dispatch({
      type: ReduxBotActions.FETCH_BOT_LIST_SUCCESS,
      payload: results,
    });

    setPageState({ empty: false, error: false });
    setGlobalSpinner(false);
  };

  const onError = (err: any): void => {
    console.error(err);

    dispatch({
      type: ReduxBotActions.FETCH_BOT_LIST_ERROR,
    });

    setPageState({ empty: false, error: true });
    setGlobalSpinner(false);
    setFetchedData([]);
  };

  const {
    loading: loadSpinner,
    pageNum,
    fetchedData: data,
    setFetchedData,
    nextUrl,
  } = useInfiniteScroll({
    parentNode,
    lastElement,
    apiConfig: {
      getData: getAssistantList,
    },
    beforeStartFetchingData: () => {},
    onDataFetched,
    onError,
    filters: {},
    retryCount,
  });

  if (globalSpinner && pageNum === 1) {
    return (
      <div style={{ marginTop: "8rem" }} className="flex flex-col center">
        <CenteredLoader />
      </div>
    );
  }

  if (pageState.empty) {
    return (
      <div className="flex flex-col center mt-5">
        <EmptyList />
      </div>
    );
  }

  if (pageState.error) {
    return (
      <div style={{ height: 300 }} className="center">
        <ApiError
          message="Something went wrong. Click on retry to refetch bot list"
          onRetry={() => {
            setGlobalSpinner(true);
            setRetryCount((prevState) => prevState + 1);
          }}
        />
      </div>
    );
  }

  const handleOnUpdateAssistant = (updatedAssistant: IAssistant): void => {
    const _assistantList = data.map((assistant: IAssistant, index: number) => {
      if (assistant._id === updatedAssistant._id) {
        return updatedAssistant;
      }
      return assistant;
    });

    setFetchedData(_assistantList);
  };

  const handleOnRemoveAssistant = (removeAssistant: IAssistant): void => {
    const _assistantList = data.filter(
      (assistant: IAssistant, index: number) =>
        assistant._id !== removeAssistant._id
    );

    setFetchedData(_assistantList);
  };

  return (
    <div
      ref={(ref) => {
        setParentNode(ref);
      }}
      className={"list-container"}
    >
      <AssistantList
        data={data}
        nextUrl={nextUrl}
        onUpdateAssistant={handleOnUpdateAssistant}
        onRemoveAssistant={handleOnRemoveAssistant}
        setLastElement={setLastElement}
      />

      {nextUrl && loadSpinner && (
        <div className="w-100 my-16 flex justify-content-center align-items-center">
          <CenteredLoader />
        </div>
      )}
    </div>
  );
};

const Assistants: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.assistantsContainer}>
      <Typography
        variant={TypographyVariants.textXXL}
        weight={TypographyWeights.bold}
      >
        {assistant_list_header_label}
      </Typography>

      <ListContainer />
    </div>
  );
};

export default Assistants;
