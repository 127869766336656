export enum BoundType {
  Inbound = "INBOUND",
  OutBound = "OUTBOUND",
}

export enum StatusType {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  PendingAPIIntegration = "PENDING_API_INTEGRATION",
  ToolMissing = "TOOLMISSING",
}

export interface PhoneNumber {
  countryCode: string;
  phone: string;
}

export interface Agent {
  id: string;
  name: string;
  description?: string;
  boundType: BoundType;
  phoneNumber: PhoneNumber;
  lastEdited: string;
  status: StatusType;
}
