import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import {
  Autocomplete,
  TextField,
  type AutocompleteRenderInputParams,
} from "formik-mui";
import { Box, TextField as MuiTextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import type ActionConnector from "types/ActionConnector";
import { type SyntheticEvent, useState } from "react";
import Typography from "components/base/Typography";
import RequiredFieldMark from "components/shared/RequiredFieldMark";
import { ConnectorType } from "types/ActionConnector";
import CommonAskConfirmationButton from "components/shared/CommonAskConfirmationButton";
import Button from "components/base/Button";

interface StepSelectConnectorProps {
  isFormSubmitting: boolean;
  onClickNext: (values: StepSelectConnectorValues) => void;
  connectorList: ActionConnector[];
}

/* eslint-disable */
const getValidationSchema = (displaySheetIdInput: boolean) => {
  return yup.object().shape({
    connector_type: yup
      .string()
      .min(3, "Please select a value from dropdown")
      .required("This is a required field"),
    ...(displaySheetIdInput
      ? {
          spreadsheet_id: yup
            .string()
            .min(3, "Please enter at least 3 characters")
            .required("This is a required field"),
        }
      : {}),
  });
};
/* eslint-enable */

export interface StepSelectConnectorValues {
  connector_type: ConnectorType | "";
  spreadsheet_id: string;
}

const StepSelectConnector: React.FC<StepSelectConnectorProps> = ({
  isFormSubmitting,
  onClickNext,
  connectorList,
}) => {
  const [displaySheetIdInput, setDisplaySheetIdInput] = useState(false);

  return (
    <Formik
      initialValues={{
        connector_type: "",
        connector_type_id: "",
        spreadsheet_id: "",
      }}
      onSubmit={(values) => {
        onClickNext?.({
          connector_type: values.connector_type_id as ConnectorType,
          spreadsheet_id: values.spreadsheet_id,
        });
      }}
      validationSchema={getValidationSchema(displaySheetIdInput)}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        dirty,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form>
            <Box mx={0.5} mb={4}>
              <Box pb={0.5}>
                <Typography variant="textMd" className="w-semi-bold">
                  Select Connector
                  <RequiredFieldMark />
                </Typography>
              </Box>
              <Field
                name="connector_type"
                freeSolo
                disabled={false}
                forcePopupIcon
                disableClearable
                value={values.connector_type}
                component={Autocomplete}
                sx={{ width: 300 }}
                options={connectorList}
                filterOptions={(options: ActionConnector[]) => options}
                onChange={async (
                  _: SyntheticEvent,
                  newValue: ActionConnector
                ) => {
                  await setFieldValue("connector_type", newValue.display_name);

                  await setFieldValue(
                    "connector_type_id",
                    newValue.connector_type
                  );

                  if (newValue.connector_type === ConnectorType.GOOGLE_SHEETS) {
                    setDisplaySheetIdInput(true);
                  } else {
                    setDisplaySheetIdInput(false);
                  }
                }}
                inputValue={values.connector_type}
                getOptionLabel={(option: ActionConnector) =>
                  option.display_name || ""
                }
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <Field
                    {...params}
                    name="connector_type"
                    value={values.connector_type}
                    component={TextField}
                    placeholder="Pick a connector for action"
                    variant="outlined"
                    error={
                      touched.connector_type && Boolean(errors.connector_type)
                    }
                    helperText={touched.connector_type && errors.connector_type}
                  />
                )}
              />
            </Box>
            {displaySheetIdInput && (
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Sheet Id
                    <RequiredFieldMark />
                  </Typography>
                </Box>

                <MuiTextField
                  name="spreadsheet_id"
                  variant="outlined"
                  size="small"
                  placeholder="Enter Google Sheet Id"
                  fullWidth
                  value={values.spreadsheet_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.spreadsheet_id && Boolean(errors.spreadsheet_id)
                  }
                  helperText={touched.spreadsheet_id && errors.spreadsheet_id}
                  InputProps={{
                    classes: {
                      input: "py-12 px-14",
                    },
                  }}
                />
              </Box>
            )}

            <CommonAskConfirmationButton isLoading={isFormSubmitting}>
              <Button
                color="tertiary"
                size="medium"
                type="submit"
                disabled={!(isValid && dirty)}
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            </CommonAskConfirmationButton>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StepSelectConnector;
