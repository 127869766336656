import { getBotId, getTenantName } from "utils/helpers";
import type PaginatedResponse from "types/PaginatedResponse";
import { botBEaxiosInstance } from "api/bot";
import type BotAction from "types/BotAction";
import { type PostBotAction } from "types/BotAction";
import type ActionConnector from "types/ActionConnector";
import type ConnectorConfigResponse from "types/ConnectorConfigResponse";
import type { ConnectorConfigPayload } from "types/ConnectorConfigResponse";

export const getConnectorConfig = async (
  values: ConnectorConfigPayload,
  token: string
): Promise<ConnectorConfigResponse> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "GET",
      url: `/bot_actions/config/`,
      withCredentials: true,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
      params: values,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postBotAddAction = async (
  values: PostBotAction,
  token: string
): Promise<BotAction> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "POST",
      url: `/bot_actions/${getBotId()}`,
      data: {
        ...values,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const putBotAction = async (
  actionId: string,
  values: PostBotAction,
  token: string
): Promise<BotAction> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "PUT",
      url: `/bot_actions/byId/${actionId}/`,
      data: {
        ...values,
        bot: getBotId(),
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchBotAction = async (
  actionId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>,
  token: string
): Promise<BotAction> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "PATCH",
      url: `/bot_actions/byId/${actionId}/`,
      data: {
        ...values,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAddedBotActions = async (
  token: string
): Promise<PaginatedResponse<BotAction>> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "GET",
      url: `/bot_actions/${getBotId()}`,
      withCredentials: true,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteAction = async (
  actionId: string,
  token: string
): Promise<void> => {
  await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "DELETE",
      url: `/bot_actions/byId/${actionId}`,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getConnectorList = async (
  token: string
): Promise<ActionConnector[]> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "GET",
      url: `/bot_actions/connector_types/`,
      withCredentials: true,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
