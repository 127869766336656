import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    width: "100%",
  },
  tableContainer: {
    minHeight: "40vh",
    boxShadow: theme.boxShadows.sm,
    border: "none",
  },
  rowContainer: {
    "& .row-action-container": {
      display: "none",
    },
    "&:hover": {
      "& .row-action-container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        columnGap: "16px",
      },
    },
  },
}));

export default useStyles;
