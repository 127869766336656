import { makeStyles } from "@mui/styles";
import { ServiceNodeType } from "pages/Bot/WorkflowBuilder/config";
import APIServiceNode from "./APIServiceNode";
import { type IAPIServiceNode } from "types/Workflow";

const useStyles = makeStyles((theme) => ({
  "edit-service-node": {},
}));

const ServiceNodeComponentMap = {
  [ServiceNodeType.API_SERVICE_NODE]: APIServiceNode,
};

interface Props {
  node: IAPIServiceNode;
  onClose: () => void;
}

const EditServiceNode: React.FC<Props> = ({ node, onClose }) => {
  const classes = useStyles();

  const { type } = node;

  const EditServiceNodeComponent = ServiceNodeComponentMap?.[type];

  return (
    <div className={classes["edit-service-node"]}>
      {EditServiceNodeComponent && <EditServiceNodeComponent node={node} />}
    </div>
  );
};

export default EditServiceNode;
