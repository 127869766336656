import { Formik, Form } from "formik";
import * as yup from "yup";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import EditNodeHeader from "../../components/EditNodeHeader";
import EditContainer from "./EditContainer";

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 30 characters allowed")
    .required("This is a required field"),
  message: yup.string().required("This is a required field"),
  description: yup.string(),
});

interface Props {
  node: INode;
}

const TextNodeForm: React.FC<Props> = ({ node }) => {
  return (
    <div className="edit-text-content">
      <Form className="flex flex-col row-gap-24">
        <EditNodeHeader node={node} />
        <EditContainer node={node} />
      </Form>
    </div>
  );
};

const TextNode: React.FC<Props> = ({ node }) => {
  const { data } = node;
  return (
    <Formik
      initialValues={{
        name: data?.name,
        message: data?.message,
        description: data?.description,
      }}
      onSubmit={(values) => {}}
      validationSchema={validationSchema}
    >
      <TextNodeForm node={node} />
    </Formik>
  );
};

export default TextNode;
