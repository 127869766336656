import { type IVariable } from "../TemplatePopover";

// Utility Functions
export const getTemplateKey = (key: string): string => {
  const regex = /\{\{(.*?)\}\}/;
  const match = key.match(regex);

  if (match) {
    const value = match[1]?.split(".");
    const _key = value[value?.length - 2];
    return _key;
  }

  return key;
};

export const setValueToView = (value: string, nodeId: string): any => {
  const formattedArray = value.split(/(?=\{\{)|(?<=\}\})/);

  const textareaEditor = document.getElementById(`textarea-editor--${nodeId}`);
  if (!textareaEditor) return;

  //   let dataContentElement: any;

  //   if (document.getElementById(`editor-content-${nodeId}`)) {
  //     dataContentElement = document.getElementById(`editor-content-${nodeId}`);
  //   } else {
  //     dataContentElement = document.createElement("div");
  //   }

  //   dataContentElement.setAttribute("class", "editor-content-span");
  //   dataContentElement.setAttribute("id", `editor-content-${nodeId}`);
  let lastElementType = "";

  formattedArray.forEach((content, index) => {
    const spanId = `${nodeId}-${index}`;
    const spanElement = document.createElement("span");
    spanElement.setAttribute("id", spanId);

    const regex = /\{\{(.*?)\}\}/;
    const match = content.match(regex);

    if (match) {
      const variableValue = match[0];
      const variableKey = getTemplateKey(match[0]);

      spanElement.setAttribute("data-content-type", "variable");
      spanElement.setAttribute("class", "resolve-variable");
      spanElement.setAttribute("data-variable-value", variableValue);
      spanElement.innerText = variableKey;
      lastElementType = "variable";
    } else {
      spanElement.setAttribute("data-content-type", "text");
      spanElement.innerText = content;
      lastElementType = "text";
    }

    textareaEditor.appendChild(spanElement);
  });

  if (lastElementType === "variable") {
    const spanId = `${nodeId}-${formattedArray.length}`;
    const spanElement = document.createElement("span");
    spanElement.setAttribute("id", spanId);
    spanElement.setAttribute("data-content-type", "text");
    spanElement.innerHTML = " ";

    textareaEditor.appendChild(spanElement);
  }
};

export const getRenderedValueFromContentElement = (id: string): string => {
  const textareaEditor = document.getElementById(`textarea-editor--${id}`);
  if (!textareaEditor) {
    return "";
  }

  const children = textareaEditor.children;
  if (!children) return "";

  let value = "";

  Array.from(children).forEach((element: any, index: number) => {
    value += element.innerText;
  });

  return value;
};

export const getValueFromContentElement = (id: string): string => {
  const textareaEditor = document.getElementById(`textarea-editor--${id}`);
  if (!textareaEditor) {
    return "";
  }

  const children = textareaEditor.children;
  if (!children) return "";

  let value = "";

  Array.from(children).forEach((element: any, index: number) => {
    const elementType = element.getAttribute("data-content-type");

    if (elementType === "text") {
      value += element.innerText;
    } else if (elementType === "variable") {
      const variable = element.getAttribute("data-variable-value");
      value += variable;
    }
  });

  return value;
};

export const getSelectionElement = (): any => {
  const selection = window.getSelection();
  if (!selection) return null;

  const range = selection.getRangeAt(0);
  const selectedElement = range.commonAncestorContainer.parentElement;

  return selectedElement;
};

export const handleVariableSelectionNode = (variable: IVariable): any => {
  const element = getSelectionElement();
  const elementId = element.getAttribute("id");
  const [contentId, currentSpan] = elementId.split("-");
  const spanIndex = Number(currentSpan);

  const textareaEditor = document.getElementById(
    `textarea-editor--${contentId}`
  );
  if (!textareaEditor) return;

  element.removeAttribute("data-map-variable");
  element.setAttribute("data-content-type", "variable");
  element.setAttribute("data-variable-value", `{{${variable.value}}}`);
  element.setAttribute("class", "resolve-variable");
  element.innerText = variable.key;

  // create a text node for the upcoming text
  const nextSpanId = `${contentId}-${spanIndex + 1}`;
  const nextSpan = document.createElement("span");
  nextSpan.setAttribute("data-content-type", "text");
  nextSpan.setAttribute("id", nextSpanId);
  nextSpan.innerHTML = " ";

  textareaEditor.append(nextSpan);

  //   moveCursor(`${contentId}-0`, true, false);
  //   moveCaret({ elementId: nextSpanId, caretOffset: 0 });
  //   setTimeout(() => {
  //     // setCursorToStartOfSpan(nextSpan);
  //     moveCursor(nextSpanId, true, false);
  //   }, 200);
  moveCursor(nextSpanId, true, false);
};

export const createPlaceholderSpanElement = (
  nodeId: string,
  parentNode: any
): any => {
  const dataContent = document.createElement("div");
  dataContent.setAttribute("class", "editor-content-span");
  dataContent.setAttribute("id", `editor-content-${nodeId}`);

  const spanId = `${nodeId}-0`;
  const placeholderSpan = document.createElement("span");
  placeholderSpan.setAttribute("data-content-type", "text");
  placeholderSpan.setAttribute("id", spanId);
  placeholderSpan.innerHTML = "<br/>";

  dataContent.appendChild(placeholderSpan);
  parentNode.appendChild(dataContent);
};

export const createPlaceholderSpan = (id: string): void => {
  const textareaEditor = document.getElementById(`textarea-editor--${id}`);
  if (!textareaEditor) return;

  const spanId = `${id}-0`;
  const placeholderSpan = document.createElement("span");
  placeholderSpan.setAttribute("data-content-type", "text");
  placeholderSpan.setAttribute("id", spanId);
  placeholderSpan.innerHTML = "<br>";

  if (textareaEditor.children?.length) {
    // Remove all child elements
    while (textareaEditor.firstChild) {
      textareaEditor.removeChild(textareaEditor.firstChild);
    }
  }

  textareaEditor.appendChild(placeholderSpan);
};

export const checkForRootSpanElement = (id: string, parentNode: any): any => {
  const dataContentElement = document.getElementById(`editor-content-${id}`);

  if (!dataContentElement) {
    createPlaceholderSpanElement(id, parentNode);
    return;
  }

  const rootSpan = document.getElementById(`${id}-0`);

  if (!rootSpan) {
    const spanId = `${id}-0`;
    const placeholderSpan = document.createElement("span");
    placeholderSpan.setAttribute("data-content-type", "text");
    placeholderSpan.setAttribute("id", spanId);
    placeholderSpan.innerHTML = "<br/>";

    dataContentElement.appendChild(placeholderSpan);
    parentNode.appendChild(dataContentElement);
  }
};

export const handleDeleteRootSpanElement = (
  id: string,
  rootNode: HTMLElement
): void => {
  console.log(rootNode);
  //   if (!dataContentElement || !lastSpan) {
  //     checkForRootSpanElement(id, parentNode);
  //   }
};

export const moveCursor = (
  id: string,
  collapse = false,
  atStart = false
): any => {
  const element = document.getElementById(id);
  if (element && !element?.firstChild) {
    element.innerHTML = "</br>";
  }

  if (!element?.firstChild) return;

  const selection = window.getSelection();

  if (!selection) return;

  const range = selection.getRangeAt(0);

  if (range) {
    range.selectNodeContents(element.firstChild);

    if (collapse) range.collapse(atStart);

    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const moveCaretInElement = (element: any): void => {
  const range = document.createRange();

  // Set the range to the start of the span
  range.setStart(element, 0);
  range.setEnd(element, 0);

  // Get the current selection
  const selection = window.getSelection();

  // Remove all ranges from the selection
  selection?.removeAllRanges();

  // Add the new range to the selection
  selection?.addRange(range);
};

export const moveCaret = ({
  elementId,
  caretOffset,
  nodeOffset,
}: {
  elementId: string;
  caretOffset: number;
  nodeOffset?: number;
}): void => {
  const element = document.getElementById(elementId);
  if (!element) return;

  const selection = window.getSelection();
  if (!selection) return;

  const range = selection.getRangeAt(0);

  if (range) {
    let offset = caretOffset;
    if (nodeOffset) offset += nodeOffset;

    range.setStart(element.childNodes[0], offset);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const getCaretPosition = (element: any): any => {
  let caretOffset = 0;
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
  }
  return caretOffset;
};

export const moveCursorOnFocus = (id: string): any => {
  const editorContentId = `editor-content-${id}`;
  const editorContentElement = document.getElementById(editorContentId);

  if (!editorContentElement) return;

  const children = editorContentElement.children;
  if (!children) return;
  const lastChild = children[children.length - 1];
  if (!lastChild) return;
  const lastChildId = lastChild.getAttribute("id");
  if (!lastChildId) return;

  console.log(lastChildId);
  moveCursor(lastChildId, true, false);
  console.log("Cursor should be moved");

  // const lastSpan = editorContentElement.querySelector("span:last-child");
  // if (!lastSpan) return;

  // // Create a range object
  // const range = document.createRange();

  // // Set the range to the end of the last span
  // range.setStart(lastSpan.childNodes[0], lastSpan?.textContent?.length ?? 0);
  // range.collapse(true);

  // // Create a selection object and set the range
  // const selection = window.getSelection();
  // if (!selection) return;

  // selection.removeAllRanges();
  // selection.addRange(range);

  // console.log(range);
  // console.log(selection);
};
