import {
  type ICarousel,
  type IFieldMap,
  // type IEdge,
  // type INode,
  // type INodePosition,
} from "types/Workflow";
import {
  // NodeGroupType,
  ContextVariableConfig,
  // ContextVariable,
  // EdgeType,
  // BotPromptNodeType,
  FieldType,
  // ResponseNodeType,
  // ServiceNodeType,
  // APIServiceNodeConfig,
  // ResponseNodeConfig,
  // BotPromptNodeConfig,
  // LogicNodeType,
  // CreateNodeType,
  // TriggerNodeType,
} from "../config";
// import {
//   getIncomers,
//   type Edge,
//   type Node,
//   getOutgoers,
//   getConnectedEdges,
// } from "reactflow";
import { type ISessionContext } from "types/PreviewSession";
import { type IBotQuestion } from "../types";
import capitalize from "lodash/capitalize";

export const isObjectTyped = (obj: any): boolean => {
  const keys = Object.keys(obj);
  // Check if there are exactly 3 keys and if they are 'label', 'value', and 'type'
  return (
    keys.length === 3 &&
    keys.includes("label") &&
    keys.includes("value") &&
    keys.includes("type")
  );
};

export const formatDataToVariables = (data: any): any => {
  if (!data) {
    return null;
  }

  const formattedObject: any = {};

  if (Array.isArray(data)) {
    return data.map(formatDataToVariables);
  }

  for (const key in data) {
    if (typeof data[key] === "string" || typeof data[key] === "number") {
      formattedObject[key] = {
        meta: {
          label: key,
          type: capitalize(typeof data[key]),
        },
        value: data[key],
      };
    } else if (data[key] && isObjectTyped(data[key])) {
      formattedObject[key] = {
        meta: {
          label: data[key]?.label,
          type: data[key]?.type,
        },
        value: data[key]?.value,
      };
    } else if (Array.isArray(data[key])) {
      formattedObject[key] = {
        meta: {
          label: key,
        },
        variables: data[key].map(formatDataToVariables),
      };
    } else if (typeof data[key] === "object") {
      formattedObject[key] = {
        meta: {
          label: key,
        },
        variables: formatDataToVariables(data[key]),
      };
    }
  }

  return formattedObject;
};

export const formatSessionContextToOptions = (sessionContext: any): any => {
  if (!sessionContext) return;

  const formattedSessionContextOptions = [];

  for (const context in sessionContext) {
    const contextOption: any = {
      key: context,
      value: context,
      meta: {
        icon: ContextVariableConfig?.[context]?.ICON,
      },
      options: [],
    };

    // NODES IN CONTEXT
    const nodesContext = sessionContext?.[context] || {};

    for (const node in nodesContext) {
      const meta = sessionContext?.[context]?.[node]?.meta;

      const nodeOptionConfig = formatNodeToOptions(
        sessionContext?.[context]?.[node]
      );

      contextOption.options.push({ meta, ...nodeOptionConfig });
    }

    formattedSessionContextOptions.push(contextOption);
  }

  return formattedSessionContextOptions;
};

export const formatNodeToOptions = (nodeContext: any): any => {
  if (!nodeContext) return;

  let formattedNodeOptions = {};

  if (Array.isArray(nodeContext?.variables)) {
    formattedNodeOptions = {
      key: nodeContext?.meta?.label,
      value: `${nodeContext?.meta?.nodeId}.variables[k]`,
      options: formatVariablesToOptions(nodeContext?.variables[0]),
    };
  } else if (typeof nodeContext.variables === "object") {
    formattedNodeOptions = {
      key: nodeContext?.meta?.label,
      value: `${nodeContext?.meta?.nodeId}.variables`,
      options: formatVariablesToOptions(nodeContext?.variables),
    };
  } else if (nodeContext?.value) {
    formattedNodeOptions = {
      key: nodeContext?.meta?.label,
      value: `${nodeContext?.meta?.nodeId}.value`,
      type: nodeContext?.meta?.type || "String",
    };
  }

  return formattedNodeOptions;
};

export const formatVariablesToOptions = (variables: any): any => {
  if (!variables) return;

  const formattedOptions = [];

  for (const variable in variables) {
    if (variables[variable]?.value || variables[variable]?.value === "") {
      formattedOptions.push({
        key: variables[variable]?.meta?.label,
        value: `${variable}.value`,
        type: variables[variable]?.meta?.type || "String",
      });
    } else if (Array.isArray(variables[variable]?.variables)) {
      formattedOptions.push({
        key: variables[variable]?.meta?.label,
        value: `${variable}..variables[k]`,
        options: formatVariablesToOptions(variables[variable]?.variables[0]),
      });
    } else if (typeof variables[variable]?.variables === "object") {
      formattedOptions.push({
        key: variables[variable]?.meta?.label,
        value: `${variable}.variables`,
        options: formatVariablesToOptions(variables[variable]?.variables),
      });
    }
  }

  return formattedOptions;
};

export const flattenSessionContext = (
  obj: Record<string, any>,
  parentKey = ""
): Record<string, string | number | boolean> => {
  const flattenedObject: Record<string, string | number | boolean> = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj?.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (Array.isArray(obj[key])) {
        Object.assign(
          flattenedObject,
          flattenSessionContext(obj[key][0], `${parentKey}.${key}[k]`)
        );
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(flattenedObject, flattenSessionContext(obj[key], newKey));
      } else {
        flattenedObject[newKey] = obj[key];
      }
    }
  }

  return flattenedObject;
};

// CAROUSEL UTILITY
export const getCarouselSample = (
  sessionContext: ISessionContext,
  fieldsMap: IFieldMap[]
): ICarousel => {
  const carousel: any = {
    image: "",
    tag: "",
    heading: "",
    subheading: "",
    body: "",
    button_1: "",
    button_2: "",
  };

  if (!sessionContext || !fieldsMap) {
    return carousel;
  }

  const optionsContextMap = flattenSessionContext(sessionContext);

  for (const field of fieldsMap) {
    if (field?.value && field.field_type === FieldType.TEMPLATE_SELECT) {
      const variable = getVariable(field.value);
      carousel[field.key] = optionsContextMap?.[variable];
    }
    if (
      field?.value &&
      [FieldType.TEMPLATE_TEXTFIELD, FieldType.TEMPLATE_TEXTAREA].includes(
        field.field_type
      )
    ) {
      carousel[field.key] = formateTemplateValueToView(
        field.value,
        sessionContext
      );
    }
    if (field?.value && field.field_type === FieldType.TEXT) {
      carousel[field.key] = field.value;
    }
  }

  return carousel;
};

export const formatRecordToTypedObject = (
  records: Record<string, string>
): any => {
  const typedRecord: Record<string, any> = {};

  Object.keys(records)?.forEach((key) => {
    typedRecord[key] = {
      label: key,
      value: records[key],
      type: "String",
    };
  });

  console.log(typedRecord);

  return typedRecord;
};

export const formateTemplateValueToView = (
  templateString: string,
  sessionContext: ISessionContext
): string => {
  const optionsContextMap = flattenSessionContext(sessionContext);
  const splitArray = templateString.split(/({{[^{}]+}})/);

  const formattedArray = splitArray.map((str: string) =>
    getTemplateView(str, optionsContextMap)
  );

  return formattedArray.join("");
};

export const getVariable = (str: string): string => {
  const regex = /\{\{(.*?)\}\}/;
  const match = str.match(regex);

  if (match) {
    return match[1];
  }

  return str;
};

export const getTemplateView = (
  value: string,
  valueMap: Record<string, any>
): string => {
  const regex = /\{\{(.*?)\}\}/;
  const match = value.match(regex);

  if (match) {
    const variable = getVariable(match[1]);
    return valueMap?.[variable];
  }

  return value;
};

export const getTemplateKey = (key: string): string => {
  const regex = /\{\{(.*?)\}\}/;
  const match = key.match(regex);

  if (match) {
    const value = match[1]?.split(".");
    const key = value[value?.length - 2];
    return `{{${key}}}`;
  }

  return key;
};

export const getVariablePath = (variable: string): any => {
  const regex = /\{\{(.*?)\}\}/;
  const match = variable.match(regex);

  if (match) {
    const value = match[1].split(".").slice(0, -1);
    return value;
  }

  return [];
};

export const getVariableFromSessionContext = (
  path: any,
  sessionContext: any
): any => {
  if (!path || !sessionContext) return null;

  let variable: any = sessionContext;

  for (const key of path) {
    // For variables[k]
    const matches = key.match(/([a-zA-Z]+)(\[\w+\])/);

    if (
      matches &&
      variable?.[matches?.[1]] &&
      Array.isArray(variable[matches[1]])
    ) {
      variable = variable[matches[1]][0];
    } else {
      variable = variable[key];
    }
  }

  return variable;
};

export const formatTemplateValueToKey = (templateString: string): string => {
  const splitArray = templateString.split(/({{[^{}]+}})/);
  const formattedArray = splitArray.map((str: string) => getTemplateKey(str));

  return formattedArray.join("");
};

export const getExtractedVariables = (questions: IBotQuestion[]): any => {
  const extractedVariables: Record<string, any> = {};

  for (const question of questions) {
    for (const variable of question?.variables ?? []) {
      extractedVariables[variable?.label] = {
        label: variable.label,
        type: variable?.type,
        value: "",
      };
    }
  }

  return extractedVariables;
};
