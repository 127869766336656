import { useCallback, useEffect, useRef, useState } from "react";
import queryString from "query-string";

interface Props {
  parentNode: any;
  lastElement: any;
  apiConfig: {
    getData: ({ params }: { params: string }) => Promise<any>;
  };
  beforeStartFetchingData: () => void;
  onDataFetched: (x: any) => void;
  onError: (err: any) => void;
  filters: any;
  enableInfiniteScrolling?: boolean;
  retryCount?: number;
}

export const useInfiniteScroll = ({
  parentNode,
  lastElement,
  apiConfig,
  beforeStartFetchingData = () => {},
  onDataFetched = (x) => {},
  onError = (err) => {
    console.error(err);
  },
  filters = {},
  enableInfiniteScrolling = true,
  retryCount = 0,
}: Props): any => {
  const [pageNum, setPageNum] = useState(1);
  const [nextUrl, setNextUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState<any>([]);

  const controllerRef = useRef<any>(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];

      if (first.isIntersecting && enableInfiniteScrolling) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const callGetData = useCallback(
    (page: number, limit = 20) => {
      if (beforeStartFetchingData) {
        beforeStartFetchingData();
      }

      const params = queryString.stringify({
        ...filters,
        page,
        page_size: limit,
      });

      setLoading(true);

      // FETCH DATA
      apiConfig
        .getData({ params })
        .then((response: any) => {
          const { next, data } = response;
          const { results } = data;

          if (Array.isArray(results)) {
            setFetchedData((prevState: any) => [...prevState, ...results]);
          }

          setNextUrl(next);

          if (onDataFetched) {
            onDataFetched(response);
          }
        })
        .catch((err: any) => {
          if (onError) {
            onError(err);
          }
        });
    },
    [apiConfig, beforeStartFetchingData, filters, onDataFetched, onError]
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement && nextUrl && enableInfiniteScrolling) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, nextUrl]);

  useEffect(() => {
    if (pageNum === 1) {
      return;
    }
    controllerRef.current = new AbortController();

    setLoading(true);
    callGetData(pageNum);

    return () => {
      // cancelRequests();
    };
  }, [pageNum]);

  useEffect(() => {
    if (!apiConfig?.getData) return;

    // if user wants to supply other filters as well, then this use effect will trigger
    controllerRef.current = new AbortController();
    setPageNum(1);

    callGetData(1);

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [retryCount, filters && Object.keys(filters)?.length ? filters : null]);

  useEffect(() => {
    if (parentNode) {
      parentNode.scrollTop = 0;
    } else {
      console.log("Parent node not present.");
    }
  }, [filters && Object.keys(filters)?.length ? filters : null, parentNode]);

  return {
    loading,
    pageNum,
    callGetData,
    setPageNum,
    fetchedData,
    setFetchedData,
    nextUrl,
  };
};
