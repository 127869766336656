export enum APIType {
  Standard = "STANDARD",
  Sample = "SAMPLE",
}

export interface HeadersEntity {
  key: string;
  value: string;
  description: string;
}

export interface APIItem {
  id: string;
  name: string;
  description: string;
}

export interface APIGroup {
  groupName: string;
  groupDescription: string;
  apiList: APIItem[];
}

export interface MockAPIEntity {
  name: string;
  type: "int" | "str" | "datetime";
}

// Interface for the mock data
export interface MockAPIData {
  bookingId: number;
  userId: string;
  bookingDate: string;
}

export interface IAPI {
  _id: string;
  name: string;
  description: string;
  bot_id: string;
  workflow_ids?: null[] | null;
  http_method: string;
  url: string;
  headers?: HeadersEntity[] | null;
  body?: string | null;
  form_data?: HeadersEntity[] | null;
  query_params?: HeadersEntity[] | null;
  extracted_variables?: any;
  created: string;
  modified: string;
  last_run?: null;
  status: string;
  is_mock_api?: boolean;
  mock_api_entity?: MockAPIEntity[];
  mock_data?: MockAPIData[];
  is_generated_api?: boolean;
}

export interface Task {
  celery_task_id: string;
  status: "INPROGRESS" | "PENDING" | "SUCCESS" | "FAILURE";
  progress?: string; // Optional field, future use for tracking progress
  error?: string; // Optional field, holds any error message if any
  started_at: string; // DateTime in string format
  completed_at: string; // DateTime in string format
  modified_at: string; // DateTime in string format
}

export interface APITaskList {
  tasks?: Task[];
  apis: IAPI[];
}

export interface IRequestParam {
  key: string;
  value: string;
  description: string;
}

interface IRequestAuthorization {
  auth_method: "basic" | "token" | null;
  username?: string;
  password?: string;
  token?: string;
}

interface IRequestBody {
  body_method: string;
  form_data?: HeadersEntity[];
  json?: string;
}

export interface IIntegrationFormValues {
  name: string;
  description: string;
  http_method: string;
  http_url: string;
  params: HeadersEntity[];
  authorization: IRequestAuthorization;
  headers: HeadersEntity[];
  body?: IRequestBody;
  extracted_variables: HeadersEntity[];
}
