import { type OutlinedTextFieldProps } from "@mui/material/TextField";
import { useCallback, useState } from "react";
import TemplatePopover from "../TemplatePopover";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useStyles } from "./style";
import TextSelect from "./TextSelect";

export interface IGroupOption {
  index?: number;
  key: string;
  value: string;
  icon?: any; // !!! Need to set this type for SVH React element, so as to render as in this file or JSX element; == icon: React.FC<React.SVGProps<SVGSVGElement>>
  options?: IGroupOption[];
}

interface Props extends OutlinedTextFieldProps {
  name: string;
  value: any;
  options: IGroupOption[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TemplateSelect: React.FunctionComponent<Props> = ({
  name,
  value,
  setFieldValue,
  options,
  onBlur,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  //   const [inputValue, setInputValue] = useState("");
  const [inputFieldValue, setInputFieldValue] = useState(value);
  const [focused, setFocused] = useState(false);
  const [anchor, setAnchor] = useState<any>();
  const [openPopover, setOpenPopover] = useState(false);

  const textfieldRef = useCallback((node: any) => {
    if (node) {
      setAnchor(node);
    }
  }, []);

  // Methods
  const handleSelect = useCallback(
    (value: string, variable: any) => {
      //   const updatedValueWithKey = `{{${variable.key}}}`;
      const updatedValueWithValue = `{{${variable.value}}}`;

      //   setInputValue(updatedValueWithKey);
      setInputFieldValue(updatedValueWithValue);
      setFieldValue(name, updatedValueWithValue);

      setOpenPopover(false);
    },
    [name, setFieldValue]
  );

  // Controlled Component Methods
  const handleOnFocus = useCallback((event: any): void => {
    setOpenPopover(true);
    setFocused(true);
  }, []);

  const handleOnChange = useCallback((event: any): void => {}, []);

  const handleOnBlur = useCallback(
    (event: any): void => {
      if (!openPopover) {
        setFocused(false);
      }

      onBlur?.(event);
      setFieldValue(name, inputFieldValue);
    },
    [inputFieldValue, name, openPopover, setFieldValue]
  );

  const handleClickAway = useCallback((): void => {
    if (openPopover && focused) {
      setFocused(false);
      setOpenPopover(false);
    }
  }, [focused, openPopover]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div ref={textfieldRef} className={classes["template-select-container"]}>
        <TextSelect
          placeholder={props?.placeholder}
          value={inputFieldValue}
          focused={focused}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />

        <TemplatePopover
          ref={textfieldRef}
          value={inputFieldValue}
          options={options}
          anchor={anchor}
          open={openPopover}
          onSelect={handleSelect}
          onClose={() => {
            setOpenPopover(false);
            setFocused(false);
          }}
        />
      </div>
    </ClickAwayListener>
  );
};

export default TemplateSelect;
