import { type DragEvent } from "react";
// import { useReactFlow } from "reactflow";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useDispatch, useSelector } from "store";
import {
  uuid,
  createNode,
  createPlaceholderNode,
  createPlaceholderEdge,
  createBranchAddNodeAndEdge,
  createHorizontalNode,
} from "../utils/workflow";
import { type INodeGroup } from "types/Workflow";
import {
  BotPromptNodeType,
  CreateNodeType,
  EdgeType,
  // CreateNodeType,
  LogicNodeType,
  NodeGroupType,
  TERMINAL_NODES,
  // NodeGroupType,
} from "../config";
import { type IEdge, type INode } from "../types";

interface Props {
  pointer: number;
  id: string;
  type: string;
  group: INodeGroup;
}

function useCreateNode(): any {
  const dispatch = useDispatch();

  const { nodes, edges } = useSelector((state) => state.bot.workflow.workflow);

  const getNode = (id: string): INode => {
    return nodes.find((node: INode) => node.id === id);
  };

  const createBranchNode = ({
    parentNode,
    insertedNode,
  }: {
    parentNode: INode;
    insertedNode: Omit<INode, "id">;
  }): {
    _insertedNode: any;
    _concatenatedNodes: INode[];
    _concatenatedEdges: IEdge[];
  } => {
    const branch = {
      id: uuid(),
      name: "Branch A",
      rootNodeId: null,
      conditions: [
        [
          {
            expr1: "",
            operator: "",
            expr2: "",
          },
        ],
      ],
    };

    const default_branch = {
      id: "default",
      name: "Default Fallback Branch",
      rootNodeId: null,
      conditions: [],
    };

    const { addNode: branchNode, addNodeEdge: branchNodeEdge } =
      createBranchAddNodeAndEdge({
        nodeId: parentNode.id,
        position: parentNode.position,
        branchIndex: 0,
        branchId: branch.id,
      });
    branch.rootNodeId = branchNode.id;

    const { addNode: defaultBranchNode, addNodeEdge: defaultBranchNodeEdge } =
      createBranchAddNodeAndEdge({
        nodeId: parentNode.id,
        position: parentNode.position,
        branchIndex: 0,
        branchId: default_branch.id,
      });
    default_branch.rootNodeId = defaultBranchNode.id;

    const _insertedNode = {
      ...insertedNode,
      data: {
        ...insertedNode.data,
        branches: [branch],
        default: default_branch,
      },
    };

    return {
      _insertedNode,
      _concatenatedNodes: [branchNode, defaultBranchNode],
      _concatenatedEdges: [branchNodeEdge, defaultBranchNodeEdge],
    };
  };

  const createCarouselNode = ({
    parentNode,
    insertedNode,
  }: {
    parentNode: INode;
    insertedNode: Omit<INode, "id">;
  }): {
    _insertedNode: any;
    _concatenatedNodes: INode[];
    _concatenatedEdges: IEdge[];
  } => {
    const { addNode: button1Node, addNodeEdge: button1Edge } =
      createBranchAddNodeAndEdge({
        nodeId: parentNode.id,
        absolutePosition: {
          x: 0,
          y: 0,
        },
        branchIndex: 0,
        branchId: 1,
      });

    const { addNode: button2Node, addNodeEdge: button2Edge } =
      createBranchAddNodeAndEdge({
        nodeId: parentNode.id,
        absolutePosition: {
          x: 0,
          y: 0,
        },
        branchIndex: 1,
        branchId: 2,
      });

    const _insertedNode = {
      ...insertedNode,
      data: {
        ...insertedNode.data,
        actions_map: {
          button_1: button1Edge.target,
          button_2: button2Edge.target,
        },
      },
    };

    return {
      _insertedNode,
      _concatenatedNodes: [button1Node, button2Node],
      _concatenatedEdges: [button1Edge, button2Edge],
    };
  };

  const createNodeFromAddButton = ({
    pointer,
    id,
    type,
    group,
  }: Props): any => {
    const terminalNode = getNode(id);
    if (!terminalNode) return;

    const newNode = createNode({
      pointer,
      type,
      group,
      position: terminalNode.position,
    });

    let parentNode = {
      ...newNode,
      id: uuid(),
    };

    const sourceId = terminalNode.id;
    const targetId = parentNode.id;

    const insertedEdge = {
      id: `${sourceId}=>${targetId}`,
      source: sourceId,
      target: targetId,
      sourceHandle: `handle-source-${sourceId}-1`,
      targetHandle: `handle-target-${targetId}-3`,
      type: EdgeType.WORKFLOW_EDGE,
    };

    let concatenatedNodes: INode[] = [];
    let concatenatedEdges: IEdge[] = [];

    const childPlaceholderNode = createPlaceholderNode({
      position: {
        x: terminalNode.position.x + 400,
        y: terminalNode.position.y,
      },
    });

    const childPlaceholderEdge = createPlaceholderEdge({
      sourceId: terminalNode.id,
      targetId: childPlaceholderNode.id,
    });

    concatenatedNodes = TERMINAL_NODES.includes(type)
      ? []
      : [childPlaceholderNode];
    concatenatedEdges = TERMINAL_NODES.includes(type)
      ? []
      : [childPlaceholderEdge];

    // NODES WHERE PLACEHOLDER NOT ADDED TO NEW NODE
    if (type === LogicNodeType.BRANCH) {
      const { _insertedNode, _concatenatedNodes, _concatenatedEdges } =
        createBranchNode({ parentNode, insertedNode: parentNode });
      parentNode = { ..._insertedNode };
      concatenatedNodes = [..._concatenatedNodes];
      concatenatedEdges = [..._concatenatedEdges];
    }

    // NODES WHERE PLACEHOLDER ADDED TO NEW NODE
    if (type === BotPromptNodeType.CAROUSEL) {
      const { _insertedNode, _concatenatedNodes, _concatenatedEdges } =
        createCarouselNode({
          parentNode,
          insertedNode: parentNode,
        });
      parentNode = { ..._insertedNode };
      concatenatedNodes = [..._concatenatedNodes];
      concatenatedEdges = [..._concatenatedEdges];
    }

    const updatedNodes = [...nodes, parentNode].concat(concatenatedNodes);
    const updatedEdges = [...edges, insertedEdge].concat(concatenatedEdges);

    dispatch({
      type: WorkflowActions.SET_WORKFLOW_NODES,
      payload: {
        pointer: pointer + 1,
        nodes: updatedNodes,
      },
    });
    dispatch({
      type: WorkflowActions.SET_WORKFLOW_EDGES,
      payload: {
        edges: updatedEdges,
      },
    });
  };

  const createNodeFromPlaceholder = ({
    pointer,
    id,
    type,
    group,
  }: Props): void => {
    const parentNode = getNode(id);
    if (!parentNode) return;

    let insertedNode = createNode({
      pointer,
      type,
      group,
      position: parentNode.position,
    });
    let concatenatedNodes: INode[] = [];
    let concatenatedEdges: IEdge[] = [];

    const childPlaceholderNode = createPlaceholderNode({
      position: {
        x: parentNode.position.x + 400,
        y: parentNode.position.y,
      },
    });

    const childPlaceholderEdge = createPlaceholderEdge({
      sourceId: parentNode.id,
      targetId: childPlaceholderNode.id,
    });

    concatenatedNodes = TERMINAL_NODES.includes(type)
      ? []
      : [childPlaceholderNode];
    concatenatedEdges = TERMINAL_NODES.includes(type)
      ? []
      : [childPlaceholderEdge];

    // NODES WHERE PLACEHOLDER NOT ADDED TO NEW NODE
    if (type === LogicNodeType.BRANCH) {
      const { _insertedNode, _concatenatedNodes, _concatenatedEdges } =
        createBranchNode({ parentNode, insertedNode });
      insertedNode = { ..._insertedNode };
      concatenatedNodes = [..._concatenatedNodes];
      concatenatedEdges = [..._concatenatedEdges];
    }

    // NODES WHERE PLACEHOLDER ADDED TO NEW NODE
    if (type === BotPromptNodeType.CAROUSEL) {
      const { _insertedNode, _concatenatedNodes, _concatenatedEdges } =
        createCarouselNode({ parentNode, insertedNode });
      insertedNode = { ..._insertedNode };
      concatenatedNodes = [..._concatenatedNodes];
      concatenatedEdges = [..._concatenatedEdges];
    }

    const updatedNodes = nodes
      .map((node: INode) => {
        if (node.id === id) {
          return {
            id: node.id,
            ...insertedNode,
          };
        }

        return node;
      })
      .concat(concatenatedNodes);

    const updatedEdges = edges
      .map((edge: IEdge) => {
        if (edge.target === id) {
          return {
            ...edge,
            type: "workflow",
          };
        }
        return edge;
      })
      .concat(concatenatedEdges);

    dispatch({
      type: WorkflowActions.SET_WORKFLOW_NODES,
      payload: {
        pointer: pointer + 1,
        nodes: updatedNodes,
      },
    });
    dispatch({
      type: WorkflowActions.SET_WORKFLOW_EDGES,
      payload: {
        edges: updatedEdges,
      },
    });
  };

  const createBranchHorizontalNode = ({
    pointer,
    node,
    type,
    group,
    branchIndex,
    alignment,
    isRootNode = false,
    rootNodeParentId = "",
  }: {
    pointer: number;
    node: INode;
    type: string;
    group: INodeGroup;
    branchIndex: number;
    alignment?: string;
    isRootNode?: boolean;
    rootNodeParentId?: string;
  }): void => {
    const { id: branchNodeId, position: branchNodePosiiton } = node;

    let rootNodeParent = null;
    if (isRootNode) {
      rootNodeParent = getNode(rootNodeParentId);
    }

    let insertedNode = createHorizontalNode({
      pointer,
      type,
      group,
      position: branchNodePosiiton,
      branchIndex,
      alignment,
      isRootNode,
      rootNodeParent,
    });
    let concatenatedNodes: INode[] = [];
    let concatenatedEdges: IEdge[] = [];

    const childPlaceholderNode = {
      id: uuid(),
      type: CreateNodeType.BRANCH_ADD_NODE,
      position: {
        x: insertedNode.position.x + 320,
        y: insertedNode.position.y + 19,
      },
      width: 80,
      height: 31,
      data: {
        label: "Add",
        group: NodeGroupType.CREATE_NODE,
      },
    };

    const childPlaceholderEdge = {
      id: `${branchNodeId}=>${childPlaceholderNode?.id}`,
      source: branchNodeId,
      target: childPlaceholderNode?.id,
      type: EdgeType.PLACEHOLDER_EDGE,
      sourceHandle: `handle-source-${branchNodeId}-1`,
      targetHandle: `handle-target-${childPlaceholderNode?.id}-3`,
    };

    concatenatedNodes = TERMINAL_NODES.includes(type)
      ? []
      : [childPlaceholderNode];
    concatenatedEdges = TERMINAL_NODES.includes(type)
      ? []
      : [childPlaceholderEdge];

    // NODES WHERE PLACEHOLDER NOT ADDED TO NEW NODE
    if (type === LogicNodeType.BRANCH) {
      const { _insertedNode, _concatenatedNodes, _concatenatedEdges } =
        createBranchNode({ parentNode: node, insertedNode });
      insertedNode = { ..._insertedNode };
      concatenatedNodes = [..._concatenatedNodes];
      concatenatedEdges = [..._concatenatedEdges];
    }

    // NODES WHERE PLACEHOLDER ADDED TO NEW NODE
    if (type === BotPromptNodeType.CAROUSEL) {
      const { _insertedNode, _concatenatedNodes, _concatenatedEdges } =
        createCarouselNode({ parentNode: node, insertedNode });
      insertedNode = { ..._insertedNode };
      concatenatedNodes = [..._concatenatedNodes];
      concatenatedEdges = [..._concatenatedEdges];
    }

    const updatedNodes = nodes
      .map((node: INode) => {
        if (node.id === branchNodeId) {
          return {
            id: node.id,
            ...insertedNode,
          };
        }

        return node;
      })
      .concat(concatenatedNodes);

    const updatedEdges = edges
      .map((edge: IEdge) => {
        if (edge.target === branchNodeId) {
          return {
            ...edge,
            type: "workflow",
          };
        }
        return edge;
      })
      .concat(concatenatedEdges);

    dispatch({
      type: WorkflowActions.SET_WORKFLOW_NODES,
      payload: {
        pointer: pointer + 1,
        nodes: updatedNodes,
      },
    });
    dispatch({
      type: WorkflowActions.SET_WORKFLOW_EDGES,
      payload: {
        edges: updatedEdges,
      },
    });
  };

  const onDrop = (event: DragEvent, id: string, pointer: number): void => {
    event.preventDefault();

    if (!event?.dataTransfer) {
      return;
    }

    const selectedNode = event.dataTransfer.getData(
      "application/workflow-node"
    );
    const [group, type] = selectedNode.split("+");

    createNodeFromPlaceholder({ id, group, type, pointer });
  };

  return {
    onClick: createNodeFromPlaceholder,
    onDrop,
    createNodeFromAddButton,
    createNodeFromPlaceholder,
    createBranchHorizontalNode,
  };
}

export default useCreateNode;
