import { Box } from "@mui/material";
import type BotSettings from "types/BotSettings";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { BotSettingsFields } from "../constants";
import RenderBasicSettings from "./RenderBasicSettings";
import RenderNatureOfResponseSettings from "./RenderNatureOfResponseSettings";
import RenderWidgetBehavior from "./RenderWidgetBehavior";
import RenderAgentBehavior from "./RenderAgentBehavior";
import { useMemo, type MutableRefObject } from "react";
import RenderConversationSuggestionsWrapper from "./RenderConversationSuggestionsWrapper";
import { type PostBotSettings } from "types/BotSettings";

const validationSchema = yup.object({
  [BotSettingsFields.bot_display_name]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(16, "Maximum 16 characters allowed")
    .required("This is a required field"),
  [BotSettingsFields.header_message]: yup
    .string()
    .min(2, "Please enter at least 3 characters")
    .max(50, "Maximum 50 characters allowed")
    .required("This is a required field"),
  [BotSettingsFields.welcome_message]: yup
    .string()
    .min(3, "Please select a value from dropdown")
    .max(100, "Maximum 100 characters allowed")
    .required("This is a required field"),
  [BotSettingsFields.input_box_placeholder]: yup
    .string()
    .min(3, "Please select a value from dropdown")
    .max(50, "Maximum 50 characters allowed")
    .required("This is a required field"),
  [BotSettingsFields.bot_response_length]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.show_source_of_info]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.request_feedback]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.display_nudge]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.nudge_message]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(100, "Maximum 100 characters allowed")
    .required("This is a required field"),
  [BotSettingsFields.default_widget_state]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.no_bot_response]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.status_banner]: yup
    .string()
    .required("This is a required field"),
  [BotSettingsFields.end_conversation_msg]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(200, "Maximum 200 characters allowed")
    .required("This is a required field"),
  [BotSettingsFields.suggestions]: yup.array().of(
    yup.object({
      title: yup
        .string()
        .min(3, "Please enter at least 3 characters")
        .max(50, "Maximum 50 characters allowed"),
      action: yup.object({
        name: yup.string().min(3, "Please select an action from the list "),
      }),
    })
  ),
});

interface RenderBotSettingsProps {
  botSettings: BotSettings;
  formValuesRef: MutableRefObject<PostBotSettings | undefined>;
  setDisableSubmitButton: (val: boolean) => void;
}

const RenderBotSettings: React.FC<RenderBotSettingsProps> = ({
  botSettings,
  formValuesRef,
  setDisableSubmitButton,
}) => {
  const formInitialValues: PostBotSettings = useMemo(() => {
    const initialSuggestions = botSettings?.suggestions?.map(
      ({ title, action }) => {
        return {
          title,
          action: action ?? { id: "", name: "" },
        };
      }
    );

    let i = initialSuggestions.length;

    while (i < 4) {
      initialSuggestions.push({
        title: "",
        action: { id: "", name: "" },
      });
      i += 1;
    }

    return { ...botSettings, suggestions: initialSuggestions };
  }, [botSettings]);

  return (
    <Box mt={4}>
      <Formik
        initialValues={formInitialValues}
        enableReinitialize={true}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {({ values, isValid, dirty }) => {
          formValuesRef.current = values;
          setTimeout(() => {
            setDisableSubmitButton(!(isValid && dirty));
          }, 0);

          return (
            <Form>
              <RenderBasicSettings />
              <Box mt={3}>
                <RenderNatureOfResponseSettings />
              </Box>
              <Box mt={3}>
                <RenderWidgetBehavior />
              </Box>
              <Box mt={3}>
                <RenderAgentBehavior />
              </Box>
              <Box mt={3}>
                <RenderConversationSuggestionsWrapper />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default RenderBotSettings;
