import { makeStyles } from "@mui/styles";
import { NodeGroupType } from "pages/Bot/WorkflowBuilder/config";
import EditTriggerNode from "./EditTriggerNode";
import EditServiceNode from "./EditServiceNode";
import EditResponseNode from "./EditResponseNode";
import EditBotPromptNode from "./EditBotPromptNode";
import EditLogicNode from "./EditLogicNode";

const useStyles = makeStyles((theme) => ({
  "edit-node-content": {
    "& .edit-node-header": {
      display: "flex",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
    },
  },
}));

interface IEditNodeProps {
  type: string;
  node: any;
  onClose: () => void;
}

const EditComponentMap = {
  [NodeGroupType.TRIGGER_NODE]: EditTriggerNode,
  [NodeGroupType.RESPONSE_NODE]: EditResponseNode,
  [NodeGroupType.SERVICE_NODE]: EditServiceNode,
  [NodeGroupType.BOT_PROMPT_NODE]: EditBotPromptNode,
  [NodeGroupType.USER_SELECTION_NODE]: EditResponseNode,
  [NodeGroupType.LOGIC_NODE]: EditLogicNode,
};

const EditNode: React.FC<IEditNodeProps> = ({ type, node, onClose }) => {
  const classes = useStyles();

  const { data } = node;
  const { group } = data;

  const EditComponent = EditComponentMap?.[group] || null;

  if (!EditComponent) {
    return null;
  }

  return (
    <div className={classes["edit-node-content"]}>
      <EditComponent key={node.id} node={node} onClose={onClose} />
    </div>
  );
};

export default EditNode;
