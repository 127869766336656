import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Badge, { type BadgeProps } from "components/base/Badge/Badge";
import Typography from "components/base/Typography";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 0px",
    margin: "0px 24px",
    borderBottom: "1px solid#E1DEDA",
  },
  badgeTitle: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
}));

interface Props {
  children?: React.ReactNode;
  title: string;
  subtitle: string;
  showBadge?: boolean;
  badgeDetails?: BadgeProps;
}

const ContentHeader: React.FC<Props> = ({
  children,
  title,
  subtitle,
  showBadge = false,
  badgeDetails = null,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <div className="page-title-container flex flex-col">
        <div className="flex col-gap-8">
          <Typography
            variant="textXl"
            className="w-bold"
            sx={{ color: "#282624" }}
          >
            {title}
          </Typography>
          {showBadge && !isEmpty(badgeDetails) && (
            <Badge
              icon={badgeDetails?.icon}
              title={badgeDetails?.title ?? ""}
              color={badgeDetails?.color}
              titleClassName={
                badgeDetails?.titleClassName ?? classes.badgeTitle
              }
            />
          )}
        </div>
        <Typography variant="textSm" sx={{ color: "#6D6D6D" }}>
          {subtitle}
        </Typography>
      </div>

      <div className="page-action-container">{children}</div>
    </Box>
  );
};

export default ContentHeader;
