import SingleMessageBanner, {
  BannerType,
} from "components/shared/SingleMessageBanner";
import { TextField } from "@mui/material";
import { GeneralInfoFields } from "./types";
import { useFormikContext } from "formik";
import { type GeneralInfoProps } from ".";

const input_box_placeholder =
  "E.g. TravelEase specialises in online flight bookings, providing customers with seamless services like flight reservations, cancellations, and travel support. Located in New York, we are committed to making travel easy and accessible for all.";

const CompanyInfo: React.FC = () => {
  const { values, handleBlur, handleChange } =
    useFormikContext<GeneralInfoProps>();

  return (
    <div className="flex-col row-gap-24">
      <SingleMessageBanner
        message={
          "Ideal format - [Company Name] focuses on [Company Specialization], offering [Key Services or Products]. Serving customers in [Regions or Countries Served], our goal is to [Company Mission or Objective]."
        }
        backgroundColor="#F7FCFF"
        borderColor="#82DAFF"
        type={BannerType.Info}
      />

      <TextField
        name={GeneralInfoFields.company_info_text}
        variant="outlined"
        size="small"
        placeholder={input_box_placeholder}
        fullWidth
        value={values.company_info_text}
        onChange={handleChange}
        onBlur={handleBlur}
        minRows={3}
        maxRows={10}
        multiline
      />
    </div>
  );
};

export default CompanyInfo;
