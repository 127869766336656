import { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { type IServiceNodeForm } from "types/Workflow";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabConfigure from "./TabConfigure";
import TabTest from "./TabTest";
import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";

const useStyles = makeStyles((theme) => ({
  "edit-container": {
    padding: "88px 24px 32px",
    rowGap: "24px",

    "& .MuiTab-root": {
      textTransform: "none",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },

    "& .tab-container": {
      marginTop: "24px",
    },
  },
}));

enum TabType {
  CONFIGURE = "CONFIGURE",
  TEST = "TEST",
}

const TabComponentMap: Record<TabType, any> = {
  [TabType.CONFIGURE]: TabConfigure,
  [TabType.TEST]: TabTest,
};

interface Props {
  node: INode;
}

const EditContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext<IServiceNodeForm>();
  const { session_context, updateNodeVariablesInContext } = useSessionContext();

  const {
    api_integration_valid,
    api_integration_id,
    test_api,
    input_variable_map,
    api_response,
    output_variable,
    name,
    description,
    fallback,
  } = values;

  const [tab, setTab] = useState<TabType>(TabType.CONFIGURE);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: TabType) => {
      setTab(newValue);
    },
    []
  );

  const handleUpdateSessionContext = (): void => {
    if (
      !api_response ||
      !(output_variable?.context && output_variable?.value) ||
      !session_context?.[output_variable?.context]
    ) {
      return;
    }

    const nodeMeta = {
      label: output_variable.value,
      nodeId: node.id,
      name,
      type: node.type,
      group: node.data.group,
      description,
      counter: node.data?.ui?.counter,
    };

    updateNodeVariablesInContext({
      context: output_variable.context,
      nodeMeta,
      variables: api_response,
    });
  };

  useEffect(() => {
    if (values?.api_integration_valid) {
      setTab(TabType.TEST);
    }
  }, [api_integration_valid]);

  useEffect(() => {
    if (api_integration_valid && api_response && output_variable) {
      handleUpdateSessionContext();
    }
  }, [api_integration_valid, api_response, output_variable]);

  useEffect(() => {
    if (
      api_integration_id &&
      test_api?.length === input_variable_map?.length &&
      fallback?.type
    ) {
      void setFieldValue("api_integration_valid", true);
    }
  }, [api_integration_id, test_api, input_variable_map]);

  const TabComponent = TabComponentMap?.[tab];

  return (
    <div className={classes["edit-container"]}>
      <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary">
        <Tab label="Configure" value={TabType.CONFIGURE} />
        <Tab
          label="Test"
          value={TabType.TEST}
          disabled={!values?.api_integration_valid}
        />
      </Tabs>

      <div className="tab-container">
        <TabComponent node={node} />
      </div>
    </div>
  );
};

export default EditContainer;
