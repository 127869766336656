import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getConnectorList } from "api/actions";
import CreateAction from "./CreateAction";
import type ActionConnector from "types/ActionConnector";
import GlobalSpinner from "components/shared/GlobalSpinner";
import ApiError from "components/shared/APIError";
import { type PostBotAction } from "types/BotAction";

interface CreateActionWrapperProps {
  isFormSubmitting: boolean;
  onCreateAction: (values: PostBotAction) => void;
}

const CreateActionWrapper: React.FC<CreateActionWrapperProps> = ({
  isFormSubmitting,
  onCreateAction,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const [connectorList, setConnectorList] = useState<ActionConnector[]>();

  const [showError, setShowError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const fetchConnectorList = useCallback(async () => {
    getConnectorList(token)
      .then((response) => {
        setConnectorList(response);
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  const onRetry = useCallback(async () => {
    await fetchConnectorList();
  }, [fetchConnectorList]);

  useEffect(() => {
    setIsLoading(true);
    void fetchConnectorList();
  }, [fetchConnectorList]);

  if (isLoading) {
    return <GlobalSpinner />;
  }

  if (showError) {
    return (
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ApiError
          message="Something went wrong. Click on retry to refetch actions"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetry={onRetry}
        />
      </Box>
    );
  }

  return (
    <>
      {connectorList ? (
        <CreateAction
          connectorList={connectorList}
          isFormSubmitting={isFormSubmitting}
          onCreateAction={onCreateAction}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CreateActionWrapper;
