import { useContext } from "react";
import AssistantContext from "../AssistantContext";
import AssistantHeader from "../components/AssistantHeader";
import {
  useAssistantBuilderStyles as useStyles,
  useChatboWidgetStyles,
} from "./styles";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { AssistantBuilderFields, type IAssistantBuilder } from "../types";
import AssistantComposition from "./AssistantComposition";
import { isEqual } from "lodash";
import { BotType } from "types/BotType";
import clsx from "clsx";
import ChatBotContainer from "pages/Bot/ChatBotContainer";

const validationSchema = yup.object({
  [AssistantBuilderFields.goal]: yup.string(),
  [AssistantBuilderFields.company_info_text]: yup.string(),
  [AssistantBuilderFields.guidelines_and_rules]: yup.string(),
  [AssistantBuilderFields.agents]: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      description: yup.string(),
    })
  ),
});

const FormContainer: React.FC = () => {
  const classes = useStyles();

  const { assistant } = useContext(AssistantContext);
  const { name, description } = assistant || {};

  return (
    <div className={classes.assistantBuilderContainer}>
      <AssistantHeader title={name} description={description}>
        <div className="flex col-gap-12">
          <Button size={ButtonSizes.small} variant={ButtonVariants.outlined}>
            Settings
          </Button>
          <Button size={ButtonSizes.small}>Publish</Button>
        </div>
      </AssistantHeader>

      <div className={classes.assistantBuilderMain}>
        <div className={classes.assistantBuilderContent}>
          <AssistantComposition />
        </div>
      </div>
    </div>
  );
};

const AssistantBuilder: React.FC = () => {
  const classes = useChatboWidgetStyles();

  const assistantContext = useContext(AssistantContext);
  const { assistant } = assistantContext ?? {};
  const { channel_settings } = assistant.settings ?? {};

  const initialValue: IAssistantBuilder = {
    [AssistantBuilderFields.goal]: assistant?.general_info?.goal ?? "",
    [AssistantBuilderFields.company_info_text]:
      assistant?.general_info?.company_info_text ?? "",
    [AssistantBuilderFields.agents]: [],
    [AssistantBuilderFields.guidelines_and_rules]:
      assistant?.general_info?.guidelines_and_rules ?? "",
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        <FormContainer />
      </Formik>

      {isEqual(
        channel_settings?.enabled_channels?.[0],
        BotType.VoiceBot
      ) ? null : (
        <div className={clsx(classes.chatbotWidgetContainer)}>
          <ChatBotContainer
            botConfig={{ minimizeIcon: true, isChatOpen: false }}
            botData={assistant}
          />
        </div>
      )}
    </>
  );
};

export default AssistantBuilder;
