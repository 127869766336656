import {
  type ActionParam,
  type ConnectorActionsConfig,
} from "pages/ActionBuilder/components/AgentActions.const";
import { createReducer } from "../util";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { type PayloadAction } from "@reduxjs/toolkit";

interface ActionBuilderState {
  actionForPreview: ConnectorActionsConfig | null;
  selectedConfigurableParam: ActionParam | null;
  selectedConfigurableAction: ConnectorActionsConfig | null;
}

interface ConnectorActionsConfigWithConnectionId
  extends ConnectorActionsConfig {
  connectionId: string;
}

const initialState: ActionBuilderState = {
  actionForPreview: null,
  selectedConfigurableParam: null,
  selectedConfigurableAction: null,
};

const ActionBuilderReducer = createReducer(initialState, {
  // Action to set Action for Preview
  [ActionBuilderActions.SET_PREVIEW_ACTION]: (
    state: ActionBuilderState,
    action: PayloadAction<ConnectorActionsConfigWithConnectionId>
  ) => {
    return {
      ...state,
      actionForPreview: action.payload,
    };
  },
  // Clearing PreviewAction
  [ActionBuilderActions.CLEAR_PREVIEW_ACTION]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      actionForPreview: null,
    };
  },
  // Action to set Param for select and configure
  [ActionBuilderActions.SET_SELECTED_CONFIGURABLE_PARAM]: (
    state: ActionBuilderState,
    action: PayloadAction<ActionParam>
  ) => {
    return {
      ...state,
      selectedConfigurableParam: action.payload,
    };
  },
  // Clearing Selected param to configure
  [ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      selectedConfigurableParam: null,
    };
  },
  // Action to set Action for select and configure
  [ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION]: (
    state: ActionBuilderState,
    action: PayloadAction<ConnectorActionsConfigWithConnectionId>
  ) => {
    return {
      ...state,
      selectedConfigurableAction: action.payload,
    };
  },
  // Clearing Selected action to configure
  [ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_ACTION]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      selectedConfigurableAction: null,
    };
  },
  [ActionBuilderActions.CLEAR_SLICE]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return initialState;
  },
});

export default ActionBuilderReducer;
