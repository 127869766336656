import { TextField } from "@mui/material";
import Typography, { TypographyWeights } from "aether/Typography";
import { patchAssistant } from "api/assistant/assistant";
import Modal from "components/base/Modal/Modal";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { type IAssistant } from "types/assistant.types";

const assistant_name_label = "Assistant Name";
const assistant_description_label = "Description";

interface Props {
  open: boolean;
  assistant: IAssistant;
  onClose: () => void;
  onEditSuccessCb: (data: {
    assistantId: string;
    name: string;
    description: string;
  }) => void;
}

const EditAssistant: React.FC<Props> = ({
  open,
  assistant,
  onClose,
  onEditSuccessCb,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    _id: assistantId,
    name: assistantName,
    description: assistantDescription,
  } = assistant || {};

  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState({
    name: assistantName,
    description: assistantDescription,
  });

  const handleChange = (key: string, value: string): void => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleBlur = (key: string, value: string): void => {};

  const handleConfirm = async (): Promise<void> => {
    if (!assistantId) return;

    setSaving(true);
    patchAssistant(assistantId, {
      name: assistant.name,
      description: assistant.description,
    })
      .then((response) => {
        handleClose();
        onEditSuccessCb({ assistantId: assistant._id, ...values });
        enqueueSnackbar(`Assistant details updated successfully.`, {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`Something went wrong, try again.`, {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleClose = (): void => {
    onClose();
    setSaving(false);
  };

  return (
    <Modal
      open={open}
      title={"Edit"}
      confirmText="Save"
      confirmDisabled={!values?.name}
      showConfirmLoading={saving}
      cancelText="Cancel"
      onClose={handleClose}
      onConfirm={() => {
        void handleConfirm();
      }}
      onCancel={handleClose}
    >
      <div className="flex-col row-gap-16 my-16">
        <div className="flex-col row-gap-4">
          <Typography weight={TypographyWeights.semiBold}>
            {assistant_name_label}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={values.name}
            onChange={(event) => {
              handleChange("name", event.target.value);
            }}
            onBlur={(event) => {
              handleBlur("name", event.target.value);
            }}
          />
        </div>

        <div className="flex-col row-gap-4">
          <Typography weight={TypographyWeights.semiBold}>
            {assistant_description_label}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            multiline
            minRows={5}
            maxRows={5}
            value={values.description}
            onChange={(event) => {
              handleChange("description", event.target.value);
            }}
            onBlur={(event) => {
              handleBlur("description", event.target.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditAssistant;
