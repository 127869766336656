import { botBEAxiosInstance as botBEAxios } from "services/axios";
import {
  type ActionParam,
  type ActionType,
  type ConnectorActionsConfig,
} from "../components/AgentActions.const";

export interface AddToolInput {
  agent_id: string;
  name: string;
  action_type: ActionType;
  config: {
    action_config_id: string;
    connection_id: string;
    input_params: ActionParam[];
    output_params: ActionParam[];
  };
}

interface Metrics {
  count: number;
  sub_count: number;
}

export interface GetAllActionTypeOutput {
  type: ActionType;
  name: string;
  description: string;
  metrics: Metrics;
  icon: string | null;
}

// pending temperory
const addIdToAllparams = (data: ConnectorActionsConfig[]): void => {
  data.forEach((action): void => {
    action.input_params = action.input_params.map((param) => {
      return { ...param, _id: Date.now() * Math.random() * 100 };
    });
    action.output_params = action.output_params.map((param) => {
      return { ...param, _id: Date.now() * Math.random() * 100 };
    });
  });
};

export const getAllActionType = async (): Promise<GetAllActionTypeOutput[]> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/action-types/`,
  });
  return res.data.action_types;
};

export const getConnectorActions = async (
  id: string
): Promise<ConnectorActionsConfig[]> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/actions?connector_id=${id}`,
  });
  addIdToAllparams(res?.data?.action_configs);
  return res?.data?.action_configs;
};

export const addTool = async (data: AddToolInput): Promise<any> => {
  return await botBEAxios({
    method: "POST",
    url: `/tools/`,
    data,
  });
};
