import Button, { ButtonColors, ButtonSizes } from "aether/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";
import SelectAssistantModal from "./SelectAssistantModal";

const create_assistant_button_label = "Create a new virtual assistant";

const CreateAssistantModal: React.FC = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  return (
    <div className="pt-32">
      <Button
        size={ButtonSizes.large}
        color={ButtonColors.primary}
        endIcon={<ArrowForwardIcon />}
        onClick={() => {
          setOpenCreateModal(true);
        }}
      >
        {create_assistant_button_label}
      </Button>

      <SelectAssistantModal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
        }}
      />
    </div>
  );
};

export default CreateAssistantModal;
