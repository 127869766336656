export enum BotResponseLength {
  CONCISE = "concise",
  MEDIUM = "medium",
  VERBOSE = "verbose",
}

export enum RadioValue {
  YES = "yes",
  NO = "no",
}

export enum WidgetState {
  OPEN = "open",
  CLOSED = "closed",
}
export enum BotUnableToRespond {
  END_CONVERSATION = "end_conversation",
  AGENT_HANDOFF = "agent_handoff",
  END_CONVERSATION_CREATE_TICKET = "end_conversation_create_ticket",
}

export enum ChannelType {
  Chat = "WEBCHAT_TEXT",
  Voice = "VOICE",
}

export interface IceBreakerAction {
  id: string;
  name: string;
}
export interface BotIceBreaker {
  id: number;
  action: IceBreakerAction | null;
  created: string;
  modified: string;
  title: string;
}
interface BotSettings {
  bot_display_name: string;
  enabled_channels: ChannelType[];
  header_message: string;
  welcome_message: string;
  input_box_placeholder: string;
  bot_response_length: BotResponseLength;
  show_source_of_info: RadioValue;
  request_feedback: RadioValue;
  display_nudge: RadioValue;
  nudge_message: string;
  default_widget_state: WidgetState;
  no_bot_response: BotUnableToRespond;
  status_banner: RadioValue;
  end_conversation_msg: string;
  end_user_type: string;
  // avatar: string;
  accent_color: string;
  suggestions: BotIceBreaker[];
}

export interface FieldIceBreaker {
  title: string;
  action: IceBreakerAction;
}

interface PostIceBreaker {
  title: string;
  action: IceBreakerAction | null;
}

export interface PostBotSettings extends Omit<BotSettings, "suggestions"> {
  suggestions: PostIceBreaker[];
}

export default BotSettings;
