import React from "react";
import useStyles from "./style";
import clsx from "clsx";
import Typography from "../../components/base/Typography";

type ChipVariant = "contained" | "outlined";
type ChipColor = "default" | "success" | "warning" | "danger" | "custom";
type ChipSize = "small" | "medium" | "large";

export interface CustomColorProps {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
}

export interface ChipProps {
  label: string;
  variant?: ChipVariant;
  color?: ChipColor;
  customColors?: CustomColorProps;
  size?: ChipSize;
  className?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      variant = "contained",
      color = "default",
      size = "small",
      label,
      className,
      customColors = { color: "#000000", backgroundColor: "#ffffff" },
      startIcon,
    },
    ref
  ) => {
    // Note: Color is internal props for variants, therefore passed as prop
    const classes = useStyles({ color, customColors });

    const chipClasses = clsx(
      classes.root,
      classes[variant],
      classes[size],
      className
    );

    return (
      <div ref={ref} className={chipClasses}>
        {startIcon && startIcon}
        <Typography className="w-large" style={{ fontWeight: 700 }}>
          {label}
        </Typography>
      </div>
    );
  }
);

Chip.displayName = "Chip";

export default Chip;
