// import React from "react";
import {
  type ConnectionLineComponentProps,
  // getSimpleBezierPath,
  getSmoothStepPath,
  // getStraightPath,
} from "reactflow";

function ConnectionEdge({
  fromX,
  fromY,
  toX,
  toY,
  connectionLineStyle,
  fromPosition,
  toPosition,
}: ConnectionLineComponentProps): any {
  const [edgePath] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
    sourcePosition: fromPosition,
    targetPosition: toPosition,
  });

  return <path style={connectionLineStyle} fill="none" d={edgePath} />;
}

export default ConnectionEdge;
