import { CircularProgress, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getAssistant } from "api/assistant/assistant";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { type IAssistant } from "types/assistant.types";
import AssistantContext from "./AssistantContext";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { useDispatch } from "store";
import { BotActions, SettingsActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme: Theme) => ({
  assistantContainer: {
    width: "calc(100vw - 72px)",
    height: "100vh",

    "& .loading-container": {},
  },
}));

const Assistant: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { assistantId } = params || {};

  const [assistant, setAssistant] = useState<IAssistant | null>(null);
  const [assistantState, setAssistantState] = useState({
    loading: true,
    error: null,
  });

  const getAssistantData = useCallback(async (id: string) => {
    setAssistantState((prevState) => ({ ...prevState, loading: true }));
    getAssistant(id)
      .then((response) => {
        const { data } = response || {};

        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_SUCCESS,
          payload: data.assistant.settings,
        });
        dispatch({
          type: BotActions.SET_BOT_META,
          payload: data.assistant,
        });

        setAssistant(data.assistant);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Something went wrong, try again.`, {
          variant: "error",
        });
      })
      .finally(() => {
        setAssistantState((prevState) => ({ ...prevState, loading: false }));
      });
  }, []);

  useEffect(() => {
    if (!assistantId) {
      return;
    }

    void getAssistantData(assistantId);
  }, [assistantId, getAssistantData]);

  if (!assistantId || !assistant || assistantState.loading) {
    return (
      <div className={clsx(classes.assistantContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.assistantContainer}>
      <AssistantContext.Provider
        value={{ assistantId: assistant._id, assistant }}
      >
        <Outlet />
      </AssistantContext.Provider>
    </div>
  );
};

export default Assistant;
