import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { Handle, Position } from "reactflow";
import { ReactComponent as BotIcon } from "assets/bot-icon.svg";
import clsx from "clsx";
import CircularProgressWithLabel from "components/shared/CircularProgressWithLabel";
import { useSelector } from "store";
import { DEFAULT_FILTER, PCTSortMetricMap } from "pages/BotTree/config";
import { type IFilter } from "types/BotTree";

const useStyles = makeStyles((theme) => ({
  "data-node-wrapper": {
    width: "224px",
    display: "inline-flex",
    padding: "12px 16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
  },
  "data-node-content": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "data-node-convo": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "10px",
  },
  "convo-outcome-wrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",

    "& .convo-outcome-pct": {
      width: "116px",
      height: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "stretch",
      columnGap: "2px",

      "& .bot-convo": {
        backgroundColor: "#8CD1C5",
        borderTopLeftRadius: "18px",
        borderBottomLeftRadius: "18px",
      },

      "& .agent-convo": {
        backgroundColor: "#FFCB9B",
      },

      "& .abandoned-convo": {
        backgroundColor: "#F18B97",
      },

      "& .undetermind-convo": {
        backgroundColor: "#E1DEDA",
        borderTopRightRadius: "18px",
        borderBottomRightRadius: "18px",
      },
    },
  },
  "data-node-pct": {
    width: "36px",
    height: "36px",
  },
}));

interface IDataNodeProps {
  id: string;
  data: any;
}

const DataNode: React.FC<IDataNodeProps> = ({ id, data }: IDataNodeProps) => {
  const classes = useStyles();

  const { stats } = data || {};

  /* eslint-disable */
  const {
    success_pct,
    failure_pct,
    abandoned_pct,
    transfer_pct,
    unknown_pct,
    freq_pct,
  } = stats;
  /* eslint-enable */

  const filters: IFilter = useSelector((state) => state.botTree);
  const { sortMetric = DEFAULT_FILTER.SORT_METRIC } = filters;
  const sortMetricKey = PCTSortMetricMap[sortMetric];
  const pctValue = Math.floor(stats?.[sortMetricKey] * 100);

  return (
    <Box className={classes["data-node-wrapper"]}>
      <Handle type="target" position={Position.Top} />

      <Box className={classes["data-node-content"]}>
        <Box className={classes["data-node-convo"]}>
          <BotIcon />

          <Box className={classes["convo-outcome-wrapper"]}>
            <Typography className="w-semi-bold" sx={{ color: "#000" }}>
              {data?.label}
            </Typography>

            <Box className={"convo-outcome-pct"}>
              <Box
                className="bot-convo"
                sx={{ width: success_pct * 100 }}
              ></Box>
              <Box
                className="agent-convo"
                sx={{ width: transfer_pct * 100 }}
              ></Box>
              <Box
                className="abandoned-convo"
                sx={{ width: (failure_pct + abandoned_pct) * 100 }}
              ></Box>
              <Box
                className="undetermind-convo"
                sx={{ width: unknown_pct * 100 }}
              ></Box>
            </Box>
          </Box>
        </Box>

        <Box className={clsx(classes["data-node-pct"])}>
          {Number(pctValue) &&
          Number(pctValue) >= 0 &&
          Number(pctValue) <= 100 ? (
            <CircularProgressWithLabel size={36} value={pctValue} />
          ) : null}
        </Box>
      </Box>

      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
};

export default DataNode;
