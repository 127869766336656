import Typography from "components/base/Typography";
import { useToolConfigureStyle as useStyle } from "./style";
import {
  TextField,
  Button as MuiButton,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import IconButton from "aether/IconButton";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import SelectAPIPopover from "./SelectAPIPopover";
import { useCallback, useContext, useEffect, useState } from "react";
import { type IScript, type ITool } from "./types";
import { FieldArray, useFormikContext } from "formik";
import { getAPIIntegrationList } from "api/workflow/integration";
import {
  deleteTool,
  getToolList,
  postTool,
  updateTool,
} from "api/bot/bot-agents";
import { formatToolList, formatToolsOptions } from "pages/Bot/utils";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import AgentBuilderContext from "../AgentBuilderContext";

const tool_configure_title = "Tool";
const tool_configure_subtitle =
  "Configure the APIs to connect your assistant with external systems for a specific task";

const ToolConfigure: React.FC = () => {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { assistantId: botId, agentId } = agentBuilderContext;

  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IScript>();
  const { general_tools } = values;

  const [loading, setLoading] = useState(true);
  const [apiOptions, setApiOptions] = useState<ITool[]>([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getAPIIntegrationList(botId), getToolList(agentId)])
      .then((result) => {
        const [apiIntegrationList, toolList] = result;

        setApiOptions(formatToolsOptions(apiIntegrationList));
        void setFieldValue(
          "general_tools",
          formatToolList(toolList, apiIntegrationList)
        );
      })
      .catch((error: any) => {
        enqueueSnackbar(`Some error occurred while details ${error}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSelectToolAPI = useCallback(
    async (position: number, selectedOption: any) => {
      if (!botId) return;

      try {
        const updatedToolsAPI = general_tools.map((tool, index) => {
          if (index === position) {
            return {
              ...tool,
              api_integration_name: selectedOption.api_integration_name,
              api_integration_id: selectedOption.api_integration_id,
              api_integration_description:
                selectedOption.api_integration_description,
              http_method: selectedOption.http_method,
            };
          }

          return tool;
        });
        void setFieldValue("general_tools", updatedToolsAPI);

        const response = await postTool({
          bot_id: botId,
          agent_id: agentId,
          name: general_tools[position].name,
          api_integration_id: selectedOption.api_integration_id,
        });
        updatedToolsAPI[position] = {
          ...updatedToolsAPI[position],
          _id: response._id,
        };
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while adding tool ${error}`, {
          variant: "error",
        });
      }
    },
    [general_tools]
  );

  const handleOnBlur = useCallback(
    async (event: any, position: number) => {
      if (!general_tools?.[position]._id || !general_tools?.[position]?.name)
        return;

      try {
        handleBlur(event);
        await updateTool({
          _id: general_tools?.[position]._id,
          name: general_tools?.[position].name,
        });
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving details ${error}`, {
          variant: "error",
        });
      }
    },
    [general_tools]
  );

  const handleOnRemove = useCallback(
    async (position: number) => {
      if (!general_tools?.[position]._id) return;

      try {
        await deleteTool(general_tools[position]._id);
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while deleting tool ${error}`, {
          variant: "error",
        });
      }
    },
    [general_tools]
  );

  if (loading) {
    return (
      <div
        className={clsx(classes.toolConfigureContainer, classes.toolLoading)}
      >
        <CircularProgress size={24} />
      </div>
    );
  }

  return (
    <div className={classes.toolConfigureContainer}>
      <div className="flex-col">
        <Typography className="w-bold">{tool_configure_title}</Typography>
        <Typography variant="textSm" className="w-medium">
          {tool_configure_subtitle}
        </Typography>
      </div>

      <FieldArray name={`general_tools`}>
        {({ insert, remove, push }) => (
          <div className={classes.toolApiList}>
            {general_tools.map((tool, index) => (
              <div key={index} className="tool-api-container">
                <div className="flex align-items-center align-self-stretch col-gap-8">
                  <TextField
                    name={`general_tools.${index}.name`}
                    size="small"
                    autoComplete="off"
                    placeholder="Tool name"
                    fullWidth
                    value={tool.name}
                    onChange={handleChange}
                    onBlur={(event) => {
                      void handleOnBlur(event, index);
                    }}
                  />

                  <IconButton
                    size="xsmall"
                    color="error"
                    onClick={() => {
                      remove(index);
                      void handleOnRemove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>

                {general_tools?.[index]?.api_integration_id ? (
                  <div className={classes.selectedAPIContainer}>
                    <div className="flex align-items-center col-gap-8">
                      <Typography className="w-semi-bold">
                        {general_tools?.[index]?.api_integration_name}
                      </Typography>
                      <div>{general_tools?.[index]?.http_method}</div>
                    </div>

                    <Typography variant="textSm" className="w-medium subtle">
                      {general_tools?.[index]?.api_integration_description}
                    </Typography>
                  </div>
                ) : (
                  <SelectAPIPopover
                    index={index}
                    options={apiOptions}
                    onSelect={(position, selectedOption) => {
                      void handleSelectToolAPI(position, selectedOption);
                    }}
                  />
                )}
              </div>
            ))}

            <MuiButton
              className="align-self-start"
              variant="text"
              startIcon={<PlusIcon />}
              sx={{ color: "#C9671D" }}
              size="small"
              onClick={() => {
                push({
                  name: "",
                  api_integration_id: "",
                  description: "",
                  http_method: "",
                });
              }}
            >
              Add new tool
            </MuiButton>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default ToolConfigure;
