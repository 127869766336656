import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useScriptStyle = makeStyles((theme: Theme) => ({
  scriptContainer: {},
  scriptContent: {
    display: "flex",
    columnGap: "32px",
    padding: "24px",
  },
  scriptToolContainer: {
    flex: 1.5,
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",
  },
}));

export const useScriptEditorStyle = makeStyles((theme: Theme) => ({
  scriptEditorContainer: {
    flex: 2,
    display: "flex",
    padding: "16px 24px",
    flexDirection: "column",
    alignItems: "stretch",
    alignSelf: "stretch",
    borderRadius: "8px",
    rowGap: "16px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  scriptLoading: {
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useTestAssistantStyle = makeStyles((theme: Theme) => ({
  testAssistantContainer: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  viewTranscriptContainer: {
    display: "none",
    padding: "8px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "24px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-muted, #FCFCFB)",
  },
}));

export const useToolConfigureStyle = makeStyles((theme: Theme) => ({
  toolConfigureContainer: {
    minHeight: "40vh",
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  toolLoading: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  toolApiList: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    paddingTop: "16px",
    rowGap: "16px",

    "& .tool-api-container": {
      display: "flex",
      padding: "16px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "16px",
      alignSelf: "stretch",
      borderRadius: "8px",
      border: "1px solid var(--border-default, #E1DEDA)",
      background: "var(--inactive-surfaces-default, #FFF)",
    },
  },
  apiContainer: {},
  selectedAPIContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    borderRadius: "8px",
    alignSelf: "stretch",
    border: "1px solid var(--border-default, #E7E5E1)",
    background: "var(--inactive-surfaces-muted, #FCFCFB)",
  },
}));

export const useSelectAPIPopoverStyle = makeStyles((theme: Theme) => ({
  selectAPIContainer: {
    display: "flex",
    width: "360px",
    padding: "12px 8px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
  },
  apiOptionContainer: {
    padding: 8,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#F9F8F8",
    },
  },
}));
