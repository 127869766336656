import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { type IBotPromptCarouselNodeData } from "types/Workflow";
import EditNodeHeader from "../../components/EditNodeHeader";
import EditContainer from "./EditContainer";

const useStyles = makeStyles((theme) => ({
  "edit-carousel-node": {},
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 16 characters allowed")
    .required("This is a required field"),
  description: yup.string(),
  fields_map: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string(),
        label: yup.string(),
        value: yup.string(),
        description: yup.string(),
        field_type: yup.string(),
        required: yup.boolean(),
      })
    )
    .test("field-required", "This field is required", (value) => {
      return value?.every((obj) => {
        if (obj.required) {
          return !!obj?.value;
        }
        return true;
      });
    }),
});

interface Props {
  node: any;
}

const FormContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();

  return (
    <Form className={classes["edit-carousel-node"]}>
      <EditNodeHeader node={node} />
      <EditContainer node={node} />
    </Form>
  );
};

const CarouselNode: React.FC<Props> = ({ node }) => {
  const { data } = node;

  const initialValues: IBotPromptCarouselNodeData = {
    name: data?.name,
    description: data?.description || "",
    fields_map: data?.fields_map,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={() => {}}
    >
      <FormContainer node={node} />
    </Formik>
  );
};

export default CarouselNode;
