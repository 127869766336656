import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NodeGroupsConfig } from "../NodeTypePanel/config";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
// import Collapse from "@mui/material/Collapse";
// import { formatSelectNodeType } from "pages/WorkflowBuilder/utils";
// import { CreateNodeType } from "pages/Bot/WorkflowBuilder/config";
import clsx from "clsx";
// import { type INode } from "types/Workflow";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme) => ({
  "node-select-wrapper": {
    "& .node-type-icon": {
      display: "flex",
      padding: "8px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "8px",
    },
    "& .subnode-type-icon": {
      display: "flex",
      padding: "8px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "8px",
    },
    "& .disabled-icon": {
      backgroundColor: "#EDEBE9 !important",
      "& svg": {
        "& path": {
          fill: "#B5B1AD",
        },
      },
    },
  },
  "select-node-container": {
    "&.MuiList-root": {
      minWidth: "200px",

      "& .MuiButtonBase-root": {
        padding: "8px 16px",

        "& .MuiListItemIcon-root": {
          minWidth: "46px",
        },
      },
    },
    "& .node-type-icon": {
      display: "flex",
      padding: "8px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "8px",
    },
    "& .subnode-type-icon": {
      display: "flex",
      padding: "8px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "8px",
    },
    "& .disabled-icon": {
      backgroundColor: "#EDEBE9 !important",
      "& svg": {
        "& path": {
          fill: "#B5B1AD",
        },
      },
    },
  },
}));

const SelectPanel = ({
  anchor,
  group,
  color,
  nodes,
  handleClickCreateNode,
  onClose,
}: {
  anchor: any;
  group: any;
  color: any;
  nodes: any;
  handleClickCreateNode: any;
  onClose: any;
}): JSX.Element => {
  const classes = useStyles();

  const open = Boolean(anchor);
  const id = open ? "node-select-item" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      // placement="right"
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List component="div" className={classes["select-node-container"]}>
        {nodes?.map(
          (
            { title: nodeTitle, type, icon: NodeIcon, enabled }: any,
            subnodeIndex: number
          ) => (
            <ListItemButton
              key={subnodeIndex}
              sx={{ pl: 4 }}
              onClick={() => {
                enabled && handleClickCreateNode(group, type);
              }}
            >
              <ListItemIcon>
                <div
                  className={clsx("subnode-type-icon", {
                    "disabled-icon": !enabled,
                  })}
                  style={{ backgroundColor: color }}
                >
                  <NodeIcon />
                </div>
              </ListItemIcon>
              <Tooltip
                title={enabled ? null : "Will be launched soon"}
                placement="right"
              >
                <ListItemText primary={nodeTitle} />
              </Tooltip>
            </ListItemButton>
          )
        )}
      </List>
    </Popover>
  );
};

interface Props {
  onCreateNode: (type: string, group: string) => void;
}

const NodeTypeSelect: React.FC<Props> = ({ onCreateNode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectNode, openCreateNodeDropdown } = useSelector(
    (state) => state.bot.workflow.workflow
  );

  const [selectGroup, setSelectGroup] = useState<any>();
  const [groupAnchor, setGroupAnchor] = useState<any>(null);

  const id = openCreateNodeDropdown ? "node-select-popover" : undefined;

  const handleSelectGroup = (event: any, selectedgroup: any): void => {
    setGroupAnchor(event.target);
    setSelectGroup(selectedgroup);
  };

  const handleClickCreateNode = (group: string, type: string): void => {
    setGroupAnchor(null);
    setSelectGroup(null);

    onCreateNode(group, type);
    handleOnClose();
  };

  const handleOnClose = (): void => {
    dispatch({
      type: WorkflowActions.SET_CREATE_NODE_DROPDOWN,
      payload: { openCreateNodeDropdown: false },
    });
    dispatch({
      type: WorkflowActions.SET_SELECT_NODE,
      payload: { id: null, anchor: null },
    });
  };

  return (
    <Popover
      id={id}
      open={openCreateNodeDropdown}
      anchorEl={selectNode.anchor}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List
        className={classes["node-select-wrapper"]}
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {NodeGroupsConfig.map(
          (
            { group, title, description, icon: GroupIcon, color, nodes },
            index
          ) => (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={(event: any) => {
                  handleSelectGroup(event, {
                    group,
                    title,
                    description,
                    icon: GroupIcon,
                    color,
                    nodes,
                  });
                }}
              >
                <ListItemIcon>
                  <div
                    className="node-type-icon"
                    style={{ backgroundColor: color }}
                  >
                    <GroupIcon />
                  </div>
                </ListItemIcon>
                <ListItemText primary={title} className="mr-2" />
                {/* {selectNodeGroup[group] ? <ExpandLess /> : <ExpandMore />} */}
                <ChevronRight />
              </ListItemButton>

              {/* <Collapse
                  in={selectNodeGroup[group]}
                  timeout="auto"
                  unmountOnExit
                >
                </Collapse> */}
              {/* <List component="div" disablePadding>
                  {nodes.map(
                    (
                      { title: nodeTitle, type, icon: NodeIcon, enabled },
                      subnodeIndex
                    ) => (
                      <ListItemButton
                        key={subnodeIndex}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          enabled && handleClickCreateNode(group, type);
                        }}
                      >
                        <ListItemIcon>
                          <div
                            className={clsx("subnode-type-icon", {
                              "disabled-icon": !enabled,
                            })}
                            style={{ backgroundColor: color }}
                          >
                            <NodeIcon />
                          </div>
                        </ListItemIcon>
                        <Tooltip
                          key={index}
                          title={enabled ? null : "Will be launched soon"}
                          placement="right"
                        >
                          <ListItemText primary={nodeTitle} />
                        </Tooltip>
                      </ListItemButton>
                    )
                  )}
                </List> */}

              <SelectPanel
                anchor={groupAnchor}
                {...selectGroup}
                handleClickCreateNode={handleClickCreateNode}
                onClose={() => {
                  setGroupAnchor(null);
                  setSelectGroup(null);
                }}
              />
            </React.Fragment>
          )
        )}
      </List>
    </Popover>
  );
};

export default NodeTypeSelect;
