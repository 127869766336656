import Chip from "aether/Chip";
import { useAssistantCardStyles as useStyles } from "../styles";
import { type IAssistant } from "types/assistant.types";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";
import AssistantActionMenu from "./AssistantActionMenu";
import useRouteNavigator from "hooks/useRouteNavigator";

interface Props {
  data: IAssistant;
  setRef: any;
  onUpdateAssistant: (updatedAssistant: IAssistant) => void;
  onRemoveAssistant: (removeAssistant: IAssistant) => void;
}

const AssistantCard: React.FC<Props> = ({
  data,
  setRef,
  onUpdateAssistant,
  onRemoveAssistant,
}) => {
  const classes = useStyles();
  const { gotoAssistant } = useRouteNavigator();

  return (
    <div ref={setRef} className={classes.assistantCardContainer}>
      <div className="card-header-container">
        <div className="assistant-meta-container">
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.bold}
          >
            {data?.name}
          </Typography>
          <Typography weight={TypographyWeights.medium}>
            {data?.description}
          </Typography>
        </div>
        <Chip label={"Draft"} />
      </div>
      <div className="card-action-container">
        <Typography
          variant={TypographyVariants.textSmall}
          color={TypographyColors.subtle}
          weight={TypographyWeights.medium}
        >
          Last edited few sec. ago
        </Typography>

        <div className="flex col-gap-16">
          <Button
            size={ButtonSizes.xsmall}
            onClick={() => {
              gotoAssistant(data._id);
            }}
          >
            View details
          </Button>
          <Button size={ButtonSizes.xsmall} variant={ButtonVariants.outlined}>
            Go to live
          </Button>
          <AssistantActionMenu
            assistant={data}
            onUpdateAssistant={onUpdateAssistant}
            onRemoveAssistant={onRemoveAssistant}
          />
        </div>
      </div>
    </div>
  );
};

export default AssistantCard;
