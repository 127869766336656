import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { ReactComponent as StepLabelCircle } from "assets/step-label-circle.svg";
import { ReactComponent as StepCheckedCircle } from "assets/step-checked-circle.svg";
import { ReactComponent as StepCheckIcon } from "assets/check.svg";
import { useFormikContext } from "formik";
// import * as yup from "yup";
import { TextField } from "@mui/material";
import { type IServiceNodeForm } from "types/Workflow";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import clsx from "clsx";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
import Steps from "./StepsConfiguration";
// import { IconButton } from "@mui/material";
// import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
// import {
//   APIServiceNodeConfig,
//   ServiceNodeType,
// } from "pages/Bot/WorkflowBuilder/config";
// import { ReactComponent as CloseIcon } from "assets/x-close.svg";
// import EditNodeHeader from "../../components/EditNodeHeader";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import { useDispatch } from "store";
// import { debounce } from "lodash";
import { WorkflowActions } from "store/reduxActions/botActions";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";

const useStyles = makeStyles((theme) => ({
  "tab-configure-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",

    "& .MuiStepLabel-root": {
      padding: "0px",
      // alignItems: "flex-start",
    },
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "2px",
    },
    "& .MuiStepConnector-line": {
      width: "1px",
      backgroundColor: "#E1DEDA",
    },
    "& .request-step-container": {
      "& .method-type": {
        width: "94px",
      },
    },

    "& .step-expand-icon-container": {
      width: "24px",
    },
    "& .checked-container": {
      position: "relative",

      "& .checked-icon": {
        position: "absolute",
        top: "3px",
        left: "3px",
      },
    },
    "& .MuiTab-root": {
      textTransform: "none",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
}));

const StepIconComponent = ({
  completed,
}: {
  completed: boolean;
}): JSX.Element => {
  if (completed) {
    return (
      <div className="checked-container">
        <StepCheckedCircle />
        <StepCheckIcon className="checked-icon" />
      </div>
    );
  }

  return <StepLabelCircle />;
};

interface Props {
  node: INode;
}

const TabConfigure: React.FC<Props> = ({ node }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, data } = node;

  const { values, touched, errors, handleBlur, handleChange, isValid, dirty } =
    useFormikContext<IServiceNodeForm>();

  const [stepsState, setStepsState] = useState({
    active: data?.ui?.stepsState?.active || 0,
    completed: data?.ui?.stepsState?.completed || Array(5).fill(false),
    expanded: data?.ui?.stepsState?.expanded || [true, ...Array(4).fill(false)],
  });

  const handleNextStep = (nextStep: number): void => {
    setStepsState((prevState) => {
      let { active, completed, expanded } = { ...prevState };

      completed[active] = true;
      expanded[active] = false;
      expanded[nextStep] = true;
      active = nextStep;

      dispatch({
        type: WorkflowActions.UPDATE_WORKFLOW_NODE,
        payload: {
          id,
          config: {
            ...values,
            valid: isValid && dirty,
            ui: { ...data?.ui, stepsState: { active, completed, expanded } },
          },
        },
      });

      return { active, completed, expanded };
    });
  };

  const handleClickCompletedStep = (index: number): void => {
    setStepsState((prevState) => {
      const { expanded } = { ...prevState };
      expanded[index] = !expanded[index];

      return { ...prevState, expanded };
    });
  };

  const handleEditFromKStep = (k: number): void => {
    setStepsState((prevState) => {
      let { active, completed, expanded } = { ...prevState };

      for (let i = k; i <= 4; i++) {
        completed[i] = false;
      }

      expanded = Array(5).fill(false);
      expanded[k] = true;
      active = k;

      dispatch({
        type: WorkflowActions.UPDATE_WORKFLOW_NODE,
        payload: {
          id,
          config: {
            ...values,
            valid: isValid && dirty,
            ui: { ...data?.ui, stepsState: { active, completed, expanded } },
          },
        },
      });

      return { active, completed, expanded };
    });
  };

  useEffect(() => {
    dispatch({
      type: WorkflowActions.UPDATE_WORKFLOW_NODE,
      payload: { id, config: values },
    });
  }, [values]);

  return (
    <div className={classes["tab-configure-container"]}>
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Node Name</Typography>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          placeholder="Node name"
          fullWidth
          inputProps={{
            maxLength: 24,
          }}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">
          Add Description for your reference (Optional)
        </Typography>
        <TextField
          name="description"
          variant="outlined"
          placeholder="Eg. This action reschedule the delivery date and time"
          multiline
          rows={4}
          fullWidth
          inputProps={{
            maxLength: 100,
          }}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
        />
      </div>

      <div className="api-service-integration">
        <Stepper orientation="vertical">
          {Object.keys(Steps).map((key, index) => (
            <Step
              key={key}
              active={stepsState.active === index}
              completed={stepsState.completed[index]}
              expanded={stepsState.expanded[index]}
            >
              <StepLabel
                className={clsx({
                  "cursor-pointer": stepsState.completed[index],
                })}
                StepIconComponent={(props) => (
                  <StepIconComponent completed={stepsState.completed[index]} />
                )}
                onClick={() => {
                  stepsState.completed[index] &&
                    handleClickCompletedStep(index);
                }}
              >
                {Steps?.[key]?.renderStepLabel?.({
                  ...values,
                  completed: stepsState.completed[index],
                  expanded: stepsState.expanded[index],
                })}
              </StepLabel>

              <StepContent>
                <div className="flex flex-col mt-2">
                  {Steps?.[key]?.renderStepContent?.({
                    handleNextStep,
                    handleEditFromKStep,
                    completed: stepsState.completed[index],
                    expanded: stepsState.expanded[index],
                  })}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

export default TabConfigure;
