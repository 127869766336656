import {
  CreateNodeType,
  EdgeType,
  NodeGroupType,
} from "pages/Bot/WorkflowBuilder/config";
import { uuid } from "pages/Bot/WorkflowBuilder/utils/workflow";
import { getStraightPath } from "reactflow";

export const createAddNodeAndEdge = (
  nodeId: string,
  position: { x: number; y: number },
  branchIndex: number,
  branchId: string | number
): any => {
  const positionX = position?.x + 320;
  const positionY = position?.y + 66 + branchIndex * 44;

  const targetPosition = {
    x: positionX,
    y: positionY,
  };

  const addNode = {
    id: uuid(),
    type: CreateNodeType.BRANCH_ADD_NODE,
    position: {
      x: targetPosition.x,
      y: targetPosition.y,
    },
    width: 80,
    height: 31,
    data: {
      label: `Add`,
      group: NodeGroupType.CREATE_NODE,
      branchIndex,
      alignment: "LTR",
      isRootNode: true,
      rootNodeParentId: nodeId,
    },
  };

  const addNodeEdge = {
    id: `${nodeId}=>${addNode.id}`,
    source: nodeId,
    target: addNode.id,
    sourceHandle: `handle-source-${nodeId}-${branchId}`,
    targetHandle: `handle-target-${addNode.id}-3`,
    type: EdgeType.PLACEHOLDER_EDGE,
  };

  return { addNode, addNodeEdge };
};

export const getOffsetForStraightPath = (
  source: { x: number; y: number },
  target: { x: number; y: number }
): any => {
  const [path, labelX, labelY, offsetX, offsetY] = getStraightPath({
    sourceX: source.x,
    sourceY: source.y,
    targetX: target.x,
    targetY: target.y,
  });
  console.log(path, labelX, labelY);

  return { offsetX, offsetY };
};
