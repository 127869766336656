import { makeStyles } from "@mui/styles";
import { ReactComponent as ChevronDownArrow } from "assets/chevron-down.svg";
import Typography from "components/base/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import NodesAccordion from "./NodeAccordion";

const useStyles = makeStyles((theme) => ({
  "nodes-type-panel-wrapper": {
    width: "320px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: "8px 16px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

    "& .MuiAccordion-root": {
      boxShadow: "unset",
      backgroundColor: "transparent",

      "&:before": {
        background: "transparent",
      },

      "&.Mui-expanded": {
        margin: "0px",
      },
    },

    "& .MuiAccordionSummary-root": {
      minHeight: "unset",
      padding: "0px",
    },

    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },

    "& .MuiAccordionDetails-root": {
      padding: "8px 8px",
    },

    "& .nodes-type-panel-details": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: "24px",
      padding: "24px 0px",
    },

    "& .nodes-type-icon": {
      display: "flex",
      padding: "8px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "8px",

      "&.disabled-icon": {
        backgroundColor: "#EDEBE9 !important",
        "& svg": {
          "& path": {
            fill: "#B5B1AD",
          },
        },
      },
    },
  },
}));

const NodesTypePanel: React.FC = () => {
  const classes = useStyles();
  return (
    <Accordion className={classes["nodes-type-panel-wrapper"]}>
      <AccordionSummary
        expandIcon={<ChevronDownArrow />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="textLg" className="w-semi-bold">
          Show nodes
        </Typography>
      </AccordionSummary>

      <AccordionDetails className="nodes-type-panel-details">
        <NodesAccordion />
      </AccordionDetails>
    </Accordion>
  );
};

export default NodesTypePanel;
