import { Navigate } from "react-router-dom";
import Source from "pages/Bot/Source";
import BotActions from "pages/Bot/BotActions";
import BotSettingsComponent from "pages/Bot/BotSettingsComponent";
import EmbedChatbot from "pages/Bot/EmbedChatbot";
import Dashboard from "pages/Dashboard";
import Workflows from "pages/Bot/Workflows";
import WorkflowBuilder from "pages/Bot/WorkflowBuilder";
import APIIntegration from "pages/Bot/APIIntegration";
import APIList from "pages/Bot/APIIntegration/components/APIList";
import Integration from "pages/Bot/APIIntegration/components/Integration";
import Appearance from "pages/Bot/Appearance";
import AgentOff from "pages/Bot/AgentHandoff";
import NatureOfResponse from "pages/Bot/NatureOfResponse";
import BotContainer from "pages/BotContainer/BotContainer";
import Agents from "pages/Bot/Agents";
import AgentBuilder from "pages/AgentBuilder";
import AgentGeneralInfo from "pages/AgentBuilder/GeneralInfo";
import AgentScript from "pages/AgentBuilder/Script";
import ActionBuilderContainer from "pages/ActionBuilder/components/ActionBuilderContainer";
import AgentComposition from "pages/AgentBuilder/AgentComposition";
import Assistant from "pages/Assistant";
import AssistantBuilder from "pages/Assistant/AssistantBuilder";
import IntegrationsContainer from "pages/Integrations/IntegrationsContainer";
import ConnectionConfigureContainer from "pages/Integrations/Content/configuration/ConnectionConfigureContainer";
import GlobalIntegrationsContainer from "pages/Integrations/GlobalIntegrationsContainer";
import ConnectorConfigureContainer from "pages/Integrations/Content/configuration/ConnectorConfigureContainer";

const routes = [
  {
    index: true,
    element: <Navigate to="assistant" />,
  },
  {
    path: "assistant",
    element: <Dashboard />,
  },
  {
    path: "assistant/:assistantId",
    element: <Assistant />,
    children: [
      {
        index: true,
        element: <AssistantBuilder />,
      },
      {
        path: "agent/:agentId",
        element: <AgentBuilder />,
      },
    ],
  },
  {
    path: "bot/chat/:botId",
    element: <BotContainer />,
    children: [
      {
        index: true,
        element: <Navigate to="knowledge-bot" />,
      },
      {
        path: "knowledge-bot",
        element: <Source />,
      },
      {
        path: "actions",
        element: <BotActions />,
      },
      {
        path: "settings",
        element: <BotSettingsComponent />,
      },
      {
        path: "workflows",
        element: <Workflows />,
      },
      {
        path: "agents",
        element: <Agents />,
      },
      {
        path: "api-integration",
        element: <APIIntegration />,
        children: [
          {
            index: true,
            element: <APIList />,
          },
          {
            path: "editor/:integrationId?",
            element: <Integration />,
          },
        ],
      },
      {
        path: "appearance",
        element: <Appearance />,
      },
      {
        path: "agent-handoff",
        element: <AgentOff />,
      },
      {
        path: "nature-of-response",
        element: <NatureOfResponse />,
      },
      {
        path: "embed-chatbot",
        element: <EmbedChatbot />,
      },
    ],
  },
  {
    path: "bot/voice/:botId",
    element: <BotContainer />,
    children: [
      {
        index: true,
        element: <Navigate to="agents" />,
      },
      {
        path: "agents",
        element: <Agents />,
      },
      {
        path: "api-integration",
        element: <APIIntegration />,
        children: [
          {
            index: true,
            element: <APIList />,
          },
          {
            path: "editor/:integrationId?",
            element: <Integration />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="." />,
      },
    ],
  },
  {
    path: "bot/chat/:botId/workflow-builder/:workflowId?",
    element: <WorkflowBuilder />,
  },
  {
    path: "bot/:botId/agent-builder/:agentId",
    element: <AgentBuilder />,
    children: [
      {
        index: true,
        element: <Navigate to="composition" />,
      },
      {
        path: "general-info",
        element: <AgentGeneralInfo />,
      },
      {
        path: "script",
        element: <AgentScript />,
      },
      {
        path: "composition",
        element: <AgentComposition />,
      },
      {
        path: "*",
        element: <Navigate to="." />,
      },
    ],
  },
  {
    path: "bot/:botId/action-builder/:agentId?",
    element: <ActionBuilderContainer />,
  },
  {
    path: "integrations",
    element: <GlobalIntegrationsContainer />,
    children: [
      {
        index: true,
        element: <IntegrationsContainer />,
      },
      {
        path: "connection/:connectionId",
        element: <ConnectionConfigureContainer />,
      },
      {
        path: "connector/:connectorId",
        element: <ConnectorConfigureContainer />,
      },
    ],
  },
];

export default routes;
