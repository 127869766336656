import { forwardRef } from "react";
import {
  // InputAdornment,
  Paper,
  Popper,
  // TextField,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Typography from "components/base/Typography";
import ContextOption from "./ContextOption";
// import { ReactComponent as SearchIcon } from "assets/search-icon.svg";

const useStyles = makeStyles((theme: Theme) => ({
  "template-popover-container": {
    zIndex: "1",
  },
  "template-popover-paper": {
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--Base-White, #FFF)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",

    "& .template-dropdown-container": {
      overflowY: "auto",

      "& .context-group-container": {
        // borderTop: "1px solid #E7E5E1",
        // paddingTop: "12px",
        // paddingBottom: "12px",
      },

      "&::-webkit-scrollbar": {
        width: "0.1em",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "10px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
        borderRadius: "10px",
        backgroundColor: "#555",
      },
    },
  },

  "context-option-dropdown": {
    maxHeight: "340px",
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--Base-White, #FFF)",
    boxShadow:
      "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
      borderRadius: "10px",
      backgroundColor: "#555",
    },
  },
}));

interface IGroupOption {
  index?: number;
  key: string;
  value: string | number;
  icon?: any; // !!! Need to set this type for SVH React element, so as to render as in this file or JSX element; == icon: React.FC<React.SVGProps<SVGSVGElement>>
  options?: IGroupOption[];
  meta?: any;
}

export interface IVariable {
  key: string;
  value: string;
}

interface Props {
  anchor: any;
  open: boolean;
  value: any;
  options: IGroupOption[];
  onSelect: (value: string, variable: { key: string; value: string }) => void;
  onClose: () => void;
}

const TemplatePopover = forwardRef<any, Props>(function PopoverComponent(
  { anchor, open, value, options, onSelect, onClose },
  ref
) {
  const classes = useStyles();

  const handleSelect = (variable: IVariable): void => {
    // console.log(value, variable);
    onSelect(value, variable);
    onClose();
  };

  return (
    <Popper
      id={"template-popover-container"}
      className={classes["template-popover-container"]}
      open={open}
      anchorEl={anchor}
      placement="bottom-end"
      disablePortal
    >
      <Paper
        className={clsx(
          classes["template-popover-paper"],
          "flex flex-col align-items-stretch px-8 py-16"
        )}
        style={{ minWidth: 360, width: anchor?.offsetWidth, height: 360 }}
      >
        <div className="template-dropdown-container">
          {/* <div className="context-search-container flex flex-col align-items-stretch pb-2 px-2">
            <TextField
              size="small"
              className="template-textfield"
              placeholder="Search parameter"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          </div> */}

          <div>
            {options?.map(
              (
                {
                  index: panelIndex,
                  key: groupKey,
                  value: groupValue,
                  meta,
                  options: contextOptions,
                },
                index: number
              ) => {
                const GroupIcon = meta?.icon;

                if (!contextOptions?.length) {
                  return null;
                }

                return (
                  <div key={index} className="context-group-container">
                    <div className="group-header flex align-items-center col-gap-8 py-1 px-2">
                      <Typography
                        variant="textTiny"
                        sx={{ color: "#7C7972" }}
                        className="w-semi-bold"
                      >
                        {groupKey}
                      </Typography>
                      {GroupIcon && <GroupIcon />}
                    </div>

                    <div>
                      {contextOptions?.map((option, k) => (
                        <ContextOption
                          path={`${groupValue}.${option.value}`}
                          key={k}
                          option={option}
                          onSelect={handleSelect}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </Paper>
    </Popper>
  );
});

export default TemplatePopover;
