import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import Chip from "@mui/material/Chip";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import { ReactComponent as EditIcon } from "assets/edit-icon.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/thumbs-up-icon.svg";
import { ReactComponent as CloudUploadIcon } from "assets/upload-cloud-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/external-link-icon.svg";
import { ReactComponent as EllipseCircle } from "assets/ellipse-circle.svg";
import { ReactComponent as TagIcon } from "assets/tag-icon.svg";
import Button from "components/base/Button";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { type ISuggestionCard } from "types/FAQSuggestions";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  "suggestion-card-container": {
    borderRadius: "16px",
    background: "#FFF",
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

    "& .question-container": {
      padding: "24px 24px 16px",

      "& .card-header": {
        "& .card-action": {
          "& .icon-btn": {
            height: "40px",
            width: "40px",
            padding: "8px",
          },
        },
      },

      "& .answer-container": {
        minHeight: "84px",

        "& .answer-body": {
          width: "calc(100% - 300px)",
        },
      },
    },

    "& .card-footer": {
      padding: "12px 24px",

      "& .sources": {
        "& .external-source-anchor": {},
      },
    },
  },
}));

const SuggestionCard: React.FC<ISuggestionCard> = ({
  id,
  question,
  answer,
  sources,
  created,
  modified,
  tags,
  state,
}) => {
  const classes = useStyles();

  const importanceTag = tags?.find((tag) => tag.tag_type === "IMPORTANCE");
  const categoryTag = tags?.find((tag) => tag.tag_type === "CATEGORY");

  const source = sources?.[0];

  return (
    <div className={classes["suggestion-card-container"]}>
      <div className="question-container">
        <div className="card-header flex justify-content-between align-items-center">
          <div className="flex align-items-center col-gap-12">
            <Typography variant="textLg" className="w-bold">
              {question}
            </Typography>

            {importanceTag && (
              <div className="tag-container flex align-items-center col-gap-8">
                <Chip
                  label={importanceTag?.name}
                  sx={{
                    color: importanceTag?.color,
                    backgroundColor: importanceTag?.background_color,
                  }}
                />

                <Tooltip title={importanceTag?.more_info}>
                  <InfoIcon style={{ height: "24px", width: "24px" }} />
                </Tooltip>
              </div>
            )}
          </div>

          <div className="card-action flex align-items-center col-gap-16">
            <Button
              className="icon-btn"
              variant="outlined"
              color={"secondary"}
              size="small"
              disabled
            >
              <EditIcon />
            </Button>

            <Button
              className="icon-btn"
              variant="outlined"
              color={"secondary"}
              size="small"
              disabled
            >
              <ThumbsUpIcon />
            </Button>

            <Button
              className="icon-btn"
              variant="outlined"
              color={"primary"}
              size="small"
              disabled
            >
              <CloudUploadIcon />
            </Button>
          </div>
        </div>

        <div className="answer-container justify-content-between flex align-items-center">
          <div className="answer-body">
            <Typography variant="textLg">{answer}</Typography>
          </div>

          <Button
            className="icon-btn align-self-end"
            variant="text"
            color={"secondary"}
            size="small"
            disabled
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>

      <Divider />

      <div className="card-footer flex justify-content-between align-items-center">
        <div className="sources">
          {source?.url && (
            <Link
              className="external-source-anchor flex align-items-center col-gap-4"
              to={source?.url}
            >
              <Typography className="w-bold" sx={{ color: "#282624" }}>
                {source?.name}
              </Typography>
              <ExternalLinkIcon />
            </Link>
          )}
        </div>

        <div className="meta-container flex align-items-center col-gap-16">
          {modified && <Typography>{modified}</Typography>}

          {categoryTag && (
            <div className="meta-tag flex align-items-center col-gap-16">
              <EllipseCircle />
              <div className="flex align-items-center col-gap-8">
                <TagIcon />
                <Typography>{categoryTag?.name}</Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestionCard;
