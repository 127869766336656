// import { useContext } from "react";
import Typography from "components/base/Typography";
import { useFormikContext } from "formik";
import { MenuItem, TextField } from "@mui/material";
import { type IServiceNodeForm } from "types/Workflow";
import Button from "components/base/Button";
import { ContextVariableConfig } from "pages/Bot/WorkflowBuilder/config";
import { useRef } from "react";
import { type IStepContentProps } from "pages/Bot/WorkflowBuilder/types";

const StepAPIOutput: React.FC<IStepContentProps> = ({
  handleNextStep,
  handleEditFromKStep,
  completed,
}) => {
  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    errors,
    // isValid,
    // dirty,
  } = useFormikContext<IServiceNodeForm>();

  const activeStep = useRef<number>(2);

  const handleClickContinue = (event: any): void => {
    event.preventDefault();

    // onSave({ ...values, valid: isValid && dirty });
    handleNextStep(activeStep.current + 1);
  };

  const enableContinue =
    values.output_variable.key && values.output_variable.context;

  return (
    <div className="flex flex-col align-items-stretch mt-2 row-gap-16">
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Give it a name</Typography>
        <TextField
          name={`output_variable.value`}
          variant="outlined"
          size="small"
          placeholder=""
          fullWidth
          value={values.output_variable.value}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched?.output_variable?.value &&
            Boolean(errors.output_variable?.value)
          }
          helperText={
            touched?.output_variable?.value && errors.output_variable?.value
          }
          disabled={completed}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">
          Add description for reference
        </Typography>
        <TextField
          name={`output_variable.description`}
          variant="outlined"
          placeholder="Eg. This action reschedule the delivery date and time"
          multiline
          rows={4}
          fullWidth
          value={values.output_variable.description}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={completed}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Select Context</Typography>
        <TextField
          name={`output_variable.context`}
          variant="outlined"
          size="small"
          placeholder=""
          fullWidth
          value={values.output_variable?.context}
          onChange={handleChange}
          onBlur={(event) => {
            void setFieldValue(
              "output_variable.key",
              `${values.output_variable.context}.${values.output_variable.value}`
            );
            handleBlur(event);
          }}
          select
          error={
            touched?.output_variable?.context &&
            Boolean(errors?.output_variable?.context)
          }
          helperText={
            touched?.output_variable?.context &&
            errors?.output_variable?.context
          }
          disabled={completed}
        >
          {Object.keys(ContextVariableConfig)?.map(
            (key: any, index: number) => (
              <MenuItem key={key} value={key}>
                {ContextVariableConfig?.[key]?.label}
              </MenuItem>
            )
          )}
        </TextField>
      </div>

      <div className="mt-16">
        {completed ? (
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              handleEditFromKStep(activeStep.current);
            }}
          >
            Edit
          </Button>
        ) : (
          <Button
            size="small"
            color={"primary"}
            disabled={!enableContinue}
            onClick={handleClickContinue}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepAPIOutput;
