import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  "template-select-container": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",

    "& .template-select": {
      "& .text-input": {
        "-webkit-text-fill-color": "transparent",
      },
    },

    "& .textfield": {
      "& .DraftEditor-root": {
        width: "200px",
      },
    },
  },
}));

export const useTextEditorStyles = makeStyles((theme: Theme) => ({
  "TextEditor-root": {
    margin: "2px",
    position: "relative",

    "& .InputBase-root": {
      width: "100%",
      height: "40px",
      padding: "8.5px 10px",
      color: "rgb(255, 255, 255)",
      boxSizing: "border-box",
      cursor: "text",
      display: "inline-flex",
      "-webkit-box-align": "center",
      alignItems: "center",
      position: "relative",

      "&.is-focused": {
        height: "auto",

        "& .Editor-root": {
          width: "100%",

          "& .Editor-placeholder": {
            width: "100%",
            height: "auto",
          },

          "& .Editor-editorContainer": {
            width: "100%",

            "& .editor-content": {
              width: "100%",
              heigth: "unset",

              "& .editor-content-span": {
                height: "100%",
              },

              "& .resolve-variable": {},

              "& .offset-text": {},
            },
          },
        },
      },

      "& .Editor-root": {
        width: "100%",

        "& .Editor-placeholder": {
          position: "absolute",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "1.4375em",
          outline: "none",
          userSelect: "text",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          color: "#B5B1AD",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          zIndex: -1,
        },

        "& .Editor-editorContainer": {
          width: "100%",

          "& .editor-content": {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // height: "1.4375em",
            outline: "none",
            userSelect: "text",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            color: "#35312D",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",

            "& .editor-content-span": {
              direction: "ltr",
              textAlign: "left",
              display: "inline-flex",
              alignItems: "center",
              // overflow: "hidden",
              // whiteSpace: "normal",
              // height: "100%",
            },

            "& .resolve-variable": {
              display: "flex",
              padding: "2px 5px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              borderRadius: "4px",
              border: "1px solid var(--colors-primary-sky-300, #C1ECFD)",
              background: "var(--colors-primary-sky-100, #EAF9FE)",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "150%",
              fontFamily: "Manrope",
              color: "#3A91B4",
            },

            "& .offset-text": {
              width: "auto",
              "-webkit-user-modify": "read-write-plaintext-only",
              whiteSpace: "nowrap !important",
              overflow: "hidden",
              direction: "ltr",
              textAlign: "left",
            },
          },
        },
      },

      "& .outline-notch": {
        height: "100%",
        position: "absolute",
        textAlign: "left",
        inset: "0px 0px 0px",
        margin: "0px",
        padding: "0px 8px",
        pointerEvents: "none",
        borderStyle: "solid",
        borderWidth: "1px",
        overflow: "hidden",
        minWidth: "0%",
        border: "1px solid #E1DEDA",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

        "&.outline-notch-focused": {
          height: "auto",
          borderWidth: "2px",
          borderColor: "#FF7D04 !important",
          boxShadow: "0px 0px 0px 4px rgba(255, 125, 4, 0.13) !important",
        },
      },

      "&:hover": {
        "& .outline-notch": {
          borderColor: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
  },
}));
