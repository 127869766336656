import type React from "react";
import { useState } from "react";
import useStyles from "./ToggleButtons.styles";
import clsx from "clsx";

export interface ButtonOption {
  label: string;
  icon: React.ReactNode;
  id: string; // Unique identifier for each button
  disabled?: boolean; // Optional disabled prop default false
}

interface ToggleButtonsProps {
  button1: ButtonOption; // First button option
  button2: ButtonOption; // Second button option
  initialSelected?: string; // Initial selected id
  onToggle?: (id: string) => void; // Callback for when a button is toggled
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  button1,
  button2,
  initialSelected,
  onToggle,
}) => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState<string>(
    initialSelected ?? button1.id
  );

  const handleToggle = (id: string): void => {
    if (onToggle) {
      onToggle(id);
    }
    setActiveButton(id);
  };

  return (
    <div className={classes.toggleContainer}>
      {[button1, button2].map(({ label, icon, id, disabled }) => (
        <div
          key={id}
          className={clsx(classes.button, {
            [classes.active]: activeButton === id,
            [classes.inactive]: activeButton !== id,
            [classes.disabled]: disabled,
          })}
          onClick={() => {
            !disabled && handleToggle(id);
          }}
        >
          {icon}
          {label}
        </div>
      ))}
    </div>
  );
};

export default ToggleButtons;
