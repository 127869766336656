import { createContext } from "react";

export interface BotContextProps {
  id: string;
  name: string;
  description: string;
  settings: {
    enabled_channels: string[];
  };
}

const BotContext = createContext<BotContextProps>({
  id: "",
  name: "",
  description: "",
  settings: {
    enabled_channels: [],
  },
});
export default BotContext;
