// import { type Theme } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import {
  setValueToView,
  // createPlaceholderSpanElement,
  // moveCursorOnFocus,
  createPlaceholderSpan,
} from "./utilsV2";
import { handleKeydownChange, handleKeyupChange } from "./handlersV2";
import { useTextEditorStyles as useStyles } from "./style";

interface Props {
  id: string;
  value: string;
  placeholder?: string;
  focused: boolean;
  handleOpenPopover: () => void;
  handleClosePopover: () => void;
  onChange: (event: any) => void;
  onFocus: (event: any) => void;
  onBlur: (event: any) => void;
}

const TextEditor: React.FC<Props> = ({
  id,
  placeholder,
  value,
  focused,
  handleOpenPopover,
  handleClosePopover,
  onChange,
  onFocus,
  onBlur,
}) => {
  const classes = useStyles();

  const editorContentRef = useRef<any>();
  const renderCount = useRef(0);

  useEffect(() => {
    if (value && renderCount.current === 0) {
      setValueToView(value, id);
    }
    renderCount.current += 1;
  }, []);

  useEffect(() => {
    if (editorContentRef && !editorContentRef.current.childNodes.length) {
      createPlaceholderSpan(id);
    }
  }, [editorContentRef, id]);

  // CONTROLLED METHODS
  const handleOnFocus = (event: any): void => {
    event.preventDefault();
    onFocus(event);
  };

  const handleOnKeyDown = (event: any): void => {
    handleKeydownChange({
      id,
      event,
      onChange,
      openDropdown: handleOpenPopover,
      closeDropdown: handleClosePopover,
    });
  };

  const handleOnKeyUp = (event: any): void => {
    handleKeyupChange({
      id,
      event,
      onChange,
      openDropdown: handleOpenPopover,
      closeDropdown: handleClosePopover,
    });
  };

  const handleOnBlur = (event: any): void => {
    onBlur(event);
  };

  return (
    <div className={classes["TextEditor-root"]}>
      <div className={clsx("InputBase-root", { "is-focused": focused })}>
        <div className="Editor-root">
          {!value && <div className="Editor-placeholder">{placeholder}</div>}

          {/* <div className="Editor-editorContainer">
            <div
              ref={editorContentRef}
              id={`template-textfield-editor--${id}`}
              className="editor-content"
              contentEditable
              onKeyDown={handleOnKeyDown}
              onKeyUp={handleOnKeyUp}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            ></div>
          </div> */}

          <div className="Editor-editorContainer-v2">
            <div
              ref={editorContentRef}
              id={`textarea-editor--${id}`}
              className="editor-content-area"
              contentEditable
              onKeyDown={handleOnKeyDown}
              onKeyUp={handleOnKeyUp}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              spellCheck="false"
            ></div>
          </div>
        </div>

        <fieldset
          className={clsx("outline-notch", {
            "outline-notch-focused": focused,
          })}
        ></fieldset>
      </div>
    </div>
  );
};

export default TextEditor;
