import { makeStyles } from "@mui/styles";
import { Tooltip, type Theme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "components/base/Typography";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import { getNodeIconAndColor } from "pages/Bot/WorkflowBuilder/utils/workflow";

const useStyles = makeStyles((theme: Theme) => ({
  "node-info-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "24px",
    width: "24px",
    background: "white",
    borderRadius: "8px",

    "& svg": {
      height: 16,
      width: 16,

      "& path": {
        fill: "#35312D",
      },
    },

    "&:hover": {
      "& svg": {
        "& path": {
          fill: "#C9671D",
        },
      },
    },
  },
  "tooltip-container": {
    display: "flex",
    width: "332px",
    padding: "16px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--bg-disabled, #F9F8F8)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",

    "& .node-container": {
      width: "100%",
      display: "flex",
      padding: "12px 16px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "8px",
      alignSelf: "stretch",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",

      "& .node-icon": {
        display: "flex",
        padding: "8px",
        alignItems: "flex-start",
        gap: "10px",
        borderRadius: "8px",
        background: "var(--colors-primary-sky-600, #78C7E7)",

        "& svg": {
          height: 20,
          width: 20,
        },
      },
    },
  },
}));

const InfoPopper = ({ meta }: { meta: any }): JSX.Element => {
  const classes = useStyles();

  const { ICON, COLOR } = getNodeIconAndColor({
    group: meta?.group,
    type: meta?.type,
  });

  return (
    <Paper className={classes["tooltip-container"]}>
      <Typography className="pb-1" sx={{ color: "#B5B1AD" }}>
        Source
      </Typography>

      <div className="node-container">
        <div className="w-100 node-header flex col-gap-12">
          <div className="node-icon" style={{ backgroundColor: COLOR }}>
            {ICON && <ICON />}
          </div>
          <div className="w-100  flex flex-col row-gap-2">
            <div className="flex justify-content-between align-items-center">
              <Typography
                variant="textTiny"
                className="w-bold"
                sx={{ color: "#B5B1AD" }}
              >
                {meta?.type}
              </Typography>
              <Typography
                variant="textTiny"
                className="w-bold"
                sx={{ color: "#B5B1AD" }}
              >
                {String(meta?.counter).padStart(3, "0")}
              </Typography>
            </div>

            <Typography className="w-semi-bold">{meta?.name}</Typography>
          </div>
        </div>

        {meta?.description && (
          <div className="node-content">
            <Typography variant="textSm" sx={{ color: "#7C7972" }}>
              {meta.description}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};

interface Props {
  meta: any;
}

const NodeTooltip: React.FC<Props> = ({ meta }) => {
  const classes = useStyles();

  if (!meta) {
    return null;
  }

  return (
    <div className={classes["node-info-container"]}>
      <Tooltip
        placement="bottom-start"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "transparent",
            },
          },
        }}
        title={<InfoPopper meta={meta} />}
      >
        <InfoIcon />
      </Tooltip>
    </div>
  );
};

export default NodeTooltip;
