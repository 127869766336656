/**
 * Condition Unit: Structure for smallest condition unit, which is single IF block
 */
import { makeStyles } from "@mui/styles";
import TemplateSelect from "components/base/TemplateSelectV2";
import TextField from "@mui/material/TextField";
import {
  type INode,
  type IConditionUnit,
} from "pages/Bot/WorkflowBuilder/types";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import { useFormikContext } from "formik";
// import { useContext } from "react";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import { formatSessionContextToOptions } from "pages/Bot/WorkflowBuilder/utils/session-context";
import { OPERATOR_LABELS } from "pages/Bot/WorkflowBuilder/config";
import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
import { getOperatorList } from "pages/Bot/WorkflowBuilder/utils/workflow";

const useStyles = makeStyles((theme) => ({
  "condition-unit-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    width: "100%",

    "& .operator-container": {
      width: "120px",

      "& .MuiSelect-icon": {
        top: "unset",
        right: "8px",
      },
    },
  },
}));

interface Props extends IConditionUnit {
  node: INode;
  branchIndex: number;
  elementIndex: number;
  unitIndex: number;
}

const ConditionUnit: React.FC<Props> = ({
  node,
  branchIndex,
  elementIndex,
  unitIndex,
  expr1,
  operator,
  expr2,
}) => {
  const classes = useStyles();

  const { values } = useFormikContext<any>();
  const { branches } = values;

  const {
    session_context,
    // updateNodeVariablesInContext,
    getNodeVariableOptions,
  } = useSessionContext();
  const options = getNodeVariableOptions(node.id);

  const { handleBlur, handleChange, setFieldValue } = useFormikContext<any>();

  const operatorList = getOperatorList(
    branches[branchIndex].conditions[elementIndex][unitIndex].expr1,
    session_context
  );

  return (
    <div className={classes["condition-unit-container"]}>
      <TemplateSelect
        name={`branches.[${branchIndex}].conditions.[${elementIndex}].[${unitIndex}].expr1`}
        className="field-container"
        value={expr1}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setFieldValue={setFieldValue}
        variant={"outlined"}
        placeholder="Select"
      />

      <TextField
        name={`branches.[${branchIndex}].conditions.[${elementIndex}].[${unitIndex}].operator`}
        className="operator-container"
        value={operator}
        onChange={handleChange}
        onBlur={handleBlur}
        select
        size="small"
        SelectProps={{
          IconComponent: ChevronDown,
        }}
        placeholder="Operator"
        disabled={!operatorList.length}
      >
        {operatorList.map((operator: string, index: number) => (
          <MenuItem key={operator} value={operator}>
            {OPERATOR_LABELS[operator]}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        name={`branches.[${branchIndex}].conditions.[${elementIndex}].[${unitIndex}].expr2`}
        className="value-container"
        value={expr2}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        placeholder="Enter Value"
        size="small"
        disabled={!expr1}
      />
    </div>
  );
};

export default ConditionUnit;
