/**
 * NodeContainer: Component to encapsulate Custom-Node, with defining click and providing poppers
 */
import { makeStyles } from "@mui/styles";
// import Typography from "components/base/Typography";
// import { Handle, NodeToolbar, Position } from "reactflow";
// import clsx from "clsx";
import { useDispatch, useSelector } from "store";
import {
  CreateNodeType,
  // ServiceNodeConfig,
  // TERMINAL_NODES,
} from "pages/Bot/WorkflowBuilder/config";
// import { type IServiceNodeData } from "types/Workflow";
// import { Icon, IconButton, Paper, Popover, Popper } from "@mui/material";
// import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { useCallback, useMemo } from "react";
import { WorkflowActions } from "store/reduxActions/botActions";
import { type NodeRenderProps } from "pages/Bot/WorkflowBuilder/types";

const useStyles = makeStyles((theme) => ({
  "node-container": {},
  "node-add-popper": {
    "& .node-add-container": {
      height: "32px",
      width: "32px",
      borderRadius: "100%",
      marginLeft: "8px",
      background: "#FFF",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

      "& .node-add-button": {
        padding: 8,
        "& svg": {
          height: "16px",
          width: "16px",
        },
      },
    },
  },
}));

interface Props {
  id: string;
  type: string;
  renderNode: (nodeRenderProps: NodeRenderProps) => React.ReactNode;
}

const NodeContainer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, type, renderNode } = props;

  const { hoverNode } = useSelector((state) => state.bot.workflow.workflow);

  // const isTerminalNode = useMemo(() => {
  //   const hasOutgoingEdges = edges.some((edge: IEdge) => edge.source === id);
  //   return !hasOutgoingEdges;
  // }, [id, hoverNode]);

  const isNodeCreateType = useMemo(() => {
    return (
      type === CreateNodeType.PLACEHOLDER_NODE ||
      type === CreateNodeType.BRANCH_ADD_NODE
    );
  }, [id, type]);

  // const showAddButton = useMemo(() => {
  //   return (
  //     hoverNode.id === id &&
  //     !isNodeCreateType &&
  //     isTerminalNode &&
  //     TERMINAL_NODES.includes(type)
  //   );
  // }, [id, hoverNode]);

  const handleNodeClick = useCallback(
    (event: any) => {
      if (isNodeCreateType) {
        handleClickCreateGroupNode(event);
      } else {
        handleClickNodeSelect(event);
      }
    },
    [id, hoverNode]
  );

  const handleClickCreateGroupNode = (event: any): void => {
    dispatch({
      type: WorkflowActions.SET_SELECT_NODE,
      payload: { id: id, anchor: event.currentTarget },
    });
    dispatch({
      type: WorkflowActions.SET_CREATE_NODE_DROPDOWN,
      payload: { openCreateNodeDropdown: true },
    });
  };

  const handleClickNodeSelect = (event: any): void => {
    dispatch({
      type: WorkflowActions.SET_SELECT_NODE,
      payload: { id: id, anchor: event.currentTarget },
    });
    dispatch({
      type: WorkflowActions.SET_OPEN_DRAWER,
      payload: { openDrawer: true },
    });
  };

  return (
    <div className={classes["node-container"]}>
      {renderNode({ onNodeClick: handleNodeClick })}

      {/* <Popper
        className={classes["node-add-popper"]}
        open={showAddButton}
        anchorEl={hoverNode.anchor}
        placement="right"
        disablePortal
      >
        <div className="node-add-container">
          <IconButton
            className="node-add-button"
            onClick={(event) => {
              handleClickAddButton(event);
            }}
          >
            <PlusIcon />
          </IconButton>
        </div>
      </Popper> */}
    </div>
  );
};

export default NodeContainer;
