import Modal from "components/base/Modal/Modal";
import { useState } from "react";
import Typography, { TypographyVariants } from "aether/Typography";
import { deleteAgent } from "api/bot/bot-agents";
import IconButton from "aether/IconButton";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";

const delete_agent_modal_title = "Delete the agent";
const delete_agent_modal_text =
  "Are you sure you would like to delete the agent. This action is non reversible.";
const delete_agent_confirm_button = "Delete";
const delete_agent_cancel_button = "Don't delete";

interface Props {
  agentId: string;
  onSuccessDeleteCb?: (data: any) => void;
}

const DeleteAgentModal: React.FC<Props> = ({ agentId, onSuccessDeleteCb }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmDelete = async (): Promise<void> => {
    setLoading(true);
    deleteAgent(agentId)
      .then((response) => {
        console.log(response);
        if (onSuccessDeleteCb) {
          onSuccessDeleteCb(agentId);
        }
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <IconButton
        size="small"
        variant="outlined"
        color="error"
        onClick={(event) => {
          setOpenModal(true);
        }}
      >
        <DeleteIcon />
      </IconButton>

      <Modal
        variant={"error"}
        open={openModal}
        title={delete_agent_modal_title}
        showConfirmLoading={loading}
        confirmText={delete_agent_confirm_button}
        cancelText={delete_agent_cancel_button}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          void handleConfirmDelete();
        }}
        onCancel={() => {
          setOpenModal(false);
        }}
      >
        <div className="flex-col mb-16">
          <Typography variant={TypographyVariants.textLarge}>
            {delete_agent_modal_text}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAgentModal;
