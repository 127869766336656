/**
 * Custom-Hook: To create, update session context for workflow-builder
 */

// import { getIncomers } from "reactflow";
import { getPreviewSession } from "api/workflow";
import { useDispatch, useSelector } from "store";
import { SessionContextActions } from "store/reduxActions/botActions/contextSlice";
import {
  formatDataToVariables,
  formatSessionContextToOptions,
} from "../utils/session-context";

const useSessionContext = (): any => {
  const dispatch = useDispatch();

  const { loading, error, empty, session_context } = useSelector(
    (state) => state.bot.context
  );
  // const { nodes, edges } = useSelector((state) => state.bot.workflow.workflow);

  const initializeSessionContext = async (workflowId: string): Promise<any> => {
    dispatch({
      type: SessionContextActions.SET_SESSION_CONTEXT_LOADING,
      payload: { loading: true },
    });

    try {
      const response = await getPreviewSession(workflowId);
      const { session_context } = response || {};

      dispatch({
        type: SessionContextActions.SET_SESSION_CONTEXT_DATA,
        payload: { session_context },
      });

      return { ...response };
    } catch (err) {
      dispatch({
        type: SessionContextActions.SET_SESSION_CONTEXT_ERROR,
      });

      return { success: 0 };
    }
  };

  const addNodeInContext = ({
    context,
    nodeMeta,
  }: {
    context: string;
    nodeMeta: any;
  }): any => {
    if (session_context?.[context]?.[nodeMeta?.nodeId]) {
      return;
    }

    const updatedSessionContext = {
      ...session_context,
      [context]: {
        ...session_context[context],
        [nodeMeta.nodeId]: {
          meta: nodeMeta,
          variables: null,
        },
      },
    };

    dispatch({
      type: SessionContextActions.SET_SESSION_CONTEXT_DATA,
      payload: { session_context: updatedSessionContext },
    });
  };

  const updateNodeVariablesInContext = ({
    context,
    nodeMeta,
    variables,
  }: {
    context: string;
    nodeMeta: any;
    variables: any;
  }): any => {
    const updatedSessionContext = {
      ...session_context,
      [context]: {
        ...session_context[context],
        [nodeMeta.nodeId]: {
          meta: nodeMeta,
          variables: formatDataToVariables(variables),
        },
      },
    };

    dispatch({
      type: SessionContextActions.SET_SESSION_CONTEXT_DATA,
      payload: { session_context: updatedSessionContext },
    });
  };

  const getNodeVariableOptions = (nodeId: string): any => {
    // const parentNodes = getIncomers(
    //   {
    //     id: node.id,
    //     position: node.position,
    //     data: node.data,
    //   },
    //   nodes,
    //   edges
    // );

    const { dialog_context } = session_context;

    const filteredNodes = Object.keys(dialog_context).filter(
      (id) => id !== nodeId
    );
    const updatedDialogContext = filteredNodes.reduce(
      (acc: any, key: string) => {
        // eslint-disable-next-line
        if (dialog_context.hasOwnProperty(key)) {
          acc[key] = dialog_context[key];
        }
        return acc;
      },
      {}
    );

    const updatedSessionContext = {
      ...session_context,
      dialog_context: updatedDialogContext,
    };

    return formatSessionContextToOptions(updatedSessionContext);
  };

  return {
    loading,
    error,
    empty,
    session_context,
    initializeSessionContext,
    addNodeInContext,
    updateNodeVariablesInContext,
    getNodeVariableOptions,
  };
};

export default useSessionContext;
