import { Box, Tooltip } from "@mui/material";
import { ReactComponent as BotChatLogo } from "./assets/bot-icon-chat.svg";
import Typewriter from "./Typewriter";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import FeedbackButtons from "./FeedbackButtons";
import { type IPersona, type BotMessage } from "./types";
import SourceBubble from "./SourceBubble";
import Typography from "components/base/Typography";
import { useSelector } from "react-redux";
import { BotResponseType } from "constant/BotConstant";
import { useDispatch } from "store";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";
import MessageCarousel from "./components/MessageCarousel";
import { type IMessageAction } from "types/ChatbotType";
import { makeStyles } from "@mui/styles";
import { type Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  "chat-bubble-container": {
    "& .bot-icon-container": {
      height: "24px",
      width: "24px",

      "& img, svg": {
        height: "100%",
        width: "100%",
      },
    },
  },
}));

const BotChatIcon = ({ url }: { url: any }): JSX.Element => {
  if (url && url !== "default") {
    return <img src={url} />;
  }

  return <BotChatLogo />;
};

interface ChatComponentProps {
  chatData: BotMessage;
  prevChat: BotMessage | undefined;
  showTyping?: boolean;
  onClickPersona?: (persona: string) => void;
  onClickAction?: (action: IMessageAction) => void;
}

export const ChatMessage: React.FC<ChatComponentProps> = ({
  chatData,
  prevChat,
  showTyping,
  onClickPersona,
  onClickAction,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { conversationHandoff, agent } = useSelector(
    (state: any) => state.chatbot
  );
  const { persona } = useSelector((state: any) => state.chatbot);
  const { settings } = useSelector((state: any) => state.bot);
  const { chat_bubble_enabled, chat_bubble_url } = settings || {};

  const { type, content, speaker, text, sources, isMessageComplete } = chatData;

  const [isTypingComplete, setIsTypingComplete] = useState(false);

  const isAgent = useMemo(() => speaker === "agent", [speaker]);
  const name = useMemo(() => (isAgent ? "Agent" : "Level AI Bot"), [isAgent]);
  const previousChatSender = useMemo(
    () => (prevChat?.speaker === "agent" ? "Agent" : "Level AI Bot"),
    [prevChat?.speaker]
  );
  const isSameSpeakerAsPrevious = useMemo(
    () => prevChat && name === previousChatSender,
    [name, prevChat, previousChatSender]
  );
  const displayFeedbackButtons = useMemo(() => {
    return isTypingComplete && !isAgent;
  }, [isAgent, isTypingComplete]);
  const displaySources = useMemo(() => {
    return isTypingComplete && sources.length > 0;
  }, [isTypingComplete, sources.length]);

  useEffect(() => {
    const chatListContainer = document.getElementById("chat-list");

    if (chatListContainer) {
      chatListContainer.scrollTop =
        chatListContainer?.scrollHeight ?? chatListContainer.scrollTop;
    }
    // reset top when source array is changed and when feedback buttons need to be displayed
  }, [sources, displayFeedbackButtons]);

  const handleSelectPersona = useCallback(
    (persona: string) => {
      onClickPersona?.(persona);
      dispatch({
        type: ReduxChatbotActions.ACTION_SELECT_PERSONA,
        payload: { persona },
      });
    },
    [dispatch, onClickPersona]
  );

  if (type === BotResponseType.PERSONA_TAG) {
    return (
      <div className={clsx("d-flex align-items-end mt-1")}>
        <Tooltip placement="top" title={name}>
          <span>
            <div className={clsx("flex")}>
              <BotChatIcon url={chat_bubble_url} />
            </div>
          </span>
        </Tooltip>
        <div className="flex-column fs-14 fw-400 chatbot-chat-message-box ml-2 mr-36 w-100 p-12 row-gap-8">
          <div className="text-message-line-break">
            <Typography className="w-medium" variant="textMd">
              {text}
            </Typography>
          </div>

          <div className="persona-tags-wrapper">
            {!persona &&
              content?.map((persona: IPersona, index: number) => (
                <div
                  className="persona-tag"
                  key={index}
                  onClick={() => {
                    handleSelectPersona(persona.key);
                  }}
                >
                  <Typography className="w-semi-bold" variant="textMd">
                    {persona.label}
                  </Typography>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  if (type === BotResponseType.CAROUSEL) {
    return <MessageCarousel content={content} onClickAction={onClickAction} />;
  }

  if (text?.toString() === "AGENT_HANDOFF" && conversationHandoff) {
    return (
      <div className="agent-session-chat">
        <div className="session-border"></div>
        <Typography className="mx-2" sx={{ textAlign: "center" }}>
          {agent?.state?.friendlyName || "Agent"} has joined the conversation
        </Typography>
        <div className="session-border"></div>
      </div>
    );
  }

  if (text?.toString() === "AGENT_HANDOFF_CLOSED" && conversationHandoff) {
    return (
      <div className="agent-session-chat">
        <div className="session-border"></div>
        <Typography className="mx-2" sx={{ textAlign: "center" }}>
          {agent?.state?.friendlyName || "Agent"} has end the conversation
        </Typography>
        <div className="session-border"></div>
      </div>
    );
  }

  if (
    text?.toString() === "AGENT_HANDOFF" ||
    text?.toString() === "AGENT_HANDOFF_CLOSED" ||
    text?.toString()?.trim()?.startsWith("#")
  ) {
    return null;
  }

  return (
    <>
      <div
        className={clsx(
          classes["chat-bubble-container"],
          "d-flex align-items-end",
          {
            "flex-row-reverse": isAgent,
            "mt-1": isSameSpeakerAsPrevious,
            "mt-16": prevChat && !isSameSpeakerAsPrevious,
          }
        )}
      >
        {chat_bubble_enabled === "yes" && !isAgent && (
          <Tooltip placement="top" title={name}>
            <div
              className={clsx(
                {
                  "opacity-0": isSameSpeakerAsPrevious,
                },
                "bot-icon-container flex"
              )}
            >
              <BotChatIcon url={chat_bubble_url} />
            </div>
          </Tooltip>
        )}

        <div
          className={clsx("flex-column fs-14 fw-400 chatbot-chat-message-box", {
            "ml-2 mr-36": !isAgent,
            "chatbot-chat-message-box-agent neutral-1 mr-1 ml-72": isAgent,
            "w-100 p-12": !showTyping,
            "p-1": showTyping,
          })}
        >
          <div>
            {showTyping ? (
              <div className="typing-indicator">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            ) : (
              <>
                <div>
                  {!isAgent ? (
                    <Typewriter
                      textStream={text}
                      setIsTypingComplete={setIsTypingComplete}
                      isMessageComplete={isMessageComplete}
                    />
                  ) : (
                    <Typography className="w-medium" variant="textMd">
                      {text}
                    </Typography>
                  )}
                </div>
                {displaySources ? (
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    my={1}
                    gap={1}
                    row-gap={0.5}
                  >
                    {sources.map((source, index) => {
                      return <SourceBubble key={index} source={source} />;
                    })}
                  </Box>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>

      {/* <MessageCarousel /> */}

      {displayFeedbackButtons && <FeedbackButtons />}
    </>
  );
};
