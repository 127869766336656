import {
  getSmoothStepPath,
  type EdgeProps,
  EdgeLabelRenderer,
} from "reactflow";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  edgeButton: {
    cursor: "pointer",
    pointerEvents: "all",
    stroke: "#0984e3",
    fill: "white",

    "&:hover": {
      fill: "#f5f5f5",
    },
  },
  edgeButtonText: {
    pointerEvents: "none",
    userSelect: "none",
    fill: "#0984e3",
  },
  edgePath: { fill: "none", stroke: "#0984e3", strokeWidth: 1 },
  placeholderPath: {
    strokeWidth: 1,
    strokeDasharray: "3 3",
    stroke: "#bbb",
    fill: "none",
  },
  edgeLabel: {
    display: "inline-flex",
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    border: "1px dashed var(--icon-placeholder, #B5B1AD)",
    background: "#FFF",
  },
}));

// the placeholder edges do not have a special functionality, only used as a visual
export default function PlaceholderEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  label,
}: EdgeProps): any {
  const classes = useStyles();

  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className={classes.placeholderPath}
        d={edgePath}
        markerEnd={markerEnd}
      />
      {label && (
        <EdgeLabelRenderer>
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${edgeCenterX}px,${edgeCenterY}px)`,
            }}
            className={classes.edgeLabel}
          >
            <Typography className="w-semi-bold" sx={{ color: "#7C7972" }}>
              {label}
            </Typography>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
