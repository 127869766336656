import ContentHeader from "pages/Bot/components/ContentHeader";
import ScriptEditor from "./ScriptEditor";
import TestAssistant from "./TestAssistant";
import ToolConfigure from "./ToolConfigure";
import { useScriptStyle as useStyle } from "./style";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { type IScript } from "./types";
import { useContext } from "react";
import AgentBuilderContext from "../AgentBuilderContext";
import { BotType } from "types/BotType";

const validationSchema = yup.object({
  genral_prompt: yup.string(),
  general_tools: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      api_integration_name: yup.string(),
      description: yup.string(),
      api_integration_id: yup.string(),
      http_method: yup.string(),
    })
  ),
  associated_phone_number: yup.string(),
});

const FormContainer: React.FC = () => {
  const classes = useStyle();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { bot } = agentBuilderContext;

  const enabledChannels = bot?.settings?.enabled_channels;

  return (
    <Form className={classes.scriptContainer}>
      <ContentHeader title="Script" subtitle={""} />

      <div className={classes.scriptContent}>
        <ScriptEditor />

        <div className={classes.scriptToolContainer}>
          {enabledChannels?.[0] === BotType.VoiceBot && <TestAssistant />}
          <ToolConfigure />
        </div>
      </div>
    </Form>
  );
};

const Script: React.FC = () => {
  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agent } = agentBuilderContext;

  const initialValues: IScript = {
    general_prompt: agent?.general_prompt ?? "",
    associated_phone_number:
      agent?.voice_settings?.associated_phone_number ?? "",
    general_tools: agent?.general_tools ?? [],
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      <FormContainer />
    </Formik>
  );
};

export default Script;
