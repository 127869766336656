import {
  ServiceNodeConfig,
  ServiceNodeType,
  BotPromptNodeConfig,
  BotPromptNodeType,
  LogicNodeConfig,
  LogicNodeType,
  NodeConfig,
  NodeGroupType,
  ResponseNodeConfig,
  ResponseNodeType,
} from "pages/Bot/WorkflowBuilder/config";

const ResponseNodeAccordionConfig = {
  group: NodeGroupType.RESPONSE_NODE,
  title: "Display Information",
  description: "Show a message to users not expecting any response",
  icon: NodeConfig[NodeGroupType.RESPONSE_NODE].ICON,
  color: NodeConfig[NodeGroupType.RESPONSE_NODE].COLOR,
  nodes: [
    {
      title: "Text Message",
      type: ResponseNodeType.TEXT,
      icon: ResponseNodeConfig[ResponseNodeType.TEXT].ICON,
      enabled: true,
    },
    {
      title: "Text Message + File",
      type: ResponseNodeType.TEXT,
      icon: ResponseNodeConfig[ResponseNodeType.TEXT].ICON,
      enabled: false,
    },
    {
      title: "Card",
      type: ResponseNodeType.TEXT,
      icon: ResponseNodeConfig[ResponseNodeType.TEXT].ICON,
      enabled: false,
    },
    {
      title: "Carousel",
      type: ResponseNodeType.CAROUSEL,
      icon: ResponseNodeConfig[ResponseNodeType.CAROUSEL].ICON,
      enabled: false,
    },
  ],
};

const BotNodeAccordionConfig = {
  group: NodeGroupType.BOT_PROMPT_NODE,
  title: "Bot prompts",
  description: "Bot poses a question and expects a response in return",
  icon: NodeConfig[NodeGroupType.BOT_PROMPT_NODE].ICON,
  color: NodeConfig[NodeGroupType.BOT_PROMPT_NODE].COLOR,
  nodes: [
    {
      title: "Collect Data",
      type: BotPromptNodeType.TEXT,
      icon: BotPromptNodeConfig[BotPromptNodeType.TEXT].ICON,
      enabled: true,
    },
    {
      title: "Text Message + File",
      type: BotPromptNodeType.TEXT,
      icon: BotPromptNodeConfig[BotPromptNodeType.TEXT].ICON,
      enabled: false,
    },
    {
      title: "Card",
      type: BotPromptNodeType.TEXT,
      icon: BotPromptNodeConfig[BotPromptNodeType.TEXT].ICON,
      enabled: false,
    },
    {
      title: "Carousel",
      type: BotPromptNodeType.CAROUSEL,
      icon: BotPromptNodeConfig[BotPromptNodeType.CAROUSEL].ICON,
      enabled: true,
    },
  ],
};

const APIServiceNodeAccordionConfig = {
  group: NodeGroupType.SERVICE_NODE,
  title: "Actions",
  description:
    "Perform special tasks in background like call API to fetch information",
  icon: NodeConfig[NodeGroupType.SERVICE_NODE].ICON,
  color: NodeConfig[NodeGroupType.SERVICE_NODE].COLOR,
  nodes: [
    {
      title: "Delay a response",
      type: ServiceNodeType.DELAY_RESPONSE,
      icon: ServiceNodeConfig[ServiceNodeType.DELAY_RESPONSE].ICON,
      enabled: false,
    },
    {
      title: "Text Input Disable",
      type: ServiceNodeType.TEXT_INPUT_DISABLED,
      icon: ServiceNodeConfig[ServiceNodeType.TEXT_INPUT_DISABLED].ICON,
      enabled: false,
    },
    {
      title: "Text input Enable",
      type: ServiceNodeType.TEXT_INPUT_ENABLED,
      icon: ServiceNodeConfig[ServiceNodeType.TEXT_INPUT_ENABLED].ICON,
      enabled: false,
    },
    {
      title: "Service",
      type: ServiceNodeType.API_SERVICE_NODE,
      icon: ServiceNodeConfig[ServiceNodeType.API_SERVICE_NODE].ICON,
      enabled: true,
    },
    // {
    //   title: "Collect User feedback",
    //   type: ServiceNodeType.USER_FEEDBACK,
    //   icon: ServiceNodeConfig[ServiceNodeType.USER_FEEDBACK].ICON,
    //   enabled: false,
    // },
  ],
};

const LogicNodeAccordionConfig = {
  group: NodeGroupType.LOGIC_NODE,
  title: "Logic",
  description: "Use when you would like to branch the flow based on conditions",
  icon: NodeConfig[NodeGroupType.LOGIC_NODE].ICON,
  color: NodeConfig[NodeGroupType.LOGIC_NODE].COLOR,
  nodes: [
    {
      title: "Branch",
      type: LogicNodeType.BRANCH,
      icon: LogicNodeConfig[LogicNodeType.BRANCH].ICON,
      enabled: true,
    },
  ],
};

export const NodeGroupsConfig = [
  ResponseNodeAccordionConfig,
  BotNodeAccordionConfig,
  APIServiceNodeAccordionConfig,
  LogicNodeAccordionConfig,
];
