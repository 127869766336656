import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useFormikContext } from "formik";
// import * as yup from "yup";
import { TextField } from "@mui/material";
import { type IResponseNodeTextData } from "types/Workflow";
import TemplateTextarea from "components/base/TemplateTextAreaV2";
import { useEffect } from "react";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import { formatSessionContextToOptions } from "pages/Bot/WorkflowBuilder/utils/session-context";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useDispatch } from "store";

const useStyles = makeStyles((theme) => ({
  "edit-container": {
    padding: "108px 24px 32px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
}));

interface Props {
  node: INode;
}

const EditContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = node;

  const {
    // session_context,
    // addNodeInContext,
    // updateNodeVariablesInContext,
    getNodeVariableOptions,
  } = useSessionContext();
  const options = getNodeVariableOptions(node.id);
  //   console.log(options);

  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IResponseNodeTextData>();
  //   const { name, questions } = values;

  //   const handleAddNodeInContext = useCallback(() => {
  //     const nodeMeta = {
  //       label: name,
  //       nodeId: id,
  //       name,
  //       type: node.type,
  //       group: node?.data?.group,
  //       description: "",
  //       counter: node?.data?.ui?.counter,
  //     };

  //     addNodeInContext({
  //       context: "dialog_context",
  //       nodeMeta,
  //     });
  //   }, [id]);

  //   const handleUpdateSessionContext = () => {
  //     if (!values?.name) return;

  //     const variablesData = getExtractedVariables(questions);

  //     const nodeMeta = {
  //       label: name,
  //       nodeId: id,
  //       name,
  //       type: node.type,
  //       group: node?.data?.group,
  //       description: "",
  //       counter: node?.data?.ui?.counter,
  //     };

  //     updateNodeVariablesInContext({
  //       context: "dialog_context",
  //       nodeMeta,
  //       variables: variablesData,
  //     });
  //   };

  // We can remove this effect, if we add node meta, when node is created from placeholder create node.
  //   useEffect(() => {
  //     handleAddNodeInContext();
  //   }, [id]);

  useEffect(() => {
    dispatch({
      type: WorkflowActions.UPDATE_WORKFLOW_NODE,
      payload: { id, config: values },
    });
  }, [values]);

  return (
    <div className={classes["edit-container"]}>
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Node Name</Typography>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          placeholder="Eg. Bot Message"
          fullWidth
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">
          Add Description for your reference (Optional)
        </Typography>
        <TextField
          name="description"
          variant="outlined"
          placeholder="Eg. This is message for fallback service"
          multiline
          rows={4}
          fullWidth
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Write message</Typography>
        <TemplateTextarea
          name="message"
          variant="outlined"
          placeholder="Eg. Hey, How can I help you?"
          multiline
          rows={4}
          fullWidth
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
          // eslint-disable-next-line
          setFieldValue={setFieldValue}
          options={options}
        />
      </div>
    </div>
  );
};

export default EditContainer;
