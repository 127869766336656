import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    borderRadius: "16px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    padding: "24px",
    marginTop: "32px",
    minHeight: "250px",
  },
  step: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  title: {
    color: "var(--text-default, #282624)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px",
    marginBottom: "24px",
  },
  tableContainer: {
    padding: "16px",
  },
  table: {
    border: "1px solid var(--border-active, #F07400)",

    borderRadius: "8px",
    padding: "16px",
  },
  tableBody: {
    border: "1px solid var(--border-active, #F07400)",
  },
  tableRow: {
    width: "100%",
    "& .click-actions": {
      visibility: "hidden", // Hide by default
    },
    "&:hover": {
      "& .click-actions": {
        visibility: "visible", // Show when hovered
      },
    },
  },
  selectedTableRow: {
    border: "1px solid var(--border-active, #F07400)",
    borderRadius: "7pxpx",
  },
  rowTitle: {
    color: "var(--fg-text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  rowDescription: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  previewButton: {
    marginRight: "16px",
  },
  selectButton: {
    whiteSpace: "nowrap",
  },
  successBtn: {
    whiteSpace: "nowrap",
    color: "var(--text-Green-note, #308060)",
    background: "var(--colors-primary-lime-100, #E7FDF3)",
    "&:hover": {
      background: "var(--colors-primary-lime-100, #E7FDF3)",
    },
  },
  actionsContainer: {
    display: "flex",
  },
});
export default useStyles;
