import { Box } from "@mui/material";
import HeadedDialog from "components/shared/HeadedDialog";
import Typography from "components/base/Typography";
import { ReactComponent as IceBreakerExample } from "./imgs/iceBreakerExample.svg";

interface ViewExamplePopUpProps {
  open: boolean;
  header: React.ReactNode;
  onClickClose: () => void;
}

const ViewExamplePopUp: React.FC<ViewExamplePopUpProps> = ({
  open,
  header,
  onClickClose,
}) => {
  return (
    <HeadedDialog
      open={open}
      onClose={onClickClose}
      header={header}
      width="553px"
      height="290px"
    >
      <Box mt={1.5}>
        <Typography variant="textSm" className="w-medium">
          Imagine a company alpha, an online travel agent. They receive
          significant number of inquiries regarding flight cancellations and
          rescheduling. To help their customers quickly start, they have
          implemented two chat suggestions as shown below and connected them
          with corresponding action.
        </Typography>
        <Box mt={1}>
          <IceBreakerExample />
        </Box>
      </Box>
    </HeadedDialog>
  );
};

export default ViewExamplePopUp;
