import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { Handle, Position } from "reactflow";
import { ReactComponent as TriggerNodeIcon } from "assets/trigger-node-icon.svg";
import { useSelector } from "store";
import clsx from "clsx";
import NodeContainer from "./NodeContainer";
// import { ReactNode } from "react";
import { type INodeProps, type NodeRenderProps } from "../../types";

const useStyles = makeStyles((theme) => ({
  "trigger-node-wrapper": {
    height: "70px",
    width: "260px",

    "& .node-container": {
      width: "100%",
      display: "flex",
      padding: "12px 16px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "8px",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

      "& .node-header": {
        "& .node-type-icon": {
          display: "flex",
          padding: "8px",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "8px",
          background: "#B2E69F",
        },
      },
    },

    "&.node-selected": {
      "& .node-container": {
        border: "1px solid #B2E69F",
      },
    },
  },
}));

const NodeComponent: React.FC<INodeProps & NodeRenderProps> = ({
  id,
  type,
  data,
  onNodeClick,
}) => {
  const classes = useStyles();
  const { selectNode, openCreateNodeDropdown } = useSelector(
    (state) => state.bot.workflow.workflow
  );

  return (
    <div
      id={`node-${id}`}
      className={clsx(classes["trigger-node-wrapper"], {
        "node-selected": selectNode.id === id && !openCreateNodeDropdown,
      })}
      onClick={(event) => {
        onNodeClick(event);
      }}
    >
      <div className="node-container">
        {/* Handle - Target Clockwise: For dynamic connection */}
        <Handle
          className="handle"
          type="target"
          position={Position.Top}
          id={`handle-target-${id}-0`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Right}
          id={`handle-target-${id}-1`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Bottom}
          id={`handle-target-${id}-2`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Left}
          id={`handle-target-${id}-3`}
        />

        {/* Handle - Source Clockwise: For dynamic connection */}
        <Handle
          className="handle"
          type="source"
          position={Position.Top}
          id={`handle-source-${id}-0`}
        />
        <Handle
          className="handle"
          type="source"
          position={Position.Right}
          id={`handle-source-${id}-1`}
        />
        <Handle
          className="handle"
          type="source"
          position={Position.Bottom}
          id={`handle-source-${id}-2`}
        />
        <Handle
          className="handle"
          type="source"
          position={Position.Left}
          id={`handle-source-${id}-3`}
        />

        <div className="node-header w-100 flex col-gap-12">
          <div className="node-type-icon">
            <TriggerNodeIcon />
          </div>
          <div className="w-100 flex flex-col row-gap-2">
            <div className="flex justify-content-between align-items-center">
              <Typography
                variant="textTiny"
                className="w-semi-bold"
                sx={{ color: "#B5B1AD" }}
              >
                Trigger Node
              </Typography>
              <Typography
                variant="textTiny"
                className="w-bold"
                sx={{ color: "#B5B1AD" }}
              >
                {String(data?.ui?.counter).padStart(3, "0")}
              </Typography>
            </div>
            <Typography className="w-semi-bold" sx={{ color: "#000" }}>
              {data?.name}
            </Typography>
          </div>
        </div>

        <div className="node-content">
          <Typography
            variant="textSm"
            className="w-medium"
            sx={{ color: "#7C7972" }}
          >
            {data?.dialog_prompt}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const TriggerNode: React.FC<INodeProps> = (props) => {
  return (
    <NodeContainer
      id={props.id}
      type={props.type}
      renderNode={(nodeRenderProps: NodeRenderProps) => (
        <NodeComponent {...nodeRenderProps} {...props} />
      )}
    />
  );
};

export default TriggerNode;
