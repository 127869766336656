import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 24px 16px 24px",
    boxShadow: "0px 1px 0px 0px #E1DEDA",
    borderBottom: "1px solid var(--border-default, #E1DEDA)",
    flexShrink: 0,
  },
  IconAndtitle: {
    display: "flex",
    gap: "16px",
  },
  title: {
    color: "var(--fg-text-default, #282624)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px",
  },
  paramHead: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "16px",
  },
  paramHeadTitle: {
    color: "var(--text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
    marginLeft: "8px",
  },
  paramCount: {
    display: "flex",
    padding: "0px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "12px",
    background: "var(--inactive-surfaces-disabled, #F3F2F0)",
    marginLeft: "8px",

    color: "var(--text-default, #282624)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    alignSelf: "stretch",
    margin: "4px 0px",
  },
  content: {
    flex: 1,
    overflowY: "auto",
    padding: "24px",
    gap: "24px",
  },
  tiles: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    borderTop: "1px solid var(--border-default, #E1DEDA)",
    flexShrink: 0,
    display: "flex",
    height: "72px",
    padding: "16px 24px",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
  },
  configureBtn: {
    width: "100%",
  },
  descriptionHead: {
    color: "var(--text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  popover: {
    width: (props: any) => `${props.width}px`,
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--inactive-surfaces-muted, #FCFCFB)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    maxHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  popoverTitle: {
    color: "var(--text-default, #282624)",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  popoverDescription: {
    color: "var(--text-default, #282624)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
  },
}));

export default useStyles;
