import { type MutableRefObject, useCallback, useState } from "react";
import Button from "components/base/Button";
import CommonAskConfirmationButton from "components/shared/CommonAskConfirmationButton";
import { postBotSettings } from "api/bot/bot-settings";
import { useDispatch, useSelector } from "react-redux";
import { SettingsActions } from "store/reduxActions/botActions";
import { useSnackbar } from "notistack";
import { type PostBotSettings } from "types/BotSettings";

interface UpdateSettingsButtonProps {
  formValuesRef: MutableRefObject<PostBotSettings | undefined>;
  disableSubmitButton: boolean;
}

const UpdateSettingsButton: React.FC<UpdateSettingsButtonProps> = ({
  formValuesRef,
  disableSubmitButton,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateSettings = useCallback(() => {
    setIsSubmitting(true);

    if (formValuesRef.current) {
      const updateValues = {
        ...formValuesRef.current,
        suggestions: formValuesRef.current.suggestions
          .filter((suggestion) => !!suggestion.title)
          .map(({ action, title }) => {
            if (action?.id && action?.name) {
              return { action, title };
            }

            return { action: null, title };
          }),
      };

      postBotSettings(updateValues, token)
        .then((response) => {
          dispatch({
            type: SettingsActions.UPDATE_BOT_SETTINGS_SUCCESS,
            payload: response,
          });
          enqueueSnackbar("Settings updated", {
            variant: "success",
          });
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }, [dispatch, enqueueSnackbar, formValuesRef, token]);

  return (
    <CommonAskConfirmationButton isLoading={isSubmitting}>
      <Button
        color="tertiary"
        size="small"
        onClick={handleUpdateSettings}
        disabled={disableSubmitButton}
      >
        Save
      </Button>
    </CommonAskConfirmationButton>
  );
};

export default UpdateSettingsButton;
