import { type IBotQuestion } from "pages/Bot/WorkflowBuilder/types";
import { botBEAxiosInstance } from "services/axios";
// import { type IQuestion } from "types/Workflow";

/** Workflow APIs */
export const getWorkflowData = async (workflowId: string): Promise<any> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/workflow/${workflowId}`,
  });
};

export const getWorkflowList = async (botId: string): Promise<any> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/workflows/${botId}/`,
  });
};

export const publishWorkflow = async (body: any): Promise<any> => {
  return await botBEAxiosInstance({
    method: "POST",
    url: `/workflow/`,
    data: body,
  });
};

export const updateWorkflow = async (body: any): Promise<any> => {
  return await botBEAxiosInstance({
    method: "PUT",
    url: `/workflow/`,
    data: body,
  });
};

/** Preview Session APIs */
export const getPreviewSession = async (workflowId: string): Promise<any> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/preview_sessions/${workflowId}/`,
  });
};

export const updatePreviewSession = async (body: any): Promise<any> => {
  return await botBEAxiosInstance({
    method: "PUT",
    url: `/preview_sessions/`,
    data: body,
  });
};

export const extractEntities = async (
  questions: IBotQuestion[]
): Promise<any> => {
  return await botBEAxiosInstance({
    method: "POST",
    url: `/extract_entities/`,
    data: questions,
  });
};
