import type React from "react";
import useStyles from "./RightConfigure.style";
import { isEmpty, isEqual, isNil } from "lodash";
import { ReactComponent as IntegrationArrowsIcon } from "assets/IntegrationArrows.svg";
import { ReactComponent as LEVELAIIcon } from "assets/levelai.svg";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as TrashIcon } from "assets/trash-01.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/link-external-icon.svg";
import IconButton from "aether/IconButton";
import {
  AuthType,
  GLOBAL_INTEGRATION_SESSION_KEY,
  type ComplianceDoc,
  type ConfigField,
} from "pages/Integrations/integrations.types";
import Button, { ButtonColors } from "aether/Button";
import ConfigureConnectionFields from "./ConfigureConnectionFields";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { Formik } from "formik";
import useFormikWrapper from "./useFormikWrapper";
import { getModifiedPayload, getModifiedURL } from "./rightConfigure.util";
import { createConnection } from "pages/Integrations/Services/integrations.service";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ConfirmationUninstallModal from "./ConfirmationUninstallModal";
import useIntegrationsRouteNavigator from "hooks/useIntegrationsRouteNavigator";
import { useHandleRedirectBackAfterAuthentication } from "./useHandleRedirectBackAfterAuthentication";

interface Props {
  icon: string;
  fields: ConfigField[];
  complianceDocs: ComplianceDoc[];
  preConfiguredValues?: Record<string, string> | undefined;
  integrated?: boolean;
  authDocLink?: string;
  connectorId: string;
  authType: AuthType;
  title?: string;
  onUninstall?: () => Promise<any>;
  redirectURL?: string;
}
const RightConfigure: React.FC<Props> = ({
  icon,
  fields = [],
  complianceDocs,
  preConfiguredValues = {},
  integrated = false,
  authDocLink,
  connectorId,
  authType,
  title = "",
  onUninstall,
  redirectURL = null,
}) => {
  const classes = useStyles();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isMutationIsInProgress, setIsMutationIsInProgress] =
    useState<boolean>(false);

  const { gotoIntegrationsPage } = useIntegrationsRouteNavigator();
  const { enqueueSnackbar } = useSnackbar();

  const { UIVisibleFields, createValidationSchema, initialValues } =
    useFormikWrapper(fields, preConfiguredValues, title);

  // this hook is responsible for back redirection after successfull authentication
  useHandleRedirectBackAfterAuthentication({
    connectorId,
    authType,
    onSuccess: () => {
      enqueueSnackbar("Connection created", { variant: "success" });
      gotoIntegrationsPage();
    },
    onFailure: () => {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    },
    setIsMutationIsInProgress: setIsMutationIsInProgress,
  });

  const handleSubmit = async (values: Record<string, string>): Promise<any> => {
    if (isEqual(authType, AuthType.API_TOKEN)) {
      const payloadBody = getModifiedPayload(values, connectorId, authType);
      try {
        await createConnection(payloadBody);
        enqueueSnackbar("Connection created", { variant: "success" });
        gotoIntegrationsPage();
      } catch {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } else {
      const modifiedUrl = getModifiedURL(
        redirectURL,
        window.location.href,
        values
      );
      if (isNil(modifiedUrl)) {
        enqueueSnackbar(
          "Some problem with the redirect template url for OAuth authentication",
          { variant: "error" }
        );
      } else {
        const stringifyObj = JSON.stringify(values);
        sessionStorage.setItem(GLOBAL_INTEGRATION_SESSION_KEY, stringifyObj);
        window.location.href = modifiedUrl;
      }
    }
  };

  const handleUninstall = async (): Promise<any> => {
    await onUninstall?.();
    setShowConfirmationModal(false);
    gotoIntegrationsPage();
  };

  return (
    <div className={classes.container}>
      <div className={classes.swagContainer}>
        {!isEmpty(icon) ? (
          <img src={icon} className={classes.image} />
        ) : (
          <IconButton
            variant="contained"
            color="custom"
            customColors={{ backgroundColor: "#F3F2F0" }}
          >
            <PuzzleIcon />
          </IconButton>
        )}
        <IntegrationArrowsIcon />
        <LEVELAIIcon />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={createValidationSchema(UIVisibleFields)}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ConfigureConnectionFields
              fields={UIVisibleFields}
              preConfiguredValues={initialValues}
              disabled={integrated}
            />

            <div className={classes.actions}>
              {!integrated ? (
                <Button
                  className={classes.actionButton}
                  color={ButtonColors.primary}
                  type="submit" // Trigger Formik's submit on "Uninstall"
                  disabled={isSubmitting || !isValid} // Disable button if form is submitting or invalid
                  loading={isMutationIsInProgress}
                >
                  Install now
                </Button>
              ) : (
                <Button
                  startIcon={<TrashIcon />}
                  color={ButtonColors.secondary}
                  className={classes.actionButton}
                  onClick={() => {
                    setShowConfirmationModal(true);
                  }}
                >
                  Uninstall
                </Button>
              )}

              <Button
                className={classes.actionButton}
                endIcon={<ExternalLinkIcon />}
                color={ButtonColors.secondary}
                onClick={() => {
                  window.open(authDocLink, "_blank");
                }}
              >
                Open app docs
              </Button>
            </div>
            <div className={classes.complianceContainer}>
              {complianceDocs.map((doc, index) => {
                return (
                  <div
                    key={index}
                    className={classes.links}
                    onClick={() => {
                      window.open(doc.doc_link, "_blank");
                    }}
                  >
                    <Typography
                      variant={TypographyVariants.text}
                      weight={TypographyWeights.semiBold}
                    >
                      {doc.label}
                    </Typography>
                    <div className={classes.iconWrapper}>
                      <ExternalLinkIcon />
                    </div>
                  </div>
                );
              })}
            </div>
          </form>
        )}
      </Formik>
      {showConfirmationModal && (
        <ConfirmationUninstallModal
          onCancel={() => {
            setShowConfirmationModal(false);
          }}
          onConfirm={handleUninstall}
          name={title}
        />
      )}
    </div>
  );
};

export default RightConfigure;
