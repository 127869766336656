import { ReactComponent as ArrowDownIcon } from "assets/chevron-down.svg";
import clsx from "clsx";
import Typography from "components/base/Typography";
import { useMemo } from "react";
import { useTextSelectStyles as useStyles } from "./style";

interface Props {
  value: string;
  focused: boolean;
  placeholder?: string | undefined;
  onChange: (event: any) => void;
  onFocus: (event: any) => void;
  onBlur: (event: any) => void;
}

const TextSelect: React.FC<Props> = ({
  value,
  focused,
  placeholder = "",
  onChange,
  onFocus,
  onBlur,
}) => {
  const classes = useStyles();

  const renderValue = useMemo(() => {
    if (value) {
      const regex = /\{\{(.*?)\}\}/;
      const match = value.match(regex);

      if (match) {
        const valueMap = match[1].split(".");
        return valueMap[valueMap.length - 2];
      }

      return null;
    }
  }, [value]);

  return (
    <div className={classes["TextSelect-root"]}>
      <div className="InputBase-root">
        <div className="Editor-root">
          <div className="Editor-editorContainer">
            <div
              className="editor-content"
              onClick={onFocus}
              onChange={onChange}
              onBlur={onBlur}
            >
              {renderValue ? (
                <div className="resolve-variable">
                  <Typography
                    className="w-medium ellipsis"
                    sx={{ color: "#3A91B4" }}
                  >
                    {renderValue}
                  </Typography>
                </div>
              ) : (
                <div className="placeholder">{placeholder}</div>
              )}
            </div>
          </div>
        </div>
        <div className="Editor-select-icon">
          <ArrowDownIcon />
        </div>
        <fieldset
          className={clsx("outline-notch", {
            "outline-notch-focused": focused,
          })}
        ></fieldset>
      </div>
    </div>
  );
};

export default TextSelect;
