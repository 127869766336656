import StepAPIIntegration from "./StepAPIIntegration";
import StepAPIInput from "./StepAPIInput";
import StepAPIOutput from "./StepAPIOutput";
import StepFallback from "./StepFallback";
// import StepTest from "./StepTest";
import { ServiceNodeSteps } from "pages/Bot/WorkflowBuilder/config";
import Typography from "components/base/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  type IStepContentProps,
  type IStepLabelProps,
} from "pages/Bot/WorkflowBuilder/types";

const StepsConfig = {
  [ServiceNodeSteps.API_CONFIGURATION]: {
    label: "Step 1/5",
    title: "Api configuration",
    subtitle:
      "Select which API and end point will help you achieve the above action.",
  },
  [ServiceNodeSteps.API_INPUT]: {
    label: "Step 2/5",
    title: "Define Input",
    subtitle:
      "Ensure the API receives the correct data by mapping the following input parameters to variables from this workflow or globally available.",
  },
  [ServiceNodeSteps.API_OUTPUT]: {
    label: "Step 3/5",
    title: "Output",
    subtitle:
      "Output from the api node is in following format. Assign a name for easy use.",
  },
  [ServiceNodeSteps.API_FALLBACK]: {
    label: "Step 4/5",
    title: "Fallback",
    subtitle:
      "Define the fallback in case API gives response other than  200 i.e “ok”",
  },
  // [ServiceNodeSteps.API_RESPONSE]: {
  //   label: "Step 5/5",
  //   title: "Test",
  //   subtitle:
  //     "Define the fallback in case API gives response other than  200 i.e “ok”",
  // },
};

const StepLabelComponent = ({
  label,
  title,
  subtitle,
  completed,
  expanded,
}: {
  label: string;
  title: string;
  subtitle: string;
  completed: boolean;
  expanded: boolean;
}): JSX.Element => {
  return (
    <div className="flex justify-content-between align-items-center">
      <div className="step-label-content">
        <Typography variant="textTiny" sx={{ color: "#7C7972" }}>
          {label}
        </Typography>
        <div className="flex flex-col">
          <Typography variant="textLg" className="w-bold">
            {title}
          </Typography>
          <Typography variant="textSm" sx={{ color: "#7C7972" }}>
            {subtitle}
          </Typography>
        </div>
      </div>

      <div className="step-expand-icon-container center">
        {completed && <ExpandMoreIcon />}
      </div>
    </div>
  );
};

interface IStep {
  renderStepLabel: (props: IStepLabelProps) => JSX.Element;
  renderStepContent: (props: IStepContentProps) => JSX.Element;
}

const Steps: Record<string, IStep> = {
  [ServiceNodeSteps.API_CONFIGURATION]: {
    renderStepLabel: (props: IStepLabelProps) => {
      const { label, title, subtitle } =
        StepsConfig[ServiceNodeSteps.API_CONFIGURATION];

      const {
        api_integration_id,
        selected_api_integration,
        completed,
        expanded,
      } = props;

      const _subtitle =
        api_integration_id && completed && selected_api_integration?.name;

      return (
        <StepLabelComponent
          label={label}
          title={title}
          subtitle={_subtitle || subtitle}
          completed={completed}
          expanded={expanded}
        />
      );
    },
    renderStepContent: (props: IStepContentProps) => (
      <StepAPIIntegration {...props} />
    ),
  },
  [ServiceNodeSteps.API_INPUT]: {
    renderStepLabel: (props: IStepLabelProps) => {
      const { label, title, subtitle } =
        StepsConfig[ServiceNodeSteps.API_INPUT];

      const { completed, expanded } = props;

      return (
        <StepLabelComponent
          label={label}
          title={title}
          subtitle={subtitle}
          completed={completed}
          expanded={expanded}
        />
      );
    },
    renderStepContent: (props: IStepContentProps) => (
      <StepAPIInput {...props} />
    ),
  },
  [ServiceNodeSteps.API_OUTPUT]: {
    renderStepLabel: (props: IStepLabelProps) => {
      const { label, title, subtitle } =
        StepsConfig[ServiceNodeSteps.API_OUTPUT];

      const { completed, expanded } = props;

      return (
        <StepLabelComponent
          label={label}
          title={title}
          subtitle={subtitle}
          completed={completed}
          expanded={expanded}
        />
      );
    },
    renderStepContent: (props: IStepContentProps) => (
      <StepAPIOutput {...props} />
    ),
  },
  [ServiceNodeSteps.API_FALLBACK]: {
    renderStepLabel: (props: IStepLabelProps) => {
      const { label, title, subtitle } =
        StepsConfig[ServiceNodeSteps.API_FALLBACK];

      const { completed, expanded } = props;

      return (
        <StepLabelComponent
          label={label}
          title={title}
          subtitle={subtitle}
          completed={completed}
          expanded={expanded}
        />
      );
    },
    renderStepContent: (props: IStepContentProps) => (
      <StepFallback {...props} />
    ),
  },
  // [ServiceNodeSteps.API_RESPONSE]: {
  //   renderStepLabel: (props: IStepLabelProps) => {
  //     const { label, title, subtitle } =
  //       StepsConfig[ServiceNodeSteps.API_RESPONSE];

  //     const { completed, expanded } = props;

  //     return (
  //       <StepLabelComponent
  //         label={label}
  //         title={title}
  //         subtitle={subtitle}
  //         completed={completed}
  //         expanded={expanded}
  //       />
  //     );
  //   },
  //   renderStepContent: (props: IStepContentProps) => <StepTest {...props} />,
  // },
};

export default Steps;
