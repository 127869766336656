import type React from "react";
import { makeStyles } from "@mui/styles";
// import { useCallback, useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import Typography from "components/base/Typography";
import { useCallback, useState } from "react";

export interface AccordionCardProps {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  content: React.ReactNode;
  headerButtons?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  "accordion-card": {
    "&.MuiAccordion-root": {
      padding: "0px",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow:
        "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",

      "&:before": {
        content: "none",
        opacity: 0,
        height: 0,
      },
    },
    "& .MuiAccordionSummary-root": {
      padding: "16px 24px",

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    "& .MuiCollapse-root": {
      borderTop: "1px solid var(--border-default, #E1DEDA)",

      "& .MuiAccordionDetails-root": {
        padding: "24px",
      },
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));

const AccordionCard: React.FC<AccordionCardProps> = ({
  title,
  subtitle,
  icon,
  content,
  headerButtons,
}) => {
  const classes = useStyles();

  const [expand, setExpand] = useState(false);

  const handleClickAccordion = useCallback(
    (event: any): void => {
      event.stopPropagation();
      const targetClassList = event.target.classList;

      if (targetClassList.contains("MuiButton-root")) {
        return;
      }

      setExpand((prev) => !prev);
    },
    [expand]
  );

  return (
    <Accordion expanded={expand} className={classes["accordion-card"]}>
      <AccordionSummary
        expandIcon={<ChevronDown />}
        onClick={handleClickAccordion}
      >
        <div className="w-100 flex justify-content-between align-items-center">
          <div className="flex justify-content-start align-items-center col-gap-16">
            <div className="accordion-card-icon">{icon}</div>
            <div className="flex flex-col">
              <Typography variant="textXl" className="w-semi-bold">
                {title}
              </Typography>
              <Typography className="w-medium" sx={{ color: "#7C7972" }}>
                {subtitle}
              </Typography>
            </div>
          </div>
          <div className="mr-16">{headerButtons}</div>
        </div>
      </AccordionSummary>

      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionCard;
