import { Box, type BoxProps, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import type React from "react";
import { useMemo } from "react";
import "./index.scss";

type Side = "start" | "end";

export interface SidePanelProps extends BoxProps {
	side?: Side;
	open?: boolean;
	header?: React.ReactNode;
	onClickClose?: () => void;
	enableBackdropClose?: boolean;
	enableBottomSheet?: boolean;
}

const SidePanel: React.FC<SidePanelProps> = ({
	side,
	open,
	header,
	enableBackdropClose,
	enableBottomSheet,
	onClickClose,
	children,
	...boxProps
}) => {
	const anchor = useMemo(() => {
		if (enableBottomSheet) return "bottom";

		return side === "start" ? "left" : "right";
	}, [enableBottomSheet, side]);

	return (
		<Drawer anchor={anchor} open={open} onClose={onClickClose}>
			<Box
				position="relative"
				width={{ xs: "40vw" }}
				style={{
					height: anchor === "bottom" ? "unset" : "100%",
					maxHeight: anchor === "bottom" ? "80%" : "unset",
					overflowY: "auto",
				}}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...boxProps}
				px={3}
				py={2}
			>
				<Box display="flex" flexDirection="column" height="100%">
					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						flexDirection={side === "start" ? "row-reverse" : "row"}
						mb={3}
					>
						{header && (
							<>
								<Box
									display="flex"
									flexGrow={1}
									justifyContent={side === "start" ? "flex-end" : "flex-start"}
								>
									{header}
								</Box>
							</>
						)}
						<IconButton onClick={onClickClose}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box flex={1} overflow="auto" className="side-panel-content">
						{children}
					</Box>
				</Box>
			</Box>
		</Drawer>
	);
};

SidePanel.defaultProps = {
	side: "end",
	open: false,
	enableBackdropClose: false,
	enableBottomSheet: false,
};

export default SidePanel;
