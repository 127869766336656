import { Box, Grid, TextField as MuiTextField } from "@mui/material";
import Typography from "components/base/Typography";
import { type FieldIceBreaker } from "types/BotSettings";
import type BotAction from "types/BotAction";
import { type BotSettingsFieldsValues } from "../../constants";
import {
  type FormikProps,
  useFormikContext,
  Field,
  type FormikErrors,
  type FormikTouched,
} from "formik";
import {
  Autocomplete,
  TextField,
  type AutocompleteRenderInputParams,
} from "formik-mui";
import { type SyntheticEvent } from "react";

interface RenderIceBreakerProps {
  index: number;
  suggestion: FieldIceBreaker;
  actionList: BotAction[];
  error: FormikErrors<FieldIceBreaker> | undefined;
  touched: FormikTouched<FieldIceBreaker> | undefined;
}

const RenderIceBreaker: React.FC<RenderIceBreakerProps> = ({
  index,
  suggestion,
  actionList,
  error,
  touched,
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
  }: FormikProps<BotSettingsFieldsValues> = useFormikContext();

  return (
    <Grid container width="100%">
      <Grid item xs={5}>
        <Box mx={0.5}>
          <Box pb={0.5}>
            <Typography variant="textMd" className="w-semi-bold">
              {`Suggestion ${index + 1}`}
            </Typography>
          </Box>
          <MuiTextField
            name={`values.suggestions[${index}].title`}
            value={values.suggestions[index].title}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={async (e) => {
              await setFieldValue(
                `suggestions[${index}].title`,
                e.target.value
              );
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onBlur={async (e) => {
              await setFieldValue(
                `suggestions[${index}].title`,
                e.target.value
              );

              await setFieldTouched(`suggestions[${index}].title`);
            }}
            variant="outlined"
            size="small"
            placeholder="Enter Ice-Breaker Text"
            fullWidth
            error={touched?.title && Boolean(error?.title)}
            helperText={
              touched?.title && error?.title
                ? error?.title
                : "Max 50 characters"
            }
            InputProps={{
              classes: {
                input: "py-10 px-14",
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={5} ml={2}>
        <Box mx={0.5}>
          <Box pb={0.5}>
            <Typography variant="textMd" className="w-semi-bold">
              Select action (Optional)
            </Typography>
          </Box>
          <Field
            name={`values.suggestions[${index}].action.name`}
            value={values.suggestions[index].action.name}
            inputValue={values.suggestions[index].action.name}
            disabled={!values.suggestions[index].title}
            freeSolo
            forcePopupIcon
            disableClearable
            component={Autocomplete}
            options={actionList}
            onChange={async (_: SyntheticEvent, newValue: BotAction) => {
              await setFieldValue(`suggestions[${index}].action`, {
                id: newValue.id,
                name: newValue.name,
              });
            }}
            filterOptions={(options: BotAction[]) => options}
            getOptionLabel={(option: BotAction) => option.name || ""}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <Field
                {...params}
                name={`values.suggestions[${index}].action.name`}
                value={values.suggestions[index].action.name}
                component={TextField}
                placeholder="Select an action for Ice-breaker"
                variant="outlined"
                onChange={async (e: { target: { value: unknown } }) => {
                  await setFieldValue(`suggestions[${index}].action`, {
                    id: "",
                    name: e.target.value,
                  });
                }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onBlur={async (e: { target: { value: unknown } }) => {
                  await setFieldValue(
                    `suggestions[${index}].action.name`,
                    e.target.value
                  );

                  await setFieldTouched(`suggestions[${index}].action.name`);
                }}
                error={touched?.action?.name && Boolean(error?.action?.name)}
                helperText={touched?.action?.name && error?.action?.name}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RenderIceBreaker;
