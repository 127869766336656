import Typography from "components/base/Typography";
import { useScriptEditorStyle as useStyle } from "./style";
import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { type IScript } from "./types";
import { useCallback, useContext, useRef } from "react";
import { updateAgentScript } from "api/bot/bot-agents";
import { useSnackbar } from "notistack";
import AgentBuilderContext from "../AgentBuilderContext";

const editor_title = "Assistant’s task description";
const editor_subtitle =
  "Craft the conversation flow and instructions for your assistant, describing tasks and interactions.";

const ScriptEditor: React.FC = () => {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId } = agentBuilderContext;

  const { values, handleBlur, handleChange } = useFormikContext<IScript>();
  const { general_prompt } = values;

  const currentSavedPrompt = useRef("");

  // const [loading, setLoading] = useState(false);

  const handleOnBlur = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);

      if (
        !values.general_prompt ||
        values.general_prompt === currentSavedPrompt.current
      ) {
        return;
      }

      try {
        await updateAgentScript({
          _id: agentId,
          general_prompt: values.general_prompt,
        });

        currentSavedPrompt.current = values.general_prompt;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      }
    },
    [general_prompt]
  );

  // if (loading) {
  //   return (
  //     <div
  //       className={clsx(classes.scriptEditorContainer, classes.scriptLoading)}
  //     >
  //       <CircularProgress size={24} />
  //     </div>
  //   );
  // }

  return (
    <div className={classes.scriptEditorContainer}>
      <div className="flex justify-content-between align-items-center">
        <div className="flex-col">
          <Typography variant="textXl" className="w-bold">
            {editor_title}
          </Typography>
          <Typography className="w-medium">{editor_subtitle}</Typography>
        </div>
      </div>

      <div className="editor-tool-container">
        <TextField
          name="general_prompt"
          multiline
          minRows={18}
          fullWidth
          value={values.general_prompt}
          onChange={handleChange}
          onBlur={(event) => {
            void handleOnBlur(event);
          }}
        />
      </div>
    </div>
  );
};

export default ScriptEditor;
