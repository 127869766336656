import type React from "react";
import useStyles from "./ConnectorsAction.styles";
import ConnectorTile from "./ConnectorTile";
import { useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import SelectConnectorActions from "./SelectAction/SelectConnectorsActions";
import Loading from "aether/Loading";
import { useSnackbar } from "notistack";
import { getAllConnections } from "pages/Integrations/Services/integrations.service";
import { type Connection } from "pages/Integrations/integrations.types";

const ConnectorsAction: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [selectedConnection, setSelectedConnection] =
    useState<Connection | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getAllConnections()
      .then((res) => {
        setConnections(res);
      })
      .catch((err) => {
        enqueueSnackbar(`Something went wrong: ${err}`, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.step}>Step 1/2</div>
        <div className={classes.title}>Select a connector</div>
        {loading && <Loading />}
        <div className={classes.tiles}>
          {connections.map((tile) => {
            return (
              <ConnectorTile
                key={tile._id}
                tileConfig={tile}
                onClick={() => {
                  setSelectedConnection(tile);
                }}
                selected={isEqual(selectedConnection?._id, tile._id)}
              />
            );
          })}
        </div>
      </div>
      {!isEmpty(selectedConnection) && (
        <SelectConnectorActions selectedConnection={selectedConnection} />
      )}
    </>
  );
};

export default ConnectorsAction;
