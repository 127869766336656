import { Paper } from "@mui/material";
import Assistants from "./Assistants";
import { useStyles } from "./styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as DashboardHeaderLogo } from "assets/images/home-header.svg";
import CreateAssistantModal from "./CreateAssistantModal";

const dashboard_header_title =
  "Transform Customer Support with Level-AI virtual assistants";
const dashboard_header_subtitle =
  "Elevate your customer experience with AI-driven virtual assistants that can handle inquiries, provide instant solutions, and free up your team's time for more complex tasks.";

const BotsDashboard: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.botDashboardContainer}>
      <div className={classes.botDashboardContent}>
        <Paper className="dashboard-header-container">
          <div className="flex-col">
            <Typography
              variant={TypographyVariants.textXXXL}
              weight={TypographyWeights.bold}
            >
              {dashboard_header_title}
            </Typography>
            <Typography
              variant={TypographyVariants.textXL}
              weight={TypographyWeights.medium}
              className="dashboard-subtitle"
            >
              {dashboard_header_subtitle}
            </Typography>

            <CreateAssistantModal />
          </div>
          <div className="dashboard-logo-container">
            <DashboardHeaderLogo />
          </div>
        </Paper>

        <Assistants />
      </div>
    </div>
  );
};

export default BotsDashboard;
