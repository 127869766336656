import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import {
  AppearanceSettingsFields,
  type IAppearanceSettings,
} from "../../types";
import { type FormikProps, useFormikContext } from "formik";
// import { useSelector } from "store";

const useStyles = makeStyles((theme) => ({
  "basic-settings-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",

    "& .basic-settings-row": {
      "& .form-input-container": {
        width: "44%",
        display: "flex",
        flexDirection: "column",
        rowGap: "6px",
      },
    },
  },
}));

const BasicSettings: React.FC = () => {
  const classes = useStyles();

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
  }: FormikProps<IAppearanceSettings> = useFormikContext();

  return (
    <div className={classes["basic-settings-container"]}>
      <div className="basic-settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">Chatbot Name</Typography>
          <TextField
            name={AppearanceSettingsFields.bot_display_name}
            variant="outlined"
            size="small"
            placeholder="Chatbot Name"
            fullWidth
            value={values.bot_display_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bot_display_name && Boolean(errors.bot_display_name)}
            helperText={"Max 16 characters"}
          />
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">Header Message</Typography>
          <TextField
            name={AppearanceSettingsFields.header_message}
            variant="outlined"
            size="small"
            placeholder="Header Message"
            fullWidth
            value={values.header_message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.header_message && Boolean(errors.header_message)}
            helperText={"Max 50 characters"}
          />
        </div>
      </div>

      <div className="basic-settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">Welcome message</Typography>
          <TextField
            name={AppearanceSettingsFields.welcome_message}
            variant="outlined"
            size="small"
            placeholder="Welcome message"
            fullWidth
            multiline
            minRows={2}
            maxRows={2}
            value={values.welcome_message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.welcome_message && Boolean(errors.welcome_message)}
            helperText={touched.welcome_message && errors.welcome_message}
          />
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">Input Box Placeholder</Typography>
          <TextField
            name={AppearanceSettingsFields.input_box_placeholder}
            variant="outlined"
            size="small"
            placeholder="Chatbot Name"
            fullWidth
            value={values.input_box_placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.input_box_placeholder &&
              Boolean(errors.input_box_placeholder)
            }
            helperText={
              touched.input_box_placeholder && errors.input_box_placeholder
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicSettings;
