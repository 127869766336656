import * as yup from "yup";
import { GeneralInfoFields } from "./types";
import AccordionCard, {
  type AccordionCardProps,
} from "components/base/AccordionCard";
import { ReactComponent as CubeIcon } from "assets/cube-01.svg";
import { ReactComponent as BuildingIcon } from "assets/building-03.svg";
import { ReactComponent as TargetIcon } from "assets/target-05.svg";
import { ReactComponent as ClipboardCheckIcon } from "assets/clipboard-check.svg";
import { useGeneralInfoStyles as useStyles } from "./style";
import ContentHeader from "pages/Bot/components/ContentHeader";
import Button from "components/base/Button";
import { BoundType } from "api/bot/voice-bot-types";
import { Formik, useFormikContext } from "formik";
import BasicInfo from "./BasicInfo";
import CompanyInfo from "./CompanyInfo";
import Goal from "./Goal";
import RulesAndGuidelines from "./RulesAndGuidelines";
import { useContext, useEffect, useState } from "react";
import AgentBuilderContext from "../AgentBuilderContext";
import clsx from "clsx";
import { CircularProgress } from "@mui/material";
import {
  getAgentGeneralInfo,
  updateAgentGeneralInfo,
} from "api/bot/bot-agents";

const voice_bot_general_info_header = "General info";

export interface GeneralInfoProps {
  [GeneralInfoFields.call_type]: string;
  [GeneralInfoFields.begin_message_ai_prompt]: string;
  [GeneralInfoFields.company_info_text]: string;
  [GeneralInfoFields.goal]: string;
  [GeneralInfoFields.guidelines_and_rules]: string;
}

const validationSchema = yup.object({
  [GeneralInfoFields.call_type]: yup.string(),
  [GeneralInfoFields.begin_message_ai_prompt]: yup.string(),
  [GeneralInfoFields.company_info_text]: yup.string(),
  [GeneralInfoFields.goal]: yup.string(),
  [GeneralInfoFields.guidelines_and_rules]: yup.string(),
});

const GeneralInfoConfiguration: AccordionCardProps[] = [
  {
    title: "Basic",
    subtitle:
      "Help in understanding where this voice agent  sits in entire schema",
    icon: <CubeIcon />,
    content: <BasicInfo />,
  },
  {
    title: "Company info",
    subtitle:
      "Provide key details about your company to personalize your bot and align it with your brand identity",
    icon: <BuildingIcon />,
    content: <CompanyInfo />,
  },
  {
    title: "Goal",
    subtitle:
      "Defines the primary tasks the bot will perform, ensuring clarity on its main functions and responsibilities.",
    icon: <TargetIcon />,
    content: <Goal />,
  },
  {
    title: "Guidelines and rules",
    subtitle:
      "Define the rules for how your bot interacts with customers, might including tone, fallback behavior.",
    icon: <ClipboardCheckIcon />,
    content: <RulesAndGuidelines />,
  },
];

const FormContainer: React.FC = () => {
  const classes = useStyles();
  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId } = agentBuilderContext;

  const { values, isValid, dirty } = useFormikContext<GeneralInfoProps>();

  const [saving, setSaving] = useState(false);

  const handleSave = (): void => {
    setSaving(true);
    updateAgentGeneralInfo({ _id: agentId, ...values })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const isSaveEnabled = isValid && dirty;

  return (
    <div className={classes.generalInfoContent}>
      <ContentHeader title={voice_bot_general_info_header} subtitle="">
        <Button
          color={"primary"}
          loading={saving}
          onClick={handleSave}
          disabled={!isSaveEnabled}
        >
          Save
        </Button>
      </ContentHeader>

      <div className={classes.configurationContainer}>
        {GeneralInfoConfiguration.map((configuration, index) => (
          <AccordionCard key={index} {...configuration} />
        ))}
      </div>
    </div>
  );
};

const GeneralInfo: React.FC = () => {
  const classes = useStyles();
  const agentBuilderContext = useContext(AgentBuilderContext);

  const { agentId } = agentBuilderContext || {};

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GeneralInfoProps>({
    [GeneralInfoFields.call_type]: BoundType.Inbound,
    [GeneralInfoFields.begin_message_ai_prompt]: "",
    [GeneralInfoFields.company_info_text]: "",
    [GeneralInfoFields.goal]: "",
    [GeneralInfoFields.guidelines_and_rules]: "",
  });

  useEffect(() => {
    setLoading(true);
    getAgentGeneralInfo(agentId)
      .then((response) => {
        console.log(response);
        setInitialValues((prevState) => ({
          ...prevState,
          [GeneralInfoFields.begin_message_ai_prompt]:
            response.begin_message_config.ai_prompt ?? "",
          [GeneralInfoFields.company_info_text]:
            response.company_info_text ?? "",
          [GeneralInfoFields.goal]: response.goal ?? "",
          [GeneralInfoFields.guidelines_and_rules]:
            response.guidelines_and_rules ?? "",
        }));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className={clsx(classes.generalInfoContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.generalInfoContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        <FormContainer />
      </Formik>
    </div>
  );
};

export default GeneralInfo;
