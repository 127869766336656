import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as BotIcon } from "assets/bot-icon.svg";
import { ReactComponent as CloseIcon } from "assets/close-icon.svg";
import Typography from "components/base/Typography";
import CircularProgressWithLabel from "components/shared/CircularProgressWithLabel";
import { useDispatch, useSelector } from "store";
import { getTotalConversationFrequency } from "utils/miscellaneous";
import { Link } from "react-router-dom";
import { ReactComponent as MessageIcon } from "assets/message-chat-square.svg";
import { ReactComponent as AnnotationIcon } from "assets/annotation-info.svg";
import { type IConversationRow } from "types/BotTree";
import { ConvoOutcome } from "pages/BotTree/config";
import { ReduxBotTreeActions } from "store/reduxActions/ReduxBotTreeActions";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  "node-drawer-container": {
    display: "flex",
    width: "500px",
    height: "863px",
    padding: "24px 24px 126px 24px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",

    gap: "24px",
    flexShrink: 0,
  },
  "drawer-header-wrapper": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "& .header-text": {
      display: "flex",
      alignItems: "center",
      columnGap: "16px",
    },
  },
  "score-card-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    alignSelf: "stretch",

    "& .score-card": {
      minWidth: "128px",
      display: "flex",
      padding: "12px 16px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      borderRadius: "16px",
      border: "1px solid #E1DEDA",
      background: "#FFF",
    },
  },
  "convo-volumne-wrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
  },
  "convo-outcome-wrapper": {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",

    "& .outcome-bar-container": {
      width: "220px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",

      "& .outcome-bar-scale": {
        width: "180px",
        height: "4px",
        backgroundColor: "#F3F2F0",

        "& .bot-bar-percentage": {
          height: "100%",
          width: "61%",
          backgroundColor: "#8CD1C5",
        },

        "& .agent-bar-percentage": {
          height: "100%",
          width: "19%",
          backgroundColor: "#FFCB9B",
        },

        "& .abandoned-bar-percentage": {
          height: "100%",
          width: "5%",
          backgroundColor: "#F18B97",
        },

        "& .undetermined-bar-percentage": {
          height: "100%",
          width: "15%",
          backgroundColor: "#D9D9D9",
        },
      },
    },
  },
  "recent-convo-wrapper": {
    "& table": {
      "& th": {
        textAlign: "left",
      },

      "& td": {
        height: "28px",
      },

      "& .conversation-column": {
        width: "100px",
        marginRight: "32px",
      },
      "& .csat-column": {
        width: "68px",
        marginRight: "32px",
      },
      "& .outcome-column": {
        width: "180px",
      },

      "& .conversation-cell": {
        display: "flex",
        alignItems: "center",
        columnGap: "8px",
        textDecoration: "none",

        "& .convo-id": {
          color: "#F07400",
          textDecoration: "none",
        },
      },

      "& .outcome-cell": {
        display: "flex",
        alignItems: "center",
        columnGap: "16px",

        "& .outcome-info": {
          padding: "2px 10px",
          borderRadius: "4px",
        },
      },
    },
  },
}));

const NodeDrawerView: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { node, nodes, filters } = useSelector((state) => state.botTree);
  const { dateRange } = filters;

  const totalFrequency = getTotalConversationFrequency(nodes);

  const { data } = node;
  const { label, stats, conversations } = data;

  /* eslint-disable */
  const {
    frequency,
    success_cnt,
    failure_cnt,
    abandoned_cnt,
    transfer_cnt,
    unknown_cnt,
    success_pct,
    failure_pct,
    abandoned_pct,
    transfer_pct,
    unknown_pct,
    freq_pct,
  } = stats;
  /* eslint-enable */

  const freqPct = Math.floor(freq_pct * 100);

  const handleCloseDrawer = useCallback(() => {
    dispatch({
      type: ReduxBotTreeActions.SET_CLOSE_NODE,
    });
  }, [dispatch]);

  return (
    <Box className={classes["node-drawer-container"]}>
      <div className={classes["drawer-header-wrapper"]}>
        <div className="header-text">
          <Typography
            variant="textXl"
            className="w-semi-bold"
            sx={{ color: "#000" }}
          >
            {label}
          </Typography>
          <BotIcon />
        </div>

        <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseDrawer} />
      </div>

      <div className={classes["score-card-wrapper"]}>
        <div className="score-card csat-score-card">
          <Typography className="w-bold" sx={{ color: "#35312D" }}>
            CSAT
          </Typography>

          <div className="score-wrapper flex flex-col">
            <Typography
              variant="textXl"
              className="w-bold"
              sx={{ color: "#282624" }}
            >
              -
            </Typography>
            <Typography
              variant="textSm"
              className="w-semi-bold"
              sx={{ color: "#7C7972" }}
            >
              Average - / -
            </Typography>
          </div>
        </div>
        <div className="score-card sentiment-score-card">
          <Typography className="w-bold" sx={{ color: "#35312D" }}>
            Sentiment score
          </Typography>

          <div className="score-wrapper flex flex-col">
            <Typography
              variant="textXl"
              className="w-bold"
              sx={{ color: "#282624" }}
            >
              -
            </Typography>
            <Typography
              variant="textSm"
              className="w-semi-bold"
              sx={{ color: "#7C7972" }}
            >
              Average - / -
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes["convo-volumne-wrapper"]}>
        <Typography
          variant="textLg"
          className="w-bold"
          sx={{ color: "#282624" }}
        >
          Volume of conversation
        </Typography>

        <div className="flex flex-col row-gap-12">
          <div className="flex col-gap-16">
            <CircularProgressWithLabel size={38} value={freqPct} />

            <Typography variant="textSm" sx={{ color: "#35312D" }}>
              In last {dateRange} days {frequency} out of total {totalFrequency}{" "}
              i.e {freqPct}% of total conservations were related to ‘{label}’.{" "}
            </Typography>
          </div>

          {/* <div className="flex col-gap-16">
              <CircularProgressWithLabel size={38} value={45} />

              <Typography variant="textSm" sx={{ color: "#35312D" }}>
                {label} is most asked request under Refund
                and return request constituting 65% of ‘Return and refund’
                related request
              </Typography>
            </div> */}
        </div>
      </div>

      <div className={classes["convo-outcome-wrapper"]}>
        <Typography
          variant="textLg"
          className="w-bold"
          sx={{ color: "#282624" }}
        >
          Outcome
        </Typography>

        <div className="flex flex-col row-gap-4">
          <div className="flex align-items-center">
            <Typography sx={{ color: "#35312D", width: "190px" }}>
              Successfully resolved by bot
            </Typography>

            <div className="outcome-bar-container">
              <div className="outcome-bar-scale">
                <div
                  className="bot-bar-percentage"
                  style={{ width: Math.floor(success_pct * 180) }}
                ></div>
              </div>
            </div>

            <Typography sx={{ color: "#000" }}>
              {Math.floor(success_pct * 100)}%
            </Typography>
          </div>

          <div className="flex align-items-center">
            <Typography sx={{ color: "#35312D", width: "190px" }}>
              Handed over to agent
            </Typography>

            <div className="outcome-bar-container">
              <div className="outcome-bar-scale">
                <div
                  className="agent-bar-percentage"
                  style={{ width: Math.floor(transfer_pct * 180) }}
                ></div>
              </div>
            </div>

            <Typography sx={{ color: "#000" }}>
              {Math.floor(transfer_pct * 100)}%
            </Typography>
          </div>

          <div className="flex align-items-center">
            <Typography sx={{ color: "#35312D", width: "190px" }}>
              Abandoned
            </Typography>

            <div className="outcome-bar-container">
              <div className="outcome-bar-scale">
                <div
                  className="abandoned-bar-percentage"
                  style={{
                    width: Math.floor((failure_pct + abandoned_pct) * 180),
                  }}
                ></div>
              </div>
            </div>

            <Typography sx={{ color: "#000" }}>
              {Math.floor((failure_pct + abandoned_pct) * 100)}%
            </Typography>
          </div>

          <div className="flex align-items-center">
            <Typography sx={{ color: "#35312D", width: "190px" }}>
              Couldn&apos;t be determined
            </Typography>

            <div className="outcome-bar-container">
              <div className="outcome-bar-scale">
                <div
                  className="undetermined-bar-percentage"
                  style={{
                    width: Math.floor(unknown_pct * 180),
                  }}
                ></div>
              </div>
            </div>

            <Typography sx={{ color: "#000" }}>
              {Math.floor(unknown_pct * 100)}%
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes["recent-convo-wrapper"]}>
        <Typography
          variant="textLg"
          className="w-bold"
          sx={{ color: "#282624" }}
        >
          Recent Conversations
        </Typography>

        <div className="flex flex-col row-gap-4">
          <table>
            <thead>
              <tr className="table-header-row">
                <th className="conversation-column">
                  <Typography variant="textMd" sx={{ color: "#7C7972" }}>
                    Conversation
                  </Typography>
                </th>
                <th className="csat-column">
                  <Typography variant="textMd" sx={{ color: "#7C7972" }}>
                    CSAT
                  </Typography>
                </th>
                <th className="outcome-column">
                  <Typography variant="textMd" sx={{ color: "#7C7972" }}>
                    Outcome
                  </Typography>
                </th>
              </tr>
            </thead>

            <tbody>
              {conversations?.map((converstaion: IConversationRow) => (
                <tr key={converstaion.conversation_id}>
                  <td className="conversation-column">
                    <Link to={""} className="conversation-cell">
                      <Typography variant="textMd" className="convo-id">
                        #{converstaion.conversation_id}
                      </Typography>
                      <MessageIcon />
                    </Link>
                  </td>
                  <td className="csat-column">
                    <Typography variant="textMd" sx={{ color: "#35312D" }}>
                      -
                    </Typography>
                  </td>
                  <td className="outcome-column">
                    <div className="outcome-cell">
                      <div
                        className="outcome-info"
                        style={{
                          backgroundColor:
                            ConvoOutcome[converstaion.terminal_state].color,
                        }}
                      >
                        <Typography variant="textSm" className="w-bold">
                          {ConvoOutcome[converstaion.terminal_state].label}
                        </Typography>
                      </div>
                      <AnnotationIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};

export default NodeDrawerView;
