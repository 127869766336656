import { type IIntegrationFormValues } from "types/APIIntegration";
import { type ITool } from "./Script/types";
import { countryCodePatterns } from "constant/CountryCodeMap";
import { RequestBodyType } from "./types";

export const formatIntegrationRequest = (
  data: IIntegrationFormValues,
  bot_id?: string
): any => {
  let authorizationValue = "";

  if (data.authorization.auth_method === "token") {
    authorizationValue = `Bearer ${data.authorization.token}`;
  } else if (data.authorization.auth_method === "basic") {
    authorizationValue = `Basic ${data.authorization.username}+${data.authorization.password}`;
  }

  const authorizationHeader = {
    key: "Authorization",
    value: authorizationValue,
    description: "This is the authorization header",
  };

  const headers = [...data.headers, authorizationHeader].filter((e) => e.value);

  const requestBody: any = {
    bot_id,
    name: data.name,
    description: data.description,
    http_method: data.http_method,
    url: data.http_url,
    headers,
    query_params: data?.params?.filter((e) => e.value),
  };

  if (data?.body?.body_method === RequestBodyType.FormData) {
    requestBody.form_data = data?.body?.form_data?.filter((e) => e.value);
  } else if (data?.body?.body_method === RequestBodyType.JSON) {
    requestBody.body = data?.body?.json;
  }

  return requestBody;
};

export const formatFileRequest = (files: any, botId?: string): any => {
  if (!files || !botId) return;

  return files.map((file: any) => {
    const data = new FormData();
    data.append("resource_name", file.name);
    data.append("file", file);
    data.append("bot", botId);

    return data;
  });
};

export const formatToolsOptions = (apiIntegrationList: any): ITool[] => {
  if (!apiIntegrationList) return [];

  return apiIntegrationList.map((integration: any) => ({
    api_integration_name: integration?.name,
    api_integration_id: integration?._id,
    api_integration_description: integration?.description,
    http_method: integration?.http_method,
  }));
};

export const formatToolList = (
  tools: any,
  apiIntegrationList: any
): ITool[] => {
  if (!tools || !apiIntegrationList) return [];

  return tools.map((tool: any) => {
    const integration = apiIntegrationList.find(
      (integration: any) => integration?._id === tool.api_integration_id
    );

    return {
      ...tool,
      api_integration_name: integration?.name,
      api_integration_description: integration?.description,
      http_method: integration?.http_method,
    };
  });
};

export const formatAssociatedPhoneNumber = (
  phone?: string
): { countryCode: string | ""; localNumber: string | null } => {
  // Handle invalid input
  if (!phone || typeof phone !== "string") {
    return { countryCode: "", localNumber: null };
  }

  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, "");

  if (digits.length < 7) {
    return { countryCode: "", localNumber: null };
  }

  // Function to format the local number part
  const formatLocalNumber = (number: string): string => {
    if (number.length === 10) {
      return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
    } else if (number.length === 7) {
      return `${number.slice(0, 3)}-${number.slice(3)}`;
    } else {
      // Split into groups of 3-4 digits
      const parts = [];
      let remaining = number;
      while (remaining.length > 0) {
        if (remaining.length > 4) {
          parts.push(remaining.slice(0, 3));
          remaining = remaining.slice(3);
        } else {
          parts.push(remaining);
          remaining = "";
        }
      }
      return parts.join("-");
    }
  };

  // Handle different country code scenarios
  let countryCode = null;
  let localNumber = null;

  // Remove international prefix if present
  let workingNumber = digits;
  if (workingNumber.startsWith("00")) {
    workingNumber = workingNumber.slice(2);
  }

  // Detect country code and extract local number
  if (workingNumber.length >= 10) {
    for (const [code, pattern] of Object.entries(countryCodePatterns)) {
      if (pattern.test(workingNumber)) {
        countryCode = code;
        localNumber = workingNumber.slice(code.length);
        break;
      }
    }

    // If no specific country code was found, assume 3-digit country code
    if (!countryCode && workingNumber.length > 10) {
      countryCode = workingNumber.slice(0, 3);
      localNumber = workingNumber.slice(3);
    } else if (!countryCode) {
      // Assume it's a local number without country code
      localNumber = workingNumber;
    }
  } else {
    localNumber = workingNumber;
  }

  // Format the local number if we have one
  if (localNumber) {
    localNumber = formatLocalNumber(localNumber);
  }

  return { countryCode: `+${countryCode}`, localNumber };
};
