import { Box } from "@mui/material";
import Typography from "components/base/Typography";
import { ReactComponent as ActionIcon } from "./imgs/action-icon.svg";
import { ReduxActionActions } from "store/reduxActions/ReduxActionActions";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { getAddedBotActions } from "api/actions";
import AddedActionList from "./AddedActionList";
import AddNewAction from "./AddNewAction";
import type BotAction from "types/BotAction";

const BotActions = (): React.JSX.Element => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const addedActionList = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.actions.action_list.data
  );

  const fetchAddedActionList = useCallback(async () => {
    getAddedBotActions(token)
      .then((response) => {
        dispatch({
          type: ReduxActionActions.FETCH_ACTION_LIST_SUCCESS,
          payload: response.results,
        });
      })
      .catch(() => {
        dispatch({
          type: ReduxActionActions.FETCH_ACTION_LIST_ERROR,
        });
      });
  }, [dispatch, token]);

  useEffect(() => {
    dispatch({
      type: ReduxActionActions.FETCH_ACTION_LIST_LOADING,
    });
    void fetchAddedActionList();
  }, [dispatch, fetchAddedActionList]);

  return (
    <>
      <Box my={3} mx={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box display="flex" mb={1}>
              <Box mr={1} display="flex" alignItems="center">
                <ActionIcon />
              </Box>
              <Typography variant="h6" className="w-bold" component="h6">
                Actions
              </Typography>
            </Box>
            <div className="fs-16 fw-500">
              Link your resources to enable and train your bot
            </div>
          </Box>
          <AddNewAction />
        </Box>
        {addedActionList?.length > 0 && (
          <Box mt={4}>
            <AddedActionList addedActionList={addedActionList as BotAction[]} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default BotActions;
