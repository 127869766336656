export enum AppearanceSettingsFields {
  bot_display_name = "bot_display_name",
  header_message = "header_message",
  welcome_message = "welcome_message",
  input_box_placeholder = "input_box_placeholder",
  header_logo_enabled = "header_logo_enabled",
  header_logo_url = "header_logo_url",
  header_accent_color = "header_accent_color",
  header_text_color = "header_text_color",
  chat_bubble_enabled = "chat_bubble_enabled",
  chat_bubble_url = "chat_bubble_url",
  display_nudge = "display_nudge",
  nudge_message = "nudge_message",
  default_widget_state = "default_widget_state",
}

export interface IAppearanceSettings {
  bot_display_name: string;
  header_message: string;
  welcome_message: string;
  input_box_placeholder: string;
  header_logo_enabled: boolean;
  header_logo_url: string;
  header_accent_color: string;
  header_text_color: string;
  chat_bubble_enabled: boolean;
  chat_bubble_url: string;
  display_nudge: string;
  nudge_message: string;
  default_widget_state: string;
}
