import { useFormikContext } from "formik";
import { AssistantBuilderFields, type IAssistantBuilder } from "../types";
import { TextField } from "@mui/material";
import { useCallback, useContext, useRef } from "react";
import { patchAssistant } from "api/assistant/assistant";
import { useSnackbar } from "notistack";
import AssistantContext from "../AssistantContext";

const input_box_placeholder = `E.g.
- Do not make promises or commitments that cannot be kept.
- If the AI is unable to assist with a request, inform the caller that a human representative will be able to help them.
- Always stick to your role.
- If the conversation digresses, revert back to the main topic.`;

const GuidelinesAndRules: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext;

  const { values, handleBlur, handleChange } =
    useFormikContext<IAssistantBuilder>();

  const currentTriggerScope = useRef<string>(values.guidelines_and_rules);

  const handleOnBlur = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);

      if (
        !values.guidelines_and_rules ||
        values.guidelines_and_rules === currentTriggerScope.current
      ) {
        return;
      }

      try {
        await patchAssistant(assistantId, {
          general_info: { guidelines_and_rules: values.guidelines_and_rules },
        });

        currentTriggerScope.current = values.guidelines_and_rules;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      }
    },
    [values.guidelines_and_rules]
  );

  return (
    <TextField
      name={AssistantBuilderFields.guidelines_and_rules}
      variant="outlined"
      size="small"
      placeholder={input_box_placeholder}
      fullWidth
      value={values.guidelines_and_rules}
      onChange={handleChange}
      onBlur={(event) => {
        void handleOnBlur(event);
      }}
      minRows={12}
      maxRows={16}
      multiline
    />
  );
};

export default GuidelinesAndRules;
