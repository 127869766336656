import React from "react";
import { ConnectorType } from "types/ActionConnector";

import type BotAction from "types/BotAction";
import type { PostBotAction } from "types/BotAction";

import type AirtableConfig from "types/ConnectorConfig/AirtableConfig";
import type GoogleSheetConfig from "types/ConnectorConfig/GoogleSheetConfig";
import type SalesForceConfig from "types/ConnectorConfig/SalesForceConfig";
import SalesForceAction from "./SalesForceAction";

const AirtableAction = React.lazy(async () => await import("./AirtableAction"));

const GoogleSheetAction = React.lazy(
	async () => await import("./GoogleSheetAction")
);

interface StepAddOrEditActionProps {
	isFormSubmitting: boolean;
	onClickDone: (values: PostBotAction) => void;
	connectorConfig: AirtableConfig | GoogleSheetConfig | SalesForceConfig;
	editActionDetails?: BotAction;
}

const StepAddOrEditAction: React.FC<StepAddOrEditActionProps> = ({
	isFormSubmitting,
	onClickDone,
	connectorConfig,
	editActionDetails,
}) => {
	if (connectorConfig.connector_type === ConnectorType.AIRTABLE) {
		return (
			<AirtableAction
				isFormSubmitting={isFormSubmitting}
				onClickDone={onClickDone}
				connectorConfig={connectorConfig as AirtableConfig}
				editActionDetails={editActionDetails}
			/>
		);
	}

	if (connectorConfig.connector_type === ConnectorType.SALESFORCE) {
		return (
			<SalesForceAction
				isFormSubmitting={isFormSubmitting}
				onClickDone={onClickDone}
				connectorConfig={connectorConfig as SalesForceConfig}
				editActionDetails={editActionDetails}
			/>
		);
	}

	return (
		<GoogleSheetAction
			isFormSubmitting={isFormSubmitting}
			onClickDone={onClickDone}
			connectorConfig={connectorConfig as GoogleSheetConfig}
			editActionDetails={editActionDetails}
		/>
	);
};

export default StepAddOrEditAction;
