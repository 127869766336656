import { Box } from "@mui/material";
import { ReactComponent as SuccessBannerIcon } from "assets/bannerIcon.svg";
import { ReactComponent as InfoBannerIcon } from "assets/lightbulb-02.svg";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import _ from "lodash";
import Typography from "components/base/Typography";

export enum BannerType {
  Success = "SUCCESS",
  Info = "INFO",
}
interface BannerProps {
  message: React.ReactNode;
  borderColor?: string;
  backgroundColor?: string;
  type?: BannerType;
  className?: string;
}

const typeVsBannerIconMap = {
  [BannerType.Success]: SuccessBannerIcon,
  [BannerType.Info]: InfoBannerIcon,
};

const useStyles = makeStyles(() => ({
  banner: (props: { backgroundColor: string }) => {
    return {
      background: props.backgroundColor,
      borderRadius: "8px",
    };
  },
}));

const SingleMessageBanner: React.FC<BannerProps> = ({
  message,
  borderColor = "#A4EBC5",
  backgroundColor = "#F4FDF8",
  type = BannerType.Success,
  className = "",
}) => {
  const classes = useStyles({ backgroundColor });
  const BannerIcon = typeVsBannerIconMap[type];

  return (
    <Box
      display="flex"
      borderRadius={1}
      border={`1px solid ${borderColor}`}
      className={clsx(classes.banner, {
        [className]: !_.isEmpty(className),
      })}
      p={2}
    >
      <Box display="flex" justifyContent="center" alignItems="center" mr={2}>
        <BannerIcon />
      </Box>
      <Typography className="w-bold">{message}</Typography>
    </Box>
  );
};

export default SingleMessageBanner;
