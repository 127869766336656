import type React from "react";
import { useCallback, useMemo, useState } from "react";
import "./index.scss";
import useWebSocketHook from "./useWebSocketHook";
import { ChatMessage } from "./ChatMessage";
import { v4 as uuidv4 } from "uuid";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import { BOT_WS_BASE_URL } from "api/bot/bot-ws";
import { ReactComponent as SendArrowIcon } from "./assets/send-arrow-icon.svg";
import { makeStyles } from "@mui/styles";
import type BotSettings from "types/BotSettings";
import { useDispatch, useSelector } from "react-redux";
import { type BotMessage } from "./types";
import IceBreaker from "./IceBreaker";
import { type IMessageAction } from "types/ChatbotType";

const useStyles = makeStyles({
  "send-button": {
    height: "40px",
    width: "40px",
    background: (props: any) => props?.accentColor ?? "#ff7d04",

    "&.Mui-disabled": {
      background: "#B5B1AD",
    },

    "&:hover": {
      background: (props: any) => `${props?.accentColor}CC` ?? "#FF9736",
    },
  },
  "message-input-container": {
    "& .MuiInputBase-root": {
      paddingTop: "9.5px",
      paddingBottom: "9.5px",

      "& .MuiInputBase-input": {
        fontSize: "0.875rem",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "24px",
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          boxShadow: "none",
          borderColor: (props: any) => props?.accentColor ?? "inherit",
        },
      },
    },
  },
});

const ChatBot: React.FC = () => {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: any) => state.bot);
  const { chatbot_settings } = settings || {};
  const { header_accent_color, input_box_placeholder } = chatbot_settings || {};

  const classes = useStyles({ accentColor: header_accent_color });

  const [inputValue, setInputValue] = useState("");
  const [enableMessageInput, setEnableMessageInput] = useState(false);

  const botSettings: BotSettings = useSelector(
    (state: any) => state?.botSettings?.bot_settings.data
  );
  const botType = useSelector((state: any) => state.chatbot.botType);

  const baseURL = BOT_WS_BASE_URL(botSettings?.end_user_type, botType);

  const [messages, showTypingIndicator, sendMessage, sendAction] =
    useWebSocketHook(baseURL, dispatch, setEnableMessageInput);

  const iceBreakers = useMemo(() => {
    return botSettings?.suggestions?.map((suggestion) => suggestion.title);
  }, [botSettings?.suggestions]);

  const isMessageInputEnabled = useMemo(() => {
    if (botType === "ws_workflow") {
      return !!inputValue && !showTypingIndicator && enableMessageInput;
    }
    return !!inputValue && !showTypingIndicator;
  }, [enableMessageInput, inputValue, showTypingIndicator]);

  const generateNewMessage = useCallback((message: string): BotMessage => {
    return {
      text: message,
      id: uuidv4(),
      created: "",
      speaker: "agent",
      sources: [],
      isMessageComplete: true,
    };
  }, []);

  const sendMessageToWS = useCallback((): void => {
    if (inputValue.trim() !== "") {
      sendMessage(generateNewMessage(inputValue));
      setInputValue("");
    }
  }, [generateNewMessage, inputValue, sendMessage]);

  const onKeyDown = useCallback(
    (event: any): void => {
      if (event.key === "Enter") {
        sendMessageToWS();
      }
    },
    [sendMessageToWS]
  );

  const handleClickIceBreaker = useCallback(
    (message: string) => {
      sendMessage(generateNewMessage(message));
    },
    [generateNewMessage, sendMessage]
  );

  const handleClickPersona = useCallback(
    (pesona: string) => {
      sendMessage(generateNewMessage(pesona));
    },
    [generateNewMessage, sendMessage]
  );

  const handleClickAction = useCallback(
    (action: IMessageAction) => {
      sendAction(action);
    },
    [sendAction]
  );

  return (
    <div className="d-flex flex-column h-100">
      <div className="chat-list" id="chat-list">
        {messages.map((conversation, index) => {
          return (
            <ChatMessage
              key={conversation.id}
              chatData={conversation}
              prevChat={index > 0 ? messages[index - 1] : undefined}
              onClickPersona={handleClickPersona}
              onClickAction={handleClickAction}
            />
          );
        })}
        {showTypingIndicator ? (
          <ChatMessage
            key="typing"
            chatData={{
              id: "typing",
              text: "",
              speaker: "bot",
              created: "",
              sources: [],
              isMessageComplete: false,
            }}
            showTyping
            prevChat={undefined}
          />
        ) : null}
      </div>

      {messages.length === 1 && (
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          my={1}
          gap={1}
          row-gap={0.5}
          px={2}
        >
          {iceBreakers?.map((iceBreaker, index) => (
            <IceBreaker
              key={index}
              iceBreaker={iceBreaker}
              onClickIceBreaker={handleClickIceBreaker}
            />
          ))}
        </Box>
      )}

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        px={2}
        py={1.5}
      >
        <Grid item flex={1}>
          <TextField
            className={classes["message-input-container"]}
            variant="outlined"
            size="small"
            placeholder={input_box_placeholder}
            fullWidth
            multiline
            onKeyDown={onKeyDown}
            onChange={(e) => {
              if (e.target.value === "\n") {
                // if enter key is pressed down add it to input value
                return;
              }
              setInputValue(e.target.value);
            }}
            value={inputValue}
            InputProps={{
              classes: {
                // multiline: clsx(classes.multiline, "px-16"),
                // input: classes.input,
                // notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
        <Grid item ml={1}>
          <IconButton
            color="inherit"
            size="small"
            onClick={sendMessageToWS}
            disabled={!isMessageInputEnabled}
            // className={clsx(classes.sendButton, {
            //   [classes.disabledSendButton]: !isMessageInputEnabled,
            // })}
            className={classes["send-button"]}
          >
            <SendArrowIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatBot;
