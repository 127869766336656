import { makeStyles } from "@mui/styles";
// import Typography from "components/base/Typography";
import {
  // LogicNodeConfig,
  LogicNodeType,
} from "pages/Bot/WorkflowBuilder/config";
import BranchNode from "./BranchNode";
// import { useDispatch } from "store";
// import { IconButton } from "@mui/material";
// import { ReactComponent as CloseIcon } from "assets/x-close.svg";
// import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
// import { WorkflowActions } from "store/reduxActions/botActions";
// import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  "edit-branch-node": {
    "& .node-header": {
      display: "flex",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
      boxShadow: "0px 1px 0px 0px #E1DEDA",

      "& .node-type-icon": {
        display: "flex",
        padding: "8px",
        alignItems: "flex-start",
        gap: "10px",
        borderRadius: "8px",
        background: "#D8C24F",
      },
    },

    "& .edit-node-content": {
      padding: "32px 24px",
    },
  },
}));

const LogicNodeComponentMap = {
  [LogicNodeType.BRANCH]: BranchNode,
};

interface Props {
  node: any;
  onClose: () => void;
}

const EditLogicNode: React.FC<Props> = ({ node, onClose }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const { type } = node;
  // const { name } = data;

  const EditLogicNodeComponent = LogicNodeComponentMap?.[type];
  // const NodeIcon = LogicNodeConfig?.[type]?.ICON;

  // const [logicNode, setLogicNode] = useState(data);

  // const handleClickClose = (): void => {
  //   dispatch({
  //     type: WorkflowActions.UPDATE_WORKFLOW_NODE,
  //     payload: { id, config: logicNode },
  //   });
  //   onClose();
  // };

  // const handleOnSave = (node: any): void => {
  //   setLogicNode(node);
  //   dispatch({
  //     type: WorkflowActions.UPDATE_WORKFLOW_NODE,
  //     payload: { id, config: node },
  //   });
  // };

  // const handleDeleteNode = (): void => {
  //   dispatch({
  //     type: WorkflowActions.DELETE_NODE,
  //     payload: { node },
  //   });
  // };

  return (
    <div className={classes["edit-branch-node"]}>
      {/* <div className="node-header">
        <div className="flex col-gap-16">
          <div className="node-type-icon">{NodeIcon && <NodeIcon />}</div>
          <div className="flex flex-col">
            <Typography
              variant="textTiny"
              className="w-semi-bold"
              sx={{ color: "#B5B1AD" }}
            >
              Branches
            </Typography>
            <Typography
              variant="textLg"
              className="w-semi-bold"
              sx={{ color: "#000" }}
            >
              {name}
            </Typography>
          </div>
        </div>

        <div className="flex align-items-center">
          <IconButton onClick={handleDeleteNode}>
            <DeleteIcon />
          </IconButton>

          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div> */}

      {EditLogicNodeComponent && <EditLogicNodeComponent node={node} />}
    </div>
  );
};

export default EditLogicNode;
