import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import Typography from "components/base/Typography";
import { useBotNavigatioBarStyles as useStyles } from "./style";
import { type INavigationCategory } from "../types";

interface Props {
  navigations: INavigationCategory[];
}

const BotNavigation: React.FC<Props> = ({ navigations }) => {
  const classes = useStyles();
  const location = useLocation();

  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    if (location?.pathname) {
      const _path = location.pathname.split("/")[4];
      setActivePath(_path);
    }
  }, [location]);

  return (
    <div className={classes["navbar-container"]}>
      <div className="navigation-category-wrapper flex flex-col row-gap-16">
        {navigations.map(({ category, key, title, paths }) => (
          <div className="navigation-category" key={key}>
            <Typography
              variant="textSm"
              sx={{ color: "#7C7972" }}
              className="w-semi-bold my-8 mx-12"
            >
              {title}
            </Typography>

            <div className="category-paths-container flex flex-col">
              {paths.map(
                ({ path, title: pathTitle, key: pathKey, enabled }) => (
                  <Link
                    to={enabled ? path : ""}
                    className={clsx("py-8 px-12", {
                      active: activePath === path,
                      disabled: !enabled,
                    })}
                    key={pathKey}
                  >
                    <Typography
                      className="link-text w-semi-bold"
                      sx={{ color: "#282624" }}
                    >
                      {pathTitle}
                    </Typography>
                  </Link>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BotNavigation;
