import {
  NodeGroupType,
  // ContextVariableConfig,
  // ContextVariable,
  // EdgeType,
  BotPromptNodeType,
  // FieldType,
  // ResponseNodeType,
  ServiceNodeType,
  // APIServiceNodeConfig,
  // ResponseNodeConfig,
  // BotPromptNodeConfig,
  LogicNodeType,
  CreateNodeType,
  // TriggerNodeType
} from "../config";
// import {
//   // getIncomers,
//   // type Edge,
//   // type Node,
//   // getOutgoers,
//   // getConnectedEdges,
// } from "reactflow";
// import { type ISessionContext } from "types/PreviewSession";
import { type IBranch, type IEdge, type INode } from "../types";
// import { uuid } from "./workflow";
import {
  createBranchAddNodeAndEdge,
  createPlaceholderEdge,
  createPlaceholderNode,
  updateNodeById,
} from "./workflow";

const formatResponseNodes = (nodes: INode[]): INode[] => {
  return nodes.map((node: INode) => {
    if (node.type === NodeGroupType.TRIGGER_NODE) {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        width: node?.width ?? 260,
        height: node?.height ?? 70,
        dragHandle: ".node-drag-container",
        data: {
          name: node.data.name,
          group: node.data.group,
          dialog_prompt: node.data.dialog_prompt,
          ui: node.data?.ui,
        },
      };
    }

    if (node.type === ServiceNodeType.API_SERVICE_NODE) {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        width: node?.width,
        height: node?.height,
        dragHandle: ".node-drag-container",
        data: {
          name: node.data.name,
          group: node.data.group,
          description: node.data.description,
          api_integration_id: node.data.api_integration_id,
          input_variable_map: node.data.input_variable_map,
          output_variable: {
            key: node.data.output_variable.key,
            value: node.data.output_variable.value,
            description: node.data.output_variable.description,
            context: node.data.output_variable.key.split(".")[0],
          },
          actions_map: node.data?.actions_map,
          fallback: node.data?.fallback,
          ui: node.data?.ui,
        },
      };
    }

    if (node.type === BotPromptNodeType.CAROUSEL) {
      return {
        id: node.id,
        type: node.type,
        position: node.position,
        width: node?.width,
        height: node?.height,
        dragHandle: ".node-drag-container",
        data: {
          ...node.data,
          fields_map: node.data.fields_map,
        },
      };
    }

    if (node.type === LogicNodeType.BRANCH) {
      const branches = node.data.branches.map((branch: any, index: number) => {
        const _branch = {
          id: branch.id,
          name: branch.name,
          conditions: branch?.conditions,
          rootNodeId: branch?.root_node_id,
        };

        return _branch;
      });

      const default_branch = {
        id: node.data?.default?.id,
        name: node.data?.default?.name,
        conditions: node.data?.default?.conditions,
        rootNodeId: node.data?.default?.root_node_id,
      };

      const updatedNode = {
        id: node.id,
        type: node.type,
        position: node.position,
        width: node?.width,
        height: node?.height,
        dragHandle: ".node-drag-container",
        data: {
          ...node.data,
          branches,
          default: default_branch,
        },
      };

      return updatedNode;
    }

    return {
      dragHandle: ".node-drag-container",
      ...node,
    };
  });
};

const formatResponseEdges = (edges: IEdge[]): IEdge[] => {
  return edges;
};

const createWorflowBuilderMap = (
  nodes: INode[],
  edges: IEdge[]
): { nodes: INode[]; edges: IEdge[] } => {
  let updatedNodes = [...nodes];
  let updatedEdges = [...edges];

  // INTERNAL NODES MAP SETUP
  updatedNodes.forEach((node: INode, nodeIndex: number) => {
    const { id, type, position, data } = node;
    const { terminal, terminalType } = data?.ui;

    // BRANCH NODE: This can be treated as terminal node for any case
    if (type === LogicNodeType.BRANCH) {
      const { branches, default: default_branch } = data;
      const updatedBranches = [...branches];

      (updatedBranches || []).forEach((branch: IBranch, index: number) => {
        if (!branch?.rootNodeId) {
          const { addNode, addNodeEdge } = createBranchAddNodeAndEdge({
            nodeId: id,
            position,
            branchIndex: index,
            branchId: branch.id,
          });

          updatedBranches[index].rootNodeId = addNode.id;

          updatedNodes = [
            ...updateNodeById({
              id,
              nodes: updatedNodes,
              config: {
                branches: updatedBranches,
              },
            }),
            addNode,
          ];
          updatedEdges = [...updatedEdges, addNodeEdge];
        }
      });

      if (!default_branch?.rootNodeId) {
        const { addNode, addNodeEdge } = createBranchAddNodeAndEdge({
          nodeId: id,
          position,
          branchIndex: branches.length,
          branchId: default_branch.id,
        });
        default_branch.rootNodeId = addNode.id;

        updatedNodes = [
          ...updateNodeById({
            id,
            nodes: updatedNodes,
            config: {
              default: default_branch,
            },
          }),
          addNode,
        ];
        updatedEdges = [...updatedEdges, addNodeEdge];
      }

      return;
    }

    // CAROUSEL NODE: For branch node if it is null
    if (type === BotPromptNodeType.CAROUSEL) {
      const { actions_map, ui } = data;
      const { button_1, button_2 } = ui;

      let updatedActionsMap = { ...actions_map };

      if (
        !actions_map?.button_1 &&
        button_1?.type === CreateNodeType.BRANCH_ADD_NODE
      ) {
        // const pNodeButton1 = createPlaceholderNode({
        //   tNode: node,
        //   // nodeId: actions_map?.button_1,
        // });
        // const pEdgeButton1 = createPlaceholderEdge({
        //   sourceId: node.id,
        //   targetId: pNodeButton1.id,
        // });

        // updatedNodes.push(pNodeButton1);
        // updatedEdges.push(pEdgeButton1);
        const { addNode: button1Node, addNodeEdge: button1Edge } =
          createBranchAddNodeAndEdge({
            nodeId: id,
            position,
            branchIndex: 0,
            branchId: 1,
          });

        updatedNodes.push(button1Node);
        updatedEdges.push(button1Edge);

        updatedActionsMap = {
          ...updatedActionsMap,
          button_1: button1Node.id,
        };
      }

      if (
        !actions_map?.button_2 &&
        button_2?.type === CreateNodeType.BRANCH_ADD_NODE
      ) {
        const { addNode: button2Node, addNodeEdge: button2Edge } =
          createBranchAddNodeAndEdge({
            nodeId: id,
            position,
            branchIndex: 1,
            branchId: 2,
          });

        updatedNodes.push(button2Node);
        updatedEdges.push(button2Edge);

        updatedActionsMap = {
          ...updatedActionsMap,
          button_2: button2Node.id,
        };
      }

      updatedNodes = [
        ...updateNodeById({
          id,
          nodes: updatedNodes,
          config: {
            actions_map: updatedActionsMap,
          },
        }),
      ];

      return;
    }

    // TERMINAL NODES SETUP
    if (terminal && terminalType === CreateNodeType.PLACEHOLDER_NODE) {
      const pNode = createPlaceholderNode({ tNode: node });
      const pEdge = createPlaceholderEdge({
        sourceId: node.id,
        targetId: pNode.id,
      });

      updatedNodes.push(pNode);
      updatedEdges.push(pEdge);
    }

    if (terminal && terminalType === CreateNodeType.BRANCH_ADD_NODE) {
      const { addNode, addNodeEdge } = createBranchAddNodeAndEdge({
        nodeId: id,
        position,
        branchIndex: 0,
        branchId: 1,
      });

      updatedNodes.push(addNode);
      updatedEdges.push(addNodeEdge);
    }
  });

  return {
    nodes: updatedNodes,
    edges: updatedEdges,
  };
};

export const formatWorkflowResponse = (response: any): any => {
  const { nodes, edges } = response;

  const formattedNodes = formatResponseNodes(nodes);
  const formattedEdges = formatResponseEdges(edges);

  const workflowMap = createWorflowBuilderMap(formattedNodes, formattedEdges);

  return {
    ...response,
    nodes: workflowMap?.nodes,
    edges: workflowMap?.edges,
  };
};
