import { Box, Grid, TextField as MuiTextField } from "@mui/material";
import Typography from "components/base/Typography";
import { type FormikProps, useFormikContext } from "formik";
import { BotSettingsFields, type BotSettingsFieldsValues } from "../constants";
import CollapsibleCard from "components/shared/CollapsibleCard";
import { ReactComponent as BasicSettingsIcon } from "./imgs/basicSettingsIcon.svg";

const RenderBasicSettings: React.FC = () => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
  }: FormikProps<BotSettingsFieldsValues> = useFormikContext();
  return (
    <CollapsibleCard
      header={
        <>
          <Typography variant="textLg" className="w-bold">
            Basic Settings
          </Typography>
          <Box color="#7C7972">
            <Typography variant="textSm" className="w-medium" color="inherit">
              Botname, Sub heading, Welcome message & overview message
            </Typography>
          </Box>
        </>
      }
      headerIcon={<BasicSettingsIcon />}
      renderContent={
        <Box p={3}>
          <Grid container width="100%" justifyContent="space-between">
            <Grid item xs={5}>
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Chatbot Name
                  </Typography>
                </Box>
                <MuiTextField
                  name={BotSettingsFields.bot_display_name}
                  value={values.bot_display_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  placeholder="Enter Chatbot Name"
                  fullWidth
                  error={
                    touched.bot_display_name && Boolean(errors.bot_display_name)
                  }
                  helperText={
                    touched.bot_display_name && errors.bot_display_name
                      ? errors.bot_display_name
                      : "Max 16 characters"
                  }
                  InputProps={{
                    classes: {
                      input: "py-8 px-12",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Header message
                  </Typography>
                </Box>
                <MuiTextField
                  name={BotSettingsFields.header_message}
                  value={values.header_message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  placeholder="Enter Header Message"
                  fullWidth
                  error={
                    touched.header_message && Boolean(errors.header_message)
                  }
                  helperText={
                    touched.header_message && errors.header_message
                      ? errors.header_message
                      : "Max 50 characters"
                  }
                  InputProps={{
                    classes: {
                      input: "py-8 px-12",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Welcome message
                  </Typography>
                </Box>

                <MuiTextField
                  name={BotSettingsFields.welcome_message}
                  value={values.welcome_message}
                  multiline
                  minRows={2}
                  maxRows={2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  placeholder="Enter Welcome Message"
                  fullWidth
                  error={
                    touched.welcome_message && Boolean(errors.welcome_message)
                  }
                  helperText={
                    touched.welcome_message && errors.welcome_message
                      ? errors.welcome_message
                      : "Max 100 characters"
                  }
                  InputProps={{
                    classes: {
                      multiline: "py-12 px-14",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mx={0.5} mb={4}>
                <Box pb={0.5}>
                  <Typography variant="textMd" className="w-semi-bold">
                    Input Box Placeholder
                  </Typography>
                </Box>
                <MuiTextField
                  name={BotSettingsFields.input_box_placeholder}
                  value={values.input_box_placeholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  placeholder="Enter Header Message"
                  fullWidth
                  error={
                    touched.input_box_placeholder &&
                    Boolean(errors.input_box_placeholder)
                  }
                  helperText={
                    touched.input_box_placeholder &&
                    errors.input_box_placeholder
                      ? errors.input_box_placeholder
                      : "Keep it simple and short"
                  }
                  InputProps={{
                    classes: {
                      input: "py-8 px-12",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default RenderBasicSettings;
