import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "components/base/Typography";
import { useCallback, useState } from "react";
import { formatToHumanStringFromDate } from "utils/dateUtils";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import type BotAction from "types/BotAction";
import EditAction from "./EditAction";
import DeleteActionModal from "./DeleteActionModal";
import ToggleAction from "./ToggleAction";

interface Column {
  id: "name" | "dateUploaded" | "actions" | "visibility";
  label: string;
  maxWidth?: string;
  width?: string;
}
interface AddedActionListProps {
  addedActionList: BotAction[];
}

const AddedActionList: React.FC<AddedActionListProps> = ({
  addedActionList,
}) => {
  const [editActionDetails, setEditActionDetails] = useState<BotAction | null>(
    null
  );

  const [deleteActionId, setDeleteActionId] = useState<string | null>(null);

  const handleClickDeleteAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      event.stopPropagation();
      setDeleteActionId(id);
    },
    []
  );

  const handleDeleteActionModalClose = useCallback(() => {
    setDeleteActionId(null);
  }, []);

  const createDate = useCallback((created: string) => {
    const date = new Date(created);

    return formatToHumanStringFromDate(date);
  }, []);

  const columns: readonly Column[] = [
    { id: "name", label: "Action", maxWidth: "40%", width: "40%" },
    {
      id: "dateUploaded",
      label: "Date\u00a0Uploaded",
      maxWidth: "30%",
      width: "30%",
    },
    {
      id: "visibility",
      label: "Visibility",
      maxWidth: "20%",
      width: "20%",
    },
    {
      id: "actions",
      label: "",
      maxWidth: "10%",
      width: "10%",
    },
  ];

  return (
    <>
      <Paper elevation={1} className="p-24">
        <Box display="flex" flexDirection="column">
          <div className="fs-18 fw-700">Actions</div>
        </Box>
        <Box mt={3}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="table-head">
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        maxWidth: column.maxWidth,
                        width: column.width,
                      }}
                      className="px-4"
                    >
                      <div className="fs-12 fw-600">{column.label}</div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {addedActionList.map((action) => {
                  const { id, name, enabled, description, created } = action;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={id}
                      style={{ cursor: "pointer " }}
                      onClick={() => {
                        setEditActionDetails(action);
                      }}
                    >
                      <TableCell className="px-4">
                        {
                          <Box display="flex">
                            <Box
                              display="flex"
                              alignItems="start"
                              flexDirection="column"
                            >
                              <Typography
                                variant="textMd"
                                className="w-semi-bold"
                                maxLines={1}
                              >
                                {name}
                              </Typography>
                              {description && (
                                <Typography
                                  variant="textSm"
                                  className="w-semi-bold"
                                  maxLines={2}
                                  color="#7C7972"
                                >
                                  {description}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        }
                      </TableCell>
                      <TableCell className="px-4">
                        <Typography variant="textSm" className="w-semi-bold">
                          {createDate(created)}
                        </Typography>
                      </TableCell>
                      <TableCell className="px-4">
                        <ToggleAction id={id} enabled={enabled} />
                      </TableCell>
                      <TableCell className="px-4">
                        <IconButton
                          color="inherit"
                          size="small"
                          onClick={(e) => {
                            handleClickDeleteAction(e, id);
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      {editActionDetails && (
        <EditAction
          editActionDetails={editActionDetails}
          setEditActionDetails={setEditActionDetails}
        />
      )}
      {deleteActionId && (
        <DeleteActionModal
          actionId={deleteActionId}
          onClickClose={handleDeleteActionModalClose}
        />
      )}
    </>
  );
};

export default AddedActionList;
