import { makeStyles } from "@mui/styles";
import { useFormikContext, FieldArray } from "formik";
import Typography from "components/base/Typography";
// import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { type INode, type IBranch } from "pages/Bot/WorkflowBuilder/types";
import BranchComponent from "./BranchComponent";
import Button from "components/base/Button";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import DefaultBranch from "./DefaultBranch";
import { useEffect } from "react";
import { uuid } from "pages/Bot/WorkflowBuilder/utils/workflow";
import { useDispatch } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";
// import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";

const useStyles = makeStyles((theme) => ({
  "edit-container": {
    padding: "108px 24px 32px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",

    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "stretch",
    // rowGap: "24px",

    "& .add-branch-button": {
      width: "200px",
      "& svg": {
        height: "16px",
        width: "16px",
      },
    },
  },
}));

interface Props {
  node: INode;
}

const EditContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = node;

  // const { session_context, addNodeInContext, updateNodeVariablesInContext } =
  //   useSessionContext();

  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<any>();

  const { branches } = values;
  const restBranches = branches.filter(
    (br: IBranch) => br.id !== "default_branch"
  );

  useEffect(() => {
    dispatch({
      type: WorkflowActions.UPDATE_WORKFLOW_NODE,
      payload: { id, config: values },
    });
  }, [values]);

  return (
    <div className={classes["edit-container"]}>
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Node Name</Typography>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          placeholder=""
          fullWidth
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{
            maxLength: 24,
          }}
          error={touched.name && Boolean(errors.name)}
          // helperText={touched.name && errors.name}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">
          Add Description for your reference (Optional)
        </Typography>
        <TextField
          name="description"
          variant="outlined"
          placeholder="Eg. This action reschedule the delivery date and time"
          multiline
          rows={4}
          fullWidth
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.description && Boolean(errors.description)}
          //   helperText={touched.description && errors.description}
        />
      </div>

      <FieldArray name="branches">
        {({ insert, remove, push }) => (
          <div>
            <div className="edit-branches-container flex flex-col row-gap-24">
              {restBranches.length > 0 &&
                restBranches.map((branch: IBranch, index: number) => (
                  <BranchComponent
                    key={index}
                    node={node}
                    {...branch}
                    branchIndex={index}
                    onDeleteBranch={(branchIndex) => {
                      remove(branchIndex);
                    }}
                  />
                ))}
            </div>

            <Button
              className="add-branch-button mt-4"
              color="primary"
              startIcon={<PlusIcon />}
              onClick={() => {
                const newBranch = {
                  id: uuid(),
                  name: `Branch ${restBranches.length}`,
                  pathId: null,
                  conditions: [
                    [
                      {
                        expr1: "",
                        operator: "",
                        expr2: "",
                      },
                    ],
                  ],
                };
                push(newBranch);
              }}
            >
              Add another path
            </Button>
          </div>
        )}
      </FieldArray>

      <DefaultBranch />
    </div>
  );
};

export default EditContainer;
