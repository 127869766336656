import { makeStyles } from "@mui/styles";
import Header from "../components/ContentHeader";
import { ReactComponent as BasicSettingsIcon } from "assets/message-text-square.svg";
import { ReactComponent as ChatWidgetSettingsIcon } from "assets/brush-icon.svg";
import { ReactComponent as ChatCollapsedSettingsIcon } from "assets/cursor-box.svg";
import BasicSettings from "./components/BasicSettings";
import AccordionCard, {
  type AccordionCardProps,
} from "components/base/AccordionCard";
import ChatWidgetSettings from "./components/ChatWidgetSettings";
import ChatCollapsedSettings from "./components/ChatCollapsedSettings";
import { Formik, Form, useFormikContext, type FormikProps } from "formik";
import { AppearanceSettingsFields, type IAppearanceSettings } from "./types";
import Button from "components/base/Button";
import { useDispatch, useSelector } from "store";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { postBotSettings } from "api/bot/appearance";
import { useSnackbar } from "notistack";
import { SettingsActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme) => ({
  "appearance-container": {
    "& .content-wrapper": {
      display: "flex",
      flexDirection: "column",
      padding: "24px 24px",
      gap: "32px",

      "& .test": {
        backgroundColor: "black",
      },
    },
  },
}));

const validationSchema = yup.object({
  [AppearanceSettingsFields.bot_display_name]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(16, "Maximum 16 characters allowed")
    .required("This is a required field"),
  [AppearanceSettingsFields.header_message]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(50, "Maximum 50 characters allowed")
    .required("This is a required field"),
  [AppearanceSettingsFields.welcome_message]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.input_box_placeholder]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.header_logo_enabled]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.header_logo_url]: yup.string(),
  [AppearanceSettingsFields.header_accent_color]: yup.string(),
  [AppearanceSettingsFields.header_text_color]: yup.string(),
  [AppearanceSettingsFields.chat_bubble_enabled]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.chat_bubble_url]: yup.string(),
  [AppearanceSettingsFields.display_nudge]: yup.string(),
  [AppearanceSettingsFields.nudge_message]: yup.string(),
  [AppearanceSettingsFields.default_widget_state]: yup.string(),
});

const SettingConfigurations: AccordionCardProps[] = [
  {
    title: "Basic",
    subtitle: "Botname, Sub heading, Welcome message & overview message",
    icon: <BasicSettingsIcon />,
    content: <BasicSettings />,
  },
  {
    title: "Chat widget",
    subtitle: "Avatar, Logo, brand color",
    icon: <ChatWidgetSettingsIcon />,
    content: <ChatWidgetSettings />,
  },
  {
    title: "Chat collapsed state",
    subtitle: "Nudge message, Closed state appearance, ",
    icon: <ChatCollapsedSettingsIcon />,
    content: <ChatCollapsedSettings />,
  },
];

const AppearanceSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id: botId } = useSelector((state) => state.bot.meta);

  const { values, isValid, dirty }: FormikProps<IAppearanceSettings> =
    useFormikContext();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch({
      type: SettingsActions.UPDATE_BOT_SETTINGS_SUCCESS,
      payload: values,
    });
  }, [values]);

  // const handleUpdateBotSetting = useCallback(
  //   ({ key, value }: { key: string; value: string }) => {},
  //   [values]
  // );

  const handleSaveAppearanceSettings = (): void => {
    setSaving(true);
    postBotSettings(botId, values)
      .then((response) => {
        console.log(response);
        dispatch({
          type: SettingsActions.UPDATE_BOT_SETTINGS_SUCCESS,
          payload: response,
        });
        enqueueSnackbar("Settings updated", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Something went wrong. Please try again", {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const isSaveEnabled = dirty && isValid;

  return (
    <Form>
      <div className="header">
        <Header title="Appearance" subtitle="Modify Bot name look and style">
          <Button
            color="primary"
            size="small"
            onClick={handleSaveAppearanceSettings}
            disabled={!isSaveEnabled}
            loading={saving}
          >
            Save
          </Button>
        </Header>
      </div>

      <div className="content-wrapper">
        {SettingConfigurations.map((configuration, index) => (
          <AccordionCard key={index} {...configuration} />
        ))}
      </div>
    </Form>
  );
};

const Appearance: React.FC = () => {
  const classes = useStyles();
  const { settings } = useSelector((state) => state.bot);

  const initialValues = {
    [AppearanceSettingsFields.bot_display_name]:
      settings?.bot_display_name ?? "",
    [AppearanceSettingsFields.header_message]: settings?.header_message ?? "",
    [AppearanceSettingsFields.welcome_message]: settings?.welcome_message ?? "",
    [AppearanceSettingsFields.input_box_placeholder]:
      settings?.input_box_placeholder ?? "",
    [AppearanceSettingsFields.header_logo_enabled]:
      settings?.header_logo_enabled ?? "",
    [AppearanceSettingsFields.header_logo_url]: settings?.header_logo_url ?? "",
    [AppearanceSettingsFields.header_accent_color]:
      settings?.header_accent_color ?? "",
    [AppearanceSettingsFields.header_text_color]:
      settings?.header_text_color ?? "",
    [AppearanceSettingsFields.chat_bubble_enabled]:
      settings?.chat_bubble_enabled ?? "",
    [AppearanceSettingsFields.chat_bubble_url]: settings?.chat_bubble_url ?? "",
    [AppearanceSettingsFields.display_nudge]: settings?.display_nudge ?? "",
    [AppearanceSettingsFields.nudge_message]: settings?.nudge_message ?? "",
    [AppearanceSettingsFields.default_widget_state]:
      settings?.default_widget_state ?? "",
  };

  return (
    <div className={classes["appearance-container"]}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        <AppearanceSettings />
      </Formik>
    </div>
  );
};

export default Appearance;
