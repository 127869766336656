import { botBEAxiosInstance } from "services/axios";
import { type IAgentHandoff } from "types/ChatbotType";

export const getFAQsSuggestionData = async ({
  params,
}: {
  params: string;
}): Promise<IAgentHandoff> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/faqs?${params}`,
  });
};
