import { Box, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  // getPreviewSession,
  getWorkflowData,
  updateWorkflow,
} from "api/workflow";
import { useEffect, useState } from "react";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useDispatch, useSelector } from "store";
import Header from "./components/Header";
import NodeDrawerContent from "./components/NodeDrawerContent";
import { useParams } from "react-router-dom";
import { ReactComponent as HelpCircle } from "assets/help-circle.svg";
// import { ReactComponent as DotsVertical } from "assets/dots-vertical.svg";
// import { ReactComponent as ShareIcon } from "assets/share-icon.svg";
import { ReactComponent as UploadCloudIcon } from "assets/upload-cloud-icon.svg";
import Button from "components/base/Button";
import WorkflowCanvas from "./components/WorkflowCanvas";
// import { defaultValue, WorkflowContext } from "./WorkflowContext";
import { getAPIIntegrationList } from "api/workflow/integration";
import { formatWorkflowRequest } from "./utils/request";
// import { CreateNodeType } from "./config";
import { useSnackbar } from "notistack";
// import { type IWorkflowContextValue } from "types/Workflow";
import { ReactFlowProvider } from "reactflow";
// import ChatBotContainer from "pages/Bot/ChatBotContainer";
// import useBotStatus from "hooks/useBotStatus";
import useSessionContext from "./hooks/useSessionContext";
import { APIIntegrationsActions } from "store/reduxActions/botActions/apiIntegrationsSlice";
import { type INode } from "./types";
import { formatWorkflowResponse } from "./utils/response";
// import useLayout from "./hooks/useLayout";
import { cleanDagreLayout } from "./utils/layout";

const useStyles = makeStyles((theme) => ({
  "bot-tree-container": {},
  "bot-tree-canvas": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "node-drawer-container": {
    width: "480px",
    minWidth: "408px",
  },
  "chatbot-section": {
    backgroundColor: "transparent",
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: "1000",
    justifyContent: "flex-end",
  },
}));

const Workflow: React.FC = () => {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { botId, workflowId } = params;
  // const { botData } = useBotStatus({ botId });

  const { selectNode, title, description, nodes, edges, pointer, openDrawer } =
    useSelector((state) => state.bot.workflow.workflow);
  // console.log("Workflow -", [...nodes]);

  const selectedNode = selectNode.id
    ? nodes.find((node: INode) => node.id === selectNode.id)
    : null;

  const { session_context, initializeSessionContext } = useSessionContext();

  const [previewSession, setPreviewSession] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    return () => {
      dispatch({
        type: WorkflowActions.RESET_WORKFLOW_DATA,
      });
      cleanDagreLayout();
    };
  }, []);

  useEffect(() => {
    if (!workflowId) return;

    dispatch({
      type: WorkflowActions.SET_WORKFLOW_LOADING,
      payload: { loading: true },
    });

    void Promise.all([
      initializeSessionContext(workflowId),
      getAPIIntegrationList(botId),
      getWorkflowData(workflowId),
    ])
      .then((values: any) => {
        // eslint-disable-next-line
        const [preview_session, apiIntegrations, workflowResponse] = values;

        setPreviewSession(preview_session);

        dispatch({
          type: APIIntegrationsActions.SET_API_INTEGRATIONS_LIST,
          payload: { api_integrations: apiIntegrations },
        });

        const formattedResponse = formatWorkflowResponse(workflowResponse);

        dispatch({
          type: WorkflowActions.SET_WORKFLOW_DATA,
          payload: {
            workflow: {
              pointer: formattedResponse?.pointer,
              workflowId: formattedResponse?._id,
              bot_id: formattedResponse?.bot_id,
              title: formattedResponse?.title,
              description: formattedResponse?.description,
              nodes: formattedResponse?.nodes,
              edges: formattedResponse?.edges,
            },
          },
        });
      })
      .catch((err) => {
        console.error(err);

        dispatch({
          type: WorkflowActions.SET_WORKFLOW_PAGE_ERROR,
        });
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch({
          type: WorkflowActions.SET_WORKFLOW_LOADING,
          payload: { loading: false },
        });
      });
  }, [botId, dispatch, enqueueSnackbar, workflowId]);

  const handleCloseDrawer = (): void => {};

  const handlePublishWorkflow = (): void => {
    if (!workflowId) return;

    const updatedWorkflow = formatWorkflowRequest({
      _id: workflowId,
      botId,
      title,
      description,
      pointer,
      nodes,
      edges,
    });

    const body = {
      workflow: updatedWorkflow,
      preview_session: { ...previewSession, session_context },
    };

    setSaving(true);
    updateWorkflow(body)
      .then((response) => {
        enqueueSnackbar("Workflow updated successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  // const openNodeDrawer =
  //   Boolean(selectedNodeId) &&
  //   selectedNode?.type !== CreateNodeType.PLACEHOLDER_NODE &&
  //   selectedNode?.type !== CreateNodeType.BRANCH_ADD_NODE;

  return (
    <ReactFlowProvider>
      <Box className={classes["bot-tree-container"]}>
        <Header title={title}>
          <div className="flex justify-content-center align-items-center col-gap-16">
            <div className="p-8">
              <HelpCircle />
            </div>

            {/* <Tooltip title="Will be launched soon">
              <div>
                <Button
                  color={"secondary"}
                  className="col-gap-8"
                  disabled
                  endIcon={<ShareIcon />}
                >
                  Share
                </Button>
              </div>
            </Tooltip> */}

            <Button
              color={"primary"}
              className="col-gap-8"
              onClick={handlePublishWorkflow}
              loading={saving}
              endIcon={<UploadCloudIcon />}
            >
              Save
            </Button>
          </div>
        </Header>

        <Box className={classes["bot-tree-canvas"]}>
          <WorkflowCanvas />
        </Box>

        {/* {botData && (
          <div className={classes["chatbot-section"]}>
            <ChatBotContainer
              botConfig={{ minimizeIcon: true, isChatOpen: false }}
              botData={botData}
            />
          </div>
        )} */}

        <Drawer
          ModalProps={{
            slotProps: {
              root: {
                style: {
                  top: "unset",
                  bottom: "unset",
                  left: "unset",
                  right: "0",
                },
              },
            },
            hideBackdrop: true,
            sx: {
              ".MuiPaper-root": {
                borderRadius: "0px",
                "&::-webkit-scrollbar": { width: "0px" },
                "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "6px",
                },
                "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
              },
            },
          }}
          anchor={"right"}
          open={openDrawer}
          // onClose={handleCloseDrawer}
          hideBackdrop
        >
          <div className={classes["node-drawer-container"]}>
            <NodeDrawerContent
              node={selectedNode}
              onClose={handleCloseDrawer}
            />
          </div>
        </Drawer>
      </Box>
    </ReactFlowProvider>
  );
};

export default Workflow;
