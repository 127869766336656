import { type IBotQuestion } from "../types";

export const formatRequestQuestion = (question: IBotQuestion): any => {
  return {
    ...question,
    variables: question?.variables?.map((variable) => {
      if (typeof variable === "object") {
        return variable?.label;
      }

      return variable;
    }),
  };
};
