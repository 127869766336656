/**
 * Condition Element: Structure for combining more than one Condition Unit, i.e. IF Blocks
 */
import { makeStyles } from "@mui/styles";
import ConditionUnit from "./ConditionUnit";
import Typography from "components/base/Typography";
import {
  type INode,
  type IConditionElement,
} from "pages/Bot/WorkflowBuilder/types";
import { Button, IconButton } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { FieldArray } from "formik";

const useStyles = makeStyles((theme) => ({
  "condition-element-container": {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "24px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-muted, #FCFCFB)",

    "& .delete-element-button": {
      "& svg": {
        height: "24px",
        width: "24px",
      },
    },
  },
}));

interface Props {
  node: INode;
  branchIndex: number;
  elementIndex: number;
  element: IConditionElement;
  onDeleteElement: (index: number) => void;
}

const ConditionElement: React.FC<Props> = ({
  node,
  branchIndex,
  elementIndex,
  element,
  onDeleteElement,
}) => {
  const classes = useStyles();

  return (
    <div className={classes["condition-element-container"]}>
      <FieldArray
        name={`branches.[${branchIndex}].conditions.[${elementIndex}]`}
      >
        {({ insert, remove, push }) => (
          <>
            {element.map((unit, index) => (
              <div key={index} className="flex flex-col row-gap-12">
                <div className="element-header">
                  {index === 0 ? (
                    <div className="flex justify-content-between align-items-center">
                      <Typography variant="textSm" className="w-bold subtle">
                        If condition
                      </Typography>
                      {elementIndex > 0 && (
                        <div className="delete-element-button">
                          <IconButton
                            onClick={() => {
                              onDeleteElement(elementIndex);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-row justify-content-between align-items-center">
                      <Typography variant="textSm" className="w-bold subtle">
                        AND Condition
                      </Typography>
                      {index > 0 && (
                        <div className="delete-unit-button">
                          <IconButton
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <MinusIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <ConditionUnit
                  node={node}
                  branchIndex={branchIndex}
                  elementIndex={elementIndex}
                  unitIndex={index}
                  {...unit}
                />
              </div>
            ))}

            <div className="add-unit-button">
              <Button
                variant="text"
                startIcon={<PlusIcon />}
                sx={{ color: "#C9671D" }}
                size="small"
                onClick={() => {
                  const newUnit = {
                    field: "",
                    operator: "",
                    value: "",
                  };
                  push(newUnit);
                }}
              >
                AND
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default ConditionElement;
