import { useMemo } from "react";
import ChatBotLayout from "./ChatBotLayout";
import ChatBot from "./ChatBot";
import ChatBotDisabledState from "./ChatBotDisabledState";
import ChatBotInProgressState from "./ChatBotInProgressState";
import { ChatAssistantStatus, type IAssistant } from "types/assistant.types";

interface ChatBotContainerProps {
  botConfig?: any;
  botData: IAssistant;
}

const ChatBotContainer: React.FC<ChatBotContainerProps> = ({
  botConfig,
  botData,
}) => {
  console.log(botConfig, botData);

  const botStatus = useMemo(() => botData?.status, [botData?.status]);

  const renderComponent = useMemo(() => {
    if (botStatus === ChatAssistantStatus.NO_INDEX) {
      return <ChatBotDisabledState />;
    }

    if (botStatus === ChatAssistantStatus.IN_PROGRESS) {
      return <ChatBotInProgressState />;
    }

    return <ChatBot />;
  }, [botStatus]);

  return <ChatBotLayout botConfig={botConfig}>{renderComponent}</ChatBotLayout>;
};

export default ChatBotContainer;
