import { Box, CircularProgress, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getBotTreeData } from "api/bot-tree";
import React, { useEffect } from "react";
import { ReduxBotTreeActions } from "store/reduxActions/ReduxBotTreeActions";
import { useDispatch, useSelector } from "store";
import { type IBotTree, type IFilter } from "types/BotTree";
import FilterUI from "./components/Filter";
import Header from "./components/Header";
import NodeDrawerView from "./components/NodeDrawerView";
import TreeCanvas from "./components/TreeCanvas";
import { DEFAULT_FILTER } from "./config";
import { ReactComponent as BrokenDataLogo } from "assets/broken-data.svg";
import { ReactComponent as EmptyStateLogo } from "assets/empty-state.svg";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  "bot-tree-container": {},
  "bot-tree-canvas": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "bot-page-loader": {
    height: "70vh",
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .page-broken-logo": {
      height: "100px",
      width: "100px",
    },

    "& .page-empty-logo": {
      height: "400px",
      width: "400px",
    },
  },
}));

const BotTreeContainer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, error, empty } = useSelector((state) => state.botTree);

  if (loading) {
    return (
      <Box className={classes["bot-page-loader"]}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes["bot-page-loader"]}>
        <BrokenDataLogo className="page-error-logo" />
        <Typography variant="textLg" className="w-semi-bold mt-8">
          Something went wrong
        </Typography>
      </Box>
    );
  }

  if (empty) {
    return (
      <Box className={classes["bot-page-loader"]}>
        <EmptyStateLogo className="page-empty-logo" />
        <Typography variant="textLg" className="w-semi-bold mt-8">
          No data
        </Typography>
      </Box>
    );
  }

  const handleNodeClick = (event: any, node: any): any => {
    dispatch({
      type: ReduxBotTreeActions.SET_OPEN_NODE,
      payload: { node },
    });
  };

  return (
    <Box>
      <TreeCanvas handleNodeClick={handleNodeClick} />
    </Box>
  );
};

const BotTree: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.app_user.token);
  const { node } = useSelector((state) => state.botTree);

  const [filters, setFilters] = React.useState<IFilter>({
    dateRange: DEFAULT_FILTER.DATE_RANGE,
    sortMetric: DEFAULT_FILTER.SORT_METRIC,
  });

  const handleCloseDrawer = (): any => {
    dispatch({
      type: ReduxBotTreeActions.SET_CLOSE_NODE,
    });
  };

  useEffect(() => {
    dispatch({
      type: ReduxBotTreeActions.SET_BOT_TREE_LOADING,
      payload: { loading: true },
    });

    getBotTreeData(token, filters)
      .then((response: IBotTree) => {
        const { nodes, edges } = response;

        dispatch({
          type: ReduxBotTreeActions.SET_BOT_TREE_DATA,
          payload: {
            nodes: nodes,
            edges: edges,
          },
        });
        dispatch({
          type: ReduxBotTreeActions.SET_BOT_TREE_LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: ReduxBotTreeActions.SET_FILTER,
          payload: {
            dateRange: filters.dateRange,
            sortMetric: filters.sortMetric,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: ReduxBotTreeActions.SET_BOT_TREE_PAGE_ERROR,
          payload: { error: true },
        });
      });
  }, [filters]);

  return (
    <Box className={classes["bot-tree-container"]}>
      <Header />

      <FilterUI
        filters={filters}
        onChangeFilter={(updateFilter: IFilter) => {
          setFilters(updateFilter);
        }}
      />

      <Box className={classes["bot-tree-canvas"]}>
        <BotTreeContainer />
      </Box>

      <Drawer
        BackdropProps={{ invisible: true }}
        ModalProps={{
          slotProps: {
            root: {
              style: {
                width: "500px",
                top: "unset",
                bottom: "unset",
                left: "unset",
                right: "0",
              },
            },
          },
          hideBackdrop: true,
        }}
        anchor={"right"}
        open={Boolean(node)}
        onClose={handleCloseDrawer}
        hideBackdrop
      >
        {node && <NodeDrawerView />}
      </Drawer>
    </Box>
  );
};

export default BotTree;
