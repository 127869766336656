export enum RenderStatus {
  IN_PROGRESS = "In Progress",
  FAILED = "Failed",
  ACTIVE = "Active",
  SUCCESS = "Success",
}

export interface ISource {
  id: number;
  file_path: string;
  download_url: string;
  created: string;
  modified: string;
  document_id: string;
  user: string;
  file_type: string;
  resource_name: string;
  link: string;
  preview_link: string;
  integration_name: string;
  title: string;
  active: boolean;
  deleted: boolean;
  pending_sync: boolean;
  bot: string;
}

export default interface Source {
  id: string;
  render_status: RenderStatus;
  created: string;
  modified: string;
  name: string;
  url: string;
  status: string;
  resource_type: string;
  enabled: boolean;
  index_name: string | null;
  crawl_completed_on: string;
  index_completed_on: string | null;
}
