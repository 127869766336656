import type React from "react";
import useStyles from "./RightPanel.container.styles";
import ActionPreview from "./ActionPreview/ActionPreview";
import ActionConfigure from "./ActiionConfigure/ActionConfigure";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { useDispatch, useSelector } from "store";
import { isEqual } from "lodash";
import { type ActionParam } from "../AgentActions.const";

const RightPanelContainer: React.FC = () => {
  const classes = useStyles();
  const {
    actionForPreview,
    selectedConfigurableParam,
    selectedConfigurableAction,
  } = useSelector((state) => state.actionBuilder);
  const dispatch = useDispatch();

  const openSelectAndConfigure = (): void => {
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION,
      payload: actionForPreview,
    });
    dispatch({ type: ActionBuilderActions.CLEAR_PREVIEW_ACTION });
  };

  const onSaveParamChanges = (value: string): void => {
    // Update input_params (create new array with updated default value)
    const updatedInputParams = selectedConfigurableAction.input_params.map(
      (param: ActionParam) => {
        if (isEqual(param._id, selectedConfigurableParam._id)) {
          return { ...param, default: value }; // Update the default field
        }
        return param; // Return original if no match
      }
    );

    // Update output_params (create new array with updated default value)
    const updatedOutputParams = selectedConfigurableAction.output_params.map(
      (param: ActionParam) => {
        if (isEqual(param._id, selectedConfigurableParam._id)) {
          return { ...param, default: value }; // Update the default field
        }
        return param; // Return original if no match
      }
    );

    const updatedAction = {
      ...selectedConfigurableAction,
      input_params: updatedInputParams,
      output_params: updatedOutputParams,
    };
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION,
      payload: updatedAction,
    });
    // close right panel
    dispatch({
      type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM,
    });
  };

  return (
    (actionForPreview || selectedConfigurableParam) && (
      <div className={classes.container}>
        {actionForPreview && (
          <ActionPreview
            onClose={() =>
              dispatch({
                type: ActionBuilderActions.CLEAR_PREVIEW_ACTION,
              })
            }
            action={actionForPreview}
            openSelectAndConfigure={openSelectAndConfigure}
          />
        )}
        {selectedConfigurableParam && (
          <ActionConfigure
            param={selectedConfigurableParam}
            onClose={() => {
              dispatch({
                type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM,
              });
            }}
            onSave={onSaveParamChanges}
          />
        )}
      </div>
    )
  );
};

export default RightPanelContainer;
