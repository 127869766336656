import ReactFlow, { useNodesState, useEdgesState } from "reactflow";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RootNode from "../Node/RootNode";
import DataNode from "../Node/DataNode";
import { getLayoutedElements } from "utils/getTreeLayout";
import { useSelector } from "store";
import "reactflow/dist/style.css";

const nodeTypes = {
  root: RootNode,
  "data-node": DataNode,
};

const useStyles = makeStyles((theme) => ({
  "tree-container": {
    width: "94vw",
    height: "78vh",
  },
}));

interface ITreeCanvasProps {
  handleNodeClick: (event: any, node: any) => void;
}

const TreeCanvas: React.FunctionComponent<ITreeCanvasProps> = ({
  handleNodeClick,
}) => {
  const classes = useStyles();
  const { nodes: treeNodes, edges: treeEdges } = useSelector(
    (state) => state.botTree
  );

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    treeNodes,
    treeEdges
  );

  // eslint-disable-next-line
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  // eslint-disable-next-line
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  return (
    <Box className={classes["tree-container"]}>
      <ReactFlow
        fitView
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        onNodeClick={handleNodeClick}
      />
    </Box>
  );
};

export default TreeCanvas;
