import { useRef } from "react";
import Typography from "components/base/Typography";
import { useFormikContext, FieldArray } from "formik";
import { type IServiceNodeForm } from "types/Workflow";
import Button from "components/base/Button";
import TemplateSelect from "components/base/TemplateSelectV2";
import { type IStepContentProps } from "pages/Bot/WorkflowBuilder/types";
import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
import { useSelector } from "react-redux";

const StepAPIInput: React.FC<IStepContentProps> = ({
  handleNextStep,
  handleEditFromKStep,
  completed,
}) => {
  const { selectNode } = useSelector(
    (state: any) => state.bot.workflow.workflow
  );

  const { getNodeVariableOptions } = useSessionContext();
  const options = getNodeVariableOptions(selectNode.id);

  const { values, setFieldValue } = useFormikContext<IServiceNodeForm>();

  const activeStep = useRef<number>(1);

  const handleClickContinue = (event: any): void => {
    event.preventDefault();
    handleNextStep(activeStep.current + 1);
  };

  const enableContinue = values.input_variable_map.every(
    (variable) => variable.value
  );

  return (
    <div className="flex flex-col align-items-stretch mt-2">
      <FieldArray name="input_variable_map">
        {({ insert, remove, push }) => (
          <div className="flex flex-col row-gap-16">
            {values.input_variable_map.length > 0 &&
              values.input_variable_map.map((input_variable, index) => (
                <div
                  key={index}
                  className="input-map-variable flex flex-col align-items-stretch row-gap-8"
                >
                  <div className="flex flex-col">
                    <Typography>{input_variable.key}</Typography>
                    <Typography variant="textSm" sx={{ color: "#7C7972" }}>
                      {input_variable.description}
                    </Typography>
                  </div>

                  <TemplateSelect
                    name={`input_variable_map.${index}.value`}
                    variant="outlined"
                    size="small"
                    placeholder={input_variable.value}
                    fullWidth
                    value={input_variable.value}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    setFieldValue={setFieldValue}
                    options={options}
                    disabled={completed}
                  />
                </div>
              ))}
          </div>
        )}
      </FieldArray>

      <div className="mt-16">
        {completed ? (
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              handleEditFromKStep(activeStep.current);
            }}
          >
            Edit
          </Button>
        ) : (
          <Button
            size="small"
            color={"primary"}
            disabled={!enableContinue}
            onClick={handleClickContinue}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepAPIInput;
