export enum AssistantBuilderFields {
  call_type = "call_type",
  company_info_text = "company_info_text",
  goal = "goal",
  guidelines_and_rules = "guidelines_and_rules",
  begin_message_ai_prompt = "begin_message_ai_prompt",
  agents = "agents",
}

export interface IAgent {
  _id: string;
  name: string;
  description: string;
}

export interface IAssistantBuilder {
  [AssistantBuilderFields.goal]: string;
  [AssistantBuilderFields.company_info_text]: string;
  [AssistantBuilderFields.agents]: IAgent[];
  [AssistantBuilderFields.guidelines_and_rules]: string;
}

// AGENTS
export enum BoundType {
  Inbound = "INBOUND",
  OutBound = "OUTBOUND",
}

export enum StatusType {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  PendingAPIIntegration = "PENDING_API_INTEGRATION",
  ToolMissing = "TOOLMISSING",
}

export interface PhoneNumber {
  countryCode: string;
  phone: string;
}

export interface Agent {
  id: string;
  name: string;
  description?: string;
  boundType: BoundType;
  phoneNumber: PhoneNumber;
  lastEdited: string;
  status: StatusType;
}

export interface AgentInfo {
  callType: BoundType;
  companyInfo: string;
  goal: string;
  welcomeConfig: {
    userFirst?: boolean;
    staticMessage?: string;
    aiPrompt?: string;
  };
  companyInfoFromKnowledgeBase: boolean;
  guidelinesAndRules: string;
}

export interface AgentInfoInput {
  company_info_text?: string;
  goal?: string;
  guidelines_and_rules?: string;
  begin_message_config: {
    user_first?: boolean;
    static_message?: string;
    ai_prompt?: string;
  };
}
