import { useEffect, useState } from "react";
import { CircularProgress, type Theme } from "@mui/material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "pages/Bot/components/ContentHeader";
import Button from "components/base/Button";
import APIResponseViewer from "./APIResponsePanel";
import RequestEditor from "./RequestEditor";
import RequestBody from "./RequestBody";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { type IIntegrationFormValues } from "types/APIIntegration";
import {
  getAPIIntegration,
  postAPIIntegration,
  putAPIIntegration,
} from "api/workflow/integration";
import { formatIntegrationRequest } from "pages/Bot/utils";
import { useSnackbar } from "notistack";
import SampleAPIData from "./SampleAPIData";
import { ReactComponent as LightningIcon } from "assets/lightning-02.svg";
import { RequestBodyType } from "pages/Bot/types";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  "integration-wrapper": {
    "& .request-container": {
      padding: "24px",
      overflowY: "scroll",
    },
  },
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 30 characters allowed")
    .required("This is a required field"),
  description: yup.string(),
  http_method: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 30 characters allowed")
    .required("This is a required field"),
  http_url: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .required("This is a required field"),
});

const IntegrationForm: React.FC = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { botId, integrationId } = params;

  const { values, isValid, setFieldValue } =
    useFormikContext<IIntegrationFormValues>();

  const [apiIntegration, setApiIntegration] = useState<any>({
    _id: integrationId,
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const getAPIIntegrationData = (id: string): any => {
    getAPIIntegration(id)
      .then((response) => {
        //! !! work on backward compatibility from backend keys so that we can directly setValues use for mapping keys into
        // form without using any inbetween layering
        // GET data and format to set it for rendering
        setApiIntegration(response);
        handleUpdateIntegration(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTestAPIIntegrationSuccess = (): void => {
    if (apiIntegration?.is_mock_api && integrationId) {
      getAPIIntegrationData(integrationId);
    }
  };

  useEffect(() => {
    if (integrationId) {
      getAPIIntegrationData(integrationId);
    }

    if (!integrationId) {
      const storedItem = sessionStorage.getItem("api-integration");

      if (storedItem) {
        const { name, description }: { name: string; description: string } =
          JSON.parse(storedItem);

        void setFieldValue("name", name);
        void setFieldValue("description", description);
      }

      setLoading(false);
    }
  }, []);

  const handleUpdateIntegration = (data: any): void => {
    void setFieldValue("name", data.name);
    void setFieldValue("description", data.description);
    void setFieldValue("http_method", data.http_method);
    void setFieldValue("http_url", data.url);
    void setFieldValue("extracted_variables", data.extracted_variables);

    // Set Headers
    const _headers = data.headers.filter(
      (header: any) => header.key !== "Authorization"
    );
    void setFieldValue("headers", _headers);

    // Set Authorization
    const authorization = data.headers.find(
      (header: any) => header.key === "Authorization"
    );

    if (authorization?.value?.includes("Bearer")) {
      void setFieldValue("authorization.auth_method", "token");

      void setFieldValue("authorization", {
        auth_method: "token",
        token: authorization.value.split(" ")[1],
      });
    }
    if (authorization?.value?.includes("Basic")) {
      const cred = authorization.value.split(" ")[1];
      const [username, password] = cred.split("+");

      void setFieldValue("authorization", {
        auth_method: "basic",
        username,
        password,
      });
    }

    // Set Params
    void setFieldValue("params", data.query_params);

    // Set Body
    if (data?.form_data?.length) {
      void setFieldValue("body", {
        body_method: RequestBodyType.FormData,
        form_data: data.form_data,
      });
    }
    if (data?.body) {
      void setFieldValue("body", {
        body_method: RequestBodyType.JSON,
        json: data.body,
      });
    }
  };

  const handleSaveAPIIntegration = (): void => {
    // First need to format body from the values and then POST
    setSaving(true);
    const body = formatIntegrationRequest(values, botId);

    if (apiIntegration?._id) {
      // PUT: API INTEGRATION
      putAPIIntegration({ _id: apiIntegration?._id, ...body })
        .then((response) => {
          setApiIntegration(response);
          handleUpdateIntegration(response);
          enqueueSnackbar("New API integration is saved", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      // POST: API INTEGRATION
      postAPIIntegration(body)
        .then((response) => {
          navigate(`./${response?._id}`, { replace: true });

          setApiIntegration(response);
          handleUpdateIntegration(response);

          enqueueSnackbar("New API integration is saved", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  if (loading) {
    return (
      <div className="w-100 flex flex-col center" style={{ height: "80vh" }}>
        <CircularProgress size="2rem" />
      </div>
    );
  }

  return (
    <div>
      <Header
        title={values.name}
        subtitle={
          values.description ||
          "Integrate API to seamlessly use them in service nodes in workflow"
        }
        badgeDetails={{
          icon: <LightningIcon />,
          title: "Sample API",
          color: "blue",
        }}
        showBadge={apiIntegration?.is_mock_api}
      >
        {apiIntegration?._id ? (
          <Box display="flex">
            <Box mr={2}>
              <APIResponseViewer
                integratonId={apiIntegration._id}
                extractedVariables={values.extracted_variables}
                onTestAPIIntegrationSuccessCb={handleTestAPIIntegrationSuccess}
              />
            </Box>
            {!apiIntegration?.is_mock_api && (
              <Box>
                <Button
                  color={"secondary"}
                  onClick={handleSaveAPIIntegration}
                  disabled={!isValid}
                  loading={saving}
                >
                  Update
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Button
            color={"primary"}
            onClick={handleSaveAPIIntegration}
            disabled={!isValid}
            loading={saving}
          >
            Save
          </Button>
        )}
      </Header>

      <div className="request-container flex flex-col justify-content-start align-items-stretch row-gap-24">
        <RequestEditor disabled={apiIntegration?.is_mock_api} />
        <RequestBody disableAllFields={apiIntegration?.is_mock_api} />
        {apiIntegration?.is_mock_api &&
          !isEmpty(apiIntegration?.mock_api_entity) &&
          !isEmpty(apiIntegration?.mock_data) && (
            <SampleAPIData
              entityHeader={apiIntegration?.mock_api_entity}
              entityValues={apiIntegration?.mock_data}
            />
          )}
      </div>
    </div>
  );
};

const Integration: React.FC = () => {
  const classes = useStyles();

  const initialValues: IIntegrationFormValues = {
    name: "",
    description: "",
    http_method: "GET",
    http_url: "",
    params: [
      // {
      //   key: "",
      //   value: "",
      //   description: "",
      // },
    ],
    authorization: {
      auth_method: null,
      username: "",
      password: "",
      token: "",
    },
    headers: [
      // {
      //   key: "",
      //   value: "",
      //   description: "",
      // },
    ],
    body: {
      body_method: "",
      form_data: [
        // {
        //   key: "",
        //   value: "",
        //   description: "",
        // },
      ],
      json: "",
    },
    extracted_variables: [
      // {
      //   key: "",
      //   value: "",
      //   description: "",
      // },
    ],
  };

  return (
    <div className={classes["integration-wrapper"]}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        <Form>
          <IntegrationForm />
        </Form>
      </Formik>
    </div>
  );
};

export default Integration;
