import {
  type LayoutAlgorithmOptions,
  // type LayoutAlgorithm,
} from "../types/layout.type";
import { useDispatch, useSelector } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";
import { dagreLayout } from "../utils/layout";
import { useReactFlow } from "reactflow";
import { type IEdge, type INode } from "../types";
import { useEffect, useRef } from "react";
import { formatNodesLayout } from "../utils/workflow";
// import { timer } from "d3-timer";

const options = {
  direction: "LR" as LayoutAlgorithmOptions["direction"],
  spacing: [80, 120] as [number, number],
};

const useLayout = (): void => {
  const dispatch = useDispatch();

  const initial = useRef(true);
  const layoutNodes = useRef<any>(null);

  const { fitView } = useReactFlow();

  const { lastAddedNodeId } = useSelector(
    (state) => state.bot.workflow.workflow
  );

  const elements = useSelector(
    (state) => ({
      nodeMap: state.bot.workflow.workflow.nodes,
      edgeMap: state.bot.workflow.workflow.edges,
    }),
    {
      equalityFn: compareElements,
    }
  );

  const nodeCount = elements?.nodeMap?.length;
  // console.log(nodeCount);

  useEffect(() => {
    if (elements?.nodeMap?.some((node) => !node?.width || !node?.height)) {
      console.log("Could not run layout");
      return;
    }

    const runLayout = async (): Promise<void> => {
      const prevLayoutNodes = layoutNodes.current;

      const nodes = elements.nodeMap;
      const edges = elements.edgeMap;

      let layoutInputNodes = nodes;

      if (compareLayout(prevLayoutNodes, nodes)) {
        layoutInputNodes = prevLayoutNodes;
      }

      const { nodes: nextNodes, edges: nextEdges } = await dagreLayout(
        layoutInputNodes,
        edges,
        options
      );

      const updatedNodes = formatNodesLayout(elements?.nodeMap, nextNodes);

      dispatch({
        type: WorkflowActions.SET_WORKFLOW_LAYOUT,
        payload: {
          nodes: updatedNodes,
          edges: nextEdges,
        },
      });

      // !!! LOGIC SET VIEW FOR NEW NODES
      if (!initial.current && nodeCount > 12) {
        // const windowWidth = window.innerWidth;
        // const windowHeight = window.innerHeight;
        // console.log(windowWidth, windowHeight);

        const lastAddedNode = elements?.nodeMap?.filter(
          (node: any) => node.id === lastAddedNodeId
        );

        const fitViewConfig = {
          duration: 500,
          includeHiddenNodes: false,
        };

        const transitionConfig: { maxZoom: number; nodes?: any } = {
          maxZoom: 1,
        };

        if (nodeCount >= 12 && nodeCount <= 20) {
          transitionConfig.maxZoom = 0.8;
        } else if (nodeCount > 20 && nodeCount < 28) {
          transitionConfig.maxZoom = 0.6;
        } else if (nodeCount > 36) {
          transitionConfig.maxZoom = 0.6;
          transitionConfig.nodes = lastAddedNode;
        }

        fitView({
          ...fitViewConfig,
          ...transitionConfig,
        });
      }

      initial.current = false;
      layoutNodes.current = nextNodes;
    };

    void runLayout();
  }, [elements]);

  // const runDagreLayout = async () => {
  //   console.log("Run Dagre Layout");
  //   const nodes = elements.nodeMap;
  //   const edges = elements.edgeMap;

  //   // console.log("Layout Nodes -");
  //   // console.log(nodes);
  //   // console.log(edges);

  //   const { nodes: nextNodes, edges: nextEdges } = await dagreLayout(
  //     nodes,
  //     edges,
  //     options
  //   );

  //   // console.log("Layout Updated Nodes -", layoutRef.current);
  //   // console.log(nextNodes);

  //   // if (nodeCount > 8) {
  //   //   const lastAddedNode = elements?.nodeMap?.filter(
  //   //     (node: any) => node.id === lastAddedNodeId
  //   //   );
  //   //   fitView({
  //   //     duration: 500,
  //   //     padding: 0.1, // Adjust padding to your preference
  //   //     includeHiddenNodes: false, // Adjust this based on your needs
  //   //     nodes: lastAddedNode, // Focus on the newly added node
  //   //     maxZoom: 1, // Set your custom zoom level
  //   //     // minZoom: 0.5, // Optionally set a minimum zoom level
  //   //   });
  //   // }

  //   // const transitions = nextNodes.map((node) => {
  //   //   return {
  //   //     id: node.id,
  //   //     // this is where the node currently is placed
  //   //     from: getNode(node.id)?.position || node.position,
  //   //     // this is where we want the node to be placed
  //   //     to: node.position,
  //   //     node,
  //   //   };
  //   // });

  //   // const t = timer((elapsed: number) => {
  //   //   const s = elapsed / 300;

  //   //   const currNodes = transitions.map(({ node, from, to }) => {
  //   //     return {
  //   //       ...node,
  //   //       position: {
  //   //         // simple linear interpolation
  //   //         x: from.x + (to.x - from.x) * s,
  //   //         y: from.y + (to.y - from.y) * s,
  //   //       },
  //   //       data: { ...node.data },
  //   //     };
  //   //   });

  //   //   dispatch({
  //   //     type: WorkflowActions.SET_WORKFLOW_LAYOUT,
  //   //     payload: {
  //   //       nodes: currNodes,
  //   //       edges: nextEdges,
  //   //     },
  //   //   });

  //   //   // setNodes(currNodes);

  //   //   // this is the final step of the animation
  //   //   if (elapsed > 300) {
  //   //     // we are moving the nodes to their destination
  //   //     // this needs to happen to avoid glitches
  //   //     const finalNodes = transitions.map(({ node, to }) => {
  //   //       return {
  //   //         id: node.id,
  //   //         position: {
  //   //           x: to.x,
  //   //           y: to.y,
  //   //         },
  //   //         data: { ...node.data },
  //   //         type: node.type,
  //   //       };
  //   //     });

  //   //     // setNodes(finalNodes);
  //   //     console.log("Set Final Nodes");
  //   //     dispatch({
  //   //       type: WorkflowActions.SET_WORKFLOW_LAYOUT,
  //   //       payload: {
  //   //         nodes: finalNodes,
  //   //         edges: nextEdges,
  //   //       },
  //   //     });

  //   //     // stop the animation
  //   //     t.stop();
  //   //   }
  //   // });

  //   // return () => {
  //   //   t.stop();
  //   // };

  //   // layoutRef.current += 1;

  //   // dispatch({
  //   //   type: WorkflowActions.SET_WORKFLOW_LAYOUT,
  //   //   payload: {
  //   //     nodes: nextNodes,
  //   //     edges: nextEdges,
  //   //   },
  //   // });
  // };
};

export default useLayout;

interface Elements {
  nodeMap: INode[];
  edgeMap: IEdge[];
}

function compareElements(xs: Elements, ys: Elements): boolean {
  return compareNodes(xs.nodeMap, ys.nodeMap);
}

function compareNodes(xsNodeMap: INode[], ysNodeMap: INode[]): boolean {
  // the number of nodes changed, so we already know that the nodes are not equal
  if (xsNodeMap.length !== ysNodeMap.length) return false;

  for (const xNode of xsNodeMap) {
    const y = ysNodeMap.find((node: INode) => node.id === xNode.id);

    // the node doesn't exist in the next state so it just got added
    if (!y) return false;

    // We don't want to force a layout change while a user might be resizing a
    // node, so we only compare the dimensions if the node is not currently
    // being resized.
    //
    // We early return here instead of using a `continue` because there's no
    // scenario where we'd want nodes to start moving around *while* a user is
    // trying to resize a node or move it around.
    // if (x.resizing || x.dragging) return true;

    // if (xNode?.data?.branches?.length !== y?.data?.branches?.length)
    //   return false;
    if (xNode.width !== y.width || xNode.height !== y.height) return false;
  }

  return true;
}

// Compare Layout for dragged nodes
function compareLayout(xNodes: INode[], yNodes: INode[]): boolean {
  if (xNodes?.length !== yNodes?.length) {
    return false;
  }

  for (const xNode of xNodes) {
    const y = yNodes.find((node: INode) => node.id === xNode.id);

    if (!y) return false;

    if (
      y?.data?.ui?.dragged &&
      (xNode.position.x !== y.position.x || xNode.position.y !== y.position.y)
    ) {
      return true;
    }
  }

  return false;
}

// function compareEdges(xs: Map<string, IEdge>, ys: Map<string, IEdge>): boolean {
//   // the number of edges changed, so we already know that the edges are not equal
//   // if (xs.size !== ys.size) return false;
//   // for (const [id, x] of xs.entries()) {
//   //   const y = ys.get(id);
//   //   // the edge doesn't exist in the next state so it just got added
//   //   if (!y) return false;
//   //   if (x.source !== y.source || x.target !== y.target) return false;
//   //   if (x?.sourceHandle !== y?.sourceHandle) return false;
//   //   if (x?.targetHandle !== y?.targetHandle) return false;
//   // }
//   // return true;
//   return true;
// }
