import { Box, type BoxProps } from "@mui/material";

const GreyBackgroundBox: React.FC<BoxProps> = ({ children, ...boxProps }) => {
	return (
		<Box
			bgcolor="#F6F9FC"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...boxProps}
		>
			{children}
		</Box>
	);
};

export default GreyBackgroundBox;
