import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useFormikContext, FieldArray } from "formik";
// import * as yup from "yup";
import TextField from "@mui/material/TextField";
// import { type IQuestion, type IBotPromptInputNodeData } from "types/Workflow";
// import TemplateTextarea from "components/base/TemplateTextAreaV2";
// import { useContext } from "react";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import { formatSessionContextToOptions } from "pages/Bot/WorkflowBuilder/utils";
// import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
// import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { extractEntities } from "api/workflow";
import clsx from "clsx";
import { useEffect } from "react";
// import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
// import { getExtractedVariables } from "pages/Bot/WorkflowBuilder/utils/session-context";
import {
  type IBotQuestion,
  type IInputNode,
  // INode,
} from "pages/Bot/WorkflowBuilder/types";
import { ENTITY_DATA_TYPE } from "pages/Bot/WorkflowBuilder/config";
import { MenuItem, Tooltip } from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import VariableEditor from "./VariableEditor";
import { formatRequestQuestion } from "pages/Bot/WorkflowBuilder/utils/formatter";

const useStyles = makeStyles((theme) => ({
  "input-question": {
    "& .delete-icon": {
      height: "16px",
      width: "16px",
    },

    "& .question-input-container": {
      "& .question-variable-container": {
        padding: "0px 8px",
        transform: "translateY(-10px)",
        opacity: 0,
        height: "0px",
        transition: "all 0.2s ease-in-out",

        "&.show-variable": {
          height: "52px",
          opacity: 1,
          transform: "translateY(0px)",

          "& .variable-response": {
            display: "flex",
          },
        },

        "& .variable-response": {
          display: "none",
          padding: "12px 16px",
          alignItems: "center",
          gap: "8px",
          alignSelf: "stretch",
          borderRadius:
            "var(--spacing-spacing-none, 0px) var(--spacing-spacing-none, 0px) 8px 8px",
          borderRight: "1px solid var(--border-default, #E1DEDA)",
          borderBottom: "1px solid var(--border-default, #E1DEDA)",
          borderLeft: "1px solid var(--border-default, #E1DEDA)",
          background: "var(--colors-shades-base-white, #FFF)",

          "& .variable": {
            display: "flex",
            padding: "2px 5px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "4px",
            border: "1px solid var(--colors-secondary-purple-600, #9062CB)",
            background: "var(--colors-secondary-purple-100, #EFE4FC)",
          },
        },
      },
    },

    "& .variable-entity-container": {
      "& .data-type-info": {
        height: "24px",
        width: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& svg": {
          height: "16px",
          width: "16px",
          "& path": {
            fill: "#35312D",
          },
        },
      },
    },
  },
}));

function getPlaceholder(index: number): string {
  const placeholder = [
    "Eg. Can you please share your Email address",
    "Eg. Can you please share your phone no.",
    "Eg. Can you please share your pincode",
  ];

  return placeholder?.[index] ?? placeholder[2];
}

interface Props {
  index: number;
  question: IBotQuestion;
  onRemove: (index: number) => void;
  handleUpdateSessionContext: () => void;
}

const BotQuestion: React.FC<Props> = ({
  index,
  question,
  onRemove,
  handleUpdateSessionContext,
}) => {
  const classes = useStyles();

  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IInputNode>();

  const handleQuestionOnBlur = (event: any): void => {
    handleBlur(event);

    const question = values?.questions[index];
    const formattedQuestion = formatRequestQuestion(question);

    extractEntities([formattedQuestion])
      .then((response) => {
        const variables = response[question?.key]?.map((variable: string) => {
          return {
            label: variable,
            type: ENTITY_DATA_TYPE[0].value,
          };
        });
        void setFieldValue(`questions.${index}.variables`, variables);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (question?.variables?.length) {
      handleUpdateSessionContext();
    }
  }, [question?.variables]);

  return (
    <div
      key={index}
      className={clsx(
        classes["input-question"],
        "flex flex-col align-items-stretch row-gap-24"
      )}
    >
      <div className="question-input-container flex flex-col">
        <div className="flex flex-col row-gap-6">
          <div className="flex justify-content-between align-items-center">
            <Typography className="w-semi-bold">What bot should ask</Typography>
            {index > 0 && (
              <IconButton
                onClick={() => {
                  onRemove(index);
                }}
              >
                <DeleteIcon className="delete-icon" />
              </IconButton>
            )}
          </div>

          <TextField
            name={`questions.${index}.question`}
            variant="outlined"
            size="small"
            placeholder={getPlaceholder(index)}
            fullWidth
            multiline
            rows={4}
            value={question.question}
            onChange={handleChange}
            onBlur={handleQuestionOnBlur}
          />
        </div>

        <div
          className={clsx("question-variable-container", {
            "show-variable": values?.questions?.[index]?.variables?.length,
          })}
        >
          <div className="variable-response">
            <Typography variant="textMd" className="flex align-items-center">
              The response from the user will be saved as{" "}
            </Typography>
            <FieldArray name={`questions.[${index}].variables`}>
              {({ insert, remove, push }) => (
                <>
                  {values?.questions?.[index]?.variables?.map((variable, k) => (
                    <VariableEditor
                      key={k}
                      questionIndex={index}
                      variableIndex={k}
                      variable={variable}
                      onRemoveVariable={remove}
                    />
                  ))}
                </>
              )}
            </FieldArray>
          </div>
        </div>
      </div>

      {!!values?.questions?.[index]?.variables?.length && (
        <div className="question-variable-settings flex flex-col row-gap-24">
          {values?.questions?.[index]?.variables?.map((variable, k) => (
            <div
              key={k}
              className="variable-entity-container flex flex-col row-gap-6"
            >
              <div className="flex align-items-center col-gap-6">
                <Typography className="w-semi-bold">
                  Select {variable?.label} data type
                </Typography>
                <Tooltip
                  title={
                    "The right data type enables the chatbot to accurately interpret user inputs, streamline workflows, and deliver relevant, context-aware responses."
                  }
                >
                  <div className="data-type-info">
                    <InfoIcon />
                  </div>
                </Tooltip>
              </div>
              <TextField
                name={`questions.${index}.variables.${k}.type`}
                variant="outlined"
                size="small"
                placeholder="Select attribute"
                select
                fullWidth
                value={variable?.type}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {ENTITY_DATA_TYPE.map((dataType) => (
                  <MenuItem key={dataType.value} value={dataType.value}>
                    {dataType.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BotQuestion;
