import type React from "react";
import { useState } from "react";
import useStyles from "./ToggleDiv.styles";
import clsx from "clsx";

interface ToggleDivButtonOption {
  label: string;
  sublabel: string;
  id: string;
  disabled?: boolean;
}

interface ToggleDivProps {
  section1: ToggleDivButtonOption;
  section2: ToggleDivButtonOption;
  initialSelected?: string;
  onToggle?: (id: string) => void;
}

const ToggleDiv: React.FC<ToggleDivProps> = ({
  section1,
  section2,
  initialSelected,
  onToggle,
}) => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState<string>(
    initialSelected ?? section1.id
  );

  const handleToggle = (id: string): void => {
    if (onToggle) {
      onToggle(id); // Call the callback if provided
    }
    setActiveButton(id);
  };

  return (
    <div className={classes.toggleContainer}>
      {[section1, section2].map(({ label, sublabel, id, disabled }) => (
        <div
          key={id}
          className={clsx(classes.selectContainer, {
            [classes.selected]: activeButton === id,
            [classes.disabled]: disabled,
          })}
          onClick={() => {
            if (!disabled) {
              handleToggle(id); // Only toggle if not disabled
            }
          }}
        >
          <div className={classes.textContainer}>
            <div className={classes.label}>{label}</div>
            <div className={classes.sublabel}>{sublabel}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToggleDiv;
