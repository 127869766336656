interface ConnectorDependentFields {
	field_key: string;
	display_name: string;
	type: string;
	placeholder: string;
}

export enum ConnectorType {
	GOOGLE_SHEETS = "GOOGLE_SHEETS",
	AIRTABLE = "AIRTABLE",
	SALESFORCE = "SALESFORCE",
}

interface ActionConnector {
	connector_type: ConnectorType;
	display_name: string;
	required_fields: ConnectorDependentFields[];
}

export default ActionConnector;
