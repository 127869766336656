import { TextField } from "@mui/material";
import { GeneralInfoFields } from "./types";
import { useFormikContext } from "formik";
import { type GeneralInfoProps } from ".";

const input_box_placeholder = `E.g.
- Do not make promises or commitments that cannot be kept.
- If the AI is unable to assist with a request, inform the caller that a human representative will be able to help them.
- Always stick to your role.
- If the conversation digresses, revert back to the main topic.`;

const RulesAndGuidelines: React.FC = () => {
  const { values, handleBlur, handleChange } =
    useFormikContext<GeneralInfoProps>();

  return (
    <div>
      <TextField
        name={GeneralInfoFields.guidelines_and_rules}
        variant="outlined"
        size="small"
        placeholder={input_box_placeholder}
        fullWidth
        value={values.guidelines_and_rules}
        onChange={handleChange}
        onBlur={handleBlur}
        minRows={8}
        maxRows={10}
        multiline
      />
    </div>
  );
};

export default RulesAndGuidelines;
