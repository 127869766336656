import { SettingsActions } from "store/reduxActions/botActions";
import { createReducer } from "../util";

const initialState = {
  isLoading: false,
  isError: false,
};

const botSettingsSlice = createReducer(initialState, {
  [SettingsActions.FETCH_BOT_SETTINGS_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: true,
      isError: false,
    };
  },
  [SettingsActions.FETCH_BOT_SETTINGS_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [SettingsActions.FETCH_BOT_SETTINGS_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  },
  [SettingsActions.UPDATE_BOT_SETTINGS_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [SettingsActions.UPDATE_BOT_SETTING_PROPERTY]: (state: any, action: any) => {
    const { key, value } = action.payload;
    return {
      ...state,
      [key]: value,
      isLoading: false,
    };
  },
});

export default botSettingsSlice;
