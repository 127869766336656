import {
  Box,
  Grid,
  Radio,
  FormControlLabel,
  TextField as MuiTextField,
} from "@mui/material";
import Typography from "components/base/Typography";
import { Field, type FormikProps, useFormikContext } from "formik";
import { BotSettingsFields, type BotSettingsFieldsValues } from "../constants";
import { RadioGroup } from "formik-mui";
import { RadioValue, WidgetState } from "types/BotSettings";
import GreyBackgroundBox from "components/shared/GreyBackgroundBox";
import BlurredBox from "components/shared/BlurredBox";
import CollapsibleCard from "components/shared/CollapsibleCard";
import { ReactComponent as WidgetBehaviorIcon } from "./imgs/widgetBehaviorIcon.svg";

const RenderWidgetBehavior: React.FC = () => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
  }: FormikProps<BotSettingsFieldsValues> = useFormikContext();

  return (
    <CollapsibleCard
      header={
        <>
          <Typography variant="textLg" className="w-bold">
            Widget
          </Typography>
          <Box color="#7C7972">
            <Typography variant="textSm" className="w-medium" color="inherit">
              Nudge message, Closed state appearance
            </Typography>
          </Box>
        </>
      }
      headerIcon={<WidgetBehaviorIcon />}
      renderContent={
        <GreyBackgroundBox className="p-24">
          <BlurredBox readonly>
            <Grid container width="100%" justifyContent="space-between">
              <Grid item xs={5}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      Display a nudge when widget is closed
                    </Typography>
                  </Box>
                  <Field
                    component={RadioGroup}
                    name={BotSettingsFields.display_nudge}
                  >
                    <FormControlLabel
                      value={RadioValue.YES}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={RadioValue.NO}
                      control={<Radio />}
                      label="No"
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      The default state of widget
                    </Typography>
                  </Box>
                  <Field
                    component={RadioGroup}
                    name={BotSettingsFields.default_widget_state}
                  >
                    <FormControlLabel
                      value={WidgetState.CLOSED}
                      control={<Radio />}
                      label="Closed"
                    />
                    <FormControlLabel
                      value={WidgetState.OPEN}
                      control={<Radio />}
                      label="Open"
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      Nudge message
                    </Typography>
                  </Box>
                  <MuiTextField
                    name={BotSettingsFields.nudge_message}
                    value={values.nudge_message}
                    multiline
                    minRows={2}
                    maxRows={2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    size="small"
                    placeholder="Enter Nudge Message"
                    fullWidth
                    error={
                      touched.nudge_message && Boolean(errors.nudge_message)
                    }
                    helperText={
                      touched.nudge_message && errors.nudge_message
                        ? errors.nudge_message
                        : "Max 100 characters"
                    }
                    InputProps={{
                      classes: {
                        multiline: "py-12 px-14",
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </BlurredBox>
        </GreyBackgroundBox>
      }
    />
  );
};

export default RenderWidgetBehavior;
