// import { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
// import CenteredLoader from "components/CenteredLoader";
import { IconButton } from "@mui/material";
// import { useSnackbar } from "notistack";
import { ReactComponent as UploadIcon } from "assets/upload-icon.svg";
// import sleep from "sleep-promise";

const useStyles = makeStyles((theme) => ({
  "upload-source-container": {
    "& .upload-btn-container": {
      display: "flex",
      width: "32px",
      height: "32px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      borderRadius: "8px",

      "& svg": {
        height: "18px",
        width: "18px",
      },
    },
  },
}));

interface ToggleSourceProps {
  id: string;
  name: string;
}

const SourceUpload: React.FC<ToggleSourceProps> = ({ id, name }) => {
  const classes = useStyles();

  // const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={classes["upload-source-container"]}>
      <div className="upload-btn-container">
        <IconButton color="inherit" size="small" disabled>
          <UploadIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default SourceUpload;
