import { useCallback, useState } from "react";
import Button from "components/base/Button";
import { ReactComponent as AddIcon } from "assets/add-icon.svg";
import SidePanel from "components/shared/SidePanel";
import Typography from "components/base/Typography";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { ReduxActionActions } from "store/reduxActions/ReduxActionActions";
import { postBotAddAction } from "api/actions";
import { type PostBotAction } from "types/BotAction";
import CreateActionWrapper from "./CreateActionWrapper";

const AddNewAction: React.FC = () => {
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const dispatch = useDispatch();

  const handleSidePanelClose = useCallback(() => {
    setOpenSidePanel(false);
  }, []);

  const handleSidePanelOpen = useCallback(() => {
    setOpenSidePanel(true);
  }, []);

  const handleSubmitForm = useCallback(
    (values: PostBotAction) => {
      setIsFormSubmitting(true);

      postBotAddAction(values, token)
        .then((response) => {
          dispatch({
            type: ReduxActionActions.POST_ACTION_SUCCESS,
            payload: response,
          });
          enqueueSnackbar("Action created", {
            variant: "success",
          });

          setOpenSidePanel(false);
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsFormSubmitting(false);
        });
    },
    [dispatch, enqueueSnackbar, token]
  );

  return (
    <>
      <Button
        color="tertiary"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleSidePanelOpen}
      >
        Create a new action
      </Button>
      <SidePanel
        open={openSidePanel}
        onClickClose={handleSidePanelClose}
        header={
          <Typography variant="textXl" className="w-semi-bold">
            Create a new action
          </Typography>
        }
      >
        <CreateActionWrapper
          isFormSubmitting={isFormSubmitting}
          onCreateAction={handleSubmitForm}
        />
      </SidePanel>
    </>
  );
};

export default AddNewAction;
