import { IconButton } from "@mui/material";
import { useBotHeaderStyles as useStyles } from "./style";
import { ReactComponent as ArrowLeft } from "assets/arrow-left.svg";
import Typography from "components/base/Typography";
import useRouteNavigator from "hooks/useRouteNavigator";

interface Props {
  //   children: React.ReactNode;
  title: string;
  description?: string;
}

const BotHeader: React.FC<Props> = ({ title, description = "" }) => {
  const classes = useStyles();
  const { gotoBotDashboard } = useRouteNavigator();

  const handleBackClick = (): void => {
    gotoBotDashboard();
  };

  return (
    <div className={classes["bot-header-container"]}>
      <div className="flex align-items-center col-gap-12">
        <IconButton className="back-button" onClick={handleBackClick}>
          <ArrowLeft />
        </IconButton>

        <div className="bot-details-container flex flex-col row-gap-4">
          <Typography variant="textXl" className="w-bold">
            {title}
          </Typography>
          <Typography className="w-medium">{description}</Typography>
        </div>
      </div>

      {/* <div className="bot-header-actions">{children}</div> */}
    </div>
  );
};

export default BotHeader;
