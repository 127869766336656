import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useSelector } from "store";
import { type IServiceNodeForm } from "types/Workflow";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import EditNodeHeader from "../../components/EditNodeHeader";
import EditContainer from "./EditContainer";

const useStyles = makeStyles((theme) => ({
  "api-service-node-container": {},
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 30 characters allowed")
    .required("This is a required field"),
  description: yup.string(),
  api_integration_id: yup.string().required("This is a required field"),
  input_variable_map: yup.array().required("This is a required field"),
  output_variable: yup.object().shape({
    key: yup.string().required("This is a required field"),
    description: yup.string(),
    context: yup.string().required("This field is required"),
  }),
  fallback: yup.object().shape({
    type: yup.string().required("This is a required field"),
  }),
  api_response: yup.mixed().required("This is a required field"),
});

interface Props {
  node: INode;
}

const FormContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();

  return (
    <Form>
      <div className={classes["api-service-node-container"]}>
        <EditNodeHeader node={node} />
        <EditContainer node={node} />
      </div>
    </Form>
  );
};

const APIServiceNode: React.FC<Props> = ({ node }) => {
  const api_integrations = useSelector(
    (state) => state.bot.api_integrations.list
  );
  const { data } = node;

  const initialValues: Omit<IServiceNodeForm, "group" | "ui"> = {
    name: data.name || "",
    description: data?.description || "",
    api_integration_id: data?.api_integration_id || "",
    selected_api_integration: data?.api_integration_id
      ? api_integrations?.find((e: any) => e._id === data?.api_integration_id)
      : null,
    input_variable_map: data?.input_variable_map || [],
    output_variable: data?.output_variable || {
      key: "",
      value: "",
      description: "",
      context: "",
    },
    fallback: data?.fallback ?? {
      type: "",
    },
    api_integration_valid: false,
    test_api: data?.test_api ?? [],
    api_response: null,
    actions_map: data?.actions_map ?? {},
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      <FormContainer node={node} />
    </Formik>
  );
};

export default APIServiceNode;
