import { makeStyles } from "@mui/styles";
// import { ReactComponent as ChatMessageIcon } from "assets/chat-message-icon.svg";
// import Typography from "components/base/Typography";
// import { ReactComponent as CloseIcon } from "assets/x-close.svg";
import { ResponseNodeType } from "pages/Bot/WorkflowBuilder/config";
import EditTextNode from "./TextNode";
// import { useDispatch } from "store";
// import { useState } from "react";
// import { WorkflowActions } from "store/reduxActions/botActions";
// import { IconButton } from "@mui/material";
// import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";

const useStyles = makeStyles((theme) => ({
  "edit-response-node": {
    "& .node-header": {
      display: "flex",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
      boxShadow: "0px 1px 0px 0px #8CD1C5",

      "& .node-type-icon": {
        display: "flex",
        padding: "8px",
        alignItems: "flex-start",
        gap: "10px",
        borderRadius: "8px",
        background: "#B2E69F",
      },
    },

    "& .edit-node-content": {
      padding: "32px 24px",
    },
  },
}));

const ResponseNodeComponentMap = {
  [ResponseNodeType.TEXT]: EditTextNode,
  [ResponseNodeType.CAROUSEL]: EditTextNode,
};

interface Props {
  node: any;
  onClose: () => void;
}

const EditResponseNode: React.FC<Props> = ({ node, onClose }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const { type } = node;

  // const [responseNode, setResponseNode] = useState(data);

  // const handleOnSave = (data: any): void => {
  //   setResponseNode(data);

  //   dispatch({
  //     type: WorkflowActions.UPDATE_WORKFLOW_NODE,
  //     payload: { id, config: data },
  //   });
  // };

  // const handleClickClose = (): void => {
  //   dispatch({
  //     type: WorkflowActions.UPDATE_WORKFLOW_NODE,
  //     payload: { id, config: responseNode },
  //   });

  //   onClose();
  // };

  // const handleDeleteNode = (): void => {
  //   dispatch({
  //     type: WorkflowActions.DELETE_NODE,
  //     payload: { node },
  //   });
  // };

  const EditResponseNodeComponent = ResponseNodeComponentMap?.[type];

  return (
    <div className={classes["edit-response-node"]}>
      {EditResponseNodeComponent && <EditResponseNodeComponent node={node} />}
    </div>
  );
};

export default EditResponseNode;
