import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	required: {
		color: "red",
		marginLeft: "2px",
	},
});

const RequiredFieldMark: React.FC = () => {
	const classes = useStyles();
	return (
		<Box component="span" className={classes.required}>
			*
		</Box>
	);
};

export default RequiredFieldMark;
