import { useCallback, useState } from "react";
import ColoredSwitch from "components/base/ColoredSwitch";
import CenteredLoader from "components/shared/CenteredLoader";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { SourceActions } from "store/reduxActions/botActions";
import { patchLinkSource, patchFileSource } from "api/source";

interface ToggleSourceProps {
  action: "file" | "link";
  enabled: boolean;
  id: string;
}

const SourceVisibility: React.FC<ToggleSourceProps> = ({
  action,
  enabled,
  id,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: any) => state.app_user);

  const { enqueueSnackbar } = useSnackbar();

  const [showLoader, setShowLoader] = useState(false);

  const alterSourceStatus = useCallback(async () => {
    if (action === "link") {
      await patchLinkSource(id, { enabled: !enabled }, token)
        .then((response) => {
          dispatch({
            type: SourceActions.PUT_LINK_SOURCE_SUCCESS,
            payload: response,
          });

          enqueueSnackbar(
            `Source ${response.name} ${
              response.enabled ? "enabled" : "disabled"
            }`,
            {
              variant: "success",
            }
          );
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setShowLoader(false);
        });
    } else if (action === "file") {
      await patchFileSource(id, { active: !enabled }, token)
        .then((response) => {
          dispatch({
            type: SourceActions.PUT_FILE_SOURCE_SUCCESS,
            payload: { id, response },
          });

          enqueueSnackbar(
            `Source ${response.active ? "enabled" : "disabled"}`,
            {
              variant: "success",
            }
          );
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  }, [action, dispatch, enabled, enqueueSnackbar, id, token]);

  const handleClickToggle = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      setShowLoader(true);

      await alterSourceStatus();
    },
    [alterSourceStatus]
  );

  return (
    <>
      {showLoader ? (
        <Box display="flex" justifyContent="start">
          <CenteredLoader />
        </Box>
      ) : (
        <ColoredSwitch
          checked={enabled}
          onClick={(e) => {
            void handleClickToggle(e);
          }}
        />
      )}
    </>
  );
};

export default SourceVisibility;
