import type React from "react";
import { useStyles } from "./Badge.styles";
import clsx from "clsx";

export interface BadgeProps {
  icon: React.ReactNode;
  title: string;
  color?: "red" | "blue" | "grey";
  titleClassName?: string;
}

const Badge: React.FC<BadgeProps> = ({
  icon,
  title,
  color = "grey",
  titleClassName,
}) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.badge}>
      <span className={classes.icon}>{icon}</span>
      <span className={clsx(classes.title, titleClassName)}>{title}</span>
    </div>
  );
};

export default Badge;
