import { type IBotTree, type IEdge, type INode } from "types/BotTree";

export const formatTree = ({
  nodes,
  edges,
}: {
  nodes: INode[];
  edges: IEdge[];
}): IBotTree => {
  const formattedNodes = nodes.map((node) => ({
    ...node,
    id: node.id.toString(),
  }));

  const formattedEdges = edges.map((edge) => ({
    ...edge,
    id: edge.id.toString(),
    source: edge.source.toString(),
    target: edge.target.toString(),
  }));

  return {
    nodes: formattedNodes,
    edges: formattedEdges,
  };
};

export const getRootNode = (nodes: INode[]): INode | undefined => {
  return nodes.find((node) => node.type === "root");
};

export const getTotalConversationFrequency = (nodes: INode[]): number => {
  const rootNode = nodes.find((node) => node.type === "root");

  if (!rootNode) return 0;

  return rootNode.data.stats.frequency;
};
