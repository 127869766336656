import { type FieldIceBreaker } from "types/BotSettings";

export enum BotSettingsFields {
	bot_display_name = "bot_display_name",
	header_message = "header_message",
	welcome_message = "welcome_message",
	input_box_placeholder = "input_box_placeholder",
	bot_response_length = "bot_response_length",
	show_source_of_info = "show_source_of_info",
	request_feedback = "request_feedback",
	display_nudge = "display_nudge",
	nudge_message = "nudge_message",
	default_widget_state = "default_widget_state",
	no_bot_response = "no_bot_response",
	status_banner = "status_banner",
	end_conversation_msg = "end_conversation_msg",
	suggestions = "suggestions",
}
export interface BotSettingsFieldsValues {
	bot_display_name: string;
	header_message: string;
	welcome_message: string;
	input_box_placeholder: string;
	bot_response_length: string;
	show_source_of_info: string;
	request_feedback: string;
	display_nudge: string;
	nudge_message: string;
	default_widget_state: string;
	no_bot_response: string;
	status_banner: string;
	end_conversation_msg: string;
	suggestions: FieldIceBreaker[];
}
