import { botBEAxiosInstance } from "services/axios";
import { type APITaskList, type APIGroup } from "types/APIIntegration";

export const getAPIIntegrationList = async (botId?: string): Promise<any> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/api_integrations/${botId}/`,
  });
};

export const getAPIIntegrationListV2 = async (
  botId: string
): Promise<APITaskList> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/api_integrations/v2/${botId}/`,
  });
};

export const getAllMockAPIs = async (botId?: string): Promise<APIGroup[]> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/mock/${botId}/apis`,
  });
};

export const postAPIIntegration = async (body: any): Promise<any> => {
  return await botBEAxiosInstance({
    method: "POST",
    url: `/api_integration/`,
    data: body,
  });
};

export const createMockAPIIntegration = async (
  botId: string,
  body: {
    id: string;
    description?: string;
  }
): Promise<any> => {
  return await botBEAxiosInstance({
    method: "POST",
    url: `mock/${botId}/apis`,
    data: body,
  });
};

export const postCreateNewSampleAPI = async (
  botId: string,
  describeObjective: string
): Promise<any> => {
  const body = {
    generate_instructions: describeObjective,
  };
  return await botBEAxiosInstance({
    method: "POST",
    url: `mock/${botId}/apis_generation/`,
    data: body,
  });
};

export const putAPIIntegration = async (body: any): Promise<any> => {
  return await botBEAxiosInstance({
    method: "PUT",
    url: `/api_integration/`,
    data: body,
  });
};

export const getAPIIntegration = async (id: any): Promise<any> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/api_integration/${id}`,
  });
};

export const testAPIIntegration = async (
  id: string,
  body: any
): Promise<any> => {
  return await botBEAxiosInstance({
    method: "POST",
    url: `/api_integration/run/${id}`,
    data: body,
  });
};
