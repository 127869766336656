import type React from "react";
import useStyles from "./ActionConfigure.style";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";
import { ReactComponent as TrashIcon } from "assets/trash-01.svg";
import { ReactComponent as HelpCircleIcon } from "assets/help-circle.svg";
import { ReactComponent as SaveIcon } from "assets/save-01.svg";
import { ReactComponent as NoteIcon } from "assets/alert-square.svg";
import Button from "components/base/Button";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { type ActionParam } from "../../AgentActions.const";
import { useEffect, useState } from "react";

interface Props {
  param: ActionParam;
  onClose: () => void;
  onSave: (value: string) => void;
}

const ActionConfigure: React.FC<Props> = ({ param, onClose, onSave }) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState(param.default ?? "");

  useEffect(() => {
    setDefaultValue(param.default ?? "");
  }, [param]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{param.name}</div>
          <CrossIcon onClick={onClose} />
        </div>
        <div className={classes.content}>
          <div>
            <div className={classes.descriptionHead}>Description</div>
            <div className={classes.description}>{param.description}</div>
            <div className={classes.example}>{param.example}</div>
          </div>
          {/* next row */}
          <div>
            <div className={classes.fieldTitle}>Display name</div>
            <div className={classes.inputContainer}>
              <div className={classes.inputValue}>{param.name}</div>
              <HelpCircleIcon />
            </div>
          </div>
          {/* next row */}
          <div>
            <div className={classes.fieldTitle}>
              Provide a default value (optional)
            </div>
            <input
              type="text"
              className={classes.defaultInput}
              value={defaultValue}
              onChange={(e) => {
                setDefaultValue(e.target.value);
              }}
            />
            <div className={classes.noteContainer}>
              <div className={classes.noteIcon}>
                <NoteIcon />
              </div>
              <div className={classes.note}>
                Default value will used incase user input is not available.
              </div>
            </div>
          </div>
          {/* next row */}
          <div>
            <div className={classes.fieldTitle}>
              How the departure date is sourced?
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputValue}>
                Extracted from the user’s query
              </div>
              <ExpandMore />
            </div>
          </div>
          {/* next row */}
          <div>
            <div className={classes.fieldTitle}>
              Is this input required from the user to continue the conversation?
            </div>
            <RadioGroup row={false} className="px-1" value={param.required}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes, it's mandatory"
                disabled
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No, it's optional"
                disabled
              />
            </RadioGroup>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            color="secondary"
            className={classes.configureBtn}
            onClick={onClose}
            iconBtn
            startIcon={<TrashIcon />}
          >
            Cancel changes
          </Button>
          <Button
            color="primary"
            className={classes.configureBtn}
            onClick={() => {
              onSave(defaultValue);
            }}
            iconBtn
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ActionConfigure;
