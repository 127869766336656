import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
// import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import {
  // type ICarousel,
  type IBotPromptCarouselNodeData,
  // type IFieldMap,
} from "types/Workflow";
import {
  formatRecordToTypedObject,
  // formatSessionContextToOptions,
  getCarouselSample,
} from "pages/Bot/WorkflowBuilder/utils/session-context";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import { FieldType } from "pages/Bot/WorkflowBuilder/config";
// import { IconButton } from "@mui/material";
// import clsx from "clsx";
// import CarouselItem from "components/shared/CarouselItem";
// import { Link } from "react-router-dom";
// import TemplateTextField from "components/base/TemplateTextFieldV2";
// import TemplateTextarea from "components/base/TemplateTextAreaV2";
// import TemplateSelect from "components/base/TemplateSelectV2";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useDispatch } from "store";
import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import FieldsMap from "./FieldsMap";

const useStyles = makeStyles((theme) => ({
  "edit-container": {
    padding: "108px 24px 32px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  "carousel-container": {
    "& .carousel-sample": {
      display: "flex",
      width: "432px",
      padding: "23px 16px",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      borderRadius: "8px",
      border: "1px solid var(--border-default, #E1DEDA)",
      background: "var(--bg-disabled, #F9F8F8)",
      "& .carousel-card": {
        position: "relative",
        display: "flex",
        width: "236px",
        height: "306px",
        paddingBottom: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "16px",
        border: "1px solid var(--border-default, #E1DEDA)",
        background: "#fff",
        "& .tag": {
          display: "flex",
          padding: "2px 8px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          position: "absolute",
          left: "12px",
          top: "12px",
          borderRadius: "4px",
          background: "#FFF",
          boxShadow: "4px 4px 5px 0px rgba(0, 0, 0, 0.15)",
        },
        "& .carousel-header": {
          width: "236px",
          height: "117px",
          borderRadius:
            "16px 16px var(--spacing-spacing-none, 0px) var(--spacing-spacing-none, 0px)",
          background: "var(--colors-shades-stone-400, #E7E5E1)",
          "& .carousel-image": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          },
        },
        "& .carousel-action": {
          height: "72px",
          width: "100%",
          "& .card-action-btn": {
            height: "50%",
            borderTop: "1px solid #E7E5E1",
            "& a": {
              textDecoration: "none",
              color: "#E06F06",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "24px",
            },
          },
        },
      },
    },
    "& .carousel-data-container": {
      "& .carousel-input-container": {
        "& .input-parameter": {
          width: "180px",
          "&.required": {
            "&::after": {
              content: "'*'",
              color: "#e32",
              display: "inline",
            },
          },
        },
      },
    },
  },
}));

interface Props {
  node: INode;
}

const EditContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = node;

  const {
    session_context,
    updateNodeVariablesInContext,
    getNodeVariableOptions,
  } = useSessionContext();
  const options = getNodeVariableOptions(id);

  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<IBotPromptCarouselNodeData>();
  const { name, description, fields_map } = values;

  const handleUpdateSessionContext = (): void => {
    if (!values?.name) return;

    const { name, description, fields_map } = values;
    const variablesData = getCarouselSample(session_context, fields_map);

    const nodeMeta = {
      label: name,
      nodeId: id,
      name,
      type: node.type,
      group: node?.data?.group,
      description,
      counter: node?.data?.ui?.counter,
    };

    updateNodeVariablesInContext({
      context: "dialog_context",
      nodeMeta,
      variables: formatRecordToTypedObject(
        variablesData as Record<string, any>
      ),
    });
  };

  useEffect(() => {
    dispatch({
      type: WorkflowActions.UPDATE_WORKFLOW_NODE,
      payload: { id, config: values },
    });
    // Need to check performance for this call on each value change or where to put this
    // handleUpdateSessionContext();
  }, [values]);

  // Can we use this as setFieldValue trigger only once for change
  useEffect(() => {
    handleUpdateSessionContext();
  }, [fields_map]);

  // TO-DO: Need to find a way such that each field value when field update session context
  // !!!!! NEED TO FIND A BETTER FOR VALIDITY AND CONTEXT UPDATE ON FIELDS CHECKING
  //   useEffect(() => {
  //     if (isValid) {
  //       handleUpdateSessionContext();
  //     }
  //   }, [isValid]);
  // I THINK BLUR IS BEST FOR TEXT FIELD AND SETFIELDVALUE IN OTHER FIELDS

  return (
    <div className={classes["edit-container"]}>
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Node Name</Typography>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          placeholder=""
          fullWidth
          value={name}
          onChange={handleChange}
          onBlur={(event: any) => {
            handleBlur(event);
            handleUpdateSessionContext();
          }}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>

      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">
          Add Description for your reference (Optional)
        </Typography>
        <TextField
          name="description"
          variant="outlined"
          placeholder="Eg. This action reschedule the delivery date and time"
          multiline
          rows={4}
          fullWidth
          value={description}
          onChange={handleChange}
          onBlur={(event: any) => {
            handleBlur(event);
            handleUpdateSessionContext();
          }}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
        />
      </div>

      <FieldsMap
        node={node}
        sessionContext={session_context}
        options={options}
        handleUpdateSessionContext={handleUpdateSessionContext}
      />
    </div>
  );
};

export default EditContainer;
