import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useFormikContext } from "formik";
// import * as yup from "yup";
import TextField from "@mui/material/TextField";
// import { type IQuestion, type IBotPromptInputNodeData } from "types/Workflow";
// import TemplateTextarea from "components/base/TemplateTextAreaV2";
// import { useContext } from "react";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import { formatSessionContextToOptions } from "pages/Bot/WorkflowBuilder/utils";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
// import { extractEntities } from "api/workflow";
// import clsx from "clsx";
import { useState } from "react";
// import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
// import { getExtractedVariables } from "pages/Bot/WorkflowBuilder/utils/session-context";
import {
  // IBotQuestion,
  type IBotVariable,
  type IInputNode,
  // INode,
} from "pages/Bot/WorkflowBuilder/types";
// import { ENTITY_DATA_TYPE } from "pages/Bot/WorkflowBuilder/config";
// import { MenuItem, Tooltip } from "@mui/material";
// import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import { ReactComponent as StringTypeIcon } from "assets/icons/string-icon.svg";
import { ReactComponent as NumberTypeIcon } from "assets/icons/number-icon.svg";
import { ReactComponent as PhoneTypeIcon } from "assets/icons/phone-icon.svg";
import { ReactComponent as MailTypeIcon } from "assets/icons/mail-icon.svg";

import Popover from "@mui/material/Popover";
import Button from "components/base/Button";

const useStyles = makeStyles((theme) => ({
  "variable-editor-container": {
    "& .variable": {
      display: "flex",
      padding: "2px 5px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      borderRadius: "4px",
      border: "1px solid var(--colors-secondary-purple-600, #9062CB)",
      background: "var(--colors-secondary-purple-100, #EFE4FC)",
    },
  },

  "variable-popover-container": {
    display: "flex",
    width: "332px",
    padding: "16px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    gap: "16px",
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--bg-disabled, #F9F8F8)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",

    "& .variable-header": {
      "& .data-type-icon": {
        display: "flex",
        padding: "4px",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        border: "1px solid var(--border-default, #E1DEDA)",

        "& svg": {
          width: "16px",
          height: "16px",
        },
      },

      "& .variable-delete-button": {
        display: "flex",
        width: "24px",
        height: "24px",
        padding: "0px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid var(--colors-system-error-200, #F5AE9D)",
        background: "var(--dropdown-background-default, #FFF)",

        "& svg": {
          height: "14px",
          width: "14px",

          "& path": {
            fill: "#E53811",
          },
        },
      },
    },

    "& .variable-content": {},
  },
}));

const DataTypeMap: Record<string, any> = {
  String: StringTypeIcon,
  Number: NumberTypeIcon,
  Email: MailTypeIcon,
  Phone: PhoneTypeIcon,
};

interface Props {
  questionIndex: number;
  variableIndex: number;
  variable: IBotVariable;
  onRemoveVariable: (index: number) => void;
}

const VariablePopover: React.FC<Props & { handlePopoverClose: () => void }> = ({
  questionIndex,
  variableIndex,
  variable,
  onRemoveVariable,
  handlePopoverClose,
}) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<IInputNode>();

  const [variableState, setVariableState] = useState({
    label: variable?.label,
    type: variable?.type,
    description: variable?.description,
    addDescription: !!variable?.description,
  });

  const handleSaveVariable = async (): Promise<any> => {
    await setFieldValue(
      `questions[${questionIndex}].variables[${variableIndex}]`,
      variableState
    );
    handlePopoverClose();
  };

  const handleDeleteVariable = (): void => {
    onRemoveVariable(variableIndex);
    handlePopoverClose();
  };

  const DataTypeIcon = DataTypeMap[variableState?.type];

  return (
    <div className={classes["variable-popover-container"]}>
      <div className="variable-header flex justify-content-between align-items-center">
        <div className="flex align-items-center col-gap-8">
          <div className="data-type-icon">
            {DataTypeIcon && <DataTypeIcon />}
          </div>
          <Typography className="w-semi-bold subtle">
            {variableState?.type}
          </Typography>
        </div>

        <IconButton
          className="variable-delete-button"
          onClick={handleDeleteVariable}
        >
          <DeleteIcon />
        </IconButton>
      </div>

      <div className="variable-content flex flex-col row-gap-16">
        <div className="variable-name flex flex-col row-gap-6">
          <Typography className="w-semi-bold">Variable name</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={variableState?.label}
            onChange={(event) => {
              setVariableState((state) => ({
                ...state,
                label: event.target.value,
              }));
            }}
          />
        </div>

        <div className="variable-description">
          {variableState?.addDescription ? (
            <div className="variable-description-container flex flex-col row-gap-6">
              <Typography className="w-semi-bold">
                Variable description
              </Typography>
              <TextField
                name={`questions.${questionIndex}.variables.${variableIndex}.description`}
                variant="outlined"
                size="small"
                placeholder="Please add a description for your reference"
                multiline
                rows={3}
                fullWidth
                value={variableState?.description}
                onChange={(event) => {
                  setVariableState((state) => ({
                    ...state,
                    description: event.target.value,
                  }));
                }}
              />
            </div>
          ) : (
            <Link
              to={""}
              className="flex flex-row justify-content-start align-items-center no-decoration"
              onClick={() => {
                setVariableState((state) => ({
                  ...state,
                  addDescription: true,
                }));
              }}
            >
              <div className="flex align-items-center col-gap-4">
                <Typography sx={{ color: "#C9671D" }} className="w-bold">
                  Add description
                </Typography>
                <PlusIcon />
              </div>
            </Link>
          )}
        </div>
      </div>

      <div className="variable-footer">
        <Button
          color={"primary"}
          // eslint-disable-next-line
          onClick={handleSaveVariable}
          disabled={!variableState?.label}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const VariableEditor: React.FC<Props> = ({
  questionIndex,
  variableIndex,
  variable,
  onRemoveVariable,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes["variable-editor-container"]}>
      <div
        className="variable-container"
        onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      >
        <Typography className="variable" sx={{ color: "#734BA6" }}>
          {variable?.label}
        </Typography>
      </div>

      <Popover
        id={`variable-${variable.label}-popover`}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
      >
        <VariablePopover
          questionIndex={questionIndex}
          variableIndex={variableIndex}
          variable={variable}
          onRemoveVariable={onRemoveVariable}
          handlePopoverClose={handlePopoverClose}
        />
      </Popover>
    </div>
  );
};

export default VariableEditor;
