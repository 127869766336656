import { makeStyles } from "@mui/styles";
import { type Theme } from "@mui/material/styles";
import { type CustomColorProps } from ".";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },
  // Variants
  icon: (props: { color: string; customColors: CustomColorProps }) => ({
    "& svg": {
      "& path": {
        fill: {
          primary: "#F07400", // TODO: define on theme
          secondary: "#E7FDF3",
          tertiary: "#fff3cd",
          warning: "#f8d7da",
          error: "#E53811",
          custom: props?.customColors?.backgroundColor,
        }[props.color],
      },
      "&:hover": {
        "& path": {
          primary: "#FF7B00", // TODO: define on theme, that is strip of primary on scale light to dark
          secondary: "#E7FDF3",
          tertiary: "#fff3cd",
          warning: "#f8d7da",
          error: "#EF866F",
          custom: props?.customColors?.backgroundColor,
        }[props.color],
      },
    },
  }),
  contained: (props: { color: string; customColors: CustomColorProps }) => ({
    background: {
      primary: "#F07400", // TODO: define on theme
      secondary: "#E7FDF3",
      tertiary: "#fff3cd",
      warning: "#f8d7da",
      error: "#E53811",
      custom: props?.customColors?.backgroundColor,
    }[props.color],

    "&:hover": {
      background: {
        primary: "#FF7B00", // TODO: define on theme, that is strip of primary on scale light to dark
        secondary: "#E7FDF3",
        tertiary: "#fff3cd",
        warning: "#f8d7da",
        error: "#EF866F",
        custom: props?.customColors?.backgroundColor,
      }[props.color],
    },
  }),
  outlined: (props: { color: string; customColors: CustomColorProps }) => ({
    borderRadius: "8px",
    border: "1px solid #E1DEDA",
    background: "#FFF",

    "& svg": {
      "& path": {
        fill: {
          primary: "#FF7B00", // TODO: define on theme, that is strip of primary on scale light to dark
          secondary: "#35312D",
          tertiary: "#fff3cd",
          warning: "#f8d7da",
          error: "#E53811",
          custom: props?.customColors?.backgroundColor,
        }[props.color],
      },
    },

    "&:hover": {
      backgroundColor: "#F9F8F8",
    },
  }),

  // Size variants
  xsmall: {
    width: 32,
    height: 32,

    "& svg": {
      width: 16,
      height: 16,
    },
  },
  small: {
    width: 40,
    height: 40,

    "& svg": {
      width: 24,
      height: 24,
    },
  },
  medium: {
    width: 48,
    height: 48,
  },
  large: {
    width: 56,
    height: 56,
  },
}));

export default useStyles;
