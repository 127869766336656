/*
 * This example shows how you can use custom nodes and edges to dynamically add elements to your react flow graph.
 * A global layouting function calculates the new positions for the nodes every time the graph changes and animates existing nodes to their new position.
 *
 * There are three ways of adding nodes to the graph:
 *  1. Click an existing node: Create a new child node of the clicked node
 *  2. Click on the plus icon of an existing edge: Create a node in between the connected nodes of the edge
 *  3. Click a placeholder node: Turn the placeholder into a "real" node to prevent jumping of the layout
 *
 * The graph elements are added via hook calls in the custom nodes and edges. The layout is calculated every time the graph changes (see hooks/useLayout.ts).
 */
import {
  type DragEvent,
  useEffect,
  useRef,
  // useCallback,
  useState,
} from "react";

import ReactFlow, {
  type ProOptions,
  Panel,
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType,
  type Node,
  // ConnectionLineType,
  // useStore,
  ConnectionMode,
  // MiniMap,
  // useReactFlow,
} from "reactflow";

import nodeTypes from "../Node";
import edgeTypes from "../Edge";
import NodesTypePanel from "../NodeTypePanel";

import "reactflow/dist/style.css";
import { useDispatch, useSelector } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";
import NodeTypeSelect from "../NodeTypeSelect";
import useCreateNode from "pages/Bot/WorkflowBuilder/hooks/useCreateNode";
import {
  CreateNodeType,
  EdgeType,
  // NodeGroupType,
  // NodeGroupType,
} from "pages/Bot/WorkflowBuilder/config";
import { type INode } from "../../types";
import useLayout from "../../hooks/useLayout";
// import WorkflowEdge from "../Edge/WorkflowEdge";
import ConnectionEdge from "../Edge/ConnectionEdge";
import { checkConnectionValidity } from "../../utils/workflow";
// import { compareNodesChangeEvent } from "../../utils/workflow";
// import { cloneDeep } from "lodash";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

const onDragOver = (event: DragEvent): void => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

interface IConnectionState {
  connectionEvent: "CLICK" | "DRAG" | null;
  connectionStartPosition: { x: number | null; y: number | null };
  connectionPosition: { x: number | null; y: number | null };
  connectionLineStyle: { strokeWidth: number; stroke: string };
}

const ReactFlowCanvas: React.FC = () => {
  const dispatch = useDispatch();

  // const { project } = useReactFlow();
  const { workflow, connection } = useSelector((state) => state.bot.workflow);
  const {
    pointer,
    selectNode,
    nodes: workflowNodes,
    edges: workflowEdges,
  } = workflow;
  const { connectionInProgress, connectionNodeId } = connection;

  const selectedNode = selectNode.id
    ? workflowNodes?.find((node: INode) => node.id === selectNode.id)
    : null;

  const {
    createNodeFromPlaceholder,
    createNodeFromAddButton,
    createBranchHorizontalNode,
  } = useCreateNode();

  const [nodes, setNodes, onNodesChange] = useNodesState(workflowNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(workflowEdges);

  const [connectionState, setConnectionState] = useState<IConnectionState>({
    connectionEvent: null,
    connectionStartPosition: { x: null, y: null },
    connectionPosition: { x: null, y: null },
    connectionLineStyle: { strokeWidth: 2, stroke: "transparent" },
  });

  const prevNodesRef = useRef(workflowNodes);
  const prevEdgesRef = useRef(workflowEdges);

  // const connectionPosition = useStore((state) => state.connectionPosition);
  // const connectionStatus = useStore((state) => state.connectionStatus);
  // const connectionNodeId = useStore((state) => state.connectionNodeId);
  // const connectionHandleId = useStore((state) => state.connectionHandleId);

  useEffect(() => {
    if (connectionInProgress) {
      const startPositionX = connectionState.connectionPosition?.x;
      const startPositionY = connectionState.connectionPosition?.y;
      const positionX = connectionState.connectionStartPosition?.x;
      const positionY = connectionState.connectionStartPosition?.y;

      if (!startPositionX || !startPositionY || !positionX || !positionY) {
        return;
      }

      const distance = Math.sqrt(
        Math.pow(positionX - startPositionX, 2) +
          Math.pow(positionY - startPositionY, 2)
      );

      if (distance > 36) {
        setConnectionState((prevState) => ({
          ...prevState,
          connectionLineStyle: {
            strokeWidth: 1,
            strokeDasharray: "3 3",
            stroke: "#bbb",
            fill: "none",
          },
        }));
      } else {
        setConnectionState((prevState) => ({
          ...prevState,
          connectionLineStyle: {
            strokeWidth: 2,
            stroke: "transparent",
          },
        }));
      }
    }
  }, [connectionInProgress, connectionState.connectionPosition]);

  useEffect(() => {
    const prevNodes = prevNodesRef.current;
    const prevEdges = prevEdgesRef.current;

    // Compare nodes
    const nodesChanged =
      JSON.stringify(workflowNodes) !== JSON.stringify(prevNodes);

    // Compare edges
    const edgesChanged =
      JSON.stringify(workflowEdges) !== JSON.stringify(prevEdges);

    if (nodesChanged) {
      // console.log("Setting New Nodes");
      // console.log(prevNodes);
      // console.log(workflowNodes);

      // Trigger setNodes or any other action
      // console.log(workflowNodes);
      setNodes(workflowNodes);
      // Update refs
      prevNodesRef.current = workflowNodes;
    }

    if (edgesChanged) {
      setEdges(workflowEdges);
      prevEdgesRef.current = workflowEdges;
    }
  }, [workflowNodes, workflowEdges]);

  const handleCreateNode = (group: string, type: string): void => {
    const placeholderNode =
      selectedNode?.type === CreateNodeType.PLACEHOLDER_NODE ||
      selectedNode?.type === CreateNodeType.BRANCH_ADD_NODE
        ? selectedNode
        : null;

    if (!placeholderNode) {
      createNodeFromAddButton({
        pointer,
        id: selectedNode.id,
        type,
        group,
      });
      return;
    }

    if (placeholderNode.type === CreateNodeType.BRANCH_ADD_NODE) {
      createBranchHorizontalNode({
        pointer,
        node: placeholderNode,
        type,
        group,
        branchIndex: placeholderNode.data.branchIndex,
        alignment: placeholderNode.data?.alignment,
        isRootNode: placeholderNode.data?.isRootNode,
        rootNodeParentId: placeholderNode.data?.rootNodeParentId,
      });
      return;
    }

    createNodeFromPlaceholder({
      pointer,
      id: placeholderNode.id,
      group,
      type,
    });
  };

  // NODE EVENT HANDLER
  const handleOnNodesChange = (event: any): void => {
    // console.log(event);

    // const selectEvent =
    //   event?.length === 1 && event[0]?.type === "select" ? event : null;

    // console.log(selectEvent);

    // // if (selectEvent) {
    // //   dispatch({
    // //     type: WorkflowActions.SET_SELECT_NODE_ID,
    // //     payload: { nodeId: selectEvent.id },
    // //   });
    // // }

    onNodesChange(event);

    // !!! We are passing determinstic heights from node creation and updating in branch nodes dynamically
    // const updatedNodes = compareNodesChangeEvent(event, workflowNodes);

    // if (updatedNodes.length) {
    //   console.log("Event -", event);
    //   // console.log("Nodes Dimension Change - ", updatedNodes.length);
    //   // console.log(updatedNodes);

    //   dispatch({
    //     type: WorkflowActions.UPDATE_WORKFLOW_NODES,
    //     payload: { updatedNodes },
    //   });
    // }
  };

  const handleOnNodeDragStart = (
    event: React.MouseEvent,
    node: Node,
    nodes: Node[]
  ): void => {
    // console.log(`onNodeDragStart`);
  };

  const handleOnNodeDragStop = (
    event: React.MouseEvent,
    node: Node,
    nodes: Node[]
  ): void => {
    dispatch({
      type: WorkflowActions.UPDATE_NODE_POSITION,
      payload: { id: node.id, position: node.position },
    });
  };

  const handleNodeClick = (event: any, node: any): void => {
    // console.log("Node click react-flow-canvas");
    // dispatch({
    //   type: WorkflowActions.SET_SELECT_NODE,
    //   payload: { id: node.id, anchor: event.currentTarget },
    // });
    // if (node?.data?.group === NodeGroupType.CREATE_NODE) {
    //   return;
    // }
    // dispatch({
    //   type: WorkflowActions.SET_OPEN_DRAWER,
    //   payload: { openDrawer: true },
    // });
  };

  const handleOnNodeMouseEnter = (
    event: React.MouseEvent,
    node: Node
  ): void => {
    // console.log("Handle Mouse Enter");
    dispatch({
      type: WorkflowActions.SET_HOVER_NODE,
      payload: { id: node.id, anchor: event.currentTarget },
    });
  };

  const handleOnNodeMouseLeave = (
    event: React.MouseEvent,
    node: Node
  ): void => {
    dispatch({
      type: WorkflowActions.SET_HOVER_NODE,
      payload: { id: null, anchor: null },
    });
  };

  // EDGE EVENT HANDLER
  const handleOnEdgesChange = (event: any): void => {
    if (event[0].type === "remove") return;
    onEdgesChange(event);
  };

  // PAN EVENT HANDLER
  const handleOnPanClick = (event: React.MouseEvent): void => {
    // const { clientX, clientY } = event;
    // const canvasPosition = project({ x: clientX, y: clientY });
    // console.log(canvasPosition);
  };

  const handleOnPanMouseMove = (event: React.MouseEvent): void => {
    if (connectionInProgress) {
      setConnectionState((prevState) => ({
        ...prevState,
        connectionEvent: "DRAG",
        connectionPosition: { x: event.clientX, y: event.clientY },
      }));
    }
  };

  // CONNECTION EVENT HANDLER
  const onConnect = (params: any): void => {
    const isConnectionValid = checkConnectionValidity({
      connection: params,
      nodes: workflowNodes,
      edges: workflowEdges,
    });

    if (!isConnectionValid) {
      return;
    }

    const connectedEdge = {
      ...params,
      id: `${params.source}=>${params.target}`,
      type: EdgeType.WORKFLOW_EDGE,
    };

    dispatch({
      type: WorkflowActions.SET_WORKFLOW_EDGES,
      payload: {
        edges: [...workflowEdges, connectedEdge],
      },
    });
  };

  const onConnectStart = (event: any, params: any): void => {
    dispatch({
      type: WorkflowActions.SET_CONNECTION_STATE,
      payload: {
        connectionInProgress: true,
        connectionNodeId: params.nodeId,
        connectionHandleId: params.handleId,
      },
    });

    setConnectionState((prevState) => ({
      ...prevState,
      connectionEvent: "CLICK",
      connectionStartPosition: { x: event.clientX, y: event.clientY },
    }));
  };

  const onConnectEnd = (event: any): void => {
    if (connectionState.connectionEvent === "CLICK") {
      const anchor = document.getElementById(
        `handle-source-${connectionNodeId}-1-button`
      );
      dispatch({
        type: WorkflowActions.SET_SELECT_NODE,
        payload: { id: connectionNodeId, anchor },
      });
      dispatch({
        type: WorkflowActions.SET_CREATE_NODE_DROPDOWN,
        payload: { openCreateNodeDropdown: true },
      });
    }

    dispatch({
      type: WorkflowActions.SET_CONNECTION_STATE,
      payload: {
        connectionInProgress: false,
        connectionIsValid: false,
        connectionNodeId: null,
        connectionHandleId: null,
      },
    });

    setConnectionState((prevState) => ({
      connectionEvent: null,
      connectionStartPosition: { x: null, y: null },
      connectionPosition: { x: null, y: null },
      connectionLineStyle: { strokeWidth: 2, stroke: "transparent" },
    }));
  };

  useLayout();

  return (
    <>
      <ReactFlow
        proOptions={proOptions}
        defaultEdgeOptions={{
          type: "smoothstep",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 12,
            height: 12,
          },
        }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onEdgesChange={handleOnEdgesChange}
        onNodesChange={handleOnNodesChange}
        onNodeClick={handleNodeClick}
        onNodeDragStart={handleOnNodeDragStart}
        onNodeDragStop={handleOnNodeDragStop}
        onPaneClick={handleOnPanClick}
        onNodeMouseEnter={handleOnNodeMouseEnter}
        onNodeMouseLeave={handleOnNodeMouseLeave}
        connectionMode={ConnectionMode.Strict}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onConnect={onConnect}
        connectionLineComponent={ConnectionEdge}
        // connectionLineType={ConnectionLineType.SmoothStep}
        connectionLineStyle={connectionState.connectionLineStyle}
        fitView
        // defaultViewport={{ x: 50, y: 350, zoom: 1 }}
        fitViewOptions={{
          maxZoom: 1,
        }}
        onDragOver={onDragOver}
        nodeDragThreshold={2}
        panOnScroll
        onPaneMouseMove={handleOnPanMouseMove}
        disableKeyboardA11y={true}
        snapToGrid
      >
        <Controls />
        <Panel position="top-left" className="nodes-type-panel">
          <NodesTypePanel />
        </Panel>
        {/* <MiniMap pannable zoomable /> */}
      </ReactFlow>

      <NodeTypeSelect onCreateNode={handleCreateNode} />
    </>
  );
};

export default ReactFlowCanvas;
