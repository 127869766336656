import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { useFormikContext, FieldArray } from "formik";
// import * as yup from "yup";
import TextField from "@mui/material/TextField";
import {
  type ICarousel,
  type IBotPromptCarouselNodeData,
  type IFieldMap,
} from "types/Workflow";
import {
  // formatSessionContextToOptions,
  getCarouselSample,
} from "pages/Bot/WorkflowBuilder/utils/session-context";
import { useCallback, useEffect, useState } from "react";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
import { FieldType } from "pages/Bot/WorkflowBuilder/config";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import CarouselItem from "components/shared/CarouselItem";
import { Link } from "react-router-dom";
import TemplateTextField from "components/base/TemplateTextFieldV2";
import TemplateTextarea from "components/base/TemplateTextAreaV2";
import TemplateSelect from "components/base/TemplateSelectV2";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useDispatch } from "store";
// import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
import { type INode } from "pages/Bot/WorkflowBuilder/types";

const useStyles = makeStyles((theme) => ({
  "carousel-container": {
    "& .carousel-sample": {
      display: "flex",
      width: "432px",
      padding: "23px 16px",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      borderRadius: "8px",
      border: "1px solid var(--border-default, #E1DEDA)",
      background: "var(--bg-disabled, #F9F8F8)",
      "& .carousel-card": {
        position: "relative",
        display: "flex",
        width: "236px",
        height: "306px",
        paddingBottom: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "16px",
        border: "1px solid var(--border-default, #E1DEDA)",
        background: "#fff",
        "& .tag": {
          display: "flex",
          padding: "2px 8px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          position: "absolute",
          left: "12px",
          top: "12px",
          borderRadius: "4px",
          background: "#FFF",
          boxShadow: "4px 4px 5px 0px rgba(0, 0, 0, 0.15)",
        },
        "& .carousel-header": {
          width: "236px",
          height: "117px",
          borderRadius:
            "16px 16px var(--spacing-spacing-none, 0px) var(--spacing-spacing-none, 0px)",
          background: "var(--colors-shades-stone-400, #E7E5E1)",
          "& .carousel-image": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          },
        },
        "& .carousel-action": {
          height: "72px",
          width: "100%",
          "& .card-action-btn": {
            height: "50%",
            borderTop: "1px solid #E7E5E1",
            "& a": {
              textDecoration: "none",
              color: "#E06F06",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "24px",
            },
          },
        },
      },
    },
    "& .carousel-data-container": {
      "& .carousel-input-container": {
        "& .input-parameter": {
          width: "180px",
          "&.required": {
            "&::after": {
              content: "'*'",
              color: "#e32",
              display: "inline",
            },
          },
        },
      },
    },
  },
}));

interface Props {
  node: INode;
  sessionContext: any;
  options: any;
  handleUpdateSessionContext: () => void;
}

const FieldsMap: React.FC<Props> = ({
  node,
  sessionContext,
  options,
  handleUpdateSessionContext,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = node;

  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IBotPromptCarouselNodeData>();
  const { fields_map } = values;

  const [carousel, setCarousel] = useState<ICarousel>(
    getCarouselSample(sessionContext, fields_map)
  );

  useEffect(() => {
    if (sessionContext && fields_map) {
      setCarousel(getCarouselSample(sessionContext, fields_map));
    }
  }, [sessionContext, fields_map]);

  const onRemoveButton = useCallback((): void => {
    dispatch({
      type: WorkflowActions.DELETE_CAROUSEL_BUTTON,
      payload: { nodeId: id, deleteMap: "button_2" },
    });
  }, []);

  const onAddButton = useCallback((button2: IFieldMap) => {
    dispatch({
      type: WorkflowActions.ADD_CAROUSEL_BUTTON,
      payload: { nodeId: id, addMap: "button_2" },
    });
  }, []);

  return (
    <div className={classes["carousel-container"]}>
      <div className="flex flex-col">
        <Typography className="w-semi-bold">Configure</Typography>
        <Typography variant="textSm" sx={{ color: "#7C7972" }}>
          Map and define data you would like to show in your carousal as per
          example below
        </Typography>
      </div>

      <CarouselItem item={carousel} />

      <div className="carousel-data-container my-24">
        <div className="flex flex-col row-gap-12 mb-24">
          <Typography>Parameters defined and used in this flow</Typography>
        </div>

        <FieldArray name="fields_map">
          {({ insert, remove, push }) => (
            <div className="carousel-input-container flex flex-col row-gap-24">
              {values.fields_map.length > 0 &&
                values.fields_map.map((field, index): React.ReactNode => {
                  if (field.field_type === FieldType.TEXT) {
                    return (
                      <div
                        key={index}
                        className="carousel-input-row flex align-items-center col-gap-8"
                      >
                        <Typography
                          className={clsx("w-semi-bold input-parameter", {
                            required: field?.required,
                          })}
                        >
                          {field.label}
                        </Typography>

                        <div className="flex w-100">
                          <TextField
                            name={`fields_map.${index}.value`}
                            className={"w-100 flex flex-col align-item-stretch"}
                            variant="outlined"
                            size="small"
                            placeholder={field.value}
                            value={field.value}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // error={touched.fields_map?.[index]?.value && Boolean(errors.fields_map?.[index]?.value)}
                            // helperText={touched.fields_map?.[index]?.value && errors.fields_map?.[index]?.value}
                          />
                          {field.key !== "button_1" && (
                            <IconButton
                              onClick={() => {
                                remove(index);
                                onRemoveButton();
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    );
                  }

                  if (field.field_type === FieldType.TEMPLATE_SELECT) {
                    return (
                      <div
                        key={index}
                        className="carousel-input-row flex align-items-center col-gap-8"
                      >
                        <Typography
                          className={clsx("w-semi-bold input-parameter", {
                            required: field?.required,
                          })}
                        >
                          {field.label}
                        </Typography>

                        <div className="flex w-100">
                          <TemplateSelect
                            name={`fields_map.${index}.value`}
                            className={"w-100 flex flex-col align-item-stretch"}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            value={field.value}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur} // Need to check this blur event along with setFieldValue such that no race conditions on these
                            // eslint-disable-next-line
                            setFieldValue={setFieldValue}
                            options={options}
                          />
                        </div>
                      </div>
                    );
                  }

                  if (field.field_type === FieldType.TEMPLATE_TEXTFIELD) {
                    return (
                      <div
                        key={index}
                        className="carousel-input-row flex align-items-center col-gap-8"
                      >
                        <Typography
                          className={clsx("w-semi-bold input-parameter", {
                            required: field?.required,
                          })}
                        >
                          {field.label}
                        </Typography>

                        <div className="flex w-100">
                          <TemplateTextField
                            name={`fields_map.${index}.value`}
                            className={"w-100 flex flex-col align-item-stretch"}
                            variant="outlined"
                            size="small"
                            placeholder="Flight {{flight_name}}"
                            value={field.value}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // eslint-disable-next-line
                            setFieldValue={setFieldValue}
                            options={options}
                          />
                        </div>
                      </div>
                    );
                  }

                  if (field.field_type === FieldType.TEMPLATE_TEXTAREA) {
                    return (
                      <div
                        key={index}
                        className="carousel-input-row flex align-items-center col-gap-8"
                      >
                        <Typography
                          className={clsx("w-semi-bold input-parameter", {
                            required: field?.required,
                          })}
                        >
                          {field.label}
                        </Typography>

                        <div className="flex w-100">
                          <TemplateTextarea
                            name={`fields_map.${index}.value`}
                            className={"w-100 flex flex-col align-item-stretch"}
                            variant="outlined"
                            size="small"
                            placeholder="Flight {{flight_name}}"
                            value={field.value}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // eslint-disable-next-line
                            setFieldValue={setFieldValue}
                            options={options}
                          />
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}

              {!values.fields_map?.[7] && (
                <Link
                  to={""}
                  className="flex flex-row align-items-center no-decoration"
                  onClick={() => {
                    const button2 = {
                      key: "button_2",
                      label: "Button 2",
                      value: "Button 2",
                      description: "",
                      field_type: FieldType.TEXT,
                      required: false,
                    };
                    push(button2);
                    onAddButton(button2);
                  }}
                >
                  <div className="flex align-items-center col-gap-4">
                    <Typography variant="textSm" sx={{ color: "#C9671D" }}>
                      Add Button
                    </Typography>
                  </div>
                </Link>
              )}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default FieldsMap;
