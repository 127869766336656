import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "components/base/Typography";
import useStyles from "./style";
import TableBodyContainer from "./TableBodyContainer";

export interface TableColumnProps<T> {
  id: keyof T;
  label: string;
  width?: string;
  minWidth?: string;
  align?: "left" | "right" | "center";
  format?: (value: number) => string;
  render?: (props: any) => React.ReactNode;
}

export interface AddItemButtonProps {
  label: string;
  onClickItem: () => void;
}

export interface TableProps<RowData> {
  columns: Array<TableColumnProps<RowData>>;
  data: RowData[];
  loading?: boolean;
  empty?: boolean;
  emptyMessage?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  rowClassNames?: string;
  addItemButton?: AddItemButtonProps;
}

// FACTORY GENERATOR COMPONENT
const Table = <RowData,>(): React.FC<TableProps<RowData>> => {
  const TableComponent: React.FC<TableProps<RowData>> = ({
    data,
    columns,
    loading = false,
    empty = false,
    emptyMessage = "",
    error = false,
    errorMessage = "",
    className = "",
    rowClassNames = "",
    addItemButton,
  }) => {
    const classes = useStyles();

    const TableBodyComponent = TableBodyContainer<RowData>();

    return (
      <div className={classes.tableWrapper}>
        <MuiTableContainer className={classes.tableContainer}>
          <MuiTable>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ width: column.width }}
                  >
                    <Typography className="w-bold">{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBodyComponent
              data={data}
              columns={columns}
              loading={loading}
              empty={empty}
              emptyMessage={emptyMessage}
              error={error}
              errorMessage={errorMessage}
              rowClassNames={rowClassNames}
              addItemButton={addItemButton}
            />
          </MuiTable>
        </MuiTableContainer>
      </div>
    );
  };

  TableComponent.displayName = "Table";

  return TableComponent;
};

export default Table;
