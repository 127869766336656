import Typography from "components/base/Typography";
import { useTestAssistantStyle as useStyle } from "./style";
import { InputAdornment, TextField } from "@mui/material";
import Button from "components/base/Button";
import { type IScript } from "./types";
import { useFormikContext } from "formik";
import CountryFlagIcon from "components/base/CountryFlagIcon";
import { formatAssociatedPhoneNumber } from "pages/Bot/utils";

const test_assistant_title = "Test your assistant";
const test_assistant_subtitle =
  "Simulate real customer interactions to ensure your assistant meets performance expectations. Please dial the no. below";
const view_transcript_title = "Last call";
const view_transcript_button = "View Transcript";

const TestAssistant: React.FC = () => {
  const classes = useStyle();

  const { values } = useFormikContext<IScript>();
  const { associated_phone_number } = values;
  const { countryCode, localNumber } = formatAssociatedPhoneNumber(
    associated_phone_number
  );
  const displayPhoneNumber = associated_phone_number
    ? `${countryCode} ${localNumber}`
    : "";

  return (
    <div className={classes.testAssistantContainer}>
      <div className="flex-col">
        <Typography className="w-bold">{test_assistant_title}</Typography>
        <Typography variant="textSm" className="w-medium">
          {test_assistant_subtitle}
        </Typography>
      </div>

      <TextField
        size="small"
        autoComplete="off"
        fullWidth
        value={displayPhoneNumber}
        disabled
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CountryFlagIcon countryCode={countryCode} />
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.viewTranscriptContainer}>
        <div className="flex-col">
          <Typography>{view_transcript_title}</Typography>
          <Typography>2min 40sec, Today </Typography>
        </div>

        <Button size="x-small" color={"secondary"}>
          {view_transcript_button}
        </Button>
      </div>
    </div>
  );
};

export default TestAssistant;
