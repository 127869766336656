import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ReduxActionActions } from "store/reduxActions/ReduxActionActions";
import HeadedDialog from "components/shared/HeadedDialog";
import Typography from "components/base/Typography";
import Button from "components/base/Button";
import CommonAskConfirmationButton from "components/shared/CommonAskConfirmationButton";
import { deleteAction } from "api/actions";

const useStyles = makeStyles({
  input: {
    fontSize: "16px",
  },
  notchedOutline: {
    borderRadius: "8px !important",
  },
  deleteButton: {
    background: "#E53811",
    "&:hover": {
      background: "#E53811",
    },
  },
});

interface DeleteActionModalProps {
  onClickClose: () => void;
  actionId: string;
}

const DeleteActionModal: React.FC<DeleteActionModalProps> = ({
  onClickClose,
  actionId,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const dispatch = useDispatch();

  const [isDeleteCallUnderProcess, setIsDeleteCallUnderProcess] =
    useState(false);

  const handleClose = useCallback(() => {
    onClickClose?.();
  }, [onClickClose]);

  const handleClickDelete = useCallback(() => {
    setIsDeleteCallUnderProcess(true);

    deleteAction(actionId, token)
      .then(() => {
        dispatch({
          type: ReduxActionActions.DELETE_ACTION_SUCCESS,
          payload: { id: actionId },
        });
        enqueueSnackbar("Action deleted", {
          variant: "success",
        });
        onClickClose?.();
      })
      .catch(() => {
        enqueueSnackbar("Some error occurred. Please try again", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsDeleteCallUnderProcess(false);
      });
  }, [actionId, dispatch, enqueueSnackbar, onClickClose, token]);

  return (
    <HeadedDialog
      open
      onClose={handleClose}
      header={
        <Typography variant="textXl" className="w-semi-bold">
          Delete action
        </Typography>
      }
      width="474px"
      height="184px"
    >
      <Box pt={3}>
        <Box>
          <Typography variant="textSm">
            Are you sure you want to delete Phi bot. This action will delete
            this bot permanently
          </Typography>
        </Box>
        <Box display="flex" pt={3}>
          <Button color="secondary" size="large" onClick={handleClose}>
            Don&apos;t Delete
          </Button>
          <Box ml={3}>
            <CommonAskConfirmationButton isLoading={isDeleteCallUnderProcess}>
              <Button
                color="primary"
                size="large"
                onClick={handleClickDelete}
                className={classes.deleteButton}
              >
                Delete
              </Button>
            </CommonAskConfirmationButton>
          </Box>
        </Box>
      </Box>
    </HeadedDialog>
  );
};

export default DeleteActionModal;
