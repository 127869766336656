import { useNavigate } from "react-router-dom";

const useRouteNavigator = (): any => {
  const navigate = useNavigate();

  const gotoChatbotDashboard = (botId: string): void => {
    navigate(`/bot/chat/${botId}`);
  };

  const gotoVoicebotDashboard = (botId: string): void => {
    navigate(`/bot/voice/${botId}`);
  };

  const gotoBotDashboard = (): void => {
    navigate(`/bot/`);
  };

  const gotoAgentBuilder = (assistantId: string, agentId: string): void => {
    navigate(`/assistant/${assistantId}/agent/${agentId}`);
  };

  const gotoActionBuilder = (botId: string, agentId: string): void => {
    navigate(`/bot/${botId}/action-builder/${agentId}`);
  };

  const gotoSiblingPath = (path: string): void => {
    navigate(`./${path}`);
  };

  const gotoAbsolutePath = (path: string): void => {
    navigate(path);
  };

  const gotoAssistant = (assistantId: string): void => {
    navigate(`/assistant/${assistantId}`);
  };

  return {
    gotoChatbotDashboard,
    gotoVoicebotDashboard,
    gotoBotDashboard,
    gotoAgentBuilder,
    gotoActionBuilder,
    gotoSiblingPath,
    gotoAbsolutePath,
    gotoAssistant,
  };
};

export default useRouteNavigator;
