import { Box } from "@mui/material";
import Typography from "components/base/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { SettingsActions } from "store/reduxActions/botActions";
import { getBotSettings } from "api/bot/bot-settings";
import ApiError from "components/shared/APIError";
import RenderBotSettings from "./RenderBotSettings";
import type BotSettings from "types/BotSettings";
import UpdateSettingButton from "./UpdateSettingsButton";
import { type PostBotSettings } from "types/BotSettings";

const BotSettingsComponent: React.FC = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.app_user.token);

  const { data: botSettings, isError: showError } = useSelector(
    (state: any) => state.botSettings.bot_settings
  );

  const formValuesRef = useRef<PostBotSettings>();

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const fetchBotSettings = useCallback(async () => {
    getBotSettings(token)
      .then((response) => {
        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_ERROR,
        });
      });
  }, [dispatch, token]);

  const onRetry = useCallback(async () => {
    await fetchBotSettings();
  }, [fetchBotSettings]);

  return (
    <>
      <Box my={3} mx={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box display="flex" mb={1}>
              <Box mr={1} display="flex" alignItems="center">
                <SettingsIcon />
              </Box>
              <Typography variant="h6" className="w-bold" component="h6">
                Settings
              </Typography>
            </Box>
            <div className="fs-16 fw-500">
              Link your resources to enable and train your bot
            </div>
          </Box>
          <UpdateSettingButton
            formValuesRef={formValuesRef}
            disableSubmitButton={disableSubmitButton}
          />
        </Box>
        {showError && (
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ApiError
              message="Something went wrong. Click on retry to refetch actions"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetry={onRetry}
            />
          </Box>
        )}
        {botSettings && (
          <RenderBotSettings
            botSettings={botSettings as BotSettings}
            formValuesRef={formValuesRef}
            setDisableSubmitButton={setDisableSubmitButton}
          />
        )}
      </Box>
    </>
  );
};

export default BotSettingsComponent;
