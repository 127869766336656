import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
// import { ReactComponent as TriggerNodeIcon } from "assets/trigger-node-icon.svg";
import Typography from "components/base/Typography";
// import { ReactComponent as CloseIcon } from "assets/close-icon.svg";
import { Formik, Form, useFormikContext } from "formik";
import * as yup from "yup";
import { MenuItem, TextField as MuiTextField } from "@mui/material";
import { useDispatch } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";
import EditNodeHeader from "../components/EditNodeHeader";
import { type INode } from "pages/Bot/WorkflowBuilder/types";

const useStyles = makeStyles((theme) => ({
  "edit-trigger-node": {
    "& .edit-container": {
      padding: "108px 24px 32px",
      display: "flex",
      flexDirection: "column",
      rowGap: "24px",
    },
  },
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 16 characters allowed")
    .required("This is a required field"),
  trigger: yup.string().required("This is a required field"),
  dialog_prompt: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .required("This is a required field"),
});

interface IEditTriggerNode {
  node: any;
  onClose: () => void;
}

interface Props {
  node: INode;
}

const FormContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = node;

  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<any>();

  useEffect(() => {
    dispatch({
      type: WorkflowActions.UPDATE_WORKFLOW_NODE,
      payload: { id, config: values },
    });
  }, [values]);

  return (
    <Form className={classes["edit-trigger-node"]}>
      <EditNodeHeader node={node} enableDelete={false} />

      <div className="edit-container">
        <div className="form-input-container flex flex-col row-gap-6">
          <Typography className="w-semi-bold">Node Name</Typography>
          <MuiTextField
            name="name"
            variant="outlined"
            size="small"
            placeholder="Eg. Trigger node"
            fullWidth
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            // helperText={touched.name && errors.name}
          />
        </div>

        <div className="form-input-container flex flex-col row-gap-6">
          <Typography className="w-semi-bold">
            When this workflow should be triggered
          </Typography>
          <MuiTextField
            name="trigger"
            variant="outlined"
            size="small"
            placeholder="Select"
            fullWidth
            value={1}
            onChange={handleChange}
            onBlur={handleBlur}
            select
            error={touched.trigger && Boolean(errors.trigger)}
            // helperText={touched.trigger && errors.trigger}
          >
            <MenuItem key={1} value={1}>
              Intent is identified
            </MenuItem>
          </MuiTextField>
        </div>

        <div className="form-input-container flex flex-col row-gap-6">
          <Typography className="w-semi-bold">
            Write what customer should say
          </Typography>
          <MuiTextField
            name="dialog_prompt"
            variant="outlined"
            placeholder="Eg. What user should say"
            multiline
            rows={4}
            fullWidth
            value={values.dialog_prompt}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.dialog_prompt && Boolean(errors.dialog_prompt)}
            // helperText={touched.dialog_prompt && errors.dialog_prompt}
          />
        </div>
      </div>
    </Form>
  );
};

const EditTriggerNode: React.FC<IEditTriggerNode> = ({ node, onClose }) => {
  const { data } = node;

  const initialValues = {
    name: data?.name || "",
    trigger: data?.trigger || "",
    dialog_prompt: data?.dialog_prompt || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {}}
      validationSchema={validationSchema}
    >
      <FormContainer node={node} />
    </Formik>
  );
};

export default EditTriggerNode;
