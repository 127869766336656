import { useCallback, useState } from "react";
import CenteredLoader from "components/shared/CenteredLoader";
import { Box, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import LoopIcon from "@mui/icons-material/Loop";
import sleep from "sleep-promise";

interface ToggleSourceProps {
  id: string;
  name: string;
}

const SourceRefresh: React.FC<ToggleSourceProps> = ({ id, name }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showLoader, setShowLoader] = useState(false);

  const refreshSource = useCallback(async () => {
    await sleep(1000);

    setShowLoader(false);

    enqueueSnackbar(`Source ${name} refresh triggered`, {
      variant: "success",
    });
  }, [enqueueSnackbar, name]);

  const handleClickRefreshIcon = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      setShowLoader(true);

      await refreshSource();
    },
    [refreshSource]
  );

  return (
    <>
      {showLoader ? (
        <Box display="flex" justifyContent="start">
          <CenteredLoader />
        </Box>
      ) : (
        <IconButton
          color="inherit"
          size="small"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleClickRefreshIcon}
        >
          <LoopIcon />
        </IconButton>
      )}
    </>
  );
};

export default SourceRefresh;
