import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { type IAgentComposition } from "./types";
import { useCallback, useContext, useRef } from "react";
import AgentBuilderContext from "../AgentBuilderContext";
import { patchAgent } from "api/bot/bot-agents";
import { useSnackbar } from "notistack";

const instructions_placeholder =
  "E.g. Assist customers with tasks like flight bookings, cancellations, and providing real-time flight status updates, ensuring a smooth and efficient customer experience. ";
const Instructions: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { values, handleBlur, handleChange } =
    useFormikContext<IAgentComposition>();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId } = agentBuilderContext;

  const currentInstructions = useRef<string>(values.general_prompt);

  const handleOnBlur = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);

      if (!values.trigger || values.trigger === currentInstructions.current) {
        return;
      }

      try {
        await patchAgent({
          _id: agentId,
          general_prompt: values.general_prompt,
        });

        currentInstructions.current = values.trigger;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      }
    },
    [values.general_prompt]
  );

  return (
    <div className="flex flex-col">
      <TextField
        name="general_prompt"
        variant="outlined"
        size="small"
        placeholder={instructions_placeholder}
        fullWidth
        value={values.general_prompt}
        onChange={handleChange}
        onBlur={(event) => {
          void handleOnBlur(event);
        }}
        minRows={18}
        multiline
      />
    </div>
  );
};

export default Instructions;
