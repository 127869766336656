import { IconButton } from "@mui/material";
import useStyles from "./style";
import { ReactComponent as ArrowLeft } from "assets/arrow-left.svg";
import Typography from "components/base/Typography";
import useRouteNavigator from "hooks/useRouteNavigator";
import { type ITabSwitchOption } from "components/base/TabSwitch";

interface Props {
  title: string;
  description?: string;
  backPath?: string | null;
  navigations?: ITabSwitchOption[];
  children?: React.ReactNode;
}

const BotHeader: React.FC<Props> = ({
  title,
  description = "",
  backPath = null,
  navigations,
  children,
}) => {
  const classes = useStyles();
  const { gotoBotDashboard, gotoAbsolutePath } = useRouteNavigator();

  // const [selectedTab, setSelectedTab] = useState(navigations?.[0].key);

  const handleBackClick = (): void => {
    if (backPath) {
      gotoAbsolutePath(backPath);
      return;
    }

    gotoBotDashboard();
  };

  // const handleOnChangeTab = (key: string): void => {
  //   setSelectedTab(key);
  //   gotoSiblingPath(key);
  // };

  return (
    <div className={classes.botHeaderContainer}>
      <div className={classes.botDetailsContainer}>
        <IconButton className="back-button" onClick={handleBackClick}>
          <ArrowLeft />
        </IconButton>

        <div className="bot-details-container flex flex-col row-gap-4">
          <Typography variant="textXl" className="w-bold">
            {title}
          </Typography>
          <Typography className="w-medium">{description}</Typography>
        </div>
      </div>

      {/* <div className={classes.botTabNavigation}>
        {navigations && selectedTab && (
          <TabSwitch
            value={selectedTab}
            onChange={(key: string) => {
              handleOnChangeTab(key);
            }}
            options={navigations}
          />
        )}
      </div> */}

      <div className={classes.botActionsContainer}>{children}</div>
    </div>
  );
};

export default BotHeader;
