import { Box, Grid, Radio, FormControlLabel } from "@mui/material";
import Typography from "components/base/Typography";
import { Field } from "formik";
import { BotSettingsFields } from "../constants";
import { RadioGroup } from "formik-mui";
import { BotResponseLength, RadioValue } from "types/BotSettings";
import GreyBackgroundBox from "components/shared/GreyBackgroundBox";
import CollapsibleCard from "components/shared/CollapsibleCard";
import { ReactComponent as NatureOfResponseIcon } from "./imgs/natureOfResponseIcon.svg";

const RenderNatureOfResponseSettings: React.FC = () => {
  return (
    <CollapsibleCard
      header={
        <>
          <Typography variant="textLg" className="w-bold">
            Nature of response
          </Typography>
          <Box color="#7C7972">
            <Typography variant="textSm" className="w-medium" color="inherit">
              Response length, Tone, Feedback on bot response, Source of
              information
            </Typography>
          </Box>
        </>
      }
      headerIcon={<NatureOfResponseIcon />}
      renderContent={
        <GreyBackgroundBox className="p-24">
          <div>
            <Grid container width="100%" justifyContent="space-between">
              <Grid item xs={5}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      Response length
                    </Typography>
                  </Box>
                  <Field
                    component={RadioGroup}
                    name={BotSettingsFields.bot_response_length}
                  >
                    <FormControlLabel
                      value={BotResponseLength.CONCISE}
                      control={<Radio />}
                      label="Concise"
                    />
                    <FormControlLabel
                      value={BotResponseLength.MEDIUM}
                      control={<Radio />}
                      label="Medium (Till 500 characters)"
                    />
                    <FormControlLabel
                      value={BotResponseLength.VERBOSE}
                      control={<Radio />}
                      label="Verbose"
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      Show source of info with a response
                    </Typography>
                  </Box>
                  <Field
                    component={RadioGroup}
                    name={BotSettingsFields.show_source_of_info}
                  >
                    <FormControlLabel
                      value={RadioValue.YES}
                      control={<Radio />}
                      label="Show Source"
                    />
                    <FormControlLabel
                      value={RadioValue.NO}
                      control={<Radio />}
                      label="Hide Source"
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box mx={0.5} mb={4}>
                  <Box pb={0.5}>
                    <Typography variant="textMd" className="w-semi-bold">
                      Request feedback on bot generated responses
                    </Typography>
                  </Box>
                  <Field
                    component={RadioGroup}
                    name={BotSettingsFields.request_feedback}
                  >
                    <FormControlLabel
                      value={RadioValue.YES}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={RadioValue.NO}
                      control={<Radio />}
                      label="Not Required"
                    />
                  </Field>
                </Box>
              </Grid>
            </Grid>
          </div>
        </GreyBackgroundBox>
      }
    />
  );
};

export default RenderNatureOfResponseSettings;
