import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "store";
import { Handle, type Node, Position, useUpdateNodeInternals } from "reactflow";
import Typography from "components/base/Typography";
import { LogicNodeConfig } from "pages/Bot/WorkflowBuilder/config";
import { Link } from "react-router-dom";
import { ReactComponent as NodeHandleIcon } from "assets/node-handle.svg";
import {
  type NodeRenderProps,
  type IBranch,
  type INodeProps,
} from "pages/Bot/WorkflowBuilder/types";
import { useCallback, useEffect } from "react";
import {
  moveObjectToEndOfArray,
  updateNodeById,
} from "pages/Bot/WorkflowBuilder/utils/workflow";
import { WorkflowActions } from "store/reduxActions/botActions";
import { createAddNodeAndEdge } from "./utils";
import clsx from "clsx";
import NodeContainer from "../NodeContainer";

const useStyles = makeStyles((theme) => ({
  "branch-node-wrapper": {
    width: "260px",
    minHeight: "150px",

    "& .node-container": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "stretch",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

      "& .node-header": {
        padding: "12px 16px",

        "& .node-type-icon": {
          display: "flex",
          padding: "8px",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "8px",
          background: "#D8C24F",
        },
      },

      "& .branches-container": {
        "& .branch-button": {
          height: "44px",
          position: "relative",
          borderTop: "1px solid #E7E5E1",
          padding: "10px 12px",

          "& a": {
            textDecoration: "none",
            color: "#615514",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
          },

          "& .handle-icon": {
            position: "absolute",
            right: "12px",

            "& circle": {
              stroke: "#B09E3B",
            },
          },
        },
      },
    },

    "&.node-selected": {
      "& .node-container": {
        border: "1px solid #B09E3B",
      },
    },
  },
}));

const NodeComponent: React.FC<INodeProps & NodeRenderProps> = ({
  id,
  type,
  data,
  onNodeClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updateNodeInternals = useUpdateNodeInternals();

  const { nodes, edges, selectNode, pointer, openCreateNodeDropdown } =
    useSelector((state) => state.bot.workflow.workflow);
  const currentNode = nodes.find((node: Node) => node.id === id);

  const { name, branches, ui, default: default_branch } = data;

  const BranchNodeIcon = LogicNodeConfig?.[type]?.ICON;

  const handleOnChangeBranches = useCallback(() => {
    let updatedNodes = [...nodes];
    let updatedEdges = [...edges];
    const updatedBranches = [...branches];

    // handle rootNodeId null coming from response IDs
    (updatedBranches || []).forEach((branch: IBranch, index: number) => {
      // console.log(cloneDeep(branch));
      if (!branch?.rootNodeId) {
        const { addNode, addNodeEdge } = createAddNodeAndEdge(
          id,
          currentNode.position,
          index,
          branch.id
        );
        updatedBranches[index].rootNodeId = addNode.id;

        let root = null;
        if (index > 0) {
          root = { height: 150 + index * 44 };
        }
        console.log("Updated Height -", root);

        updatedNodes = [
          ...updateNodeById({
            id,
            nodes: updatedNodes,
            config: {
              branches: updatedBranches,
            },
            root,
          }),
          addNode,
        ];
        // For Updating Default Node Position
        updatedNodes = moveObjectToEndOfArray({
          key: "id",
          value: default_branch.rootNodeId,
          arr: updatedNodes,
        });

        updatedEdges = [...updatedEdges, addNodeEdge];
        // For Updating Default Node Position
        updatedEdges = moveObjectToEndOfArray({
          key: "id",
          value: `${id}=>${default_branch.rootNodeId}`,
          arr: updatedEdges,
        });
      }
    });

    // Only Dispatch changes if there is change in nodes and edges
    if (
      JSON.stringify(nodes) !== JSON.stringify(updatedNodes) ||
      JSON.stringify(edges) !== JSON.stringify(updatedEdges)
    ) {
      dispatch({
        type: WorkflowActions.SET_WORKFLOW_NODES,
        payload: { pointer, nodes: updatedNodes },
      });

      dispatch({
        type: WorkflowActions.SET_WORKFLOW_EDGES,
        payload: { edges: updatedEdges },
      });
    }
  }, [nodes, branches, default_branch]);

  useEffect(() => {
    updateNodeInternals(id);
    handleOnChangeBranches();
  }, [branches, default_branch]);

  return (
    <div
      id={`node-${id}`}
      onClick={(event) => {
        onNodeClick(event);
      }}
      className={clsx(classes["branch-node-wrapper"], {
        "node-selected": selectNode.id === id && !openCreateNodeDropdown,
      })}
    >
      <div className="node-container">
        {/* Handle - Target Clockwise: For dynamic connection */}
        <Handle
          className="handle"
          type="target"
          position={Position.Top}
          id={`handle-target-${id}-0`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Right}
          id={`handle-target-${id}-1`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Bottom}
          id={`handle-target-${id}-2`}
        />
        <Handle
          className="handle"
          type="target"
          position={Position.Left}
          id={`handle-target-${id}-3`}
        />

        {/* Handle - Source Clockwise: For dynamic connection */}
        {branches.map((branch: any, index: number) => (
          <Handle
            key={index}
            className="handle"
            type="source"
            position={Position.Right}
            style={{ top: `${82 + index * 44}px`, right: "8px" }}
            id={`handle-source-${id}-${branch.id}`}
          />
        ))}

        <Handle
          className="handle"
          type="source"
          position={Position.Right}
          style={{ top: `${82 + branches?.length * 44}px`, right: "8px" }}
          id={`handle-source-${id}-default`}
        />

        <div className="w-100 node-header flex col-gap-12">
          <div className="node-type-icon">
            <BranchNodeIcon />
          </div>

          <div className="w-100 flex flex-col row-gap-2">
            <div className="flex justify-content-between align-items-center">
              <Typography
                variant="textTiny"
                className="w-semi-bold"
                sx={{ color: "#B5B1AD" }}
              >
                Branches
              </Typography>
              <Typography
                variant="textTiny"
                className="w-bold"
                sx={{ color: "#B5B1AD" }}
              >
                {String(ui?.counter).padStart(3, "0")}
              </Typography>
            </div>
            <Typography className="w-semi-bold" sx={{ color: "#000" }}>
              {name}
            </Typography>
          </div>
        </div>

        <div className="branches-container">
          {branches.map((branch: IBranch, index: number) => (
            <div key={index} className="branch-button center" id={branch.id}>
              <Link to="">{branch.name}</Link>
              <NodeHandleIcon className="handle-icon" />
            </div>
          ))}
          <div className="branch-button center" id={"default-branch"}>
            <Link to="">Default Branch</Link>
            <NodeHandleIcon className="handle-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

const BranchNode: React.FC<INodeProps> = (props) => {
  return (
    <NodeContainer
      id={props.id}
      type={props.type}
      renderNode={(nodeRenderProps: NodeRenderProps) => (
        <NodeComponent {...nodeRenderProps} {...props} />
      )}
    />
  );
};

export default BranchNode;
