import { Chip } from "@mui/material";
import { RenderStatus } from "types/Source";

const getStyles = (label: RenderStatus): Record<string, string> => {
  if (label === RenderStatus.IN_PROGRESS) {
    return {
      color: "#B54708",
      backgroundColor: "#FFFAEB",
    };
  } else if (label === RenderStatus.SUCCESS || label === RenderStatus.ACTIVE) {
    return {
      color: "#027A48",
      backgroundColor: "#ECFDF3",
    };
  } else if (label === RenderStatus.FAILED) {
    return {
      color: "#B42318",
      backgroundColor: "#FEF3F2",
    };
  }

  return {
    color: "transparent",
    backgroundColor: "transparent",
  };
};

const getStatusLabel = ({
  pending_sync,
  render_status,
}: {
  pending_sync?: boolean;
  render_status?: string;
}): RenderStatus | null => {
  if (pending_sync) {
    return RenderStatus.IN_PROGRESS;
  }
  if (render_status) {
    // const values = Object.values(RenderStatus);

    switch (render_status) {
      case "Success":
        return RenderStatus.SUCCESS;
      case "In Progress":
        return RenderStatus.IN_PROGRESS;
      case "Failed":
        return RenderStatus.FAILED;
    }
  }

  if (pending_sync === false) {
    return RenderStatus.ACTIVE;
  }

  return null;
};

interface Props {
  pending_sync?: boolean;
  render_status?: string;
}

const SourceStatus: React.FC<Props> = ({ pending_sync, render_status }) => {
  const label: RenderStatus | null = getStatusLabel({
    pending_sync,
    render_status,
  });

  if (!label) return null;

  return (
    <Chip label={label} style={getStyles(label)} className="fs-12 fw-500" />
  );
};

export default SourceStatus;
