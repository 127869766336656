import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import GlobalSpinner from "components/shared/GlobalSpinner";
import ApiError from "components/shared/APIError";
import { Box } from "@mui/material";
import type BotAction from "types/BotAction";
import {
  type GoogleSheetConnectorDetails,
  type PostBotAction,
} from "types/BotAction";
import StepAddOrEditAction from "../../../ActionSteps/StepAddOrEditAction";
import { getConnectorConfig } from "api/actions";
import { ConnectorType } from "types/ActionConnector";
import { type ConnectorConfigPayload } from "types/ConnectorConfigResponse";
import type AirtableConfig from "types/ConnectorConfig/AirtableConfig";
import type GoogleSheetConfig from "types/ConnectorConfig/GoogleSheetConfig";
import type SalesForceConfig from "types/ConnectorConfig/SalesForceConfig";

interface StepEditActionWrapperProps {
  isFormSubmitting: boolean;
  onClickDone: (values: PostBotAction) => void;
  editActionDetails: BotAction;
}

const StepEditActionWrapper: React.FC<StepEditActionWrapperProps> = ({
  isFormSubmitting,
  onClickDone,
  editActionDetails,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const [connectorConfig, setConnectorConfig] = useState<
    AirtableConfig | GoogleSheetConfig | SalesForceConfig
  >();

  const [showError, setShowError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const connectorPayload = useMemo(() => {
    const payload: ConnectorConfigPayload = {
      connector_type: editActionDetails.connector_type,
    };

    if (editActionDetails.connector_type === ConnectorType.GOOGLE_SHEETS) {
      const { spreadsheet_id: spreadSheetId } =
        editActionDetails.connector_config as GoogleSheetConnectorDetails;
      payload.spreadsheet_id = spreadSheetId;
    }

    return payload;
  }, [editActionDetails.connector_config, editActionDetails.connector_type]);

  const fetchConnectorConfig = useCallback(async () => {
    getConnectorConfig(connectorPayload, token)
      .then((response) => {
        setConnectorConfig(response);
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [connectorPayload, token]);

  const onRetry = useCallback(async () => {
    await fetchConnectorConfig();
  }, [fetchConnectorConfig]);

  useEffect(() => {
    setIsLoading(true);

    void fetchConnectorConfig();
  }, [fetchConnectorConfig]);

  if (isLoading) {
    return <GlobalSpinner />;
  }

  if (showError) {
    return (
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ApiError
          message="Something went wrong. Click on retry to refetch actions"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetry={onRetry}
        />
      </Box>
    );
  }

  return (
    <>
      {connectorConfig ? (
        <StepAddOrEditAction
          isFormSubmitting={isFormSubmitting}
          onClickDone={onClickDone}
          connectorConfig={connectorConfig}
          editActionDetails={editActionDetails}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default StepEditActionWrapper;
