import { useState } from "react";
import { makeStyles } from "@mui/styles";
import SuggestionCard from "../SuggestionCard";
import { getFAQsSuggestionData } from "api/faqs-suggestion";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { type ISuggestionCard } from "types/FAQSuggestions";
import { CircularProgress } from "@mui/material";
import { ReactComponent as BrokenDataLogo } from "assets/broken-data.svg";
import { ReactComponent as EmptyStateLogo } from "assets/empty-state.svg";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  "recommendations-container-loader": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",

    "& .page-broken-logo": {
      height: "100px",
      width: "100px",
    },

    "& .page-empty-logo": {
      height: "400px",
      width: "400px",
    },
  },
}));

const RecommendationList: React.FC = () => {
  const classes = useStyles();

  const [parentNode, setParentNode] = useState<any>();
  const [lastElement, setLastElement] = useState<any>();
  // const [filters, setFilters] = useState<any>({});
  const [globalSpinner, setGlobalSpinner] = useState(true);
  const [pageState, setPageState] = useState({ empty: false, error: false });

  const {
    loading: loadSpinner,
    pageNum,
    fetchedData: data,
    setFetchedData,
    nextUrl,
  } = useInfiniteScroll({
    parentNode,
    lastElement,
    apiConfig: {
      getData: getFAQsSuggestionData,
    },
    beforeStartFetchingData: () => {},
    onDataFetched: ({ count }) => {
      if (!count) {
        setPageState({ empty: true, error: false });
      }

      setGlobalSpinner(false);
    },
    onError: (err) => {
      console.error(err);
      setPageState({ empty: false, error: true });
      setGlobalSpinner(false);
      setFetchedData([]);
    },
    filters: {},
  });

  if (globalSpinner && pageNum === 1) {
    return (
      <div className={classes["recommendations-container-loader"]}>
        <CircularProgress />
      </div>
    );
  }

  if (pageState.empty) {
    return (
      <div className={classes["recommendations-container-loader"]}>
        <EmptyStateLogo className="page-empty-logo" />
        <Typography variant="textLg" className="w-semi-bold mt-8">
          No data
        </Typography>
      </div>
    );
  }

  if (pageState.error) {
    return (
      <div className={classes["recommendations-container-loader"]}>
        <BrokenDataLogo className="page-error-logo" />
        <Typography variant="textLg" className="w-semi-bold mt-8">
          Something went wrong
        </Typography>
      </div>
    );
  }

  return (
    <div
      className="recommendation-container-node flex flex-col row-gap-24"
      ref={(ref) => {
        setParentNode(ref);
      }}
    >
      {data?.map((row: ISuggestionCard, index: number) => {
        return (
          <div
            key={row.id}
            ref={index === data?.length - 1 && nextUrl ? setLastElement : null}
          >
            <SuggestionCard {...row} />
          </div>
        );
      })}
      {nextUrl && loadSpinner && (
        <div className="w-100 flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default RecommendationList;
