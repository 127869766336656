import { makeStyles } from "@mui/styles";
// import Typography from "components/base/Typography";
// import { ReactComponent as CloseIcon } from "assets/x-close.svg";
// import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { BotPromptNodeType } from "pages/Bot/WorkflowBuilder/config";
import CarouselNode from "./CarouselNode";
import InputNode from "./InputNode";
// import { useState } from "react";
// import { WorkflowActions } from "store/reduxActions/botActions";
// import { useDispatch } from "store";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import {
//   type IWorkflowContext,
//   type IWorkflowContextValue,
// } from "types/Workflow";
// import {
//   formatDataToVariables,
//   getCarouselSample,
//   getExtractedVariables,
// } from "pages/Bot/WorkflowBuilder/utils/session-context";
// import { IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  "edit-bot-prompt-node": {
    "& .node-header": {
      display: "flex",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
      boxShadow: "0px 1px 0px 0px #E1DEDA",

      "& .node-type-icon": {
        display: "flex",
        padding: "8px",
        alignItems: "flex-start",
        gap: "10px",
        borderRadius: "8px",
        background: "#AD79EF",
      },
    },

    "& .edit-node-content": {
      padding: "32px 24px",
    },
  },
}));

const BotPromptNodeComponentMap = {
  [BotPromptNodeType.TEXT]: InputNode,
  [BotPromptNodeType.CAROUSEL]: CarouselNode,
};

interface INodeDrawerProps {
  node: any;
  onClose: () => void;
}

const EditBotPromptNode: React.FC<INodeDrawerProps> = ({ node, onClose }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const { type } = node;

  const EditBotPromptNodeComponent = BotPromptNodeComponentMap?.[type];

  // const updatePreviewSession = (): void => {
  //   if (!botPromptNode?.name) {
  //     return;
  //   }

  //   const { name, description } = botPromptNode;
  //   let _sessionContext = {};

  //   if (type === BotPromptNodeType.CAROUSEL) {
  //     const { fields_map } = botPromptNode;
  //     const variablesData = getCarouselSample(session_context, fields_map);

  //     _sessionContext = {
  //       ...session_context,
  //       dialog_context: {
  //         ...session_context?.dialog_context,
  //         [node.id]: {
  //           meta: {
  //             label: name,
  //             nodeId: node.id,
  //             name,
  //             type: node.type,
  //             group: node.data.group,
  //             description,
  //             counter: node.data?.ui?.counter,
  //           },
  //           variables: formatDataToVariables(variablesData),
  //         },
  //       },
  //     };
  //   } else if (type === BotPromptNodeType.TEXT) {
  //     const { questions } = botPromptNode;
  //     const extractedVariables = getExtractedVariables(questions);

  //     _sessionContext = {
  //       ...session_context,
  //       dialog_context: {
  //         ...session_context?.dialog_context,
  //         [node.id]: {
  //           meta: {
  //             label: name,
  //             nodeId: node.id,
  //             name,
  //             type: node.type,
  //             group: node.data.group,
  //             description,
  //             counter: node.data?.ui?.counter,
  //           },
  //           variables: formatDataToVariables(extractedVariables),
  //         },
  //       },
  //     };
  //   }

  //   const _previewSession = {
  //     ...preview_session,
  //     session_context: _sessionContext,
  //   };

  //   setContextValue((prevContext: IWorkflowContextValue) => ({
  //     ...prevContext,
  //     preview_session: _previewSession,
  //   }));
  // };

  // const handleClickClose = (): void => {
  //   dispatch({
  //     type: WorkflowActions.UPDATE_WORKFLOW_NODE,
  //     payload: { id, config: botPromptNode },
  //   });

  //   updatePreviewSession();
  //   onClose();
  // };

  // const handleOnSave = (updatedNode: any): void => {
  //   setBotPromptNode(updatedNode);

  //   dispatch({
  //     type: WorkflowActions.UPDATE_WORKFLOW_NODE,
  //     payload: { id, config: updatedNode },
  //   });
  // };

  // const handleDeleteNode = (): void => {
  //   dispatch({
  //     type: WorkflowActions.DELETE_NODE,
  //     payload: { node },
  //   });
  // };

  return (
    <div className={classes["edit-bot-prompt-node"]}>
      {EditBotPromptNodeComponent && <EditBotPromptNodeComponent node={node} />}
    </div>
  );
};

export default EditBotPromptNode;
