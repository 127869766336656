import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { Handle, Position } from "reactflow";

const useStyles = makeStyles((theme) => ({
  "root-node-wrapper": {
    display: "inline-flex",
    padding: "12px 16px",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    background: "#78C7E7",
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
  },
}));

interface IRootNodeProps {
  id: string;
  data: any;
}

const RootNode: React.FC<IRootNodeProps> = ({ id, data }: IRootNodeProps) => {
  const classes = useStyles();

  return (
    <Box className={classes["root-node-wrapper"]}>
      <Typography variant="textLg" className="w-bold" sx={{ color: "#fff" }}>
        {data?.label}
      </Typography>
      <Typography className="w-semi-bold" sx={{ color: "#fff" }}>
        {data?.stats?.frequency} conversations
      </Typography>

      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
};

export default RootNode;
