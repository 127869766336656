import {
  createPlaceholderSpan,
  getCaretPosition,
  getRenderedValueFromContentElement,
  getSelectionElement,
  getValueFromContentElement,
  // handleDeleteRootSpanElement,
  moveCaret,
  // moveCaretInElement,
  moveCursor,
} from "./utilsV2";

// KEY-DOWN EVENT HANDLE
export const handleKeydownChange = ({
  id,
  event,
  onChange,
  openDropdown,
  closeDropdown,
}: {
  id: string;
  event: KeyboardEvent;
  onChange: (value: any) => void;
  openDropdown: () => void;
  closeDropdown: () => void;
}): void => {
  const { key } = event;

  // KEYBOARD ACTION EVENT
  if (key === "Backspace" || key === "Delete") {
    handleBackspaceAndDeleteKeydown({ id, event });
  }
  if (key === "Enter") {
    event.preventDefault();
  }

  // KEYBOARD PRINTABLE CHARACTER EVENT
  // const renderedValue = getRenderedValueFromContentElement(id);
  // if (renderedValue && renderedValue.length >= 100 && key.length === 1) {
  //   event.preventDefault();
  // }
};

const handleBackspaceAndDeleteKeydown = ({
  id,
  event,
}: {
  id: string;
  event: KeyboardEvent;
}): void => {
  // Block for single key event only, i.e one character at a time
  const textareaEditor = document.getElementById(`textarea-editor--${id}`);
  if (!textareaEditor) return;

  const textareaEditorChildren = textareaEditor.children;
  const lastSpan = textareaEditorChildren?.[0];

  if (lastSpan.innerHTML === "<br>") {
    event.preventDefault();
  } else if (lastSpan.innerHTML === "" || lastSpan?.textContent?.length === 1) {
    lastSpan.innerHTML = "<br>"; // Add <br> if span has only one character
    event.preventDefault(); // Prevent the default backspace action
  }
};

// =========================================== KEY-DOWN END ===========================================================

// KEY-UP HANDLE //
export const handleKeyupChange = ({
  id,
  event,
  onChange,
  openDropdown,
  closeDropdown,
}: {
  id: string;
  event: KeyboardEvent;
  onChange: (value: any) => void;
  openDropdown: () => void;
  closeDropdown: () => void;
}): void => {
  const { key } = event;

  // KEYBOARD ACTION EVENT
  if (key === "Backspace" || key === "Delete") {
    handleBackspaceAndDeleteKeyup({ id, event });
    const updatedValue = getValueFromContentElement(id);
    onChange(updatedValue);
  }
  if (key.length !== 1) {
    return;
  }

  // KEYBOARD PRINTABLE CHARACTER EVENT
  const renderedValue = getRenderedValueFromContentElement(id);
  if (renderedValue && renderedValue.length >= 100) {
    event.preventDefault();
    return;
  }

  handleKeyupInputChange({
    key,
    onChange,
    openDropdown,
    closeDropdown,
  });
};

const handleBackspaceAndDeleteKeyup = ({
  id,
  event,
}: {
  id: string;
  event: KeyboardEvent;
}): void => {
  const textareaEditor = document.getElementById(`textarea-editor--${id}`);
  if (!textareaEditor) return;

  const lastSpan = document.getElementById(`${id}-0`);
  if (!lastSpan) {
    createPlaceholderSpan(id);
    event.preventDefault();
    return;
  }

  const currentElement = getSelectionElement();
  const currentElementId = currentElement?.getAttribute("id");
  if (!currentElement || !currentElementId) return;

  const [contentId, currentSpan] = currentElementId?.split("-");
  const currentSpanIndex = Number(currentSpan);

  if (currentElement.getAttribute("data-content-type") === "variable") {
    currentElement.setAttribute("data-content-type", "text");
    currentElement.removeAttribute("data-variable-value");
    currentElement.classList.remove("resolve-variable");

    if (currentSpanIndex > 0) {
      let currentIndex = currentSpanIndex;
      let caretElementId = currentElementId;
      let nodeOffset = 0;
      const caretOffset = getCaretPosition(currentElement);

      const previousSpanElementId = `${contentId}-${currentSpanIndex - 1}`;
      const previousSpanElement = document.getElementById(
        previousSpanElementId
      );

      if (
        previousSpanElement?.textContent?.length &&
        previousSpanElement.getAttribute("data-content-type") === "text"
      ) {
        const currentElementText = currentElement.innerText;
        nodeOffset = previousSpanElement.innerText.length;
        caretElementId = previousSpanElementId;

        previousSpanElement.innerText += currentElementText;
        currentIndex = currentSpanIndex - 1;
        currentElement.remove();
      }

      const nextSpanElementId = `${contentId}-${currentSpanIndex + 1}`;
      const nextSpanElement = document.getElementById(nextSpanElementId);

      if (
        nextSpanElement &&
        nextSpanElement.getAttribute("data-content-type") === "text"
      ) {
        const currenSelectionElementId = `${contentId}-${currentIndex}`;
        const currentSelectionElement = document.getElementById(
          currenSelectionElementId
        );
        if (!currentSelectionElement) return;

        currentSelectionElement.innerText += nextSpanElement.innerText;
        nextSpanElement.remove();
      }

      moveCaret({
        elementId: caretElementId,
        nodeOffset,
        caretOffset,
      });
    }
  }
};

const handleKeyupInputChange = ({
  key,
  onChange,
  openDropdown,
  closeDropdown,
}: {
  key: string;
  onChange: (value: any) => void;
  openDropdown: () => void;
  closeDropdown: () => void;
}): void => {
  const currentElement = getSelectionElement();
  const currentElementId = currentElement.getAttribute("id");
  const [contentId, currentSpan] = currentElementId.split("-");
  const currentSpanIndex = Number(currentSpan);

  const textareaEditor = document.getElementById(
    `textarea-editor--${contentId}`
  );
  if (!textareaEditor) return;

  const textareaEditorChildren = textareaEditor.children;
  const lastSpan = textareaEditorChildren?.[0];

  if (
    lastSpan.textContent?.length === 1 &&
    lastSpan.innerHTML.includes("<br>")
  ) {
    lastSpan.innerHTML = lastSpan.innerHTML.replace(/<br>/gi, "");
    moveCursor(`${contentId}-0`, true, false);
  }

  // Check if current input key is inside resolved variable and then we need to update whole variable into text content and check for before and after node
  if (currentElement.getAttribute("data-content-type") === "variable") {
    currentElement.setAttribute("data-content-type", "text");
    currentElement.removeAttribute("data-variable-value");
    currentElement.classList.remove("resolve-variable");

    if (currentSpanIndex > 0) {
      let currentIndex = currentSpanIndex;
      let caretElementId = currentElementId;
      let nodeOffset = 0;
      const caretOffset = getCaretPosition(currentElement);

      const previousSpanElementId = `${contentId}-${currentSpanIndex - 1}`;
      const previousSpanElement = document.getElementById(
        previousSpanElementId
      );

      if (
        previousSpanElement &&
        previousSpanElement.getAttribute("data-content-type") === "text"
      ) {
        const currentElementText = currentElement.innerText;
        nodeOffset = previousSpanElement.innerText.length;
        caretElementId = previousSpanElementId;

        previousSpanElement.innerText += currentElementText;
        currentIndex = currentSpanIndex - 1;
        currentElement.remove();
      }

      const nextSpanElementId = `${contentId}-${currentSpanIndex + 1}`;
      const nextSpanElement = document.getElementById(nextSpanElementId);

      if (
        nextSpanElement &&
        nextSpanElement.getAttribute("data-content-type") === "text"
      ) {
        const currenSelectionElementId = `${contentId}-${currentIndex}`;
        const currentSelectionElement = document.getElementById(
          currenSelectionElementId
        );
        if (!currentSelectionElement) return;

        currentSelectionElement.innerText += nextSpanElement.innerText;
        nextSpanElement.remove();
      }

      moveCaret({
        elementId: caretElementId,
        nodeOffset,
        caretOffset,
      });
    }
  }

  // Check if current span is for resolving variable then continue inside otherwise add variable map span
  if (key === "{") {
    const isCurrenSpanVariableMap =
      currentElement.getAttribute("data-map-variable");

    if (isCurrenSpanVariableMap) {
      //   console.log("Already map variable in this span");
    } else {
      //   console.log("Starting variable mapping in this span");
      // Check if current span is empty or not
      const currentSpanText = currentElement.innerText;
      // console.log(currentSpanText);
      const textSetArray = Array.from(new Set(currentSpanText));
      // console.log(textSetArray);

      if (
        currentSpanText &&
        textSetArray.length === 1 &&
        textSetArray[0] === "{"
      ) {
        // console.log("setting current span as data map variable");
        // If current span inner text is only contains '{', then add data-map-variable true
        currentElement.setAttribute("data-map-variable", "true");
      } else {
        // console.log("adding next span");
        // Adding Next Span for variable map
        const currentSpanUpdatedText = currentSpanText.substring(
          0,
          currentSpanText.length - 1
        );

        const nextSpanElement = document.createElement("span");
        const nextSpanElementId = `${contentId}-${currentSpanIndex + 1}`;
        nextSpanElement.setAttribute("id", nextSpanElementId);
        nextSpanElement.setAttribute("data-map-variable", "true");

        currentElement.innerText = currentSpanUpdatedText;
        nextSpanElement.innerHTML = "{";

        textareaEditor.appendChild(nextSpanElement);

        moveCursor(nextSpanElementId, true, false);
      }
    }

    openDropdown();
  }

  // Check if current span is resolving variable, but input key is not from variable map, convert it into text span
  if (key !== "{") {
    const isCurrenSpanVariableMap =
      currentElement.getAttribute("data-map-variable");

    // If current span is variable map, then convert it into text
    if (isCurrenSpanVariableMap) {
      closeDropdown();

      if (currentSpanIndex > 0) {
        const previousSpanElementId = `${contentId}-${currentSpanIndex - 1}`;
        const previousSpanElement = document.getElementById(
          previousSpanElementId
        );
        if (!previousSpanElement) return;

        const previousSpanText = previousSpanElement.innerText;
        const currentElementText = currentElement.innerText;

        previousSpanElement.innerText = `${previousSpanText}${currentElementText}`;
        currentElement.remove();
        moveCursor(previousSpanElementId, true, false);
      } else {
        currentElement.removeAttribute("data-map-variable");
        currentElement.setAttribute("data-content-type", "text");
      }
    } else {
      currentElement.setAttribute("data-content-type", "text");
    }
  }

  const value = getValueFromContentElement(contentId);
  onChange(value);
};
// =========================================== KEY-UP END =============================================================
