import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import EditNodeHeader from "../../components/EditNodeHeader";
import EditContainer from "./EditContainer";

const useStyles = makeStyles((theme) => ({
  "edit-branch-node": {},
}));

const conditions = yup.array().of(
  yup.array().of(
    yup.object().shape({
      expr1: yup.string(),
      operator: yup.string(),
      expr2: yup.string(),
    })
  )
);

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 16 characters allowed")
    .required("This is a required field"),
  description: yup.string(),
  branches: yup.array().of(
    yup.object({
      key: yup.string(),
      name: yup
        .string()
        .min(3, "Please enter at least 3 characters")
        .max(30, "Maximum 16 characters allowed")
        .required("This is a required field"),
      conditions: conditions,
    })
  ),
});

interface Props {
  node: INode;
}

const FormContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();

  return (
    <Form className={classes["edit-branch-node"]}>
      <EditNodeHeader node={node} />
      <EditContainer node={node} />
    </Form>
  );
};

const BranchNode: React.FC<Props> = ({ node }) => {
  const { data } = node;

  const initialValues = {
    name: data?.name,
    description: data?.description || "",
    branches: data?.branches,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      <FormContainer node={node} />
    </Formik>
  );
};

export default BranchNode;
