import { type DragEvent } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { NodeGroupsConfig } from "./config";
import { ReactComponent as ChevronDownArrow } from "assets/chevron-down.svg";
import Typography from "components/base/Typography";
import clsx from "clsx";
import { Tooltip } from "@mui/material";

const onDragStart = ({
  event,
  group,
  type,
}: {
  event: DragEvent;
  group: string;
  type: string;
}): void => {
  event.dataTransfer.setData("application/workflow-node", `${group}+${type}`);
  event.dataTransfer.effectAllowed = "move";
};

const NodesAccordion: React.FC = () => {
  return (
    <div className="flex flex-col align-items-stretch row-gap-16">
      {NodeGroupsConfig.map(
        (
          { group, icon: GroupIcon, title, description, color, nodes },
          index
        ) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ChevronDownArrow />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="flex align-items-start col-gap-12 mr-8">
                <div
                  className="nodes-type-icon"
                  style={{ backgroundColor: color }}
                >
                  <GroupIcon />
                </div>
                <div className="flex flex-col">
                  <Typography variant="textLg" className="w-semi-bold">
                    {title}
                  </Typography>
                  <Typography variant="textSm" className="w-normal">
                    {description}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="flex flex-col align-items-stretch">
              {nodes.map(
                (
                  { type, title: nodeTitle, icon: NodeIcon, enabled },
                  index
                ) => (
                  <div
                    key={index}
                    className="flex align-items-start col-gap-12 p-8 rounded-8"
                    draggable={enabled}
                    onDragStart={(event: DragEvent) => {
                      onDragStart({ event, group, type });
                    }}
                  >
                    <div
                      className={clsx("nodes-type-icon", {
                        "disabled-icon": !enabled,
                      })}
                      style={{ backgroundColor: color }}
                    >
                      <NodeIcon />
                    </div>
                    <Tooltip
                      key={index}
                      title={enabled ? null : "Will be launched soon"}
                      placement="right"
                    >
                      <div className="flex flex-col">
                        <Typography className="w-semi-bold">
                          {nodeTitle}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                )
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </div>
  );
};

export default NodesAccordion;
