import { useSelector } from "react-redux";
import { getConnectorConfig } from "api/actions";
import { type ConnectorConfigPayload } from "types/ConnectorConfigResponse";
import type ActionConnector from "types/ActionConnector";
import { ConnectorType } from "types/ActionConnector";
import { useSnackbar } from "notistack";
import { type PostBotAction } from "types/BotAction";
import StepSelectConnector, {
  type StepSelectConnectorValues,
} from "pages/Bot/BotActions/ActionSteps/StepSelectConnector";
import React, { useCallback, useState } from "react";
import type AirtableConfig from "types/ConnectorConfig/AirtableConfig";
import type GoogleSheetConfig from "types/ConnectorConfig/GoogleSheetConfig";
import type SalesForceConfig from "types/ConnectorConfig/SalesForceConfig";

const StepAddOrEditAction = React.lazy(
  async () => await import("../../ActionSteps/StepAddOrEditAction")
);

interface CreateActionProps {
  isFormSubmitting: boolean;
  onCreateAction: (values: PostBotAction) => void;
  connectorList: ActionConnector[];
}

const CreateAction: React.FC<CreateActionProps> = ({
  isFormSubmitting,
  onCreateAction,
  connectorList,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [connectorConfig, setConnectorConfig] = useState<
    AirtableConfig | GoogleSheetConfig | SalesForceConfig | null
  >(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  // const dispatch = useDispatch();

  const [isFetchConnectorConfigLoading, setIsFetchConnectorConfigLoading] =
    useState(false);

  const fetchConnectorConfig = useCallback(
    async (payload: ConnectorConfigPayload) => {
      setIsFetchConnectorConfigLoading(true);
      getConnectorConfig(payload, token)
        .then((response) => {
          setConnectorConfig(response);
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsFetchConnectorConfigLoading(false);
        });
    },
    [token, enqueueSnackbar]
  );

  const handleClickNext = useCallback(
    (values: StepSelectConnectorValues) => {
      const payload: ConnectorConfigPayload = {
        connector_type: values.connector_type as ConnectorType,
        ...(values.connector_type === ConnectorType.GOOGLE_SHEETS && {
          spreadsheet_id: values.spreadsheet_id,
        }),
      };

      void fetchConnectorConfig(payload);
    },
    [fetchConnectorConfig]
  );

  if (connectorConfig) {
    return (
      <StepAddOrEditAction
        connectorConfig={connectorConfig}
        isFormSubmitting={isFormSubmitting}
        onClickDone={onCreateAction}
      />
    );
  }

  return (
    <StepSelectConnector
      connectorList={connectorList}
      onClickNext={handleClickNext}
      isFormSubmitting={isFetchConnectorConfigLoading}
    />
  );
};

export default CreateAction;
