import { makeStyles } from "@mui/styles";
import { type INode, type IBranch } from "pages/Bot/WorkflowBuilder/types";
import type React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as EditIcon } from "assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { ReactComponent as CancelIcon } from "assets/x-close.svg";
import Typography from "components/base/Typography";
import ConditionElement from "./ConditionElement";
import Button from "components/base/Button";
import { FieldArray, useFormikContext } from "formik";
import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme) => ({
  "branch-container": {
    display: "flex",
    width: "432px",
    paddingBottom: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-default, #FFF)",

    "&.MuiAccordion-root": {
      padding: "0px",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "none",

      "&:before": {
        content: "none",
        opacity: 0,
        height: 0,
      },
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      padding: "12px 16px",
      backgroundColor: "white !important",

      "&.MuiButtonBase-root": {
        cursor: "auto",
      },

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "&.Mui-expanded": {
        minHeight: "unset", // Need to remove this property from this definition
      },
    },
    "& .MuiCollapse-root": {
      width: "100%",
      borderTop: "1px solid var(--border-default, #E1DEDA)",

      "& .MuiAccordionDetails-root": {
        padding: "16px",
      },
    },
    "&.Mui-expanded": {
      margin: 0,
    },

    "& .expand-button": {
      padding: "0px",
    },

    "& .branch-header-container": {
      "& .edit-branch-name-button": {
        padding: "2px",
        "& svg": {
          width: "16px",
          height: "16px",
        },
      },
      "& .delete-branch-button": {
        padding: "2px",
        "& svg": {
          width: "16px",
          height: "16px",

          "& path": {
            fill: "#E53811",
          },
        },
      },
      "& .branch-name-input-container": {
        "& .MuiInputBase-root": {
          height: "24px",
        },
      },
      "& .edit-action-container": {
        "& .confirm-edit-button": {
          padding: "2px",
          "& svg": {
            height: "16px",
            width: "16px",
          },
        },
        "& .cancel-edit-button": {
          padding: "2px",
          "& svg": {
            height: "16px",
            width: "16px",
          },
        },
      },
    },

    "& .element-container": {
      "& .operator-or-container": {
        display: "flex",
        width: "52px",
        padding:
          "var(--spacing-spacing-xxs-4-px, 4px) var(--spacing-spacing-md-16-px, 16px)",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "var(--radius-border-radius, 8px)",
        border: "1px solid var(--border-default, #E1DEDA)",
        background: "var(--inactive-surfaces-muted, #FCFCFB)",
        margin: "20px 0px",
      },
    },
  },
}));

interface Props extends IBranch {
  node: INode;
  branchIndex: number;
  onDeleteBranch: (branchIndex: number) => void;
}

const BranchComponent: React.FC<Props> = ({
  node,
  id,
  name,
  conditions,
  rootNodeId,
  branchIndex,
  onDeleteBranch,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedNode } = useSelector((state) => state.bot.workflow.workflow);

  const { handleBlur, handleChange } = useFormikContext<any>();

  const [expand, setExpand] = useState(true);
  const [editName, setEditName] = useState(false);

  const handleToggleExpand = (): void => {
    setExpand(!expand);
  };

  const handleEditName = (): void => {
    setEditName(true);
  };

  return (
    <Accordion expanded={expand} className={classes["branch-container"]}>
      <AccordionSummary>
        <div className="branch-header-container w-100 flex flex-row justify-content-between align-items-center">
          <div className="branch-name-container flex align-items-center col-gap-8">
            {editName && expand ? (
              <TextField
                className="branch-name-input-container"
                name={`branches.[${branchIndex}].name`}
                value={name}
                variant="standard"
                color="primary"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  maxLength: 24,
                }}
                // error={touched?.branches?.[branchIndex]?.name && Boolean(errors?.branches?.[branchIndex]?.name)}
              />
            ) : (
              <Typography className="w-semi-bold">{name}</Typography>
            )}

            {!editName && expand && (
              <IconButton
                className="edit-branch-name-button"
                onClick={() => {
                  handleEditName();
                }}
              >
                <EditIcon />
              </IconButton>
            )}
            {editName && expand && (
              <div className="edit-action-container flex align-items-center col-gap-8">
                <IconButton
                  className="confirm-edit-button"
                  onClick={() => {
                    setEditName(false);
                  }}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  className="cancel-edit-button"
                  onClick={() => {
                    setEditName(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className="flex align-items-center col-gap-16">
            {branchIndex > 0 && (
              <IconButton
                className="delete-branch-button"
                onClick={() => {
                  onDeleteBranch(branchIndex);
                  dispatch({
                    type: WorkflowActions.DELETE_NODE_BRANCH,
                    payload: {
                      nodeId: selectedNode.id,
                      branchId: id,
                      branchIndex,
                      rootNodeId,
                    },
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}

            <IconButton
              className="expand-button"
              onClick={() => {
                handleToggleExpand();
              }}
            >
              <ChevronDown />
            </IconButton>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <FieldArray name={`branches.[${branchIndex}].conditions`}>
          {({ insert, remove, push }) => (
            <div className="branch-conditions-container flex flex-col row-gap-24">
              <div className="conditions-container">
                {conditions?.map((element, index) => (
                  <div key={index} className="element-container">
                    <ConditionElement
                      key={index}
                      node={node}
                      branchIndex={branchIndex}
                      elementIndex={index}
                      element={element}
                      onDeleteElement={(elementIndex) => {
                        remove(elementIndex);
                      }}
                    />
                    {conditions?.length > 0 &&
                      index < conditions?.length - 1 && (
                        <div className="operator-or-container">
                          <Typography className="w-semi-bold">OR</Typography>{" "}
                        </div>
                      )}
                  </div>
                ))}
              </div>

              <div className="add-element-button">
                <Button
                  color={"secondary"}
                  size="x-small"
                  startIcon={<PlusIcon />}
                  onClick={() => {
                    const newElement = [
                      {
                        field: "",
                        operator: "",
                        value: "",
                      },
                    ];
                    push(newElement);
                  }}
                >
                  OR
                </Button>
              </div>
            </div>
          )}
        </FieldArray>
      </AccordionDetails>
    </Accordion>
  );
};

export default BranchComponent;
