import { createContext } from "react";

export interface AgentBuilderContextProps {
  assistantId: string;
  agentId: string;
  bot: any;
  agent: any;
}

const AgentBuilderContext = createContext<AgentBuilderContextProps>({
  assistantId: "",
  agentId: "",
  bot: null,
  agent: null,
});
export default AgentBuilderContext;
