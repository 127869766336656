import type React from "react";
import useStyles from "./ConnectorTile.styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import IconButton from "aether/IconButton";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { isEmpty } from "lodash";
import Chip from "aether/Chip";

interface Props {
  onClick: () => void;
  icon: string;
  title: string;
  description?: string;
  showTag?: boolean;
}

const ConnectorTile: React.FC<Props> = ({
  onClick,
  icon,
  title,
  description,
  showTag,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={onClick}>
      {!isEmpty(icon) ? (
        <img src={icon} alt={title} className={classes.image} />
      ) : (
        <IconButton
          variant="contained"
          color="custom"
          customColors={{ backgroundColor: "#F3F2F0" }}
        >
          <PuzzleIcon />
        </IconButton>
      )}
      <div className={classes.titleTagContainer}>
        <Typography
          variant={TypographyVariants.textLarge}
          weight={TypographyWeights.bold}
        >
          {title}
        </Typography>
        {showTag && (
          <Chip
            startIcon={<CheckIcon />}
            label={"Integrated"}
            color="success"
            size="small"
          />
        )}
      </div>
      <Typography
        variant={TypographyVariants.text}
        weight={TypographyWeights.medium}
        className={classes.description}
      >
        {description}
      </Typography>
    </div>
  );
};

export default ConnectorTile;
