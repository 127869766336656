import { useAgentListStyles as useStyles } from "./style";
import ContentHeader from "pages/Bot/components/ContentHeader";
import Typography from "components/base/Typography";
import { useContext, useEffect, useState } from "react";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
import NewAgentModal from "./NewAgentModal";
import useRouteNavigator from "hooks/useRouteNavigator";
import { getAllAgents } from "api/bot/bot-agents";
import IconButton from "aether/IconButton";
import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";
import { ReactComponent as PhoneInboundIcon } from "assets/phone-inbound-icon.svg";
import Chip from "aether/Chip";
import Table, { type TableColumnProps } from "aether/Table";
import BotContext from "../BotContext";
import { BotType } from "types/BotType";
import { formatAssociatedPhoneNumber } from "../utils";
import CountryFlagIcon from "components/base/CountryFlagIcon";
import DeleteAgentModal from "./DeleteAgentModal";

const agent_header_label = "Agents";

interface TableStateProps {
  loading: boolean;
  empty: boolean;
  error: boolean;
}

interface ChatAgentsData {
  name: string;
  description: string;
  editedOn: string;
  status: string;
  actions: string;
}

interface VoiceAgentsData {
  name: string;
  description: string;
  type: string;
  phone: string;
  editedOn: string;
  status: string;
  actions: string;
}

const Agents: React.FC = () => {
  const classes = useStyles();
  const { gotoAgentBuilder } = useRouteNavigator();

  const botContext = useContext(BotContext);
  const { id: botId, settings } = botContext;

  const [tableData, setTableData] = useState<any>([]);
  const [tableState, setTableState] = useState<TableStateProps>({
    loading: false,
    empty: false,
    error: false,
  });

  const enabledChannel = settings?.enabled_channels?.[0];

  useEffect(() => {
    setTableState((prevState) => ({ ...prevState, loading: true }));
    getAllAgents(botId)
      .then((response) => {
        if (response.length) {
          setTableData(response);
        } else {
          setTableState((prevState) => ({ ...prevState, empty: true }));
        }
      })
      .catch((error) => {
        console.log(error);
        setTableState((prevState) => ({ ...prevState, error: true }));
      })
      .finally(() => {
        setTableState((prevState) => ({ ...prevState, loading: false }));
      });
  }, []);

  const handleOnNewAgentCreated = (data: any): void => {
    setTableData([data, ...tableData]);
    setTableState((prevState) => ({ ...prevState, empty: false }));
  };

  const handleGotoAgentBuilder = (event: any, agentId: string): void => {
    gotoAgentBuilder(botId, agentId);
  };

  const handleOnSuccessDeleteCb = (agentId: string): void => {
    const updatedAgents = tableData.filter(
      (agent: any) => agent._id !== agentId
    );
    setTableData(updatedAgents);
  };

  const ChatAgentsTable = Table<ChatAgentsData>();
  const VoiceAgentsTable = Table<VoiceAgentsData>();

  const chatAgentsColumns: Array<TableColumnProps<ChatAgentsData>> = [
    {
      id: "name",
      label: "Agent Name",
      width: "35%",
      render: (row: any) => (
        <div className={classes.agentNameContainer}>
          <Typography
            className="w-semi-bold agent-name"
            onClick={(event) => {
              handleGotoAgentBuilder(event, row._id);
            }}
          >
            {row.name}
          </Typography>
          <Typography variant="textSm">{row.description}</Typography>
        </div>
      ),
    },
    {
      id: "editedOn",
      label: "Last edited",
      width: "20%",
      render: (row: any) => (
        <Typography>
          {formatToHumanStringFromDateTime(
            new Date(row?.modified),
            "MMM d, yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      width: "15%",
      render: (row: any) => (
        <Chip label={"Active"} color="success" size="large" />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "15%",
      align: "right",
      render: (row: any) => (
        <div className={classes.rowActionsContainer}>
          <DeleteAgentModal
            agentId={row._id}
            onSuccessDeleteCb={handleOnSuccessDeleteCb}
          />

          <IconButton
            size="small"
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleGotoAgentBuilder(event, row._id);
            }}
          >
            <ArrowRight />
          </IconButton>
        </div>
      ),
    },
  ];
  const voiceAgentsColumns: Array<TableColumnProps<VoiceAgentsData>> = [
    {
      id: "name",
      label: "Agent Name",
      width: "25%",
      render: (row: any) => (
        <div className={classes.agentNameContainer}>
          <Typography
            className="w-semi-bold agent-name"
            onClick={(event) => {
              handleGotoAgentBuilder(event, row._id);
            }}
          >
            {row.name}
          </Typography>
          <Typography variant="textSm">{row.description}</Typography>
        </div>
      ),
    },
    {
      id: "type",
      label: "Type",
      width: "10%",
      render: (row: any) => (
        <div className="flex col-gap-8 align-items-center">
          <PhoneInboundIcon />
          <Typography className="w-semi-bold">Inbound</Typography>
        </div>
      ),
    },
    {
      id: "phone",
      label: "Phone no.",
      width: "20%",
      render: (row: any) => {
        const { associated_phone_number } = row?.voice_settings || {};
        const { countryCode, localNumber } = formatAssociatedPhoneNumber(
          associated_phone_number
        );
        const displayPhoneNumber = associated_phone_number
          ? `${countryCode} ${localNumber}`
          : "";

        return (
          <div className="flex align-items-center">
            <CountryFlagIcon countryCode={countryCode} />
            <Typography className="w-semi-bold ">
              {displayPhoneNumber}
            </Typography>
          </div>
        );
      },
    },
    {
      id: "editedOn",
      label: "Last edited",
      width: "15%",
      render: (row: any) => (
        <Typography>
          {formatToHumanStringFromDateTime(
            new Date(row?.modified),
            "MMM d, yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      width: "15%",
      render: (row: any) => (
        <Chip label={"Active"} color="success" size="large" />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "13%",
      align: "right",
      render: (row: any) => (
        <div className={classes.rowActionsContainer}>
          <DeleteAgentModal
            agentId={row._id}
            onSuccessDeleteCb={handleOnSuccessDeleteCb}
          />
          <IconButton
            size="small"
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleGotoAgentBuilder(event, row._id);
            }}
          >
            <ArrowRight />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.agentListContainer}>
      <ContentHeader title={agent_header_label} subtitle={""}>
        <NewAgentModal
          onSuccessCb={handleOnNewAgentCreated}
          agentsCount={tableData?.length}
        />
      </ContentHeader>

      <div className={classes.agentListContent}>
        {enabledChannel === BotType.VoiceBot && (
          <VoiceAgentsTable
            columns={voiceAgentsColumns}
            data={tableData}
            loading={tableState.loading}
            empty={tableState.empty}
            error={tableState.error}
            rowClassNames={classes.agentListTableRow}
          />
        )}
        {enabledChannel === BotType.ChatBot && (
          <ChatAgentsTable
            columns={chatAgentsColumns}
            data={tableData}
            loading={tableState.loading}
            empty={tableState.empty}
            error={tableState.error}
            rowClassNames={classes.agentListTableRow}
          />
        )}
      </div>
    </div>
  );
};

export default Agents;
