import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Typography from "components/base/Typography";
import { ReactComponent as FilePlusIcon } from "assets/file-plus-icon.svg";
import { ReactComponent as EllipseCircle } from "assets/ellipse-circle.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { useRef, useState } from "react";
import Button from "components/base/Button";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { formatFileRequest } from "pages/Bot/utils";
import { uploadSource } from "api/source";
import { useDispatch, useSelector } from "store";
import { useSnackbar } from "notistack";
import FileTypeIcon from "components/shared/FileTypeIcon";
import { SourceActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme) => ({
  "source-upload-container": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",

    "& .upload-drag-container": {
      display: "flex",
      padding: "24px",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      alignSelf: "stretch",
      borderRadius: "16px",
      border: "1px dashed var(--border-default, #E1DEDA)",
      background: "var(--inactive-surfaces-muted, #FCFCFB)",

      "&.upload-drag-active": {
        borderColor: "#F07400",
      },
    },

    "& .upload-ready-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",

      "& .upload-file-row": {
        display: "flex",
        padding: "8px",
        alignItems: "center",
        gap: "24px",

        "& .file-type-icon": {
          display: "flex",
          width: "24px",
          height: "24px",
          padding: "4px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "3px",
          background: "var(--inactive-surfaces-subtle, #F9F8F8)",
          boxShadow:
            "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        },

        "& .delete-btn-container": {
          display: "none",
          height: "24px",
          width: "24px",
          justifyContent: "center",
          alignItems: "center",

          "& svg": {
            height: "16px",
            width: "16px",

            "& path": {
              fill: "#E53811",
            },
          },
        },

        "&:hover": {
          backgroundColor: "#F9F8F8",

          "& .delete-btn-container": {
            display: "flex",
          },
        },
      },
    },
  },
}));

const FileSource: React.FC = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { botId } = params || {};

  const { token } = useSelector((state) => state.app_user);

  const browseFileRef = useRef<any>();

  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const handleDragEnter = (event: any): void => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (event: any): void => {
    if (!event?.dataTransfer) return;

    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDragLeave = (): void => {
    setDragging(false);
  };

  const handleDrop = (event: any): void => {
    if (!event?.dataTransfer) return;

    event.preventDefault();
    setDragging(false);
    const newFiles = Array.from(event.dataTransfer.files);
    setFiles((prevState: any) => [...prevState, ...newFiles]);
  };

  const handleFileSelect = (e: any): void => {
    const _files = Array.from(e.target.files);
    setFiles((prevState: any) => [...prevState, ..._files]);
  };

  const handleClick = (): void => {
    browseFileRef.current.click();
  };

  const handleDeleteRow = (index: number): void => {
    const _files = [...files];
    _files.splice(index, 1);

    setFiles(_files);
  };

  const handleUploadFiles = (): void => {
    const _files = formatFileRequest(files, botId);

    setLoading(true);
    Promise.all(
      _files.map(async (file: any) => await uploadSource(file, token))
    )
      .then((results) => {
        dispatch({
          type: SourceActions.POST_FILE_SOURCE_SUCCESS,
          payload: { files: results },
        });

        enqueueSnackbar("Source uploaded successfully", {
          variant: "success",
        });

        setFiles([]);
      })
      .catch((error) => {
        console.error("Error:", error);
        enqueueSnackbar("Source failed to upload", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes["source-upload-container"]}>
      <div
        className={clsx("upload-drag-container", {
          "upload-drag-active": dragging,
        })}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div>
          <FilePlusIcon />
        </div>
        <div className="flex flex-col align-items-center">
          <Typography
            variant="textLg"
            className="w-semi-bold"
            sx={{ color: "#000000" }}
          >
            Drag and drop file here
          </Typography>
          <div className="flex align-items-center col-gap-8">
            <Typography className="w-medium" sx={{ color: "#7C7972" }}>
              PDF, .doc, .docx
            </Typography>
            <EllipseCircle />
            <Typography className="w-medium" sx={{ color: "#7C7972" }}>
              Upto 100MB
            </Typography>
          </div>
        </div>

        <div className="browse-file-container">
          <input
            ref={browseFileRef}
            type="file"
            id="fileInput"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            multiple
            accept=".pdf, .doc, .docx, .txt"
            size={100 * 1024 * 1024}
          />
          <Button color="primary" onClick={handleClick} disabled={loading}>
            Browse
          </Button>
        </div>
      </div>

      {files?.length > 0 && (
        <div className="upload-ready-container">
          {files.map((file: any, index: number) => (
            <div key={index} className="upload-file-row">
              <div className="file-type-icon">
                <FileTypeIcon type={file.type} />
              </div>

              <Typography className="w-semi-bold">{file.name}</Typography>

              <div className="delete-btn-container">
                <IconButton
                  onClick={() => {
                    handleDeleteRow(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="upload-action-container">
        <Button
          color="primary"
          startIcon={<PlusIcon />}
          disabled={!files?.length}
          loading={loading}
          onClick={handleUploadFiles}
        >
          Add source and train
        </Button>
      </div>
    </div>
  );
};

export default FileSource;
