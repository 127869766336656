import BotTree from "pages/BotTree";

const routes = [
  {
    path: "bot-tree",
    element: <BotTree />,
  },
];

export default routes;
