import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "components/base/Typography";
import { ReactComponent as CloseIcon } from "assets/x-close.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { getNodeIconAndColor } from "pages/Bot/WorkflowBuilder/utils/workflow";
import { useDispatch } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";
import { type INode } from "pages/Bot/WorkflowBuilder/types";
import { SessionContextActions } from "store/reduxActions/botActions/contextSlice";
import { ContextVariableState } from "pages/Bot/WorkflowBuilder/config";

const useStyles = makeStyles((theme) => ({
  "node-header": {
    display: "flex",
    padding: "24px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    boxShadow: "0px 1px 0px 0px #E1DEDA",
    position: "fixed",
    width: "480px",
    minWidth: "408px",
    zIndex: 100,
    backgroundColor: "white",

    "& .node-type-icon": {
      display: "flex",
      padding: "8px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "8px",

      "& svg": {
        width: 20,
        height: 20,
      },
    },

    "& .delete-node-button": {
      "& svg": {
        "& path": {
          fill: "#E53811",
        },
      },
    },
  },
}));

interface Props {
  node: INode;
  enableDelete?: boolean;
  enableClose?: boolean;
  onDelete?: () => void;
  onClose?: () => void;
}

const EditNodeHeader: React.FC<Props> = ({
  node,
  enableDelete = true,
  enableClose = true,
  onDelete,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { type, data } = node;
  const { name, group } = data;

  const handleDelete = (): void => {
    const nodeId = node.id;

    // DELETE NODE
    dispatch({
      type: WorkflowActions.DELETE_NODE,
      payload: { node },
    });

    // DELETE NODE CONTEXT
    dispatch({
      type: SessionContextActions.DELETE_NODE_CONTEXT,
      payload: { contextState: ContextVariableState.DIALOG, nodeId },
    });
  };

  const handleClose = (): void => {
    if (onClose) {
      onClose();
      return;
    }

    dispatch({
      type: WorkflowActions.SET_OPEN_DRAWER,
      payload: { openDrawer: false },
    });
    setTimeout(() => {
      dispatch({
        type: WorkflowActions.SET_SELECT_NODE,
        payload: { id: null, anchor: null },
      });
    }, 200);
  };

  const { ICON, COLOR } = getNodeIconAndColor({ type, group });

  return (
    <div className={classes["node-header"]}>
      <div className="flex col-gap-16">
        <div className="node-type-icon" style={{ backgroundColor: COLOR }}>
          {ICON && <ICON />}
        </div>
        <div className="flex flex-col">
          <Typography
            variant="textTiny"
            className="w-semi-bold"
            sx={{ color: "#B5B1AD" }}
          >
            {group}
          </Typography>
          <Typography
            variant="textLg"
            className="w-semi-bold"
            sx={{ color: "#000" }}
          >
            {name}
          </Typography>
        </div>
      </div>

      <div className="flex align-items-center">
        {enableDelete && (
          <IconButton className="delete-node-button" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}

        {enableClose && (
          <IconButton className="close-node-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default EditNodeHeader;
