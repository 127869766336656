import { type NodeTypes } from "reactflow";
import TriggerNode from "./TriggerNode";
import APIServiceNode from "./APIServiceNode";
import ResponseNode from "./ResponseNode";
import CarouselResponseNode from "./CarouselResponseNode";
import PlaceholderNode from "./PlaceholderNode";
import {
  ServiceNodeType,
  BotPromptNodeType,
  CreateNodeType,
  LogicNodeType,
  ResponseNodeType,
  TriggerNodeType,
} from "pages/Bot/WorkflowBuilder/config";
import InputNode from "./InputNode";
import BranchNode from "./BranchNode";
import BranchAddNode from "./BranchAddNode";

const nodeTypes: NodeTypes = {
  [CreateNodeType.PLACEHOLDER_NODE]: PlaceholderNode,
  [CreateNodeType.BRANCH_ADD_NODE]: BranchAddNode,
  [TriggerNodeType.TRIGGER]: TriggerNode,
  [ServiceNodeType.API_SERVICE_NODE]: APIServiceNode,
  [ResponseNodeType.TEXT]: ResponseNode,
  [BotPromptNodeType.CAROUSEL]: CarouselResponseNode,
  [BotPromptNodeType.TEXT]: InputNode,
  [LogicNodeType.BRANCH]: BranchNode,
};

export default nodeTypes;
