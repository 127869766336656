import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useSelectBotModalStyles = makeStyles((theme: Theme) => ({
  createAssistantModal: {
    "& .MuiPaper-root": {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
  },
  createAssistantContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    rowGap: "16px",

    "& .select-bot-type-container": {
      "& .select-container": {
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        alignItems: "center",
        gap: "16px",
        borderRadius: "8px",
        border: "1px solid var(--border-default, #E1DEDA)",
        background: "#FFF",

        "&.selected": {
          borderColor: "#F07400",
        },
      },

      "& .chatbot-icon-container, .voicebot-icon-container": {
        display: "flex",
        padding: "12px",
        alignItems: "center",
        gap: "10px",
        borderRadius: "32px",
        border: "1.5px solid #FF8C1F",
        background: "#FFF5EB",
      },
    },
  },
}));
