import Button from "components/base/Button";
import { isNil } from "lodash";
import type React from "react";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import useStyles from "./style";
import { useSelector } from "store";
import {
  addTool,
  type AddToolInput,
} from "pages/ActionBuilder/Service/ActionBuilder.service";
import { ActionType } from "../AgentActions.const";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const FinalSubmitAddAction: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { agentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedConfigurableAction } = useSelector(
    (state) => state.actionBuilder
  );

  const addAction = (): void => {
    // add tool to the Agent
    if (agentId) {
      const body: AddToolInput = {
        agent_id: agentId,
        name: selectedConfigurableAction?.name,
        action_type: ActionType.CONNECTORS,
        config: {
          connection_id: selectedConfigurableAction?.connectionId,
          action_config_id: selectedConfigurableAction?._id,
          input_params: selectedConfigurableAction?.input_params,
          output_params: selectedConfigurableAction?.output_params,
        },
      };
      addTool(body)
        .then(() => {
          enqueueSnackbar("Successfully added", { variant: "success" });
          // redirect to the parent page back
          navigate(-1);
        })
        .catch((err) => {
          enqueueSnackbar(`Something went wrong: ${err}`, { variant: "error" });
        });
    }
  };
  return (
    <>
      {!isNil(selectedConfigurableAction) && (
        <div className={classes.confirmAddContainer}>
          <div className={classes.confirmNote}>
            {`Add ${selectedConfigurableAction.name} action`}
          </div>
          <Button
            color="primary"
            onClick={addAction}
            iconBtn
            startIcon={<CheckIcon />}
          >
            Add action
          </Button>
        </div>
      )}
    </>
  );
};

export default FinalSubmitAddAction;
