import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { WorkflowActions } from "store/reduxActions/botActions";
import { useSelector } from "store";
import { ReactComponent as BrokenDataLogo } from "assets/broken-data.svg";
import { ReactComponent as EmptyStateLogo } from "assets/empty-state.svg";
import Typography from "components/base/Typography";
import ReactFlowCanvas from "./ReactFlowCanvas";

const useStyles = makeStyles((theme) => ({
  "bot-page-loader": {
    height: "88vh",
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .page-error-logo": {
      height: "240px",
      width: "240px",
    },

    "& .page-empty-logo": {
      height: "400px",
      width: "400px",
    },
  },
  "workflow-builder-container": {
    width: "94vw",
    height: "calc(100vh - 76px)",
  },
}));

const WorkflowCanvas: React.FC = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const { loading, error, empty } = useSelector((state) => state.bot.workflow);

  if (loading) {
    return (
      <Box className={classes["bot-page-loader"]}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes["bot-page-loader"]}>
        <BrokenDataLogo className="page-error-logo" />
        <Typography variant="textLg" className="w-semi-bold mt-8">
          Something went wrong
        </Typography>
      </Box>
    );
  }

  if (empty) {
    return (
      <Box className={classes["bot-page-loader"]}>
        <EmptyStateLogo className="page-empty-logo" />
        <Typography variant="textLg" className="w-semi-bold mt-8">
          No data
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes["workflow-builder-container"]}>
      <ReactFlowCanvas />
    </div>
  );
};

export default WorkflowCanvas;
