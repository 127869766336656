import { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import Typography from "components/base/Typography";
import { ReactComponent as EllipseCircle } from "assets/ellipse-circle.svg";
import ContextChild from "./ContextChild";
import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import { ReactComponent as ChevronUp } from "assets/chevron-up.svg";
import NodeTooltip from "./NodeTooltip";
import { type IVariable } from ".";
import { getNodeIconAndColor } from "pages/Bot/WorkflowBuilder/utils/workflow";
import { useContextOptionStyle as useStyles } from "./style";

const PopoverOption = ({
  path,
  option,
  onSelect,
}: {
  option: any;
  path: string;
  onSelect: (variable: IVariable) => void;
}): JSX.Element => {
  const [anchor, setAnchor] = useState<any>();

  const handleOpen = (event: any): void => {
    setAnchor(event.target);
  };

  const handleClose = (): void => {
    setAnchor(null);
  };

  return (
    <div className="context-option-value--popover">
      <ListItemButton className="context-option-value" onClick={handleOpen}>
        <div className="w-100 flex justify-content-between align-items-center col-gap-12">
          <div className="option-value-meta flex align-items-center col-gap-8">
            <Typography className="title w-semi-bold ellipsis">
              {option?.key}
            </Typography>
            {/* <Typography
                    variant="textSm"
                    className="example w-semi-bold ellipsis"
                    sx={{ color: "#7C7972" }}
                  >
                    Eg. {opt?.value}
                  </Typography> */}
          </div>

          <div className="option-value-actions">
            <div className="option-value-info-icon center">
              <NodeTooltip meta={option?.meta} />
            </div>
            <div className="option-value-popover-icon">
              <ChevronRight />
            </div>
          </div>
        </div>
      </ListItemButton>

      <ContextChild
        anchor={anchor}
        option={option.options}
        path={`${path}.${option.key}`}
        onSelect={onSelect}
        onClose={handleClose}
      />
    </div>
  );
};

const Option = ({
  path,
  option,
  onSelect,
}: {
  path: string;
  option: any;
  onSelect: (variable: IVariable) => void;
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const { ICON } = getNodeIconAndColor({
    group: option?.meta?.group,
    type: option?.meta?.type,
  });

  return (
    <>
      <ListItemButton
        className="context-option"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="w-100 flex justify-content-between col-gap-12">
          <div className="option-meta-container flex grow-1 col-gap-8">
            <div className="node-icon pt-1">{ICON && <ICON />}</div>

            <div className="option-title-container flex flex-col align-items-stretch">
              <div className="option-header flex align-items-center col-gap-8">
                <Typography className="title w-semi-bold ellipsis">
                  {option?.key}
                </Typography>
                <EllipseCircle />
                <Typography
                  className="option-values ellipsis"
                  variant="textMd"
                  sx={{ color: "#7C7972" }}
                >
                  {option?.options?.length} values
                </Typography>
              </div>

              {option?.meta?.description && (
                <Typography variant="textSm" sx={{ color: "#7C7972" }}>
                  {option?.meta?.description}
                </Typography>
              )}
            </div>
          </div>

          <div className="option-actions-container flex align-items-center col-gap-8">
            <div className="context-info-icon center">
              <NodeTooltip meta={option?.meta} />
            </div>

            <div className="context-dropdown-icon">
              {open ? <ChevronUp /> : <ChevronDown />}
            </div>
          </div>
        </div>
      </ListItemButton>

      <Collapse
        className="context-option-value-list"
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {option?.options?.map((opt: any, k: number) => {
          if (opt?.options) {
            return (
              <PopoverOption
                key={k}
                path={path}
                option={opt}
                onSelect={onSelect}
              />
            );
          }

          return (
            <ListItemButton
              className="context-option-value"
              key={k}
              onClick={() => {
                onSelect({ key: opt.key, value: `${path}.${opt.value}` });
              }}
            >
              <div className="w-100 flex justify-content-between align-items-center col-gap-12">
                <div className="option-value-meta flex align-items-center col-gap-8">
                  <Typography className="title w-semi-bold ellipsis">
                    {opt?.key}
                  </Typography>
                  {/* <Typography
                    variant="textSm"
                    className="example w-semi-bold ellipsis"
                    sx={{ color: "#7C7972" }}
                  >
                    Eg. {opt?.value}
                  </Typography> */}
                </div>

                <div className="option-value-actions">
                  <div className="option-value-info-icon center">
                    <NodeTooltip meta={option?.meta} />
                  </div>
                </div>
              </div>
            </ListItemButton>
          );
        })}
      </Collapse>
    </>
  );
};

interface Props {
  path: string;
  option: any;
  onSelect: (variable: IVariable) => void;
}

const ContextOption: React.FC<Props> = ({ path, option, onSelect }) => {
  const classes = useStyles();

  if (!option?.key) {
    return null;
  }

  return (
    <List className={classes["context-option-container"]}>
      {option?.options ? (
        <Option path={path} option={option} onSelect={onSelect} />
      ) : (
        <ListItemButton
          className={classes["select-option-btn"]}
          onClick={() => {
            onSelect({ key: option.key, value: `${path}.${option.value}` });
          }}
        >
          <div className="w-full flex align-items-center col-gap-8">
            <Typography className="w-semi-bold">{option?.key}</Typography>
          </div>
        </ListItemButton>
      )}
    </List>
  );
};

export default ContextOption;
