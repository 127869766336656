import { CircularProgress, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { type IAPI } from "types/APIIntegration";
import Badge from "components/base/Badge/Badge";
import { ReactComponent as LightningIcon } from "assets/lightning-02.svg";
import { ReactComponent as ZapFastIcon } from "assets/zap-fast.svg";

const useStyles = makeStyles((theme: Theme) => ({
  "api-list-table-wrapper": {
    width: "100%",
    padding: "16px 24px",

    "& .api-list-table": {
      minHeight: "40vh",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      borderRadius: "12px",
      border: "var(--spacing-spacing-none, 1px) solid var(--Gray-200, #EAECF0)",
      background: "var(--Base-White, #FFF)",
      boxShadow:
        "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",

      "& .api-type-chip": {
        display: "flex",
        padding: "2px 8px",
        alignItems: "center",
        borderRadius: "16px",
        background: "var(--Gray-100, #F2F4F7)",
        mixBlendMode: "multiply",
      },
    },
  },
  zapIcon: {
    "& path": {
      fill: "#ff8000",
    },
  },
}));

interface Props {
  rows: IAPI[];
  loading: boolean;
}

const List: React.FC<Props> = ({ rows, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes["api-list-table-wrapper"]}>
      <TableContainer className="api-list-table" component={Paper}>
        {loading ? (
          <div
            className="w-100 flex flex-col center"
            style={{ height: "40vh" }}
          >
            <CircularProgress size="2rem" />
          </div>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 650 }}>Name and description</TableCell>
                <TableCell align="left" sx={{ width: 180 }}>
                  Last updated
                </TableCell>
                <TableCell align="left" sx={{ width: 160 }}>
                  Status
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row: any) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      className="flex flex-col no-decoration"
                      to={`./editor/${row._id}`}
                    >
                      <div className="flex justify-content-start align-items-center col-gap-8">
                        <Typography className="w-semi-bold">
                          {row.name}
                        </Typography>
                        <div className="api-type-chip">
                          <Typography
                            variant="textTiny"
                            className="w-semi-bold"
                          >
                            {row.http_method}
                          </Typography>
                        </div>
                        {row?.is_mock_api && (
                          <Badge
                            icon={<LightningIcon />}
                            title="Sample API"
                            color="blue"
                          />
                        )}
                        {row?.is_generated_api && (
                          <Badge
                            icon={<ZapFastIcon className={classes.zapIcon} />}
                            title="Created using prompt"
                            color="grey"
                          />
                        )}
                      </div>
                      <Typography variant="textSm">
                        {row.description}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell align="left">{row.modified}</TableCell>
                  <TableCell align="left">
                    <Chip label={row.status} />
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default List;
