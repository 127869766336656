import FAQSuggestions from "pages/FAQSuggestions";
import RecommendationList from "pages/FAQSuggestions/components/Recommendation";
import RejectedList from "pages/FAQSuggestions/components/Rejected";
import ShortlistedList from "pages/FAQSuggestions/components/Shortlisted";
import { Navigate } from "react-router-dom";

const routes = [
  {
    path: "faq-suggestions",
    element: <FAQSuggestions />,
    children: [
      {
        index: true,
        element: <Navigate to="recommendations" />,
      },
      {
        path: "recommendations",
        element: <RecommendationList />,
      },
      {
        path: "shortlisted",
        element: <ShortlistedList />,
      },
      {
        path: "rejected",
        element: <RejectedList />,
      },
    ],
  },
];

export default routes;
