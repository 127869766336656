import { useRef } from "react";
import Typography from "components/base/Typography";
import { ReactComponent as CodeCircleIcon } from "assets/code-circle.svg";
import { useFormikContext } from "formik";
import { MenuItem, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { type IServiceNodeForm } from "types/Workflow";
import Button from "components/base/Button";
import { type IAPI } from "types/APIIntegration";
import { useSelector } from "store";
import { type IStepContentProps } from "pages/Bot/WorkflowBuilder/types";

const StepAPIIntegration: React.FC<IStepContentProps> = ({
  handleNextStep,
  handleEditFromKStep,
  completed,
}) => {
  const api_integrations = useSelector(
    (state) => state.bot.api_integrations.list
  );

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    // isValid,
    // dirty,
  } = useFormikContext<IServiceNodeForm>();

  const activeStep = useRef<number>(0);

  const handleClickContinue = (event: any): void => {
    event.preventDefault();

    const { extracted_variables } = values?.selected_api_integration;
    const input_variable_map = extracted_variables?.map(
      (variable: any, index: number) => ({
        key: variable.key,
        value: values.input_variable_map?.[index]?.value || "",
        description: variable.description,
      })
    );
    const test_variable_map = extracted_variables?.map((variable: any) => ({
      key: variable.key,
      value: "",
      description: variable.description,
    }));

    void setFieldValue("input_variable_map", input_variable_map);
    void setFieldValue("test_api", test_variable_map);

    handleNextStep(activeStep.current + 1);
  };

  const handlOnBlur = (event: any): void => {
    const apiIntegrationId = event.target.value;
    const selected_api_integration = api_integrations?.find(
      (e: IAPI) => e._id === apiIntegrationId
    );
    void setFieldValue("selected_api_integration", selected_api_integration);
    handleBlur(event);
  };

  return (
    <div className="flex flex-col align-items-stretch row-gap-8 mt-16">
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Select API</Typography>
        <TextField
          name={`api_integration_id`}
          variant="outlined"
          size="small"
          placeholder="Node trigger"
          fullWidth
          value={values.api_integration_id}
          onChange={handleChange}
          onBlur={handlOnBlur}
          select
          error={
            touched.api_integration_id && Boolean(errors.api_integration_id)
          }
          helperText={touched.api_integration_id && errors.api_integration_id}
          disabled={completed}
        >
          {api_integrations?.map((integration: any, index: number) => (
            <MenuItem key={integration._id} value={integration._id}>
              {integration.name}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <Link to={""} className="flex flex-row align-items-center no-decoration">
        <div className="flex align-items-center col-gap-4">
          <CodeCircleIcon />
          <Typography variant="textSm" sx={{ color: "#C9671D" }}>
            Integrate New API
          </Typography>
        </div>
      </Link>

      <div className="mt-16">
        {completed ? (
          <Button
            size="small"
            color={"secondary"}
            onClick={() => {
              handleEditFromKStep(activeStep.current);
            }}
          >
            Edit
          </Button>
        ) : (
          <Button
            size="small"
            color={"primary"}
            disabled={!values.api_integration_id}
            onClick={handleClickContinue}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepAPIIntegration;
