import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as EmptyStateIcon } from "assets/empty-state-icon.svg";
import Typography, { TypographyWeights } from "aether/Typography";
import Button, { ButtonSizes } from "aether/Button";
import { type AddItemButtonProps } from "aether/Table";

export const useStyles = makeStyles((theme: Theme) => ({
  tableEmptyContainer: {
    height: "30vh",

    "& .table-empty-logo": {
      "& svg": {},
    },
  },
}));

interface Props {
  colSpan: number;
  message: string;
  addItemButton?: AddItemButtonProps;
}

const TableEmpty: React.FC<Props> = ({
  colSpan,
  message,
  addItemButton = null,
}) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableEmptyContainer}>
      <TableCell colSpan={colSpan}>
        <div className="table-empty-logo flex-col center">
          <EmptyStateIcon />

          <div className="flex-col row-gap-16 align-items-center">
            <Typography weight={TypographyWeights.semiBold}>
              {message}
            </Typography>

            {addItemButton && (
              <Button
                size={ButtonSizes.xsmall}
                startIcon={<PlusIcon />}
                onClick={() => {
                  addItemButton?.onClickItem();
                }}
              >
                {addItemButton.label}
              </Button>
            )}
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableEmpty;
