import { createContext } from "react";
import { type IAssistant } from "types/assistant.types";

export interface AssistantContextProps {
  assistantId: string;
  assistant: IAssistant;
}

const AssistantContext = createContext<AssistantContextProps>({
  assistantId: "",
  assistant: {
    _id: "",
    created: "",
    modified: "",
    name: "",
    description: "",
    enabled: false,
    deleted: false,
    general_info: {
      goal: null,
      guidelines_and_rules: null,
      company_info_text: null,
    },
  },
});
export default AssistantContext;
