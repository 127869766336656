import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useFormikContext, FieldArray } from "formik";
// import * as yup from "yup";
import TextField from "@mui/material/TextField";
// import { type IQuestion, type IBotPromptInputNodeData } from "types/Workflow";
// import TemplateTextarea from "components/base/TemplateTextAreaV2";
// import { useContext } from "react";
// import { WorkflowContext } from "pages/Bot/WorkflowBuilder/WorkflowContext";
// import { formatSessionContextToOptions } from "pages/Bot/WorkflowBuilder/utils";
import { Link } from "react-router-dom";
// import IconButton from "@mui/material/IconButton";
// import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
// import { extractEntities } from "api/workflow";
// import clsx from "clsx";
import { useCallback, useEffect } from "react";
import useSessionContext from "pages/Bot/WorkflowBuilder/hooks/useSessionContext";
import { getExtractedVariables } from "pages/Bot/WorkflowBuilder/utils/session-context";
import {
  type IBotQuestion,
  type IInputNode,
  type INode,
} from "pages/Bot/WorkflowBuilder/types";
import BotQuestion from "./BotQuestion";
import { useDispatch } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";

const useStyles = makeStyles((theme) => ({
  "edit-container": {
    padding: "108px 24px 32px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",

    "& .edit-node-content": {
      padding: "32px 24px",
    },

    "& .input-question": {
      "& .delete-icon": {
        height: "16px",
        width: "16px",
      },

      "& .question-input-container": {
        "& .question-variable-container": {
          padding: "0px 8px",
          transform: "translateY(-10px)",
          opacity: 0,
          height: "0px",
          transition: "all 0.2s ease-in-out",

          "&.show-variable": {
            height: "52px",
            opacity: 1,
            transform: "translateY(0px)",

            "& .variable-response": {
              display: "flex",
            },
          },

          "& .variable-response": {
            display: "none",
            padding: "12px 16px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
            borderRadius:
              "var(--spacing-spacing-none, 0px) var(--spacing-spacing-none, 0px) 8px 8px",
            borderRight: "1px solid var(--border-default, #E1DEDA)",
            borderBottom: "1px solid var(--border-default, #E1DEDA)",
            borderLeft: "1px solid var(--border-default, #E1DEDA)",
            background: "var(--colors-shades-base-white, #FFF)",

            "& .variable": {
              display: "flex",
              padding: "2px 5px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              border: "1px solid var(--colors-secondary-purple-600, #9062CB)",
              background: "var(--colors-secondary-purple-100, #EFE4FC)",
            },
          },
        },
      },
    },
  },
}));

interface Props {
  node: INode;
}

const EditContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = node;

  const { session_context, addNodeInContext, updateNodeVariablesInContext } =
    useSessionContext();

  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<IInputNode>();
  const { name, questions } = values;

  const handleAddNodeInContext = useCallback(() => {
    const nodeMeta = {
      label: name,
      nodeId: id,
      name,
      type: node.type,
      group: node?.data?.group,
      description: "",
      counter: node?.data?.ui?.counter,
    };

    addNodeInContext({
      context: "dialog_context",
      nodeMeta,
    });
  }, [id]);

  const handleUpdateSessionContext = (): void => {
    if (!values?.name) return;

    const variablesData = getExtractedVariables(questions);

    const nodeMeta = {
      label: name,
      nodeId: id,
      name,
      type: node.type,
      group: node?.data?.group,
      description: "",
      counter: node?.data?.ui?.counter,
    };

    updateNodeVariablesInContext({
      context: "dialog_context",
      nodeMeta,
      variables: variablesData,
    });
  };

  // We can remove this effect, if we add node meta, when node is created from placeholder create node.
  useEffect(() => {
    handleAddNodeInContext();
  }, [id]);

  useEffect(() => {
    dispatch({
      type: WorkflowActions.UPDATE_WORKFLOW_NODE,
      payload: { id, config: values },
    });
  }, [values]);

  useEffect(() => {
    console.log(session_context);
  }, [session_context]);

  return (
    <div className={classes["edit-container"]}>
      <div className="form-input-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Node Name</Typography>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          placeholder=""
          fullWidth
          value={values.name}
          onChange={handleChange}
          onBlur={(event) => {
            handleBlur(event);
            handleUpdateSessionContext();
          }}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>

      <FieldArray name="questions">
        {({ insert, remove, push }) => (
          <div className="flex flex-col row-gap-16">
            {values.questions.length > 0 &&
              values.questions.map((q: IBotQuestion, index: number) => (
                <BotQuestion
                  key={index}
                  index={index}
                  question={q}
                  onRemove={remove}
                  handleUpdateSessionContext={handleUpdateSessionContext}
                />
              ))}

            {values?.questions?.length < 10 && (
              <Link
                to={""}
                className="flex flex-row align-items-center no-decoration"
                onClick={() => {
                  const _q = {
                    key: `q${values.questions.length + 1}`,
                    question: "",
                  };
                  push(_q);
                }}
              >
                <div className="flex align-items-center col-gap-4">
                  <PlusIcon />
                  <Typography
                    variant="textLg"
                    sx={{ color: "#C9671D" }}
                    className="w-bold"
                  >
                    Add another question
                  </Typography>
                </div>
              </Link>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default EditContainer;
