import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAgentListStyles = makeStyles((theme: Theme) => ({
  agentListContainer: {},
  agentListContent: {
    display: "flex",
    columnGap: "32px",
    padding: "24px",
  },
  agentListTable: {
    minHeight: "40vh",
    boxShadow: theme.boxShadows.sm,
    border: "none",
  },
  agentListTableRow: {
    "& $agentNameContainer": {
      display: "flex",
      flexDirection: "column",
      rowGap: "4px",
      "& .agent-name": {
        alignSelf: "flex-start",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& $rowActionsContainer": {
      display: "none",
    },
    "&:hover": {
      "& $rowActionsContainer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        columnGap: "16px",
      },
    },
  },
  agentNameContainer: {},
  rowActionsContainer: {},
}));

export const useNewAgentModalStyles = makeStyles((theme: Theme) => ({}));
