import { Popover } from "@mui/material";
import Button from "components/base/Button";
import Typography from "components/base/Typography";
import { useState } from "react";
import { useSelectAPIPopoverStyle as useStyles } from "./style";
// import { ReactComponent as LinkExternal } from "assets/link-external-icon.svg";
import { type ITool } from "./types";

interface Props {
  index: number;
  options: ITool[];
  onSelect: (index: number, option: any) => void;
}

const SelectAPIPopover: React.FC<Props> = ({ index, options, onSelect }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleSelectOption = (
    event: React.MouseEvent<HTMLDivElement>,
    option: any
  ): void => {
    event.stopPropagation();
    onSelect(index, option);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "select-tool-api-popover" : undefined;

  return (
    <>
      <Button color="secondary" size="x-small" onClick={handleClick}>
        Select API
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={classes.selectAPIContainer}>
          {options?.map((option, k) => (
            <div
              key={k}
              className={classes.apiOptionContainer}
              onClick={(event) => {
                handleSelectOption(event, option);
              }}
            >
              <div className="flex align-items-center col-gap-8">
                <Typography className="w-semi-bold">
                  {option.api_integration_name}
                </Typography>
                <div>{option.http_method}</div>

                {/* <LinkExternal /> */}
              </div>

              <Typography className="w-medium">
                {option.api_integration_description}
              </Typography>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default SelectAPIPopover;
