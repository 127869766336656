import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useBotHeaderStyles = makeStyles((theme: Theme) => ({
  "bot-header-container": {
    position: "fixed",
    display: "flex",
    width: "calc(100% - 72px)",
    padding: "16px 32px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
    zIndex: 1000,

    "& .back-button": {},

    "& .bot-details-container": {
      "& span": {
        lineHeight: "1",
      },
    },
  },
}));

export const useBotNavigatioBarStyles = makeStyles((theme: Theme) => ({
  "navbar-container": {
    position: "fixed",
    height: "100%",
    width: "188px",
    backgroundColor: "#FFF",
    padding: "16px 12px",
    display: "flex",
    flexDirection: "column",
    zIndex: 100,

    "& .bot-name": {
      cursor: "default",
      display: "inline-block",
      width: "100%",
    },

    "& .navigation-category-wrapper": {
      "& .navigation-category": {
        "& .category-paths-container": {
          "& a": {
            textDecoration: "none",

            "&:hover:not(.disabled) , &.active:not(.disabled) ": {
              cursor: "pointer",
              borderRadius: "8px",
              background: "#FFF5EB",

              "& span": {
                color: "#E06F06",
              },
            },

            "&.disabled": {
              "& span": {
                color: "#B5B1AD",
              },
            },
          },
        },
      },
    },
  },
}));
