import { makeStyles } from "@mui/styles";
import { type Theme } from "@mui/material";

export const useContextOptionStyle = makeStyles((theme: Theme) => ({
  "context-option-container": {
    padding: "4px 0px",

    "& .context-option": {
      width: "100%",
      padding: "8px",

      "& .option-meta-container": {
        width: "calc(100%)",

        "& .node-icon": {
          "& svg": {
            width: "16px",
            height: "16px",
            "& path": {
              fill: "#B5B1AD",
            },
          },
        },

        "& .option-title-container": {
          width: "100%",

          "& .option-header": {
            width: "100%",

            "& .title": {
              maxWidth: "66%",
            },

            "& .option-values": {
              maxWidth: "28%",
            },
          },
        },
      },

      "& .option-actions-container": {
        "& .context-info-icon": {
          display: "none",
        },
        "& .context-dropdown-icon": {
          width: "24px",
          height: "24px",

          "& svg": {
            width: "100%",
            height: "100%",
          },
        },
      },

      "&:hover": {
        "& .option-meta-container": {
          width: "calc(100% - 82px)",

          "& .option-title-container": {
            "& .option-header": {
              "& .title": {},

              "& .option-values": {},
            },
          },
        },

        "& .option-actions-container": {
          "& .context-info-icon": {
            display: "flex",
          },
          "& .context-dropdown-icon": {},
        },
      },
    },

    "& .context-option-value-list": {
      width: "88%",
      paddingStart: "30px",

      "& .context-option-value": {
        width: "100%",

        "& .option-value-meta": {
          width: "100%",

          "& .title": {
            maxWidth: "76%",
          },
          "& .example": {
            flexGrow: 1,
          },
        },
        "& .option-value-actions": {
          "& .option-value-info-icon": {
            display: "none",
            width: "24px",
            height: "24px",

            "& svg": {
              width: "16px",
              height: "16px",
            },
          },
        },

        "&:hover": {
          "& .option-value-meta": {
            width: "calc(100% - 36px)",

            "& .title": {},
            "& .example": {},
          },
          "& .option-value-actions": {
            "& .option-value-info-icon": {
              display: "flex",
            },
          },
        },

        "& .context-option-value--popover": {
          "& .context-option-value": {
            width: "100%",

            "& .option-value-meta": {
              width: "100%",

              "& .title": {
                maxWidth: "76%",
              },
              "& .example": {
                flexGrow: 1,
              },
            },
            "& .option-value-actions": {
              "& .option-value-info-icon": {
                display: "none",
                width: "24px",
                height: "24px",

                "& svg": {
                  width: "16px",
                  height: "16px",
                },
              },

              "& .option-value-popover-icon": {
                width: "16px",
                height: "16px",

                "& svg": {
                  width: "100%",
                  height: "100%",
                },
              },
            },

            "&:hover": {
              "& .option-value-meta": {
                width: "calc(100% - 36px)",

                "& .title": {},
                "& .example": {},
              },
              "& .option-value-actions": {
                "& .option-value-info-icon": {
                  display: "flex",
                },
              },
            },
          },
        },
      },
    },
  },
  "select-option-btn": {
    padding: "0px",

    "& .option-example": {
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
}));
