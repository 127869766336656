// All the redux actions inside chatbot
export const ReduxChatbotActions = {
  ACTION_START_SESSION: "ACTION_START_SESSION",
  ACTION_END_SESSION: "ACTION_END_SESSION",
  ACTION_END_HANDOFF: "ACTION_END_HANDOFF",
  ACTION_UPDATE_CONVERSATION_STATE: "ACTION_UPDATE_CONVERSATION_STATE",
  ACTION_ADD_MESSAGE: "ACTION_ADD_MESSAGE",
  ACTION_REMOVE_MESSAGE: "ACTION_REMOVE_MESSAGE",
  ACTION_UPDATE_MESSAGE: "ACTION_UPDATE_MESSAGE",
  ACTION_ADD_PARTICIPANT: "ACTION_ADD_PARTICIPANT",
  ACTION_REMOVE_PARTICIPANT: "ACTION_REMOVE_PARTICIPANT",
  ACTION_UPDATE_PARTICIPANT: "ACTION_UPDATE_PARTICIPANT",
  ACTION_SELECT_PERSONA: "ACTION_SELECT_PERSONA",
  ACTION_SET_BOT_TYPE: "ACTION_SET_BOT_TYPE",
  ACTION_SET_BOT_SESSION: "ACTION_SET_BOT_SESSION",
  ACTION_SET_CONNECTION_STATE: "ACTION_SET_CONNECTION_STATE",
  ACTION_SET_RECONNECT: "ACTION_SET_RECONNECT",
};
