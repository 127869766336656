import BotDescription from "../BotDescription";
import { ReactComponent as ChatBotDisabledIcon } from "./assets/chat-bot-disabled-icon.svg";

const ChatBotDisabledState: React.FC = () => {
	return (
		<BotDescription
			imageComponent={<ChatBotDisabledIcon />}
			heading={"It’s bit quiet here"}
			subHeading={
				"Add sources to get Phi start providing answers to your's customer's questions 🤓"
			}
		/>
	);
};

export default ChatBotDisabledState;
