import { useBasicInfoStyles as useStyles } from "./style";
import ToggleButtons, {
  type ButtonOption,
} from "components/base/ToggleButtons/ToggleButtons";
import { ReactComponent as PhoneIncoming } from "assets/phone-inbound-icon.svg";
import { ReactComponent as PhoneOutgoing } from "assets/phone-outbound-icon.svg";
import { BoundType } from "api/bot/voice-bot-types";
import Typography from "components/base/Typography";
import { TextField } from "@mui/material";
import { GeneralInfoFields } from "./types";
import { useFormikContext } from "formik";
import { type GeneralInfoProps } from ".";

const input_box_placeholder =
  "E.g. AI Travel Assistant responsible for handling flight-related bookings, answering flight inquiries, managing cancellations, and providing real-time updates, ensuring seamless customer experiences for an online travel company.";
const welcome_message_input_label = "Instructions for welcome message";

const BasicInfo: React.FC = () => {
  const classes = useStyles();

  const { values, handleBlur, handleChange } =
    useFormikContext<GeneralInfoProps>();

  const button1: ButtonOption = {
    label: "Inbound call",
    id: BoundType.Inbound,
    icon: <PhoneIncoming />,
  };
  const button2: ButtonOption = {
    label: "Outbound call",
    id: BoundType.OutBound,
    icon: <PhoneOutgoing />,
    disabled: true,
  };

  return (
    <div className={classes.basicInfoContainer}>
      <div className="flex-col row-gap-8">
        <Typography className="w-semi-bold">Call Type</Typography>
        <ToggleButtons
          button1={button1}
          button2={button2}
          initialSelected={button1.id}
        />
      </div>

      <div className="flex-col row-gap-8">
        <Typography className="w-semi-bold">
          {welcome_message_input_label}
        </Typography>
        <TextField
          name={GeneralInfoFields.begin_message_ai_prompt}
          variant="outlined"
          size="small"
          placeholder={input_box_placeholder}
          fullWidth
          value={values.begin_message_ai_prompt}
          onChange={handleChange}
          onBlur={handleBlur}
          minRows={3}
          maxRows={10}
          multiline
        />
      </div>
    </div>
  );
};

export default BasicInfo;
