import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "pages/Bot/components/ContentHeader";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import Button from "components/base/Button";
import List from "./List";
// import NewAPIModal from "./NewAPIModal";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SampleAPIModal from "./SampleAPIModal";
import {
  type APIGroup,
  APIType,
  type IAPI,
  type Task,
} from "types/APIIntegration";
import { isEmpty, isEqual } from "lodash";
import {
  createMockAPIIntegration,
  getAllMockAPIs,
  getAPIIntegrationListV2,
  postCreateNewSampleAPI,
} from "api/workflow/integration";
import { useSnackbar } from "notistack";
import Banner, { BannerType } from "components/shared/Banner/Banner";

export const useStyles = makeStyles((theme: Theme) => ({
  "api-list-container": {
    "& .api-integrate-btn": {
      "& svg": {
        marginRight: "8px",
        width: "16px",
        height: "16px",

        "& path": {
          fill: "white",
        },
      },
    },
  },
  taskBannerContainer: {
    margin: "16px 24px 0px 24px",
  },
}));

const GEAR_ICON_GIF =
  "https://s3-alpha-sig.figma.com/img/ce52/4a2c/054fc2d7e6df4fdcdd530f19ea79a224?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WE7U-~Un9~sfQYsrVyu~CEurkN17N56qlJlU2G29LGiLWjGi3YjC0bFCPStEhW0-J-ncQV8YPXu-6CRNhPm~m-JfQ1jHJzAKoVD2ewzYFYH6SDKIR7zCv58PoX7OL9VVCL7zvDyP2GYAZ58hzak7rMMszdR0vS2qz1r0gjMKLwjAK3t8Ur5c~wdsG0-423t318AL6V-Zl9Z2Jy3~xcyn9ceqc~oJBkenPJQ4NQclifMHCixUrEqHagdJCQtgMSbwCwiApOFFMuOlW18~FSB-vFEzCIxci3~2yfQSODjzG1Wkqpd1XQNxnUrnNNWWLSj3vs4j1H32Q88HmTU2fNU8QA__";

const APIList: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { botId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sampleAPIs, setSampleAPIs] = useState<APIGroup[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [apiList, setAPIList] = useState<IAPI[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);

  const getList = (botId: string): void => {
    setLoading(true);
    getAPIIntegrationListV2(botId)
      .then((response) => {
        setAPIList(response?.apis ?? []);
        setTasks(response?.tasks ?? []);
      })
      .catch((error: any) => {
        enqueueSnackbar(`Something went wrong: ${error}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (botId) {
      getList(botId);
    }
  }, []);

  useEffect(() => {
    getAllMockAPIs(botId)
      .then((response) => {
        setSampleAPIs(response);
      })
      .catch((err) => {
        enqueueSnackbar(`Something went wrong: ${err}`, {
          variant: "error",
        });
      });
  }, []);

  const createNewSampleAPI = async (
    botId: string,
    describeObjective: string
  ): Promise<any> => {
    await postCreateNewSampleAPI(botId, describeObjective);
    getList(botId);
  };

  const handleNewAPIIntegration = async ({
    name,
    describeObjective,
    sampleAPIId = "",
    type = APIType.Standard,
    isNewSampleAPI = false,
  }: {
    name: string;
    describeObjective: string;
    sampleAPIId?: string;
    type?: APIType;
    isNewSampleAPI?: boolean;
  }): Promise<any> => {
    if (isEqual(type, APIType.Sample)) {
      if (!botId) return;
      try {
        if (isNewSampleAPI) {
          // describeObjective will never be empty in case of isNewSampleAPI handled in Modal
          await createNewSampleAPI(botId, describeObjective);
          return;
        }
        const response = await createMockAPIIntegration(botId, {
          id: sampleAPIId,
          description: describeObjective,
        });
        navigate(`./editor/${response?._id}`);
      } catch (err: any) {
        enqueueSnackbar(`Something went wrong: ${err}`, {
          variant: "error",
        });
      }
    } else {
      sessionStorage.setItem(
        "api-integration",
        JSON.stringify({ name, description: describeObjective })
      );
      navigate("./editor");
    }
  };

  return (
    <div className={classes["api-list-container"]}>
      <Header
        title={"API"}
        subtitle={
          "Integrate API to seamlessly use them in service nodes in workflow"
        }
      >
        <Button
          className="api-integrate-btn"
          color={"primary"}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <PlusIcon />
          Integrate New API
        </Button>
      </Header>
      {!isEmpty(tasks) && (
        <div className={classes.taskBannerContainer}>
          <Banner
            gif={GEAR_ICON_GIF}
            title="API Setup in Progress"
            subtitle="We're processing your prompt to set up the required API(s). This may take a few minutes. Feel free to check back later for the status."
            type={BannerType.Info}
          />
        </div>
      )}
      <div className="flex flex-col align-items-center">
        <List rows={apiList} loading={loading} />
      </div>

      {isModalOpen && (
        <SampleAPIModal
          open={isModalOpen}
          onProceed={handleNewAPIIntegration}
          sampleAPIs={sampleAPIs}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}

      {/* <NewAPIModal
          open={isModalOpen}
          onClickProceed={handleNewAPIIntegration}
          onClose={() => {
            setIsModalOpen(false);
          }}
        /> */}
    </div>
  );
};

export default APIList;
