import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useFormikContext } from "formik";
import { FormControlLabel } from "@mui/material";
import { type IServiceNodeForm } from "types/Workflow";
import Button from "components/base/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import clsx from "clsx";
// import { useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "store";
// import {
//   CreateNodeType,
//   NodeGroupType,
//   ResponseNodeType,
// } from "pages/Bot/WorkflowBuilder/config";
import { createPlaceholderNode } from "pages/Bot/WorkflowBuilder/utils/workflow";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useRef } from "react";
import {
  type INode,
  type IStepContentProps,
} from "pages/Bot/WorkflowBuilder/types";

const useStyles = makeStyles((theme) => ({
  "fallback-container": {
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiRadio-root": {
      paddingTop: "4px",
    },
  },
}));

const StepFallback: React.FC<IStepContentProps> = ({
  handleNextStep,
  handleEditFromKStep,
  completed,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const activeStep = useRef<number>(3);

  const { pointer, selectNode, nodes, edges } = useSelector(
    (state) => state.bot.workflow.workflow
  );
  const selectedNode = nodes.find((node: INode) => node.id === selectNode.id);

  const { values, handleChange, setFieldValue } =
    useFormikContext<IServiceNodeForm>();

  const handleClickContinue = (event: any): void => {
    event.preventDefault();

    if (values?.fallback?.type === "custom" && !values?.actions_map?.failure) {
      const fallbackNode = createPlaceholderNode({
        tNode: selectedNode,
        position: {
          x: selectedNode.position.x - 400,
          y: selectedNode.position.y + 80,
        },
      });

      const fallbackEdge = {
        type: "placeholder",
        label: "if response fails",
        id: `${selectedNode.id}=>${fallbackNode.id}`,
        source: selectedNode.id,
        target: fallbackNode.id,
        sourceHandle: `handle-source-${selectedNode.id}-3`,
      };

      // SET VALUE INTO FORM - values.fallback.actions_map
      void setFieldValue("actions_map", { failure: fallbackNode.id });

      // PUSH CUSTOM RESPONSE NODE INTO WORKFLOW
      dispatch({
        type: WorkflowActions.SET_WORKFLOW_NODES,
        payload: { pointer, nodes: [...nodes, fallbackNode] },
      });

      dispatch({
        type: WorkflowActions.SET_WORKFLOW_EDGES,
        payload: { edges: [...edges, fallbackEdge] },
      });
    }

    handleValidateAPIIntegration();
    handleNextStep(activeStep.current + 1);
  };

  const handleValidateAPIIntegration = (): void => {
    const { api_integration_id, test_api, input_variable_map } = values;

    if (api_integration_id && test_api?.length === input_variable_map?.length) {
      void setFieldValue("api_integration_valid", true);
    }
  };

  return (
    <div
      className={clsx(
        classes["fallback-container"],
        "flex flex-col align-items-stretch mt-2 row-gap-16"
      )}
    >
      <div className="flex flex-col col-gap-12 mb-8">
        <RadioGroup
          className="px-1 row-gap-8"
          aria-labelledby="auth-method"
          name={"fallback.type"}
          value={values?.fallback?.type}
          onChange={handleChange}
        >
          <FormControlLabel
            value="smart"
            disabled={completed}
            control={<Radio />}
            label={
              <div className="flex flex-col">
                <Typography className="w-semi-bold">Smart fallback</Typography>
                <Typography className="w-regular" sx={{ color: "#35312D" }}>
                  let system define the fallback based on the response from the
                  service. It is a standard workflow.
                </Typography>
              </div>
            }
          />
          <FormControlLabel
            value="custom"
            disabled={completed}
            control={<Radio />}
            label={
              <div className="flex flex-col">
                <Typography className="w-semi-bold">Custom fallback</Typography>
                <Typography className="w-regular" sx={{ color: "#35312D" }}>
                  This will allow you to add anode or another workflow as a
                  fallback opton
                </Typography>
              </div>
            }
          />
        </RadioGroup>
      </div>

      <div className="mt-16">
        {completed ? (
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              handleEditFromKStep(activeStep.current);
            }}
          >
            Edit
          </Button>
        ) : (
          <Button
            size="small"
            color={"primary"}
            disabled={!values?.fallback?.type}
            onClick={handleClickContinue}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepFallback;
