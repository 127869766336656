import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ReactComponent as UploadCloudIcon } from "assets/upload-cloud-icon.svg";
import { ReactComponent as LevelLogoLight } from "assets/level-logo-light.svg";
import { ReactComponent as PersonIcon } from "assets/person-icon.svg";
import { ReactComponent as BotIcon } from "assets/bot-icon.svg";
import { type FormikProps, useFormikContext } from "formik";
import { type IAppearanceSettings } from "../../types";
import { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { uploadBotLogoFile } from "api/bot/appearance";
import LinearProgress from "@mui/material/LinearProgress";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  "chat-widget-settings-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",

    "& .settings-row": {
      "& .form-input-container": {
        width: "44%",
        display: "flex",
        flexDirection: "column",
        rowGap: "6px",
      },

      "& .color-picker-wrapper": {
        borderRadius: "5px",
        border: "1px solid #E1DEDA",

        "& .color-picker": {
          display: "block",
          padding: "0px",
          height: "36px",
          width: "36px",
          cursor: "pointer",
          border: "none",

          "&::-webkit-color-swatch-wrapper": {
            padding: "0px",
            border: "none",
            borderRadius: "4px",
          },

          "&::-moz-color-swatch": {
            border: "none",
            borderRadius: "4px",
          },

          "&::-webkit-color-swatch": {
            border: "none",
            borderRadius: "4px",
          },
        },
      },

      "& .header-logo-container": {
        "& .header-logo": {
          display: "flex",
          width: "72px",
          height: "72px",
          padding: "8px 12px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--input-border-default, #E1DEDA)",
          background: "var(--input-background-default, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          "& .logo-content": {},
        },

        "& .header-logo-upload": {
          "& .header-upload-button": {
            "& svg": {
              width: "14px",
              height: "14px",

              "& path": {
                fill: "#C9671D",
              },
            },
          },
        },
      },

      "& .chat-bubble-logo-container": {
        "& .chat-bubble-logo": {
          display: "flex",
          width: "56px",
          height: "56px",
          padding: "16px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--border-default, #E1DEDA)",
          background: "#FFF",

          "&.select-chat-bubble-logo": {
            border: "1px solid #F07400",
          },

          "& .logo-content": {
            height: "24px",
            width: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "16px",
            background: "var(--inactive-surfaces-disabled, #F3F2F0)",

            "& svg": {
              width: "16px",
              height: "16px",
            },
          },
        },

        "& .chat-bubble-logo-upload": {
          "& .chat-bubble-upload-button": {
            "& svg": {
              width: "14px",
              height: "14px",

              "& path": {
                fill: "#C9671D",
              },
            },
          },
        },
      },
    },
  },
}));

interface ILogoState {
  file: File | null;
  preview: any | null;
  loading: boolean;
  url: string | null;
}

const ChatWidgetSettings: React.FC = () => {
  const classes = useStyles();
  const params = useParams();

  // NEED TO REFACTOR ON THE BOT INDEX PAGE TO STORE SETTINGS ON CENTRALIZE MANNER
  const { botId } = params || {};

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
  }: FormikProps<IAppearanceSettings> = useFormikContext();

  const headerLogoRef = useRef<any>();
  const chatBubbleLogoRef = useRef<any>();

  const [headerLogo, setHeaderLogo] = useState<ILogoState>({
    url: values.header_logo_url,
    file: null,
    preview: null,
    loading: false,
  });
  const [chatBubbleLogo, setChatBubbleLogo] = useState<ILogoState>({
    url: values.chat_bubble_url,
    file: null,
    preview: null,
    loading: false,
  });
  const [selectChatBubbleLogo, setSelectChatBubbleLogo] = useState(
    values.chat_bubble_url ? "custom" : "default"
  );
  const [headerAccentColor, setHeaderAccentColor] = useState(
    values?.header_accent_color ?? "#FF7D04"
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    values?.header_text_color ?? "#FFFFFF"
  );

  const handleHeaderLogoChange = useCallback(
    (event: any) => {
      const file = event.target.files[0];

      if (!botId || !file) return;

      const formData = new FormData();
      formData.append("header_logo_url", file);

      setHeaderLogo((state) => ({ ...state, loading: true }));
      uploadBotLogoFile(botId, formData)
        .then(async (response) => {
          setHeaderLogo((state) => ({
            ...state,
            loading: false,
            url: response.header_logo_url,
          }));
          await setFieldValue("header_logo_url", response.header_logo_url);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setHeaderLogo((state) => ({ ...state, loading: false }));
        });
    },
    [botId]
  );

  const handleChatBubbleLogoChange = useCallback(
    (event: any) => {
      const file = event.target.files[0];

      if (!botId || !file) return;

      const formData = new FormData();
      formData.append("chat_bubble_url", file);

      setChatBubbleLogo((state) => ({ ...state, loading: true }));
      uploadBotLogoFile(botId, formData)
        .then((response) => {
          setChatBubbleLogo((state) => ({
            ...state,
            loading: false,
            url: response.header_logo_url,
          }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setChatBubbleLogo((state) => ({ ...state, loading: false }));
        });
    },
    [botId]
  );

  const handleSelectChatBubbleLogo = useCallback(
    async (type: string) => {
      setSelectChatBubbleLogo(type);

      if (type === "default") {
        // DEFAULT
        await setFieldValue("chat_bubble_url", "default");
      }

      if (type === "custom") {
        // CUSTOM
        await setFieldValue("chat_bubble_url", chatBubbleLogo.url);
      }
    },
    [selectChatBubbleLogo, chatBubbleLogo]
  );

  const handleChangeAccentColor = useCallback(async (event: any) => {
    setHeaderAccentColor(event.target.value);
    await setFieldValue("header_accent_color", event.target.value);
  }, []);

  const handleChangeHeaderTextColor = useCallback(async (event: any) => {
    setHeaderTextColor(event.target.value);
    await setFieldValue("header_text_color", event.target.value);
  }, []);

  return (
    <div className={classes["chat-widget-settings-container"]}>
      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Have an avatar/logo in header
          </Typography>
          <RadioGroup
            row
            aria-labelledby="header_logo_enabled"
            name="header_logo_enabled"
            value={values?.header_logo_enabled}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">Accent Color</Typography>
          <div className="flex justify-content-start align-items-center col-gap-8">
            <div className="color-picker-wrapper">
              <input
                type="color"
                className="color-picker"
                value={headerAccentColor}
                onChange={(event) => {
                  void handleChangeAccentColor(event);
                }}
              />
            </div>

            <TextField
              name="header_accent_color"
              variant="outlined"
              size="small"
              placeholder=""
              fullWidth
              value={headerAccentColor}
              onChange={(event) => {
                void handleChangeAccentColor(event);
              }}
              onBlur={handleBlur}
              error={
                touched.header_accent_color &&
                Boolean(errors.header_accent_color)
              }
            />
          </div>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Avatar/logo in the header
          </Typography>

          <div className="header-logo-container flex justify-content-start align-items-center col-gap-24">
            <div className="header-logo">
              <div className="logo-content">
                {headerLogo?.url ? (
                  <img
                    src={headerLogo?.url}
                    alt="header-logo"
                    width="40px"
                    height="40px"
                  />
                ) : (
                  <LevelLogoLight />
                )}
              </div>
            </div>

            <div className="header-logo-upload">
              {headerLogo?.loading ? (
                <LinearProgress sx={{ width: "100px" }} />
              ) : (
                <>
                  <div
                    className="header-upload-button flex justify-content-start align-items-center col-gap-4 cursor-pointer"
                    onClick={() => headerLogoRef.current.click()}
                  >
                    <input
                      type="file"
                      ref={headerLogoRef}
                      style={{ display: "none" }}
                      onChange={handleHeaderLogoChange}
                    />
                    <UploadCloudIcon />
                    <Typography className="w-bold" sx={{ color: "#C9671D" }}>
                      Upload
                    </Typography>
                  </div>
                  <Typography
                    variant="textSm"
                    className="w-medium"
                    sx={{ color: "#7C7972" }}
                  >
                    Max 2 MB, 40x40 px
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Color of text in header
          </Typography>
          <div className="flex justify-content-start align-items-center col-gap-8">
            <div className="color-picker-wrapper">
              <input
                type="color"
                className="color-picker"
                value={headerTextColor}
                onChange={(event) => {
                  void handleChangeHeaderTextColor(event);
                }}
              />
            </div>

            <TextField
              name="header_text_color"
              variant="outlined"
              size="small"
              placeholder=""
              fullWidth
              value={headerTextColor}
              onChange={(event) => {
                void handleChangeHeaderTextColor(event);
              }}
              onBlur={handleBlur}
              error={
                touched.header_text_color && Boolean(errors.header_text_color)
              }
            />
          </div>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Have an avatar/logo in-front of chat bubble
          </Typography>
          <RadioGroup
            row
            aria-labelledby="chat_bubble_enabled"
            name="chat_bubble_enabled"
            value={values.chat_bubble_enabled}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Select Avatar/logo in-front of chat bubble
          </Typography>

          <div className="chat-bubble-logo-container flex justify-content-start align-items-center col-gap-16">
            <div
              className={clsx("chat-bubble-logo default-logo cursor-pointer", {
                "select-chat-bubble-logo": selectChatBubbleLogo === "default",
              })}
              onClick={() => {
                void handleSelectChatBubbleLogo("default");
              }}
            >
              <div className="logo-content">
                <BotIcon />
              </div>
            </div>
            <div
              className={clsx("chat-bubble-logo custom-logo cursor-pointer", {
                "select-chat-bubble-logo": selectChatBubbleLogo === "custom",
              })}
              onClick={() => {
                void handleSelectChatBubbleLogo("custom");
              }}
            >
              <div className="logo-content">
                {chatBubbleLogo?.url ? (
                  <img
                    src={chatBubbleLogo?.url}
                    alt="chat-bubble-logo"
                    width="16px"
                    height="16px"
                  />
                ) : (
                  <PersonIcon />
                )}
              </div>
            </div>

            <div className="chat-bubble-logo-upload">
              {chatBubbleLogo?.loading ? (
                <LinearProgress sx={{ width: "100px" }} />
              ) : (
                <>
                  <div
                    className="chat-bubble-upload-button flex justify-content-start align-items-center col-gap-4 cursor-pointer"
                    onClick={() => chatBubbleLogoRef.current.click()}
                  >
                    <input
                      type="file"
                      ref={chatBubbleLogoRef}
                      style={{ display: "none" }}
                      onChange={handleChatBubbleLogoChange}
                    />
                    <UploadCloudIcon />
                    <Typography className="w-bold" sx={{ color: "#C9671D" }}>
                      Upload
                    </Typography>
                  </div>
                  <Typography
                    variant="textSm"
                    className="w-medium"
                    sx={{ color: "#7C7972" }}
                  >
                    Max 2 MB, 40x40 px
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWidgetSettings;
