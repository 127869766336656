import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
  type CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "components/base/Typography";

interface ICircularProgressWithLabel extends CircularProgressProps {
  value: number;
  progressColor?: string;
}

const CircularProgressWithLabel: React.FC<ICircularProgressWithLabel> = ({
  value,
  progressColor = "#78C7E7",
  ...otherProps
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#EDEBE9",
        }}
        value={100}
        {...otherProps}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: progressColor,
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        value={value}
        {...otherProps}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
