import { useCallback } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { type IFilter } from "types/BotTree";
import { dateRangeOptions, sortMetricOptions } from "pages/BotTree/config";

const useStyles = makeStyles((theme) => ({
  "filter-container": {
    display: "flex",
    flexDirection: "row",
    columnGap: "20px",
    padding: "8px 24px",
  },
  "filter-input-container": {
    width: "228px",
  },
}));

interface Props {
  filters: IFilter;
  onChangeFilter: (updatedFilter: IFilter) => void;
}

const FilterUI: React.FC<Props> = ({ filters, onChangeFilter }) => {
  const classes = useStyles();

  const handleChangeFilter = useCallback((key: string, value: string) => {
    onChangeFilter({
      ...filters,
      [key]: value,
    });
  }, []);

  return (
    <Box className={classes["filter-container"]}>
      <Select
        labelId="date-range-filter"
        id="date-range-filter"
        className={classes["filter-input-container"]}
        value={filters.dateRange}
        onChange={(event) => {
          handleChangeFilter("dateRange", event.target.value);
        }}
        displayEmpty
      >
        {dateRangeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <Select
        labelId="sort-metric-filter"
        id="sort-metric-filter"
        className={classes["filter-input-container"]}
        value={filters.sortMetric}
        onChange={(event) => {
          handleChangeFilter("sortMetric", event.target.value);
        }}
        displayEmpty
      >
        {sortMetricOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FilterUI;
