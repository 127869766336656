export enum AssistantType {
  Chat = "WEBCHAT_TEXT",
  Voice = "VOICE",
}

export enum ChatAssistantStatus {
  READY = "READY",
  IN_PROGRESS = "IN_PROGRESS",
  NO_INDEX = "NO_INDEX",
}

export enum AssistantResponseType {
  CONCISE = "concise",
  MEDIUM = "medium",
  VERBOSE = "verbose",
}

export enum RadioValueType {
  YES = "yes",
  NO = "no",
}

export enum WidgetStateType {
  OPEN = "open",
  CLOSED = "closed",
}

export enum ChatAssistantNoResponseType {
  END_CONVERSATION = "end_conversation",
  AGENT_HANDOFF = "agent_handoff",
  END_CONVERSATION_CREATE_TICKET = "end_conversation_create_ticket",
}

export interface IceBreakerAction {
  id: string;
  name: string;
}

export interface AssistantIceBreaker {
  id: number;
  action: IceBreakerAction | null;
  created: string;
  modified: string;
  title: string;
}

export interface IChannelSettins {
  enabled_channels: AssistantType[];
}

export interface IChatbotSettings {
  bot_display_name: string;
  enabled_channels: AssistantType[];
  header_message: string;
  welcome_message: string;
  input_box_placeholder: string;
  bot_response_length: AssistantResponseType;
  show_source_of_info: RadioValueType;
  request_feedback: RadioValueType;
  display_nudge: RadioValueType;
  nudge_message: string;
  default_widget_state: WidgetStateType;
  no_bot_response: ChatAssistantNoResponseType;
  status_banner: RadioValueType;
  end_conversation_msg: string;
  end_user_type: string;
  // avatar: string;
  accent_color: string;
  suggestions: AssistantIceBreaker[];
}

export interface AssistantSettings {
  _id: string;
  assistant_id: string | null;
  channel_settings: IChannelSettins;
  chatbot_settings: IChatbotSettings;
  created: string;
  modified: string;
}

export interface IAssistant {
  _id: string;
  status?: ChatAssistantStatus;
  created: string;
  modified: string;
  name: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
  settings?: AssistantSettings;
  general_info?: {
    goal: string | null;
    guidelines_and_rules: string | null;
    company_info_text: string | null;
  };
}
