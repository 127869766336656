import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 24px 16px 24px",
    boxShadow: "0px 1px 0px 0px #E1DEDA",
    borderBottom: "1px solid var(--border-default, #E1DEDA)",
    flexShrink: 0,
  },
  IconAndtitle: {
    display: "flex",
    gap: "16px",
  },
  title: {
    color: "var(--fg-text-default, #282624)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px",
  },
  content: {
    flex: 1,
    overflowY: "auto",
    padding: "24px",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    borderTop: "1px solid var(--border-default, #E1DEDA)",
    flexShrink: 0,
    display: "flex",
    height: "72px",
    padding: "16px 24px",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
  },
  configureBtn: {
    width: "100%",
  },
  descriptionHead: {
    color: "var(--text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  example: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  fieldTitle: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  defaultInput: {
    display: "flex",
    width: "100%",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary, #E1DEDA)",
    background: "var(--dropdown-background-default, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  noteContainer: {
    display: "flex",
    gap: "4px",
    width: "100%",
  },
  note: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  noteIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding:
      "var(--spacing-vertical-padding---default, 8px) var(--spacing-horizontal-padding---default, 12px)",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "var(--radius-border-radius, 8px)",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--bg-disabled, #F9F8F8)",
  },
  inputValue: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
}));

export default useStyles;
