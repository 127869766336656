import { Handle, Position } from "reactflow";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
// import usePlaceholderClick from "pages/Bot/WorkflowBuilder/hooks/usePlaceholderNodeClick";
// import { useSelector } from "store";
import Typography from "components/base/Typography";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import NodeContainer from "./NodeContainer";
import { type INodeProps, type NodeRenderProps } from "../../types";

const useStyles = makeStyles((theme) => ({
  "placeholder-node-wrapper": {
    display: "inline-flex",
    padding:
      "var(--spacing-spacing-xxs-4-px, 4px) var(--spacing-spacing-md-16-px, 16px)",
    justifyContent: "center",
    alignItems: "center",
    gap: "var(--spacing-spacing-xs-8-px, 8px)",
    borderRadius: "var(--radius-border-radius, 8px)",
    border: "1px dashed var(--border-default, #E1DEDA)",
    background: "var(--actionable-surfaces-secondary-default, #FFF)",
    cursor: "pointer",

    "& .node-content": {
      "& svg": {
        "& path": {
          fill: "#282624",
        },
      },
    },

    "& .handle": {
      visibility: "hidden",
    },
  },
  placeholder: {
    background: "#fff",
    border: "1px dashed #bbb",
    color: "#bbb",
    boxShadow: "none",
  },
}));

const NodeComponent: React.FC<INodeProps & NodeRenderProps> = ({
  id,
  type,
  data,
  onNodeClick,
}) => {
  const classes = useStyles();

  // const { pointer } = useSelector((state) => state.bot.workflow.workflow);
  // const { onDrop } = usePlaceholderClick();

  return (
    <div
      title="click to add a node"
      // onDrop={(event) => {
      //   onDrop(event, id, pointer);
      // }}
      onClick={(event) => {
        onNodeClick(event);
      }}
      className={clsx(classes["placeholder-node-wrapper"])}
    >
      {/* Handle - Target Clockwise: For dynamic connection */}
      <Handle
        className="handle"
        type="target"
        position={Position.Top}
        id={`handle-target-${id}-0`}
      />
      <Handle
        className="handle"
        type="target"
        position={Position.Right}
        id={`handle-target-${id}-1`}
      />
      <Handle
        className="handle"
        type="target"
        position={Position.Bottom}
        id={`handle-target-${id}-2`}
      />
      <Handle
        className="handle"
        type="target"
        position={Position.Left}
        id={`handle-target-${id}-3`}
      />

      <div className="node-content flex align-items-center col-gap-8">
        <PlusIcon />
        <Typography className="w-semi-bold">Add</Typography>
      </div>
    </div>
  );
};

const PlaceholderNode: React.FC<INodeProps> = (props) => {
  return (
    <NodeContainer
      id={props.id}
      type={props.type}
      renderNode={(nodeRenderProps: NodeRenderProps) => (
        <NodeComponent {...props} {...nodeRenderProps} />
      )}
    />
  );
};

export default PlaceholderNode;
