import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAssistantHeaderStyles = makeStyles((theme: Theme) => ({
  assistantHeaderContainer: {
    position: "fixed",
    display: "flex",
    width: "calc(100% - 72px)",
    padding: "12px 32px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
    zIndex: 1000,

    "& .back-button": {},

    "& .assistant-details-container": {
      "& span": {
        lineHeight: "1",
      },
    },
  },
}));
