import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    padding: "16px 24px",
  },
}));

interface Props {
  title: string;
  subtitle: string;
}

const Header: React.FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="textXl" className="w-bold" sx={{ color: "#282624" }}>
        {title}
      </Typography>
      <Typography variant="textSm" sx={{ color: "#6D6D6D" }}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
