import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import BotHeader from "./components/BotHeader";
import BotNavigation from "./components/BotNavigation";
import { type INavigationCategory } from "./types";
// import { isEqual } from "lodash";
import { type IBot } from "types/BotType";
// import clsx from "clsx";
// import ChatBotContainer from "./ChatBotContainer";
import BotContext from "./BotContext";

const useStyles = makeStyles((theme: Theme) => ({
  botLayoutContainer: {
    height: "100vh",
    width: "100%",

    "& .bot-main-container": {
      height: "calc(100vh - 72px)",
      width: "100%",
      position: "relative",
      top: "72px",

      "& .bot-main-content": {
        position: "relative",
        left: "188px",
        height: "100%",
        width: "calc(100% - 188px)",
        overflowX: "auto",
        display: "flex",

        "& .main-outlet-container": {
          height: "100%",
          width: "100%",
          minWidth: "1180px",
          overflowY: "auto",
        },
      },
    },
  },
  chatbotWidgetContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F3F2F0",

    [theme.breakpoints.up("xl")]: {
      minWidth: "480px",
      maxWidth: "600px",
    },

    [theme.breakpoints.down("xl")]: {
      backgroundColor: "transparent",
      position: "fixed",
      right: 0,
      bottom: 0,
      zIndex: "1000",
      justifyContent: "flex-end",
    },
  },
}));

interface Props {
  botData: IBot;
  navigations: INavigationCategory[];
}

const Bot: React.FC<Props> = ({ botData, navigations }) => {
  const classes = useStyles();

  const contextValue = {
    id: botData.id,
    name: botData.name,
    description: botData.description,
    settings: botData?.settings,
  };

  return (
    <BotContext.Provider value={{ ...contextValue }}>
      <div className={classes.botLayoutContainer}>
        <BotHeader title={botData?.name} description={botData?.description} />

        <div className="bot-main-container">
          <BotNavigation navigations={navigations} />

          <div className="bot-main-content">
            <div className="main-outlet-container">
              <Outlet />
            </div>

            {/* {isEqual(
              botData?.settings?.enabled_channels?.[0],
              BotType.VoiceBot
            ) ? null : (
              <div className={clsx(classes.chatbotWidgetContainer)}>
                <ChatBotContainer botData={botData} />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </BotContext.Provider>
  );
};

export default Bot;
