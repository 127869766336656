import {
  // type ICarousel,
  // type IFieldMap,
  type IEdge,
  type INode,
  // type INodePosition,
} from "types/Workflow";
import {
  NodeGroupType,
  // ContextVariableConfig,
  // ContextVariable,
  EdgeType,
  BotPromptNodeType,
  // FieldType,
  // ResponseNodeType,
  ServiceNodeType,
  // APIServiceNodeConfig,
  // ResponseNodeConfig,
  // BotPromptNodeConfig,
  LogicNodeType,
  CreateNodeType,
  TriggerNodeType,
} from "../config";
import {
  // getIncomers,
  // type Edge,
  type Node,
  // getOutgoers,
  // getConnectedEdges,
} from "reactflow";
// import { type ISessionContext } from "types/PreviewSession";
import { type IBranch } from "../types";

export const getTerminalNodes = (nodes: INode[], edges: IEdge[]): any => {
  const placeholderEdges = edges.filter(
    (edge) => edge.type === EdgeType.PLACEHOLDER_EDGE
  );

  const getNode = (nodeId: string): any => {
    return nodes.find((node: INode) => node.id === nodeId);
  };

  const terminalNodes = placeholderEdges.map((edge: IEdge) => {
    const sourceNode = getNode(edge.source);
    const targetNode = getNode(edge.target);

    if (sourceNode && targetNode) {
      return {
        id: sourceNode.id,
        terminalType: targetNode?.type,
      };
    }

    return {
      id: "",
      terminalType: "",
    };
  });

  return terminalNodes;
};

export const formatRequestNodes = (nodes: INode[], edges: IEdge[]): INode[] => {
  const terminalNodes = getTerminalNodes(nodes, edges);

  return nodes
    .filter(
      (node: Node) =>
        node.type !== CreateNodeType.PLACEHOLDER_NODE &&
        node.type !== CreateNodeType.BRANCH_ADD_NODE
    )
    .map((node: Node) => {
      const terminal = terminalNodes.find(
        (n: { id: string; terminalType: string }) => n.id === node.id
      );

      if (node.type === TriggerNodeType.TRIGGER) {
        return {
          id: node.id,
          type: node.type,
          position: node.position,
          width: node?.width,
          height: node?.height,
          data: {
            name: node.data.name,
            group: node.data.group,
            dialog_prompt: node.data.dialog_prompt,
            ui: {
              ...node?.data?.ui,
              terminal: Boolean(terminal?.id),
              terminalType: terminal?.terminalType,
            },
          },
        };
      }

      if (node.type === ServiceNodeType.API_SERVICE_NODE) {
        const output_variable = {
          key: node.data?.output_variable?.key,
          value: node.data?.output_variable?.value,
          description: node.data?.output_variable?.description,
        };

        const updatedNode: any = {
          id: node.id,
          type: node.type,
          position: node.position,
          width: node?.width,
          height: node?.height,
          data: {
            name: node.data.name,
            group: node.data.group,
            description: node.data?.description,
            api_integration_id: node.data?.api_integration_id,
            input_variable_map: node?.data?.input_variable_map,
            output_variable,
            // actions_map: node.data?.actions_map,
            fallback: node.data?.fallback,
            ui: {
              ...node?.data?.ui,
              terminal: Boolean(terminal?.id),
              terminalType: terminal?.terminalType,
              stepsState: node?.data?.ui?.stepsState,
            },
          },
        };

        if (node?.data?.actions_map) {
          updatedNode.data.actions_map = node.data.actions_map;
        }

        return updatedNode;
      }

      if (node.type === BotPromptNodeType.CAROUSEL) {
        const button1Node = nodes.find(
          (n) => n.id === node.data?.actions_map?.button_1
        );
        const button2Node = nodes.find(
          (n) => n.id === node.data?.actions_map?.button_2
        );

        const actions_map = {
          button_1:
            button1Node?.id &&
            button1Node?.data.group === NodeGroupType.CREATE_NODE
              ? null
              : button1Node?.id,
          button_2:
            button2Node?.id &&
            button1Node?.data.group === NodeGroupType.CREATE_NODE
              ? null
              : button2Node?.id,
        };

        return {
          id: node.id,
          type: node.type,
          position: node.position,
          width: node?.width,
          height: node?.height,
          data: {
            ...node.data,
            actions_map,
            fields_map: node.data.fields_map,
            ui: {
              ...node?.data?.ui,
              terminal: Boolean(terminal?.id),
              terminalType: terminal?.terminalType,
              button_1: {
                type: button1Node?.type,
              },
              button_2: {
                type: button2Node?.type,
              },
            },
          },
        };
      }

      if (node.type === LogicNodeType.BRANCH) {
        const branches = node.data.branches.map(
          (branch: IBranch, index: number) => {
            console.log(branch.rootNodeId);
            const rootNode = nodes.find((n) => n.id === branch.rootNodeId);
            console.log(rootNode);

            // Need to save null rootId for branching node
            const root_node_id =
              rootNode?.data?.group === NodeGroupType.CREATE_NODE
                ? null
                : rootNode?.id;

            return {
              id: branch.id,
              name: branch.name,
              conditions: branch?.conditions,
              root_node_id,
            };
          }
        );

        const defaultRootNode = nodes.find(
          (n) => n.id === node.data?.default?.rootNodeId
        );
        const defaultRootNodeId =
          defaultRootNode?.data?.group === NodeGroupType.CREATE_NODE
            ? null
            : defaultRootNode?.id;

        const default_branch = {
          id: node.data.default.id,
          name: node.data.default.name,
          conditions: node.data.default.conditions,
          root_node_id: defaultRootNodeId,
        };

        return {
          id: node.id,
          type: node.type,
          position: node.position,
          width: node?.width,
          height: node?.height,
          data: {
            ...node.data,
            branches,
            default: default_branch,
            ui: {
              ...node?.data?.ui,
              terminal: Boolean(terminal?.id),
              terminalType: terminal?.terminalType,
            },
          },
        };
      }

      return {
        id: node.id,
        type: node.type,
        position: node.position,
        width: node?.width,
        height: node?.height,
        data: {
          ...node.data,
          ui: {
            ...node?.data?.ui,
            terminal: Boolean(terminal?.id),
            terminalType: terminal?.terminalType,
          },
        },
      };
    });
};

const formatRequestEdges = (edges: IEdge[]): IEdge[] => {
  return edges.filter((edge) => edge.type !== EdgeType.PLACEHOLDER_EDGE);
};

export const formatWorkflowRequest = ({
  _id,
  botId,
  title,
  description,
  pointer,
  nodes,
  edges,
}: {
  _id?: string;
  botId?: string;
  title: string;
  description: string;
  pointer: number;
  nodes: INode[];
  edges: IEdge[];
}): {
  _id?: string;
  bot_id?: string;
  title: string;
  description: string;
  pointer: number;
  nodes: INode[];
  edges: IEdge[];
} => {
  const formattedNodes = formatRequestNodes(nodes, edges);
  const formattedEdges = formatRequestEdges(edges);

  return {
    _id,
    bot_id: botId,
    title,
    description,
    pointer,
    nodes: formattedNodes,
    edges: formattedEdges,
  };
};
