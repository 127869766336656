import { useParams } from "react-router-dom";
import useBotStatus from "hooks/useBotStatus";
import ApiError from "components/shared/APIError";
import GlobalSpinner from "components/shared/GlobalSpinner";
import { ChatBotNavigationItems, VoiceBotNavigationItems } from "./config";
import { Box } from "@mui/material";
import Bot from "pages/Bot";
import { BotType } from "types/BotType";
import isEqual from "lodash/isEqual";

const BotContainer: React.FC = () => {
  const params = useParams();

  const { botId } = params || {};

  const { botData, error, onRetry } = useBotStatus({ botId });

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <ApiError message={error.message} onRetry={onRetry} />
      </Box>
    );
  }

  if (!botData) {
    return <GlobalSpinner />;
  }

  return (
    <Bot
      botData={botData}
      // botName={botData?.name}
      // description={botData?.description}
      navigations={
        isEqual(botData?.settings?.enabled_channels[0], BotType.VoiceBot)
          ? VoiceBotNavigationItems
          : ChatBotNavigationItems
      }
    />
  );
};

export default BotContainer;
