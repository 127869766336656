import { makeStyles } from "@mui/styles";
import AddSource from "./components/AddSource";
import SourceList from "./components/SourceList";
import Header from "../components/ContentHeader";

const useStyles = makeStyles((theme) => ({
  "knowledge-bot-container": {
    "& .content-wrapper": {
      display: "flex",
      flexDirection: "column",
      padding: "24px 32px",
      gap: "32px",
    },
  },
}));

const Source = (): React.JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes["knowledge-bot-container"]}>
      <div className="header">
        <Header
          title="Knowledge bot"
          subtitle="Add sources and train your bot on knowledge sources"
        >
          {/* <Button color="primary" size="small" endIcon={<SaveIcon />}>
            Save
          </Button> */}
        </Header>
      </div>

      <div className="content-wrapper">
        <AddSource />
        <SourceList />
      </div>
    </div>
  );
};

export default Source;
