import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "components/base/Typography";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import Button from "components/base/Button";
import { ReactComponent as ArrowNarrowRight } from "assets/arrow-narrow-right.svg";

const useStyles = makeStyles((theme: Theme) => ({
  "new-workflow-dialog": {
    "& .dialog-container": {
      width: "532px",
    },

    "& .action-btn": {
      "& svg": {
        heigth: "16px",
        width: "16px",
        marginLeft: "8px",

        "& path": {
          fill: "white",
        },
      },

      "&:disabled": {
        "& svg": {
          "& path": {
            fill: "#B5B1AD",
          },
        },
      },
    },
  },
}));

const validationSchema = yup.object({
  title: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(30, "Maximum 30 characters allowed")
    .required("This is a required field"),
  description: yup.string(),
});

interface Props {
  open: boolean;
  onClickProceed: ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => void;
  loading: boolean;
  onClose: () => void;
}

const ComposeWorkflowModal: React.FC<Props> = ({
  open,
  onClickProceed,
  loading,
  onClose,
}) => {
  const classes = useStyles();

  const initialValues = {
    title: "",
    description: "",
  };

  return (
    <Dialog
      maxWidth={false}
      className={classes["new-workflow-dialog"]}
      onClose={onClose}
      open={open}
    >
      <div className="dialog-container flex flex-col row-gap-16">
        <div className="dialog-header flex justify-content-between">
          <Typography variant="textXl" className="w-semi-bold">
            Workflow
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="api-integration-form-container">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              onClickProceed(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              touched,
              errors,
              isValid,
              dirty,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              handleChange,
            }) => {
              return (
                <Form className="flex flex-col row-gap-24">
                  <div className="form-input-container flex flex-col row-gap-6">
                    <Typography className="w-semi-bold">Title</Typography>
                    <TextField
                      name="title"
                      variant="outlined"
                      size="small"
                      placeholder="Eg. Customer workflow"
                      fullWidth
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </div>

                  <div className="form-input-container flex flex-col row-gap-6">
                    <Typography className="w-semi-bold">
                      Description for this workflow
                    </Typography>
                    <TextField
                      name="description"
                      variant="outlined"
                      placeholder="Eg. This is workflow for customer agent chatbot"
                      multiline
                      rows={4}
                      fullWidth
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </div>

                  <div className="form-action">
                    <Button
                      className="action-btn"
                      type="submit"
                      color={"primary"}
                      size="large"
                      loading={loading}
                      disabled={!(isValid && dirty)}
                    >
                      Proceed
                      <ArrowNarrowRight />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
};

export default ComposeWorkflowModal;
