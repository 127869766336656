import { getTenantName } from "utils/helpers";
import { botBEaxiosInstance } from "api/bot";
import { type IBotTree, type IFilter } from "types/BotTree";
import { formatDateRange } from "utils/dateUtils";
import cloneDeep from "lodash/cloneDeep";
import { formatTree } from "utils/miscellaneous";

export const getBotTreeData = async (
  token: any,
  filters: IFilter
): Promise<IBotTree> => {
  const formattedDateRange = formatDateRange(Number(filters.dateRange));

  const data = {
    start_date: formattedDateRange.startDate,
    end_date: formattedDateRange.endDate,
    sort_metric: filters.sortMetric,
  };

  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "POST",
      url: `/bot_optim/get_tree_data/`,
      withCredentials: true,
      data: data,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const _response = cloneDeep(response.data);
        const { nodes, edges } = formatTree({
          nodes: _response.nodes,
          edges: _response.edges,
        });

        resolve({ nodes, edges });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
