import { useCallback, useState } from "react";
import ColoredSwitch from "../../../../components/base/ColoredSwitch";
import CenteredLoader from "../../../../components/shared/CenteredLoader";
import { Box } from "@mui/material";
import { patchBotAction } from "../../../../api/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ReduxActionActions } from "../../../../store/reduxActions/ReduxActionActions";

interface ToggleActionProps {
  enabled: boolean;
  id: string;
}

const ToggleAction: React.FC<ToggleActionProps> = ({ enabled, id }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.app_user.token);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [showLoader, setShowLoader] = useState(false);

  const alterActionStatus = useCallback(async () => {
    await patchBotAction(id, { enabled: !enabled }, token)
      .then((response) => {
        dispatch({
          type: ReduxActionActions.PUT_ACTION_SUCCESS,
          payload: response,
        });
        enqueueSnackbar(
          `Action ${response.name} ${
            response.enabled ? "enabled" : "disabled"
          }`,
          {
            variant: "success",
          }
        );
      })
      .catch(() => {
        enqueueSnackbar("Some error occurred. Please try again", {
          variant: "error",
        });
      })
      .finally(() => {
        setShowLoader(false);
      });
  }, [dispatch, enabled, enqueueSnackbar, id, token]);

  const handleClickToggle = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      setShowLoader(true);

      await alterActionStatus();
    },
    [alterActionStatus]
  );

  return (
    <>
      {showLoader ? (
        <Box display="flex" justifyContent="start">
          <CenteredLoader />
        </Box>
      ) : (
        <ColoredSwitch
          checked={enabled}
          onClick={(e) => {
            void handleClickToggle(e);
          }}
        />
      )}
    </>
  );
};

export default ToggleAction;
