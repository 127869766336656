import { botAxiosInstance } from "services/axios";
import type BotSettings from "types/BotSettings";
// import { type PostBotSettings } from "types/BotSettings";

export const getBotSettings = async (botId: string): Promise<BotSettings> => {
  return await botAxiosInstance({
    method: "GET",
    url: `/bot/${botId}/settings`,
  });
};

// TODO: Update types of these appearance and all settings
export const postBotSettings = async (
  botId: string,
  values: any
): Promise<any> => {
  return await botAxiosInstance({
    method: "PUT",
    url: `/bot/${botId}/settings`,
    data: values,
  });
};

export const updateBotSettings = async (
  botId: string,
  values: any
): Promise<any> => {
  return await botAxiosInstance({
    method: "PUT",
    url: `/bot/${botId}/settings`,
    data: values,
  });
};

export const uploadBotLogoFile = async (
  botId: string,
  data: any
): Promise<any> => {
  return await botAxiosInstance({
    method: "POST",
    url: `/bot/${botId}/logo`,
    data,
  });
};
