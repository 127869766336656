import { Box, type BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	disabled: {
		pointerEvents: "none",
		cursor: "initial",
		opacity: 0.5,
	},
}));

interface Props extends Omit<BoxProps, "className"> {
	readonly: boolean;
}

const BlurredBox: React.FC<Props> = ({ children, readonly, ...boxProps }) => {
	const classes = useStyles();

	return (
		<Box
			className={clsx({ [classes.disabled]: readonly })}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...boxProps}
		>
			{children}
		</Box>
	);
};

export default BlurredBox;
