import { type AssistantType, type IAssistant } from "types/assistant.types";
import { botBEAxiosInstance } from "services/axios";
import { formatAssistantRequest } from "./transformers";

export const createAssistant = async (data: {
  name: string;
  description: string;
  enabled_channels: [AssistantType];
}): Promise<{ data: { assistant: IAssistant }; status: string }> => {
  const body = formatAssistantRequest({ ...data });

  return await botBEAxiosInstance({
    method: "POST",
    url: `/assistants`,
    data: body,
  });
};

export const getAssistantList = async ({
  params,
}: {
  params: string;
}): Promise<{ data: { assistants: IAssistant[] }; status: string }> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/assistants?${params}`,
  });
};

export const getAssistant = async (
  assistantId: string
): Promise<{ data: { assistant: IAssistant }; status: string }> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/assistants/${assistantId}`,
  });
};

export const updateAssistant = async (
  assistantId: string,
  name: string,
  description: string
): Promise<IAssistant> => {
  const body = formatAssistantRequest({ name, description });

  return await botBEAxiosInstance({
    method: "PUT",
    url: `/assistants/${assistantId}`,
    data: body,
  });
};

export const patchAssistant = async (
  assistantId: string,
  // data: Partial<IAssistant>
  data: any
): Promise<{ data: { assistant: IAssistant }; status: string }> => {
  return await botBEAxiosInstance({
    method: "PATCH",
    url: `/assistants/${assistantId}`,
    data,
  });
};

export const deleteAssistant = async (assistantId: string): Promise<any> => {
  return await botBEAxiosInstance({
    method: "DELETE",
    url: `/assistants/${assistantId}`,
  });
};
