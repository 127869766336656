import Button from "components/base/Button";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import Modal from "components/base/Modal/Modal";
import { useContext, useState } from "react";
import Typography from "components/base/Typography";
import { TextField } from "@mui/material";
import { createAgent } from "api/bot/bot-agents";
import BotContext from "../BotContext";
import { BotType } from "types/BotType";

const create_agent_modal_title = "Create a new Agent";
const create_agent_name_label = "Agent Name";
const create_agent_name_placeholder = "E.g. Travel operations voice bot";
const create_agent_description_label = "Short description for reference";
const create_agent_description_placeholder =
  "Eg. Helps customer know flight details";

const create_new_button_label = "Create new agent";

interface Props {
  agentsCount: number;
  onSuccessCb: (data: any) => void;
}
const NewAgentModal: React.FC<Props> = ({ agentsCount, onSuccessCb }) => {
  const botContext = useContext(BotContext);
  const { id: botId, settings } = botContext;

  const enabledChannel = settings?.enabled_channels?.[0];

  const [openModal, setOpenModal] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (key: string, value: string): void => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleBlur = (key: string, value: string): void => {};

  const handleConfirm = async (): Promise<void> => {
    if (!botId) return;

    setLoading(true);
    createAgent({
      ...values,
      enabled_channels: [enabledChannel],
      bot_id: botId,
    })
      .then((response) => {
        onSuccessCb(response);
        setValues({ name: "", description: "" });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setOpenModal(false);
      });
  };

  // VOICE AGENT ONLY SUPPORT FOR SINGLE AGENT
  const isCreateAgentDisabled =
    enabledChannel === BotType.VoiceBot && agentsCount === 1;

  return (
    <div>
      <Button
        color="primary"
        size="small"
        startIcon={<PlusIcon />}
        onClick={() => {
          setOpenModal(true);
        }}
        disabled={isCreateAgentDisabled}
      >
        {create_new_button_label}
      </Button>
      <Modal
        open={openModal}
        title={create_agent_modal_title}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          void handleConfirm();
        }}
        confirmDisabled={!values?.name}
        showConfirmLoading={loading}
      >
        <div className="flex-col row-gap-16 my-16">
          <div className="flex-col row-gap-4">
            <Typography className="w-semi-bold">
              {create_agent_name_label}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder={create_agent_name_placeholder}
              fullWidth
              value={values.name}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
              onBlur={(event) => {
                handleBlur("name", event.target.value);
              }}
            />
          </div>

          <div className="flex-col row-gap-4">
            <Typography className="w-semi-bold">
              {create_agent_description_label}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder={create_agent_description_placeholder}
              fullWidth
              value={values.description}
              onChange={(event) => {
                handleChange("description", event.target.value);
              }}
              onBlur={(event) => {
                handleBlur("description", event.target.value);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewAgentModal;
