import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useGeneralInfoStyles = makeStyles((theme: Theme) => ({
  generalInfoContainer: {
    height: "100%",
  },
  generalInfoContent: {
    display: "flex",
    flexDirection: "column",
  },
  configurationContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 24px",
    gap: "32px",
  },
}));

export const useBasicInfoStyles = makeStyles((theme: Theme) => ({
  basicInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  callTypeContainer: {},
}));

export const useCompanyInfoStyles = makeStyles((theme: Theme) => ({
  basicInfoContainer: {},
}));

export const useGoalStyles = makeStyles((theme: Theme) => ({
  basicInfoContainer: {},
}));

export const useRulesAndGuildlinesStyles = makeStyles((theme: Theme) => ({
  basicInfoContainer: {},
}));
