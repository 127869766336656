import type React from "react";
import useStyles from "./ActionPreview.styles";
import { ReactComponent as LogInIcon } from "assets/log-in-01.svg";
import { ReactComponent as LogOutIcon } from "assets/log-out-01.svg";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";
import {
  type ActionParam,
  type ConnectorActionsConfig,
} from "../../AgentActions.const";
import ActionParamTile from "./ActionParamTile";
import { IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { isEqual } from "lodash";
import Button from "components/base/Button";

interface Props {
  onClose: () => void;
  action: ConnectorActionsConfig;
  openSelectAndConfigure: () => void;
}

const ActionPreview: React.FC<Props> = ({
  onClose,
  action,
  openSelectAndConfigure,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedParam, setSelectedParam] = useState<ActionParam | null>(null);
  const classes = useStyles({ width: anchorEl?.clientWidth });

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
    setSelectedParam(null);
  };

  const onClickParamItem = (
    param: ActionParam,
    event: React.MouseEvent<HTMLElement>
  ): void => {
    if (isEqual(param._id, selectedParam?._id)) {
      setSelectedParam(null);
      handlePopoverClose();
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedParam(param);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.IconAndtitle}>
            <PuzzleIcon />
            <div className={classes.title}>{action.name}</div>
          </div>
          <CrossIcon onClick={onClose} />
        </div>
        <div className={classes.content}>
          <div className={classes.descriptionHead}>Description</div>
          <div className={classes.description}>{action.description}</div>
          {/* For Input Params */}
          <div className={classes.paramHead}>
            <IconButton>
              <LogInIcon />
            </IconButton>
            <div className={classes.paramHeadTitle}>Input</div>
            <div className={classes.paramCount}>
              {action.input_params.length}
            </div>
          </div>
          <div className={classes.tiles}>
            {action.input_params.map((param) => {
              return (
                <ActionParamTile
                  key={param._id}
                  param={param}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onClickParamItem(param, event);
                  }}
                  isPopoverOpen={isEqual(selectedParam?._id, param._id)}
                  showOpenCloseState
                />
              );
            })}
          </div>
          {/* For Output params */}
          <div className={classes.paramHead}>
            <IconButton>
              <LogOutIcon />
            </IconButton>
            <div className={classes.paramHeadTitle}>Output</div>
            <div className={classes.paramCount}>
              {action.output_params.length}
            </div>
          </div>
          <div className={classes.tiles}>
            {action.output_params.map((param) => {
              return (
                <ActionParamTile
                  key={param?._id}
                  param={param}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onClickParamItem(param, event);
                  }}
                  isPopoverOpen={isEqual(selectedParam?._id, param._id)}
                  showOpenCloseState
                />
              );
            })}
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            color="primary"
            className={classes.configureBtn}
            onClick={openSelectAndConfigure}
          >
            Select & configure
          </Button>
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom", // Position it below the input
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
      >
        <div>
          <div className={classes.popoverTitle}>
            How the departure date is sourced?
          </div>
          <div className={classes.popoverDescription}>
            Extracted from the user’s query
          </div>
        </div>
        <div>
          <div className={classes.popoverTitle}>Default value</div>
          <div className={classes.popoverDescription}>
            {selectedParam?.default ? selectedParam?.default : "Null"}
          </div>
        </div>
        <div>
          <div className={classes.popoverTitle}>Optional vs mandatory</div>
          <div className={classes.popoverDescription}>
            {selectedParam?.required ? "Mandatory" : "Optional"}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ActionPreview;
