import AccordionCard, {
  type AccordionCardProps,
} from "components/base/AccordionCard";
import { ReactComponent as DetailsIcon } from "assets/details-icon.svg";
import { ReactComponent as AgentsIcon } from "assets/agents-icon.svg";
import { ReactComponent as GuidelinesRulesIcon } from "assets/guidelines-rule-icon.svg";
import { useAssistantCompositionStyles as useStyles } from "./styles";
import AssistantDetails from "./AssistantDetails";
import AssistantAgents from "./AssistantAgents";
import GuidelinesAndRules from "./GuidelinesAndRules";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";
import { useContext, useState } from "react";
import AssistantContext from "../AssistantContext";
import NewAgentModal from "./NewAgentModal";

const AssistantComposition: React.FC = () => {
  const classes = useStyles();

  const assistantContext = useContext(AssistantContext);
  const { assistant } = assistantContext;

  const [openNewAgentModal, setOpenNewAgentModal] = useState(false);

  const AssistantBuilderConfiguration: AccordionCardProps[] = [
    {
      title: "Details",
      subtitle:
        "Share key details about your assistant’s goal and company to align it with your brand identity",
      icon: <DetailsIcon />,
      content: <AssistantDetails />,
    },
    {
      title: "Agents",
      subtitle:
        "Build child assistants focused within your assistant to handle specific tasks powdered by actions",
      icon: <AgentsIcon />,
      content: (
        <AssistantAgents
          onClickItem={() => {
            setOpenNewAgentModal(true);
          }}
        />
      ),
      headerButtons: (
        <Button
          variant={ButtonVariants.outlined}
          size={ButtonSizes.xsmall}
          onClick={() => {
            setOpenNewAgentModal(true);
          }}
        >
          Add a new agent
        </Button>
      ),
    },
    {
      title: "Guidelines and rules",
      subtitle:
        "Define the rules for how your assistant should interacts with customers, might including tone, fallback behaviour.",
      icon: <GuidelinesRulesIcon />,
      content: <GuidelinesAndRules />,
    },
  ];

  return (
    <div className={classes.assistantCompositionContainer}>
      {AssistantBuilderConfiguration.map((configuration, index) => (
        <AccordionCard key={index} {...configuration} />
      ))}

      <NewAgentModal
        assistant={assistant}
        open={openNewAgentModal}
        onClose={() => {
          setOpenNewAgentModal(false);
        }}
      />
    </div>
  );
};

export default AssistantComposition;
