import dagre from "@dagrejs/dagre";
import {
  // LayoutAlgorithmOptions,
  type LayoutAlgorithm,
  // type Direction,
} from "../types/layout.type";
// import Elk, { type ElkNode } from "elkjs/lib/elk.bundled.js";
// import { Node, Edge, XYPosition } from "reactflow";
// import { CreateNodeType } from "../config";

// const elk = new Elk();

export const dagreLayout: LayoutAlgorithm = async (nodes, edges, options) => {
  const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  // console.log("Dagre Input -", nodes);

  dagreGraph.setGraph({
    rankdir: options.direction,
    nodesep: options.spacing[0],
    ranksep: options.spacing[1],
  });

  for (const node of nodes) {
    dagreGraph.setNode(node.id, {
      width: node.width ?? 0,
      height: node.height ?? 0,
    });

    // Dagre currently has an open issue that prevents it from laying out sub-flows
    // correctly if any nodes in the sub-flow are connected to nodes outside the
    // sub-flow.
    //
    // See: https://github.com/dagrejs/dagre/issues/238

    // if (node.parentNode) {
    //   dagreGraph.setParent(node.id, node.parentNode);
    // }
  }

  for (const edge of edges) {
    dagreGraph.setEdge(edge.source, edge.target);
  }

  dagre.layout(dagreGraph);

  const nextNodes = nodes.map((node) => {
    const { x, y } = dagreGraph.node(node.id);

    const position = {
      x: x - (node.width ?? 0) / 2,
      y: y - (node.height ?? 0) / 2,
    };

    return {
      ...node,
      position,
    };
  });

  // console.log("Next Nodes -", nextNodes);

  return { nodes: nextNodes, edges };
};

export const cleanDagreLayout = (): void => {
  // dagreGraph.nodes().forEach((node) => dagreGraph.removeNode(node));
  // dagreGraph.edges().forEach((edge) => dagreGraph.removeEdge(edge.v, edge.w));
};

// export const getNodeLayoutPosition = async ({
//   nodeId,
//   nodes,
//   edges,
//   options,
// }: {
//   nodeId: string;
//   nodes: Node[];
//   edges: Edge[];
//   options: any;
// }): Promise<XYPosition | undefined> => {
//   const tempDagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(
//     () => ({})
//   );

//   tempDagreGraph.setGraph({
//     rankdir: options.direction,
//     nodesep: options.spacing[0],
//     ranksep: options.spacing[1],
//   });

//   for (const node of nodes) {
//     tempDagreGraph.setNode(node.id, {
//       width: node.width ?? 0,
//       height: node.height ?? 0,
//     });
//   }

//   for (const edge of edges) {
//     tempDagreGraph.setEdge(edge.source, edge.target);
//   }

//   dagre.layout(tempDagreGraph);

//   const nextNodes = nodes.map((node) => {
//     const { x, y } = tempDagreGraph.node(node.id);

//     const position = {
//       x: x - (node.width ?? 0) / 2,
//       y: y - (node.height ?? 0) / 2,
//     };

//     return { ...node, position };
//   });

//   const node = nextNodes.find((node) => node.id === nodeId);

//   return node?.position;
// };

// const getDirection = (direction: Direction) => {
//   switch (direction) {
//     case "TB":
//       return "DOWN";
//     case "LR":
//       return "RIGHT";
//     case "BT":
//       return "UP";
//     case "RL":
//       return "LEFT";
//   }
// };

// export const elkLayout: LayoutAlgorithm = async (nodes, edges, options) => {
//   const graph = {
//     id: "elk-root",
//     layoutOptions: {
//       "elk.algorithm": "mrtree",
//       "elk.direction": getDirection(options.direction),
//       "elk.spacing.nodeNode": `${options.spacing[0]}`,
//     },
//     children: nodes.map((node) => ({
//       id: node.id,
//       width: node.width ?? 0,
//       height: node.height ?? 0,
//     })),
//     edges: edges.map((edge) => ({
//       id: edge.id,
//       sources: [edge.source],
//       targets: [edge.target],
//     })),
//   };

//   // We create a map of the laid out nodes here to avoid multiple traversals when
//   // looking up a node's position later on.
//   const root = await elk.layout(graph);
//   const layoutNodes = new Map<string, ElkNode>();
//   for (const node of root.children ?? []) {
//     layoutNodes.set(node.id, node);
//   }

//   const nextNodes = nodes.map((node) => {
//     const elkNode = layoutNodes.get(node.id)!;
//     const position = { x: elkNode.x!, y: elkNode.y! };

//     return {
//       ...node,
//       position,
//     };
//   });

//   return { nodes: nextNodes, edges };
// };
