import Button from "components/base/Button";
import { createNewWorkflowPlaceholder } from "pages/Bot/WorkflowBuilder/utils/workflow";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/ContentHeader";
import ComposeWorkflowModal from "./components/ComposeWorkflowModal";
import WorkflowList from "./components/WorkflowList";
import { publishWorkflow } from "api/workflow";
import { useDispatch } from "store";
import { WorkflowActions } from "store/reduxActions/botActions";
import { useSnackbar } from "notistack";
import { formatWorkflowRequest } from "../WorkflowBuilder/utils/request";

const Workflows: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { botId } = params;

  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleNewWorkflow = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }): void => {
    const { nodes, edges } = createNewWorkflowPlaceholder();

    const body = formatWorkflowRequest({
      botId,
      title,
      description,
      pointer: 1,
      nodes,
      edges,
    });

    setSaving(true);
    publishWorkflow(body)
      .then((response) => {
        dispatch({
          type: WorkflowActions.SET_WORKFLOW_DATA,
          payload: {
            workflow: {
              workflowId: response?._id,
              bot_id: response?.bot_id,
              title: response?.title,
              description: response?.description,
              nodes,
              edges,
            },
          },
        });

        navigate(`../workflow-builder/${response._id}`);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
      });

    // !!! Saving default nodes and edges when we create a new workflow
    // sessionStorage.setItem(
    //   "workflow",
    //   JSON.stringify({
    //     title,
    //     description,
    //     nodes,
    //     edges,
    //   })
    // );
  };

  return (
    <div className="flex flex-col align-items-stretch">
      <Header
        title={"Workflow"}
        subtitle={
          "Aautomate your interactions with customers effortlessly with intelligent workflows"
        }
      >
        <Button
          color={"primary"}
          onClick={() => {
            setOpen(true);
          }}
        >
          New Workflow
        </Button>
      </Header>

      <div className="workflows-content flex flex-col justify-content-start align-items-center p-20">
        <WorkflowList />
      </div>

      <ComposeWorkflowModal
        open={open}
        onClickProceed={handleNewWorkflow}
        loading={saving}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};

export default Workflows;
