import { useParams } from "react-router-dom";
import { CircularProgress, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BotHeader from "pages/AgentBuilder/components/BotHeader";
import { AgentBuilderNavigations } from "./config";
import { useEffect, useMemo, useState } from "react";
import { getAgentData } from "api/bot/bot-agents";
import clsx from "clsx";
import AgentBuilderContext from "./AgentBuilderContext";
import { BotType } from "types/BotType";
import { isEqual } from "lodash";
import ChatBotContainer from "pages/Bot/ChatBotContainer";
import AgentComposition from "./AgentComposition";
import { getAssistant } from "api/assistant/assistant";
import { useDispatch } from "store";
import { BotActions, SettingsActions } from "store/reduxActions/botActions";
// import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";

const useStyles = makeStyles((theme: Theme) => ({
  agentBuilderContainer: {
    width: "100%",
    height: "100vh",
  },
  agentBuilderMainContainer: {
    height: "calc(100vh - 72px)",
    width: "100%",
    position: "relative",
    top: "72px",
  },
  agentBuilderOutletContainer: {
    padding: "16px 32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "auto",
    height: "100%",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      alignItems: "flex-start",
    },
  },
  chatbotWidgetContainer: {
    backgroundColor: "transparent",
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: "1000",
    justifyContent: "flex-end",
  },
}));

const AgentBuilder: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const { assistantId, agentId } = params || {};

  const [loading, setLoading] = useState(false);
  const [botData, setBotData] = useState<any>(null);
  const [agentData, setAgentData] = useState<any>(null);

  const backPath = useMemo(() => {
    if (!assistantId || !agentId || !botData) return null;

    const enabledChannels = botData?.settings?.enabled_channels?.[0];

    if (enabledChannels === BotType.VoiceBot) {
      return `/bot/voice/${assistantId}/`;
    }

    return `/bot/chat/${assistantId}/`;
  }, [assistantId, botData]);

  useEffect(() => {
    if (!assistantId || !agentId) return;

    setLoading(true);
    Promise.all([getAssistant(assistantId), getAgentData(agentId)])
      .then((results) => {
        const [_botData, _agentData] = results;

        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_SUCCESS,
          payload: _botData.data.assistant.settings,
        });
        dispatch({
          type: BotActions.SET_BOT_META,
          payload: _botData.data.assistant,
        });
        // dispatch({
        //   type: ReduxChatbotActions.ACTION_SET_BOT_TYPE,
        //   payload: { botName: _botData.data.assistant.name },
        // });

        setBotData(_botData.data.assistant);
        setAgentData(_agentData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading || !assistantId || !agentId) {
    return (
      <div className={clsx(classes.agentBuilderContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AgentBuilderContext.Provider
      value={{ assistantId, agentId, bot: botData, agent: agentData }}
    >
      <div className={classes.agentBuilderContainer}>
        <BotHeader
          title={agentData?.name}
          description={agentData?.description}
          backPath={backPath}
          navigations={AgentBuilderNavigations}
        />

        <div className={classes.agentBuilderMainContainer}>
          <div className={classes.agentBuilderOutletContainer}>
            <AgentComposition />
          </div>
        </div>
      </div>

      {isEqual(
        botData?.settings?.enabled_channels?.[0],
        BotType.VoiceBot
      ) ? null : (
        <div className={clsx(classes.chatbotWidgetContainer)}>
          <ChatBotContainer
            botConfig={{ minimizeIcon: true, isChatOpen: false }}
            botData={botData}
          />
        </div>
      )}
    </AgentBuilderContext.Provider>
  );
};

export default AgentBuilder;
