import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { type FormikProps, useFormikContext } from "formik";
import { type IAppearanceSettings } from "../../types";

const useStyles = makeStyles((theme) => ({
  "chat-collapsed-settings-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",

    "& .settings-row": {
      "& .form-input-container": {
        width: "44%",
        display: "flex",
        flexDirection: "column",
        rowGap: "6px",
      },
    },
  },
}));

const ChatCollapsedSettings: React.FC = () => {
  const classes = useStyles();

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
  }: FormikProps<IAppearanceSettings> = useFormikContext();

  return (
    <div className={classes["chat-collapsed-settings-container"]}>
      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Display a nudge when widget is closed
          </Typography>
          <RadioGroup
            name={"display_nudge"}
            className="px-1 row-gap-8"
            value={values?.display_nudge}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">
            The default state of widget
          </Typography>
          <RadioGroup
            name={"default_widget_state"}
            className="px-1 row-gap-8"
            value={values?.default_widget_state}
            onChange={handleChange}
          >
            <FormControlLabel
              value="closed"
              control={<Radio />}
              label="Closed"
            />
            <FormControlLabel value="open" control={<Radio />} label="Open" />
          </RadioGroup>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">Nudge message</Typography>
          <TextField
            name="nudge_message"
            variant="outlined"
            size="small"
            placeholder="Hi! I can help you find answers"
            fullWidth
            multiline
            minRows={2}
            maxRows={2}
            value={values?.nudge_message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.nudge_message && Boolean(errors.nudge_message)}
            helperText={touched.nudge_message && errors.nudge_message}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatCollapsedSettings;
