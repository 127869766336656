import { type OutlinedTextFieldProps } from "@mui/material/TextField";
import { useCallback, useRef, useState } from "react";
import TemplatePopover from "../TemplatePopover";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useStyles } from "./style";
import TextEditor from "./TextEditor";
import { handleVariableSelectionNode } from "./utils";
import { v4 as uuid } from "uuid";

export interface IGroupOption {
  index?: number;
  key: string;
  value: string;
  icon?: any; // !!! Need to set this type for SVH React element, so as to render as in this file or JSX element; == icon: React.FC<React.SVGProps<SVGSVGElement>>
  options?: IGroupOption[];
}

interface Props extends OutlinedTextFieldProps {
  name: string;
  value: any;
  options: IGroupOption[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TemplateTextField: React.FunctionComponent<Props> = ({
  name,
  value,
  setFieldValue,
  options,
  onBlur,
  onChange,
  placeholder,
  ...props
}) => {
  const classes = useStyles();

  const idRef = useRef(uuid().replaceAll(/-/g, ""));
  const id = idRef.current;

  const [inputFieldValue, setInputFieldValue] = useState(value);
  const [focused, setFocused] = useState(false);
  const [anchor, setAnchor] = useState<any>();
  const [openPopover, setOpenPopover] = useState(false);

  const textfieldRef = useCallback((node: any) => {
    if (node) {
      setAnchor(node);
    }
  }, []);

  // Methods
  const handleOpenPopover = useCallback(() => {
    setOpenPopover(true);
  }, []);

  const handleClosePopover = useCallback(() => {
    setOpenPopover(false);
  }, []);

  const handleSelect = useCallback(
    (value: string, variable: any) => {
      const updatedValueWithValue = `${value}{{${variable.value}}}`;

      setInputFieldValue(updatedValueWithValue);
      setFieldValue(name, updatedValueWithValue);

      handleVariableSelectionNode(variable);

      setOpenPopover(false);
    },
    [name, setFieldValue]
  );

  // Controlled Component Methods
  const handleOnFocus = useCallback((event: any): void => {
    setFocused(true);
  }, []);

  const handleOnChange = useCallback((val: any): void => {
    setInputFieldValue(val);
  }, []);

  const handleOnBlur = useCallback(
    (event: any): void => {
      if (!openPopover) {
        setFocused(false);
      }

      onBlur?.(event);
      setFieldValue(name, inputFieldValue);
    },
    [inputFieldValue, name, openPopover, setFieldValue]
  );

  const handleClickAway = useCallback((): void => {
    if (openPopover && focused) {
      setFocused(false);
      setOpenPopover(false);
    }
  }, [focused, openPopover]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div ref={textfieldRef} className={classes["template-select-container"]}>
        <TextEditor
          id={id}
          placeholder={placeholder}
          value={inputFieldValue}
          focused={focused}
          handleOpenPopover={handleOpenPopover}
          handleClosePopover={handleClosePopover}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />

        <TemplatePopover
          ref={textfieldRef}
          value={inputFieldValue}
          options={options}
          anchor={anchor}
          open={openPopover}
          onSelect={handleSelect}
          onClose={handleClosePopover}
        />
      </div>
    </ClickAwayListener>
  );
};

export default TemplateTextField;
