import { IconButton } from "@mui/material";
import { useAssistantHeaderStyles as useStyles } from "./styles";
import { ReactComponent as ArrowLeft } from "assets/arrow-left.svg";
import useRouteNavigator from "hooks/useRouteNavigator";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface Props {
  children: React.ReactNode;
  title: string;
  description?: string;
}

const AssistantHeader: React.FC<Props> = ({
  children,
  title,
  description = "",
}) => {
  const classes = useStyles();
  const { gotoBotDashboard } = useRouteNavigator();

  const handleBackClick = (): void => {
    gotoBotDashboard();
  };

  return (
    <div className={classes.assistantHeaderContainer}>
      <div className="flex align-items-center col-gap-12">
        <IconButton className="back-button" onClick={handleBackClick}>
          <ArrowLeft />
        </IconButton>

        <div className="assistant-details-container flex flex-col">
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.bold}
          >
            {title}
          </Typography>
          <Typography weight={TypographyWeights.medium}>
            {description}
          </Typography>
        </div>
      </div>

      <div className="assistant-header-actions">{children}</div>
    </div>
  );
};

export default AssistantHeader;
