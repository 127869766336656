import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import * as yup from "yup";
import EditNodeHeader from "../../components/EditNodeHeader";
import EditContainer from "./EditContainer";
import { type IInputNode } from "pages/Bot/WorkflowBuilder/types";

const useStyles = makeStyles((theme) => ({
  "edit-text-node": {
    "& .node-header": {
      display: "flex",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
      boxShadow: "0px 1px 0px 0px #E1DEDA",

      "& .node-type-icon": {
        display: "flex",
        padding: "8px",
        alignItems: "flex-start",
        gap: "10px",
        borderRadius: "8px",
        background: "#B2E69F",
      },
    },

    "& .edit-node-content": {
      padding: "32px 24px",
    },

    "& .input-question": {
      "& .delete-icon": {
        height: "16px",
        width: "16px",
      },

      "& .question-input-container": {
        "& .question-variable-container": {
          padding: "0px 8px",
          transform: "translateY(-10px)",
          opacity: 0,
          height: "0px",
          transition: "all 0.2s ease-in-out",

          "&.show-variable": {
            height: "52px",
            opacity: 1,
            transform: "translateY(0px)",

            "& .variable-response": {
              display: "flex",
            },
          },

          "& .variable-response": {
            display: "none",
            padding: "12px 16px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
            borderRadius:
              "var(--spacing-spacing-none, 0px) var(--spacing-spacing-none, 0px) 8px 8px",
            borderRight: "1px solid var(--border-default, #E1DEDA)",
            borderBottom: "1px solid var(--border-default, #E1DEDA)",
            borderLeft: "1px solid var(--border-default, #E1DEDA)",
            background: "var(--colors-shades-base-white, #FFF)",

            "& .variable": {
              display: "flex",
              padding: "2px 5px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              border: "1px solid var(--colors-secondary-purple-600, #9062CB)",
              background: "var(--colors-secondary-purple-100, #EFE4FC)",
            },
          },
        },
      },
    },
  },
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(16, "Maximum 16 characters allowed")
    .required("This is a required field"),
  questions: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      question: yup.string(),
      variables: yup.array().of(
        yup.object().shape({
          label: yup.string(),
          type: yup.string(),
          description: yup.string(),
        })
      ),
    })
  ),
});

interface Props {
  node: any;
  // nodeId: string;
  // data: IBotPromptInputNodeData;
  // onSave: (data: IBotPromptInputNodeData) => void;
}

const FormContainer: React.FC<Props> = ({ node }) => {
  const classes = useStyles();

  return (
    <div className={classes["edit-text-node"]}>
      <Form className="flex flex-col row-gap-24">
        <EditNodeHeader node={node} />
        <EditContainer node={node} />
      </Form>
    </div>
  );
};

const TextNode: React.FC<Props> = ({ node }) => {
  const { data } = node;

  const initialValues: Omit<IInputNode, "group"> = {
    name: data?.name || "",
    questions: data?.questions || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={() => {}}
    >
      <FormContainer node={node} />
    </Formik>
  );
};

export default TextNode;
