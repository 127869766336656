import { makeStyles } from "@mui/styles";
import EditNode from "../EditNode";

const useStyles = makeStyles((theme) => ({
  "node-drawer-content": {},
}));

interface INodeDrawerProps {
  node: any;
  onClose: () => void;
}

const NodeDrawerContent: React.FC<INodeDrawerProps> = ({ node, onClose }) => {
  const classes = useStyles();

  if (!node) {
    return null;
  }

  return (
    <div className={classes["node-drawer-content"]}>
      <EditNode type={node?.type} node={node} onClose={onClose} />
    </div>
  );
};

export default NodeDrawerContent;
