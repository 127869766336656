import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  toggleContainer: {
    display: "flex",
    gap: "24px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: "8px",
    padding: "8px 12px",
    gap: "13px",
  },
  active: {
    border: "1px solid var(--border-active, #F07400)",
    background: "var(--inactive-surfaces-Brand-highlight, #FFF5EB)",
  },
  inactive: {
    border: "1px solid var(--border-default, #E1DEDA)",
  },

  disabled: {
    opacity: 0.5,
  },
}));

export default useStyles;
