import { CircularProgress, IconButton, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as PauseIcon } from "assets/pause-square.svg";
import { ReactComponent as CopyIcon } from "assets/copy-icon.svg";
import { ReactComponent as BrokenDataIcon } from "assets/broken-data.svg";
import { ReactComponent as EmptyDataIcon } from "assets/empty-state.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { useEffect, useState } from "react";
import { getWorkflowList } from "api/workflow";

const useStyles = makeStyles((theme: Theme) => ({
  "workflow-list-container": {
    display: "flex",
    width: "100%",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",

    "& .row-action": {
      "& .MuiIconButton-root": {
        "&.Mui-disabled": {
          "& svg": {
            "& path": {
              fill: "#B5B1AD",
            },
          },
        },
      },
    },
  },
}));

const ListContainer: React.FC = () => {
  const params = useParams();

  const { botId } = params;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any[]>();
  const [pageState, setPageState] = useState({ empty: false, error: false });

  useEffect(() => {
    if (!botId) return;

    setLoading(true);
    getWorkflowList(botId)
      .then((response) => {
        if (!response?.length) {
          setPageState({ empty: true, error: false });
        }

        setRows(response);
      })
      .catch((err) => {
        console.log(err);
        setPageState({ empty: false, error: true });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [botId]);

  if (loading) {
    return (
      <div className="w-100 flex flex-col center" style={{ height: "40vh" }}>
        <CircularProgress size="2rem" />
      </div>
    );
  }

  if (pageState.empty) {
    return (
      <div
        className="w-100 flex flex-col center row-gap-8"
        style={{ height: "40vh" }}
      >
        <EmptyDataIcon className="h-50" />
        <Typography>No data</Typography>
      </div>
    );
  }

  if (pageState.error) {
    return (
      <div
        className="w-100 flex flex-col center row-gap-8"
        style={{ height: "40vh" }}
      >
        <BrokenDataIcon className="h-50" />
        <Typography>Something went wrong</Typography>
      </div>
    );
  }

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="left" sx={{ width: 180 }}>
            Last updated
          </TableCell>
          <TableCell align="left" sx={{ width: 180 }}>
            Status
          </TableCell>
          <TableCell align="right" sx={{ width: 200 }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Link
                className="flex flex-col no-decoration"
                to={`../workflow-builder/${row._id}`}
              >
                <Typography className="w-semi-bold">{row.title}</Typography>
                <Typography variant="textSm">{row.description}</Typography>
              </Link>
            </TableCell>
            <TableCell align="left">{row?.modified || "-"}</TableCell>
            <TableCell align="left">
              <Chip label={row?.status || "DRAFT"} />
            </TableCell>
            <TableCell
              align="center"
              className="row-action flex justify-content-center align-items-center col-gap-16"
            >
              <IconButton disabled>
                <PauseIcon />
              </IconButton>
              <IconButton disabled>
                <CopyIcon />
              </IconButton>
              <IconButton disabled>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const WorkflowList: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes["workflow-list-container"]}>
      <Typography variant="textLg" className="w-bold" sx={{ color: "#282624" }}>
        Your Workflow
      </Typography>

      <TableContainer component={Paper}>
        <ListContainer />
      </TableContainer>
    </div>
  );
};

export default WorkflowList;
