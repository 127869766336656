import { makeStyles } from "@mui/styles";
import type React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import Typography from "components/base/Typography";

const useStyles = makeStyles((theme) => ({
  "default-branch-container": {
    display: "flex",
    width: "432px",
    paddingBottom: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-default, #FFF)",

    "&.MuiAccordion-root": {
      padding: "0px",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "none",

      "&:before": {
        content: "none",
        opacity: 0,
        height: 0,
      },
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      padding: "12px 16px",

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "&.Mui-expanded": {
        minHeight: "unset", // Need to remove this property from this definition
      },
    },
    "& .MuiCollapse-root": {
      width: "100%",
      borderTop: "1px solid var(--border-default, #E1DEDA)",

      "& .MuiAccordionDetails-root": {
        padding: "16px",
      },
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));

const DefaultBranch: React.FC = () => {
  const classes = useStyles();

  return (
    <Accordion className={classes["default-branch-container"]}>
      <AccordionSummary expandIcon={<ChevronDown />}>
        <div className="flex justify-content-start align-items-center col-gap-16">
          <div className="flex flex-col">
            <Typography className="w-semi-bold">Default Path</Typography>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div className="branch-conditions-container flex flex-col row-gap-24">
          <Typography className="w-medium">
            When none of the specified conditions are met, the flow will be
            directed to the default path.
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default DefaultBranch;
