import { TextField } from "@mui/material";
import { GeneralInfoFields } from "./types";
import { useFormikContext } from "formik";
import { type GeneralInfoProps } from ".";

const input_box_placeholder =
  "E.g. Assist customers with tasks like flight bookings, cancellations, and providing real-time flight status updates, ensuring a smooth and efficient customer experience.";

const Goal: React.FC = () => {
  const { values, handleBlur, handleChange } =
    useFormikContext<GeneralInfoProps>();

  return (
    <div>
      <TextField
        name={GeneralInfoFields.goal}
        variant="outlined"
        size="small"
        placeholder={input_box_placeholder}
        fullWidth
        value={values.goal}
        onChange={handleChange}
        onBlur={handleBlur}
        minRows={3}
        maxRows={10}
        multiline
      />
    </div>
  );
};

export default Goal;
