import Modal from "components/base/Modal/Modal";
import ToggleDiv from "components/base/ToggleDiv/ToggleDiv";
import type React from "react";
import { useState } from "react";
import { type APIGroup, APIType } from "types/APIIntegration";
import useStyles from "./SampleAPIModal.styles";
import { isNil, isEqual, isEmpty } from "lodash";
import { TextField } from "@mui/material";
import { type ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import NestedDropdown, {
  type DropdownItem,
} from "components/base/NestedDropdown/NestedDropdown";
import { ReactComponent as CodeSquareIcon } from "assets/code-square-01.svg";
import { ReactComponent as ZapFastIcon } from "assets/zap-fast.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { getDropdownItems } from "./SampleAPIModal.util";

interface Props {
  open: boolean;
  onProceed: ({
    name,
    describeObjective,
    sampleAPIId,
    type,
    isNewSampleAPI,
  }: {
    name: string;
    describeObjective: string;
    sampleAPIId?: string;
    type?: APIType;
    isNewSampleAPI?: boolean;
  }) => Promise<any>;
  sampleAPIs: APIGroup[];
  onClose: () => void;
}

const SampleAPIModal: React.FC<Props> = ({
  open,
  onProceed,
  onClose,
  sampleAPIs = [],
}) => {
  const classes = useStyles();
  const [type, setType] = useState(APIType.Standard);
  const [isNewSampleAPI, setIsNewSampleAPI] = useState(false);
  const [selectedAPI, setSelectedAPI] = useState<DropdownItem | null>(null);
  const [name, setName] = useState("");
  const [describeObjective, setDescribeObjective] = useState("");
  const dropdownAPIs = getDropdownItems(sampleAPIs);
  const [loading, setLoading] = useState(false);

  const handleToggle = (id: string): void => {
    setType(id as APIType);
  };

  const handleConfirm = (): void => {
    setLoading(true);
    onProceed({
      name,
      describeObjective, // this field become mandatory when creating new sampleAPI
      sampleAPIId: selectedAPI?.id,
      type,
      isNewSampleAPI,
    })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setDescribeObjective(e.target.value);
  };

  const onChangeName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setName(e.target.value);
  };

  const getSelectPanel = (): ReactJSXElement => {
    return (
      <div>
        <div className={classes.selectAPIContainer}>Select the API</div>
        <NestedDropdown
          data={dropdownAPIs}
          value={isNewSampleAPI ? "New sample API(s)" : selectedAPI?.title}
          onSelect={(api: DropdownItem) => {
            setSelectedAPI(api);
            setIsNewSampleAPI(false);
          }}
          placeholder="Select API"
          preInputIcon={<CodeSquareIcon />}
          showSearchInputBox={true}
          searchPlaceholder="Search a sample API"
          childCountLabel="APIs"
          showChildCount={true}
          footerConfig={{
            icon: <ZapFastIcon className={classes.zapIcon} />,
            message:
              "Could find what you need? Create a sample API with a prompt.",
            buttonConfig: {
              buttonLabel: "Create a new API",
              preFixIcon: <PlusIcon className={classes.plusIcon} />,
              onClickFooterButton: () => {
                setIsNewSampleAPI(true);
              },
            },
          }}
        />
      </div>
    );
  };

  const getNameInputBox = (): ReactJSXElement => {
    return (
      <div>
        <div className={classes.selectAPIContainer}>API Name</div>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          placeholder="Eg. View customer details"
          fullWidth
          value={name}
          onChange={onChangeName}
        />
      </div>
    );
  };

  const checkConfirmDisabled = (): boolean => {
    if (isEqual(type, APIType.Sample)) {
      return isNewSampleAPI
        ? isEmpty(describeObjective)
        : isNil(selectedAPI?.id);
    }
    return isEmpty(name);
  };

  return (
    <Modal
      open={open}
      title={"Integrate a new API"}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmDisabled={checkConfirmDisabled()}
      showConfirmLoading={loading}
    >
      <div className={classes.togglerContainer}>
        <ToggleDiv
          section1={{
            label: "Standard Integration",
            sublabel:
              "Complete the setup for a robust, fully customizable integration—ideal for production use.",
            id: APIType.Standard,
          }}
          section2={{
            label: "Sample APIs",
            sublabel:
              "Use pre-configured sample APIs for quick exploration. Great for setup before going live.",
            id: APIType.Sample,
          }}
          initialSelected={type}
          onToggle={handleToggle}
        />
      </div>
      <div className={classes.namelabel}>
        {isEqual(type, APIType.Sample) ? getSelectPanel() : getNameInputBox()}
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.descriptionLabel}>
          {isNewSampleAPI
            ? "Describe the objective of the API(s)"
            : "Description for your reference (Optional)"}
        </div>
        <TextField
          name="description"
          variant="outlined"
          placeholder="Eg.Use this API to get customer name, phone no. and email address with customer_id as input"
          multiline
          rows={6}
          fullWidth
          value={describeObjective}
          onChange={onChangeDescription}
        />
      </div>
    </Modal>
  );
};

export default SampleAPIModal;
