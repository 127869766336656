import { type AgentInfo, type AgentInfoInput } from "./voice-agent-types";
import { BoundType } from "./voice-bot-types";

export const getAgentInfoToInputTransformer = (
  infoInput: AgentInfoInput
): AgentInfo => {
  const info: AgentInfo = {
    callType: BoundType.Inbound,
    companyInfo: "",
    goal: "",
    welcomeConfig: {
      userFirst: true,
      staticMessage: "",
      aiPrompt: "",
    },
    companyInfoFromKnowledgeBase: false,
    guidelinesAndRules: "",
  };
  info.companyInfo = infoInput?.company_info_text ?? info.companyInfo;
  info.goal = infoInput?.goal ?? info.goal;
  info.guidelinesAndRules =
    infoInput?.guidelines_and_rules ?? info.guidelinesAndRules;
  info.welcomeConfig = {
    userFirst: infoInput?.begin_message_config?.user_first,
    staticMessage: infoInput?.begin_message_config?.static_message,
    aiPrompt: infoInput?.begin_message_config?.ai_prompt ?? "",
  };
  return info;
};

export const getTransformedAgentInfoInputFromInfo = (
  info: AgentInfo
): AgentInfoInput => {
  const infoInput: AgentInfoInput = {
    begin_message_config: {},
  };
  infoInput.company_info_text = info.companyInfo;
  infoInput.goal = info.goal;
  infoInput.guidelines_and_rules = info.guidelinesAndRules;
  infoInput.begin_message_config = {
    user_first: true,
    static_message: info?.welcomeConfig?.staticMessage ?? "",
    ai_prompt: info?.welcomeConfig?.aiPrompt ?? "",
  };
  return infoInput;
};

export const formatAgentGeneralInfoBody = (data: any): AgentInfoInput => {
  const body: AgentInfoInput = {
    ...data,
    begin_message_config: {
      user_first: true,
      static_message: null,
      ai_prompt: data.begin_message_ai_prompt,
    },
  };

  return body;
};
