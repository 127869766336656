import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { useSelector } from "store";
import clsx from "clsx";
import { BotPromptNodeConfig } from "pages/Bot/WorkflowBuilder/config";
// import { type IBotPromptNode } from "types/Workflow";
import { type INode, type INodeProps, type NodeRenderProps } from "../../types";
import NodeContainer from "./NodeContainer";
import { useCallback, useEffect } from "react";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { getNodeConnectedEdges, isHandleConnected } from "../../utils/workflow";

const useStyles = makeStyles((theme) => ({
  "bot-input-node-wrapper": {
    width: "260px",
    height: "70px",

    "& .node-handles": {
      "& .source-handles": {
        "& .source-handle-button-container": {
          "& .handle-not-connected": {
            zIndex: 10,
            right: "-27px",
            // top: "36px",
            height: "16px",
            width: "16px",
            cursor: "pointer",
            backgroundColor: "transparent",
          },

          "& .placeholder-edge-container": {
            position: "absolute",
            right: "-27px",
            top: "28px",
            display: "flex",
            alignItems: "center",
            zIndex: 2,

            "& .placeholder-handle": {
              width: "5px",
              height: "5px",
              flexShrink: 0,
              border: "1px solid #D0D5DD",
              borderRadius: "100%",
            },
            "& .placeholder-edge": {
              width: "12px",
              height: "1px",
              borderTop: "1px dashed #D0D5DD",
              flexShrink: 0,
            },
            "& .placeholder-button": {
              width: "16px",
              height: "16px",
              flexShrink: 0,
              border: "1px dashed var(--Gray-300, #8f8f90)",
              background: "var(--Base-White, #FFF)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              cursor: "pointer",
              fontSize: "15px",
              transition: ".2s all ease",
            },
          },
        },
      },
    },

    "& .node-container": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "stretch",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",

      "& .node-header": {
        padding: "12px 16px",

        "& .node-type-icon": {
          display: "flex",
          padding: "8px",
          alignItems: "flex-start",
          gap: "10px",
          borderRadius: "8px",
          background: "#AD79EF",
        },
      },
    },

    "&.node-selected": {
      "& .node-container": {
        border: "1px solid #734BA6",
      },
    },
  },
}));

const NodeComponent: React.FC<INodeProps & NodeRenderProps> = ({
  id,
  type,
  data,
  onNodeClick,
}) => {
  const classes = useStyles();
  const updateNodeInternals = useUpdateNodeInternals();

  const { name } = data;

  const TARGET_HANDLE_ID = `handle-target-${id}-3`;
  const SOURCE_HANDLE_ID = `handle-source-${id}-1`;

  const { nodes, edges, selectNode, openCreateNodeDropdown } = useSelector(
    (state) => state.bot.workflow.workflow
  );

  const currentNode = nodes.find((node: INode) => node.id === id);
  const connectedEdges = getNodeConnectedEdges(currentNode, edges);

  useEffect(() => {
    updateNodeInternals(id);
  }, [JSON.stringify(connectedEdges)]);

  const isSourceHandleConnected = useCallback(
    (handleId: string) => {
      return isHandleConnected({
        type: "source",
        nodeId: id,
        handleId,
        edges,
      });
    },
    [id, nodes, edges]
  );

  const NodeIcon = BotPromptNodeConfig?.[type]?.ICON;

  return (
    <div
      id={`node-${id}`}
      className={clsx(classes["bot-input-node-wrapper"], {
        "node-selected": selectNode.id === id && !openCreateNodeDropdown,
      })}
    >
      <div className="node-handles">
        <div className="target-handles">
          <Handle
            className="handle"
            type="target"
            position={Position.Top}
            id={`handle-target-${id}-0`}
          />
          <Handle
            className="handle"
            type="target"
            position={Position.Right}
            id={`handle-target-${id}-1`}
          />
          <Handle
            className="handle"
            type="target"
            position={Position.Bottom}
            id={`handle-target-${id}-2`}
          />
          <Handle
            className="handle"
            type="target"
            position={Position.Left}
            id={TARGET_HANDLE_ID}
          />
        </div>

        <div className="source-handles">
          {/* Handle - Source Clockwise: For dynamic connection */}
          <Handle
            className="handle"
            type="source"
            position={Position.Top}
            id={`handle-source-${id}-0`}
          />

          <div className="source-handle-button-container">
            {!isSourceHandleConnected(SOURCE_HANDLE_ID) && (
              <div className="placeholder-edge-container">
                <div className="placeholder-handle"></div>
                <div className="placeholder-edge"></div>
                <div
                  id={`handle-source-${id}-1-button`}
                  className="placeholder-button"
                >
                  <PlusIcon />
                </div>
              </div>
            )}

            <Handle
              id={SOURCE_HANDLE_ID}
              className={clsx("handle", {
                "handle-not-connected":
                  !isSourceHandleConnected(SOURCE_HANDLE_ID),
              })}
              type="source"
              position={Position.Right}
            />
          </div>

          <Handle
            className="handle"
            type="source"
            position={Position.Bottom}
            id={`handle-source-${id}-2`}
          />
          <Handle
            className="handle"
            type="source"
            position={Position.Left}
            id={`handle-source-${id}-3`}
          />
        </div>
      </div>

      <div
        className="node-container node-drag-container"
        onClick={(event) => {
          onNodeClick(event);
        }}
      >
        <div className="w-100 node-header flex align-items-start col-gap-12">
          <div className="node-type-icon">{NodeIcon && <NodeIcon />}</div>
          <div className="w-100 flex flex-col row-gap-2">
            <div className="flex justify-content-between align-items-center">
              <Typography
                variant="textTiny"
                className="w-semi-bold"
                sx={{ color: "#B5B1AD" }}
              >
                Bot Prompt
              </Typography>
              <Typography
                variant="textTiny"
                className="w-bold"
                sx={{ color: "#B5B1AD" }}
              >
                {String(data?.ui?.counter).padStart(3, "0")}
              </Typography>
            </div>
            <Typography className="w-semi-bold" sx={{ color: "#000" }}>
              {name}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputNode: React.FC<INodeProps> = (props) => {
  return (
    <NodeContainer
      id={props.id}
      type={props.type}
      renderNode={(nodeRenderProps: NodeRenderProps) => (
        <NodeComponent {...nodeRenderProps} {...props} />
      )}
    />
  );
};

export default InputNode;
