import type React from "react";
import { useEffect, useState } from "react";
import { type ActionTileConfig, ActionType } from "./AgentActions.const";
import ActionTile from "./ActionTile";
import useStyles from "./ActionType.styles";
import { isEqual } from "lodash";
import ConnectorsAction from "./Connectors/ConnectorsAction";
import { useSnackbar } from "notistack";
import Loading from "aether/Loading";
import { getAllActionType } from "../Service/ActionBuilder.service";
import { getRequiredActionTilesConfig } from "./ActionType.util";

const ActionTypeComponent: React.FC = () => {
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [actionTypeConfigTiles, setActionTypeConfigTiles] = useState<
    ActionTileConfig[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getAllActionType()
      .then((res) => {
        const modifiedRes = getRequiredActionTilesConfig(res);
        setActionTypeConfigTiles(modifiedRes);
      })
      .catch((err) => {
        enqueueSnackbar(`Something went wrong: ${err}`, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getChildComponent = (type: ActionType | null): React.ReactNode => {
    switch (type) {
      case ActionType.CONNECTORS:
        return <ConnectorsAction />;
      case ActionType.FLOWS:
        return <div>Not imoplemented yet</div>;
      case ActionType.API:
        return <div>Not imoplemented yet</div>;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.step}>Step 1/3</div>
        <div className={classes.title}>Select Action type</div>
        {loading && <Loading />}
        <div className={classes.tiles}>
          {actionTypeConfigTiles.map((tile) => {
            return (
              <ActionTile
                key={tile.type}
                tileConfig={tile}
                onClick={() => {
                  console.log(tile.type);
                  setActionType(tile.type);
                }}
                selected={isEqual(actionType, tile.type)}
              />
            );
          })}
        </div>
      </div>
      {getChildComponent(actionType)}
    </>
  );
};

export default ActionTypeComponent;
