import { Box } from "@mui/material";
import {
	type FormikProps,
	useFormikContext,
	FieldArray,
	type FormikErrors,
} from "formik";
import {
	BotSettingsFields,
	type BotSettingsFieldsValues,
} from "../../constants";
import type BotAction from "types/BotAction";
import RenderIceBreaker from "./RenderIceBreaker";
import { type FieldIceBreaker } from "types/BotSettings";

interface RenderConversationSuggestionsProps {
	actionList: BotAction[];
}

const RenderConversationSuggestions: React.FC<
	RenderConversationSuggestionsProps
> = ({ actionList }) => {
	const { values, touched, errors }: FormikProps<BotSettingsFieldsValues> =
		useFormikContext();

	return (
		<Box mt={3}>
			<FieldArray name={BotSettingsFields.suggestions}>
				{(arrayHelpers) =>
					values.suggestions.map((suggestion, index) => {
						return (
							<Box mt={3} key={index}>
								<RenderIceBreaker
									suggestion={suggestion}
									index={index}
									actionList={actionList}
									error={
										errors?.suggestions?.[index] as
											| FormikErrors<FieldIceBreaker>
											| undefined
									}
									touched={touched?.suggestions?.[index]}
								/>
							</Box>
						);
					})
				}
			</FieldArray>
		</Box>
	);
};

export default RenderConversationSuggestions;
