import type BotSettings from "./BotSettings";
import { type BotStatus } from "./BotStatus";

// For unclear base planning we are creating this BotType which essentially equals to ChannelType in settings
export enum BotType {
  ChatBot = "WEBCHAT_TEXT",
  VoiceBot = "VOICE",
}

export interface IBot {
  id: string;
  status: BotStatus;
  created: string;
  modified: string;
  name: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
  settings: BotSettings;
}

export default IBot;
