export const dateRangeOptions = [
  {
    label: "Last 30 days",
    value: "30",
  },
  {
    label: "Last 60 days",
    value: "60",
  },
  {
    label: "Last 90 days",
    value: "90",
  },
  {
    label: "Last 1 year",
    value: "365",
  },
  {
    label: "Last 2 year",
    value: "730",
  },
];

export const sortMetricOptions = [
  {
    label: "Most Used 5 path",
    value: "FREQ",
  },
  {
    label: "Most Successful 5 path",
    value: "SUCCESS_COUNT",
  },
  {
    label: "Most Transferred 5 path",
    value: "TRANSFER_COUNT",
  },
  {
    label: "Most Abandoned 5 path",
    value: "ABANDONED_COUNT",
  },
  //   {
  //     label: "Most Negative sentiment 5 path",
  //     value: "90",
  //   },
  //   {
  //     label: "Highest CSAT 5 path",
  //     value: "90",
  //   },
];

// eslint-disable-next-line
interface IObjectMap {
  [key: string]: string;
}

export const SortMetricMap: IObjectMap = {
  FREQ: "frequency",
  SUCCESS_COUNT: "success_cnt",
  FAILURE_COUNT: "failure_cnt",
  ABANDONED_COUNT: "abandoned_cnt",
  TRANSFER_COUNT: "transfer_cnt",
  UNKNOWN_COUNT: "unknown_cnt",
  FREQ_PCT: "freq_pct",
  SUCCESS_PCT: "success_pct",
  FAILURE_PCT: "failure_pct",
  ABANDONED_PCT: "abandoned_pct",
  TRANSFER_PCT: "transfer_pct",
  UNKNOWN_PCT: "unknown_pct",
};

export const PCTSortMetricMap: IObjectMap = {
  FREQ: "freq_pct",
  SUCCESS_COUNT: "success_pct",
  FAILURE_COUNT: "failure_pct",
  ABANDONED_COUNT: "abandoned_pct",
  TRANSFER_COUNT: "transfer_pct",
  UNKNOWN_COUNT: "unknown_pct",
};

// eslint-disable-next-line
interface IConvoOutcomeObject {
  [key: string]: {
    label: string;
    color: string;
  };
}

export const ConvoOutcome: IConvoOutcomeObject = {
  SUCCESS: {
    label: "Resolved by bot",
    color: "#F4FDF8",
  },
  FAILURE: {
    label: "Abandoned",
    color: "#FCE8E4",
  },
  TRANSFERRED: {
    label: "Resolved by agent",
    color: "#FFFEF3",
  },
  ABANDONED: {
    label: "Abandoned",
    color: "#FCE8E4",
  },
  UNKNOWN: {
    label: "Unknown",
    color: "#F9F8F8",
  },
};

export const DEFAULT_FILTER = {
  DATE_RANGE: "730",
  SORT_METRIC: "FREQ",
};
