import type React from "react";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import useStyles from "./SelectConnectorsAction.styles";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as SuccessIcon } from "assets/check-circle-broken.svg";

import { type ConnectorActionsConfig } from "../../AgentActions.const";
import Button from "components/base/Button";
import { isEmpty, isNil, noop } from "lodash";
import clsx from "clsx";
import { getConnectorActions } from "pages/ActionBuilder/Service/ActionBuilder.service";
import Loading from "aether/Loading";
import ActionDetails from "./ActionDetails";
import { useDispatch, useSelector } from "store";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { type Connection } from "pages/Integrations/integrations.types";

interface TableRowProps {
  row: ConnectorActionsConfig;
  onSelect?: () => void;
  onCancel?: () => void;
  onPreview?: () => void;
  isSelectedAction?: boolean;
}

const ActionTableRow: React.FC<TableRowProps> = ({
  row,
  onSelect = noop,
  onCancel = noop,
  onPreview = noop,
  isSelectedAction = false,
}) => {
  const classes = useStyles();

  return (
    <TableRow
      className={clsx(classes.tableRow, {
        [classes.selectedTableRow]: isSelectedAction,
      })}
      key={row._id}
    >
      <TableCell>
        <PuzzleIcon
          style={{
            width: "24px",
            height: "24px",
          }}
        />
      </TableCell>
      <TableCell>
        <div>
          <div className={classes.rowTitle}>{row.name}</div>
          <div className={classes.rowDescription}>{row.description}</div>
        </div>
      </TableCell>
      <TableCell>
        {isSelectedAction ? (
          <div className={classes.actionsContainer}>
            <Button
              variant="text"
              color="secondary"
              size="x-small"
              className={classes.previewButton}
              onClick={onCancel}
            >
              Change
            </Button>
            <Button
              className={classes.successBtn}
              variant="contained"
              color="primary"
              size="x-small"
              iconBtn
              startIcon={<SuccessIcon />}
            >
              Selected
            </Button>
          </div>
        ) : (
          <div className={clsx("click-actions", classes.actionsContainer)}>
            <Button
              variant="text"
              color="secondary"
              size="x-small"
              className={classes.previewButton}
              onClick={onPreview}
            >
              Preview
            </Button>
            <Button
              className={classes.selectButton}
              variant="contained"
              color="primary"
              size="x-small"
              onClick={onSelect}
            >
              Select & configure
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

interface SelectConnectorActionsProps {
  selectedConnection: Connection;
}

const SelectConnectorActions: React.FC<SelectConnectorActionsProps> = ({
  selectedConnection,
}) => {
  const classes = useStyles();
  const [connectorActions, setConnectorActions] = useState<
    ConnectorActionsConfig[]
  >([]);
  const dispatch = useDispatch();
  const { selectedConfigurableAction } = useSelector(
    (state) => state.actionBuilder
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setConnectorActions([]);
    setLoading(true);
    if (selectedConnection?.connector_id) {
      getConnectorActions(selectedConnection?.connector_id)
        .then((res) => {
          setConnectorActions(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // whenever connector changes, need to clear the Store
    return () => {
      dispatch({
        type: ActionBuilderActions.CLEAR_SLICE,
      });
    };
  }, [selectedConnection?.connector_id]);

  const onActionSelection = (row: ConnectorActionsConfig): void => {
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION,
      payload: { ...row, connectionId: selectedConnection._id },
    });
    dispatch({
      type: ActionBuilderActions.CLEAR_PREVIEW_ACTION,
    });
  };

  const onChangeSelectedAction = (): void => {
    dispatch({
      type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_ACTION,
    });
    dispatch({
      type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM,
    });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.step}>Step 2/2</div>
        <div className={classes.title}>Select Action</div>
        {loading && <Loading />}
        {!isEmpty(connectorActions) && (
          <TableContainer
            classes={classes.tableContainer}
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--border-default, #E1DEDA)",
            }}
          >
            <Table classes={classes.table}>
              <TableBody classes={classes.tableBody}>
                {!isNil(selectedConfigurableAction) ? (
                  <ActionTableRow
                    row={selectedConfigurableAction}
                    onCancel={onChangeSelectedAction}
                    isSelectedAction
                  />
                ) : (
                  connectorActions.map((row: ConnectorActionsConfig) => {
                    return (
                      <ActionTableRow
                        key={row?._id}
                        row={row}
                        onSelect={() => {
                          onActionSelection(row);
                        }}
                        onPreview={() => {
                          dispatch({
                            type: ActionBuilderActions.SET_PREVIEW_ACTION,
                            payload: {
                              ...row,
                              connectionId: selectedConnection._id,
                            },
                          });
                        }}
                      />
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isNil(selectedConfigurableAction) && (
          <ActionDetails action={selectedConfigurableAction} />
        )}
      </div>
    </>
  );
};

export default SelectConnectorActions;
