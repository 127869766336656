import Workflow from "pages/Bot/WorkflowBuilder";

const routes = [
  {
    path: "workflow-builder",
    element: <Workflow />,
  },
];

export default routes;
